<mat-card class="mass-evaluation-card">
  <div class="flex-container">
    <app-mass-chart [chartRawData]="massChartDataSource" [chartWidth]="1500" [chartHeight]="500" [showLabels]="true"
      [showExport]="true" [showTitle]="true" [isDarkTheme]="isDarkTheme">
    </app-mass-chart>
    <div *ngIf="isScrollingEnabled && showTable" class="scroll-button">
      <button mat-raised-button color="primary" (click)="scrollToTable()">
        {{ 'dataEvaluation.evaluationType.scrollToTable' | translate }}
      </button>
    </div>
    <table mat-table [dataSource]="massChartDataSource.dataSource" class="mat-elevation-z8 table-wide" *ngIf="showTable">
      <ng-container matColumnDef="materialManifestationName">
        <th mat-header-cell *matHeaderCellDef id="header">
          {{ 'analysis.massAnalysis.tableColumns.materialManifestationName' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="cell">
          <div>{{ element.materialManifestationName }}</div>
        </td>
      </ng-container>
      <ng-container matColumnDef="weight">
        <th mat-header-cell *matHeaderCellDef id="header">
          {{ 'analysis.massAnalysis.tableColumns.massKg' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="cell">
          <div>{{ element.weight / 1000 | number: '1.0-2' }}</div>
        </td>
      </ng-container>
      <ng-container matColumnDef="weightPercentage">
        <th mat-header-cell *matHeaderCellDef id="header">
          {{ 'analysis.massAnalysis.tableColumns.weightPercentage' | translate }}
        </th>
        <td mat-cell *matCellDef="let element" class="cell">
          <div>{{ element.weightPercentage | percent: '1.0-2' }}</div>
        </td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumnsMassAnalysis"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumnsMassAnalysis"></tr>
    </table>
  </div>
</mat-card>
