<h1 mat-dialog-title>{{ 'dataManagement.userData.passwordDialog.title' | translate }} <strong>{{login}}</strong></h1>

<mat-dialog-content>
  <form [formGroup]="changePasswordForm">
    <div class="grid-container">
      <mat-card *ngIf="!isAdmin && userId !== null">
        <mat-card-content>
          <div class="left">
            <mat-form-field class="input-field">
              <mat-label class="label-large">{{ 'dataManagement.userData.columns.oldPassword' | translate }}</mat-label>
              <input matInput formControlName="oldPassword" [type]="showOldPassword ? 'text' : 'password'"
                [ngClass]="{ 'is-invalid': submitted && formControls.oldPassword.errors }" autocomplete="off">
            </mat-form-field>
            <button mat-icon-button matSuffix (click)="changeOldPasswordVisibility()" tabindex="-1" type="button">
              <mat-icon>{{ showOldPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
            </button>
            <mat-error *ngIf="submitted && formControls.oldPassword.hasError('required')">
              {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
            </mat-error>
          </div>
        </mat-card-content>
      </mat-card>

      <mat-card>
        <mat-card-content>
          <div class="left">
            <app-password-form formControlName="newPassword" [submitted]="submitted" [isAdmin]="isAdmin">
            </app-password-form>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button (click)="doAction()" mat-raised-button color="primary" type="submit">
    {{ 'common.buttons.edit' | translate }}
  </button>
  <button (click)="closeDialog()" mat-raised-button type="button">
    {{ 'common.buttons.cancel' | translate }}
  </button>
</mat-dialog-actions>
