<table mat-table [dataSource]="dataSource" id="packaging-units-table" matSort [matSortActive]="sortByColumnName ?? ''"
  matSortDirection="desc">

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.filterMenu"
          [matMenuTriggerData]="{ caller: 'id' }" type="button">
          {{ 'commonPackagingFields.id' | translate }}
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('id')">filter_alt</mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
  </ng-container>

  <ng-container matColumnDef="analysisId">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      {{ 'commonPackagingFields.id' | translate }}
    </th>
    <td mat-cell *matCellDef="let element"> {{getAnalysisIdForPackaging(element.id)}} </td>
  </ng-container>

  <ng-container matColumnDef="tracking">
    <th mat-header-cell *matHeaderCellDef [hidden]="!displayTrackedColumn"></th>
    <td mat-cell *matCellDef="let element" [hidden]="!displayTrackedColumn">
      <mat-icon *ngIf="element.hasExternalTracking">track_changes</mat-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="tags">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.tagFilterMenu"
          [matMenuTriggerData]="{ caller: 'associatedTagIdentifiers', possibleTags: getPossibleTags(dataSource) }"
          type="button">
          Tags
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('associatedTagIdentifiers')">filter_alt
          </mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <app-tag-directive [tags]="getTagsFromIds(element.associatedTagIdentifiers)" [maxDisplayed]="2">
      </app-tag-directive>
    </td>
  </ng-container>

  <ng-container matColumnDef="packagingTypeName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.checkboxFilterMenu"
          [matMenuTriggerData]="{ caller: 'packagingTypeName', categories: getPackagingUnitTypeNames() }" type="button">
          {{ 'packagingUnit.packagingUnitType' | translate }}
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('packagingTypeName')">filter_alt</mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <img [src]="getPackagingUnitTypeImage(element.packagingTypeId)" class="packaging-type-image"
        [matTooltip]="element.packagingTypeName">
    </td>
  </ng-container>

  <ng-container matColumnDef="brandName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.filterMenu"
          [matMenuTriggerData]="{ caller: 'brandName' }" type="button">
          {{ 'packagingUnit.fields.brandName' | translate }}
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('brandName')">filter_alt</mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element"> {{element.brandName}} </td>
  </ng-container>

  <ng-container matColumnDef="productName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.filterMenu"
          [matMenuTriggerData]="{ caller: 'productName' }" type="button">
          {{ 'packagingUnit.fields.productName' | translate }}
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('productName')">filter_alt</mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element"> {{element.productName}} </td>
  </ng-container>

  <ng-container matColumnDef="articleNumber">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.filterMenu"
          [matMenuTriggerData]="{ caller: 'articleNumber' }" type="button">
          {{ 'packagingUnit.fields.articleNumber' | translate }}
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('articleNumber')">filter_alt</mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element"> {{element.articleNumber}} </td>
  </ng-container>

  <ng-container matColumnDef="creationTimestamp">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.dateFilterMenu"
          [matMenuTriggerData]="{ caller: 'creationTimestamp' }" type="button">
          {{ 'commonPackagingFields.creationDate' | translate }}
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('creationTimestamp')">filter_alt
          </mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      {{formatDateToString(element.creationTimestamp)}}
    </td>
  </ng-container>

  <ng-container matColumnDef="lastModificationTimestamp">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.dateFilterMenu"
          [matMenuTriggerData]="{ caller: 'lastModificationTimestamp' }" type="button">
          {{ 'commonPackagingFields.lastModificationDate' | translate }}
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('lastModificationTimestamp')">filter_alt
          </mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      {{formatDateToString(element.lastModificationTimestamp)}}
    </td>
  </ng-container>

  <ng-container matColumnDef="quantityInput">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" type="button">
          {{ 'dataManagement.quantities.quantity'| translate }}
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element; let index = index">
      <mat-form-field id="quantity-form">
        <input matInput type="number" autocomplete="off" [formControl]="getFormControlForQuantity(element)"
          appStopNumberScrolling>
        <button mat-icon-button matSuffix *ngIf="getFormControlForQuantity(element).disabled"
          (click)="addCountryToPackaging(element)" id="add-country-button"
          matTooltip="{{ 'dataManagement.quantities.addDistrCountryTooltip' | translate }}">
          <mat-icon id="add-country-icon">add_location_alt</mat-icon>
        </button>
      </mat-form-field>
    </td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef>
      <span class="header-text">{{ 'dataManagement.actions' | translate }}</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <button *ngIf="!isRecyclingBinSelected && !isUserValidator; else viewPackaging" mat-icon-button
        (click)="navigateToPackagingUnit(element)" matTooltip="{{ 'packagingUnit.editPackagingUnit' | translate }}"
        type="button">
        <mat-icon>edit</mat-icon>
      </button>
      <ng-template #viewPackaging>
        <button *ngIf="!isRecyclingBinSelected" mat-icon-button (click)="navigateToPackagingUnit(element)"
          matTooltip="{{ 'packagingUnit.viewPackagingUnit' | translate }}" type="button">
          <mat-icon>preview</mat-icon>
        </button>
      </ng-template>
      <button mat-icon-button *ngIf="!isUserValidator" (click)="deletePackagingPart([element])"
        matTooltip="{{ 'packagingUnit.deletePackagingUnit' | translate }}" type="button">
        <mat-icon>delete_forever</mat-icon>
      </button>
      <span class="full-button-height" *ngIf="!isRecyclingBinSelected">
        <button mat-icon-button [matMenuTriggerFor]="analyses.analysesMenu" type="button"
          *ngIf="!isGenericPackaging(element.packagingTypeId); else disabledAnalysisButton"
          (click)="analyses.loadAnalysesForPackagingUnit()"
          matTooltip="{{ 'home.packagingUnitHome.evaluations' | translate }}">
          <mat-icon svgIcon="analysis-icon" class="green-icon"></mat-icon>
        </button>
        <ng-template #disabledAnalysisButton>
          <span class="full-button-height" matTooltip="{{ 'home.packagingUnitHome.evaluationsDisabled' | translate }}">
            <button mat-icon-button disabled>
              <mat-icon class="grey-icon" svgIcon="analysis-icon"></mat-icon>
            </button>
          </span>
        </ng-template>
      </span>
      <button mat-icon-button [matMenuTriggerFor]="reports.reportsMenu" (click)="reports.loadReports()"
        matTooltip="{{ 'report.tooltips.buttonTooltip' | translate }}"
        *ngIf="!isRecyclingBinSelected && !isUserValidator" type="button">
        <mat-icon>topic</mat-icon>
      </button>

      <app-analyses-menu-packaging-units #analyses [packagingUnitId]="element.id"
        [packagingUnitType]="element.packagingTypeId" [versionId]="element.version" [isUserValidator]="isUserValidator"
        [caller]="callerPageId" (validationData)="setValidationData($event)"
        [hasRecyclabilityResult]="element.hasRecyclabilityResult" [hasLifeCycleResult]="element.hasLifeCycleResult"
        [creationTimestamp]="element.creationTimestamp"
        [analysisWrappersRec]="getAnalysisWrappersRecForPackaging(element.id)"
        [analysisWrappersLca]="getAnalysisWrappersLcaForPackaging(element.id)">
      </app-analyses-menu-packaging-units>
      <app-reports-menu-for-packaging-part #reports [callerItemId]="element.id" [callerPage]="callerPageId"
        [callerLevel]="callerLevelId">
      </app-reports-menu-for-packaging-part>
    </td>
  </ng-container>

  <ng-container matColumnDef="compare">
    <th mat-header-cell *matHeaderCellDef>
      <span class="header-text">{{ 'packagingUnit.fields.compare' | translate }}</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <mat-checkbox [checked]="isPackagingUnitSelected(element)"
        (change)="setPackagingUnitSelected($event.checked, element)"></mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" (click)="selectAllPackagingUnitsButtonClick()" type="button">
          <div>{{ 'common.buttons.selectAll' | translate }} <br> ({{selectedPackagingUnits.length}}
            {{ 'common.text.selected' | translate }})</div>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <mat-checkbox [checked]="isPackagingUnitSelected(element)"
        (change)="setPackagingUnitSelected($event.checked, element)">
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="validation">
    <th mat-header-cell *matHeaderCellDef>
      <span class="header-text">{{ 'packagingUnit.validationStatus' | translate }}</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <mat-icon *ngIf="isFullyValidatedAllValid(element.id)" class="validation-green">check</mat-icon>
      <mat-icon *ngIf="isFullyValidatedNotAllValid(element.id)" class="validation-red">clear</mat-icon>
      <mat-icon *ngIf="isNotFullyValidated(element.id) && isTouched(element.id)" class="validation-blue">auto_fix_high
      </mat-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="organizationId">
    <th mat-header-cell *matHeaderCellDef>
      <span class="header-text">{{ 'packagingUnit.organization' | translate }}</span>
    </th>
    <td mat-cell *matCellDef="let element"> {{ getOrganizationName(element.organizationId) }} </td>
  </ng-container>

  <ng-container matColumnDef="directoryName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.checkboxFilterMenu"
          [matMenuTriggerData]="{ caller: 'directoryName', categories: getDirectoryNames() }" type="button">
          {{ 'packagingUnit.fields.directoryName' | translate }}
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('directoryName')">filter_alt</mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element"> {{element.directoryName}} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  <app-filter-menu #filterComponent="columnFilterComponent" [dataSource]="dataSource"></app-filter-menu>
</table>
