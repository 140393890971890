<div class="buttons-container">
  <div class="total"> {{ getTitleString() }} </div>
  <button class="button-margin" mat-raised-button color="primary" type="button"
  (click)="invertBuyText()">
    <mat-icon>shopping_cart</mat-icon> {{
    'shop.buy' | translate }}
  </button>
  <div class="info-box" *ngIf="showBuyText">
    <p> {{this.getInfoString()}}</p>
    <p><strong>{{'shop.emailWorkaround.to' | translate}}</strong> <a [href] = this.getEmailString()>{{'shop.emailWorkaround.email' | translate}}</a>
    <p><strong>{{'shop.emailWorkaround.subject' | translate}}</strong> {{getSubjectContent()}}</p>
    <p class="newline-text"><strong>{{'shop.emailWorkaround.contentTitle' | translate}}</strong> {{this.getEmailStringForBrowser()}} </p>
  </div>
</div>
