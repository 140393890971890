import { TranslateService } from '@ngx-translate/core';
import { AggregateEvaluationRecyclabilityOutputDto } from './../../../data-transfer/entities/aggregate-evaluation-recyclability-output-dto';
import { RecyclabilityDataSource } from '../../../model/evaluations/recyclability-result';
import { RecyclabilityLabelData } from './recyclability-label-data';

export class RecyclabilityAggregatedEvaluationResult {

  analysisId = ''; // TODO only exists bcs neened in the new comparison implementation. Maybe in new aggr. eval as well
  dataSource: RecyclabilityDataSource[];
  tableDataSource: any[];
  recyclableTotal = 0;
  nonRecyclableTotal = 0;

  constructor(
    private recOutput: AggregateEvaluationRecyclabilityOutputDto,
    private translateService: TranslateService
  ) {
    this.dataSource = this.getDataSource();
    this.tableDataSource = this.getTableDataSource();
    const recValueA = this.dataSource.find(rec => rec.label === 'A')?.value ?? 0;
    const recValueB = this.dataSource.find(rec => rec.label === 'B')?.value ?? 0;
    this.recyclableTotal = +(recValueA + recValueB).toFixed(2);
    this.nonRecyclableTotal = +(100 - this.recyclableTotal).toFixed(2);
  }

  private getDataSource(): RecyclabilityDataSource[] {
    const chartDataSource: RecyclabilityDataSource[] = [];

    const labelData = new RecyclabilityLabelData(this.translateService);
    const sortedRatings = this.recOutput.results.sort((a, b) => a.rating - b.rating);
    for (const ratingData of sortedRatings) {
      chartDataSource.push({
        name: labelData.getLabelFromNumericKey(ratingData.rating) ?? '',
        label: labelData.getKeyFromNumericKey(ratingData.rating) ?? '',
        value: +(ratingData.weightPercentage * 100).toFixed(2),
        components: []
      });
    }
    return chartDataSource;
  }

  private getTableDataSource(): any[] {
    const tableDataSource = [];

    const labelData = new RecyclabilityLabelData(this.translateService);
    const sortedRatings = this.recOutput.materialResults.sort((a, b) => b.weightPercentage - a.weightPercentage);
    for (const ratingData of sortedRatings) {
      tableDataSource.push({
        material: ratingData.materialManifestationName,
        weight: ratingData.weight,
        rating: labelData.getLabelFromNumericKey(ratingData.rating),
        label: labelData.getKeyFromNumericKey(ratingData.rating),
        weightPercentage: ratingData.weightPercentage,
        components: []
      });
    }
    return tableDataSource;
  }
}
