import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { PathResolveService } from 'src/app/services/path-resolve.service';

@Component({
  selector: 'app-evaluations-menu',
  templateUrl: './evaluations-menu.component.html',
  styleUrls: ['./evaluations-menu.component.scss']
})
export class EvaluationsMenuComponent {

  constructor(
    private router: Router
  ) { }

  clickPerformEvaluations() {
    this.router.navigate([PathResolveService.getPerformEvaluationPath(true)]);
  }

  clickViewEvaluations() {
    this.router.navigate([PathResolveService.getViewEvaluationPath(true)]);
  }
}
