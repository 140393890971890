import { ResetPasswordDto } from '../entities/reset-password-dto';
import { CreateUserDto } from '../entities/create-user-dto';
import { ChangePasswordDto } from '../entities/change-password-dto';
import { UserDto } from '../entities/user-dto';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ParentApiService } from './parent-api-service';
import { OrganizationDto } from '../entities/organization-dto';
import { CreateOrganizationDto } from '../entities/create-organization-dto';
import { UserGroupDto } from '../entities/user-group-dto';
import { environment } from 'src/environments/environment';
import { OrganizationsVisibilityDto } from '../entities/organizations-visibility-dto';
import { BillingAccountDto } from '../entities/billing-account-dto';
import { CreditsDto } from '../entities/credits-dto';
import { OrganizationSettingsDto } from '../entities/organization-settings-dto';
import { CountryAuthorizationDto } from '../entities/country-authorization-dto';
import { AllowedClientsDto } from '../entities/allowed-clients-dto';
import { EnvironmentalEffectAuthorizationDto } from '../entities/environmental-effect-authorization-dto';
import { ClientDto } from '../entities/client-dto';

@Injectable({ providedIn: 'root' })
export class UserApiService extends ParentApiService {

    constructor(private http: HttpClient) {
        super();
    }

    public getUsers(): Observable<UserDto[]> {
        const url = `${environment.webApiAddress}/api/users`;
        return this.http.get<UserDto[]>(url);
    }

    public changePassword(passwordObj: ChangePasswordDto, stayOnPageIfError?: boolean): Observable<number> {
        const url = `${environment.webApiAddress}/api/account/change-password`;
        return this.http.put<number>(url, passwordObj, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
    }

    public resetPassword(passwordObj: ResetPasswordDto, stayOnPageIfError?: boolean): Observable<number> {
        const url = `${environment.webApiAddress}/api/users/reset-password`;
        return this.http.put<number>(url, passwordObj, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
    }

    public createUser(user: CreateUserDto, stayOnPageIfError?: boolean): Observable<number> {
        const url = `${environment.webApiAddress}/api/users`;
        return this.http.post<number>(url, user, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
    }

    public deleteUser(userIdToDelete: number, stayOnPageIfError?: boolean): Observable<any> {
        const url = `${environment.webApiAddress}/api/users?id=${userIdToDelete}`;
        return this.http.delete(url, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
    }

    public restoreUser(userIdToRestore: number, stayOnPageIfError?: boolean): Observable<any> {
        const url = `${environment.webApiAddress}/api/users/restore?id=${userIdToRestore}`;
        return this.http.put(url, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
    }

    public setPremiumUser(userId: number, isPremiumUser: boolean, stayOnPageIfError?: boolean): Observable<any> {
        const url = `${environment.webApiAddress}/api/users/premium-user?id=${userId}&isPremiumUser=${isPremiumUser}`;
        return this.http.put(url, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
    }

    public getOrganizations(): Observable<OrganizationDto[]> {
        const url = `${environment.webApiAddress}/api/organizations`;
        return this.http.get<OrganizationDto[]>(url);
    }

    public createOrganization(organization: CreateOrganizationDto, stayOnPageIfError?: boolean): Observable<any> {
        const url = `${environment.webApiAddress}/api/organizations`;
        return this.http.post<any>(url, organization, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
    }

    public updateOrganizationSettings(settings: OrganizationSettingsDto, stayOnPageIfError?: boolean): Observable<any> {
      const url = `${environment.webApiAddress}/api/organizations/update-settings`;
      return this.http.put<any>(url, settings, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

    public deleteOrganization(organizationIdToDelete: number, stayOnPageIfError?: boolean): Observable<any> {
        const url = `${environment.webApiAddress}/api/organizations?id=${organizationIdToDelete}`;
        return this.http.delete<any>(url, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
    }

    public restoreOrganization(organizationIdToRestore: number, stayOnPageIfError?: boolean): Observable<any> {
        const url = `${environment.webApiAddress}/api/organizations/restore?id=${organizationIdToRestore}`;
        return this.http.put(url, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
    }

    public getAllUserGroups(): Observable<UserGroupDto[]> {
        const url = `${environment.webApiAddress}/api/user-groups`;
        return this.http.get<UserGroupDto[]>(url);
    }

    public setOrganizationVisibility(visibilities: OrganizationsVisibilityDto, stayOnPageIfError?: boolean): Observable<number> {
        const url = `${environment.webApiAddress}/api/organizations/set-visibility`;
        return this.http.put<number>(url, visibilities, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
    }

    public getOrganizationVisibility(organizationId: number): Observable<OrganizationDto[]> {
        const url = `${environment.webApiAddress}/api/organizations/visibilities?id=${organizationId}`;
        return this.http.get<OrganizationDto[]>(url);
    }

    public setAllowedClients(allowedClients: AllowedClientsDto, stayOnPageIfError?: boolean): Observable<number> {
        const url = `${environment.webApiAddress}/api/organizations/set-allowed-clients`;
        return this.http.put<number>(url, allowedClients, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
    }

    public getAllowedClients(organizationId: number): Observable<ClientDto[]> {
        const url = `${environment.webApiAddress}/api/organizations/allowed-clients?id=${organizationId}`;
        return this.http.get<ClientDto[]>(url);
    }

    public setCountryAuthorizations(billingAccountId: number,
                                    authorizations: CountryAuthorizationDto[],
                                    stayOnPageIfError?: boolean): Observable<number> {
        const url = `${environment.webApiAddress}/api/billingaccounts/set-country-authorizations?billingAccountId=${billingAccountId}`;
        return this.http.put<number>(url, authorizations, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
    }

    public getCountryAuthorizations(billingAccountId: number): Observable<CountryAuthorizationDto[]> {
        const url = `${environment.webApiAddress}/api/billingaccounts/country-authorizations?billingAccountId=${billingAccountId}`;
        return this.http.get<CountryAuthorizationDto[]>(url);
    }

    public setEnvironmantalEffectsAuthorizations(billingAccountId: number,
                                                 authorizations: EnvironmentalEffectAuthorizationDto[],
                                                 stayOnPageIfError?: boolean): Observable<number> {
        const route = "/api/billingaccounts/set-environmental-effects-authorizations";
        const url = `${environment.webApiAddress}${route}?billingAccountId=${billingAccountId}`;
        return this.http.put<number>(url, authorizations, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
    }

    public getEnvironmentalEffectsAuthorizations(billingAccountId: number): Observable<EnvironmentalEffectAuthorizationDto[]> {
        const route = "/api/billingaccounts/environmental-effects-authorizations";
        const url = `${environment.webApiAddress}${route}?billingAccountId=${billingAccountId}`;
        return this.http.get<EnvironmentalEffectAuthorizationDto[]>(url);
    }

    public getBillingAccounts(): Observable<BillingAccountDto[]> {
        const url = `${environment.webApiAddress}/api/billingaccounts`;
        return this.http.get<BillingAccountDto[]>(url);
    }

    public setBillingAccountCredits(accountId: number, credits: CreditsDto, stayOnPageIfError?: boolean): Observable<any> {
        const url = `${environment.webApiAddress}/api/billingaccounts/increase-credits?id=${accountId}`;
        return this.http.put<any>(url, credits, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
    }
}
