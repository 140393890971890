import { HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { of, throwError } from 'rxjs';

export class ParentApiService {
  stayOnPageIfErrorHeader: HttpHeaders; // if true, stay on page instead of going to error page
  emptyHeader: HttpHeaders;
  acceptEnglishHeader: HttpHeaders;

  constructor() {
    this.stayOnPageIfErrorHeader = new HttpHeaders({ stayOnPageIfError: 'true' });
    this.emptyHeader = new HttpHeaders();
    this.acceptEnglishHeader = new HttpHeaders({ 'Accept-Language': 'en' });
  }

  getApiStringFromComponentTypeName(componentTypeName: string): string {
    switch (componentTypeName.toLowerCase()) {
      case 'closure':
        return '/api/closures';
      case 'inlay':
        return '/api/inlays';
      case 'in-mold label':
        return '/api/in-mold-labels';
      case 'inmoldlabel':
        return '/api/in-mold-labels';
      case 'label':
        return '/api/labels';
      case 'banderole':
        return '/api/labels';
      case 'packagingaid':
        return '/api/packaging-aids';
      case 'packaging aid':
        return '/api/packaging-aids';
      case 'sleeve':
        return '/api/sleeves';
      case 'wrap':
        return '/api/sleeves';
      case 'mainbody':
        return '/api/packaging-main-bodies';
      default:
        return '';
    }
  }

  protected handleError(error: HttpErrorResponse) {
    if (error.status === 0) {
      console.error('An error occurred:', error.error);
    } else if (error.status === 404) {
      console.error(`Backend returned code ${error.status}, body was: `, error.error);
      return of(null)
    } else {
      console.error(`Backend returned code ${error.status}, body was: `, error.error);
    }
    return throwError(() => new Error('Something bad happened; please try again later.'));
  }
}
