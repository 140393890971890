<app-packaging-unit-tree [packagingUnit]="packagingUnit" [isDarkTheme]="isDarkTheme"
  (nodeSelected)="onNodeSelected($event)">
</app-packaging-unit-tree>

<div class="total-values-container">
  <span class="label-large">{{ 'analysis.recyclabilityAnalysis.recyclableTotal' | translate }}</span>
  <span class="label-large green">{{dataSourceForCountry.recyclableTotal}}%</span>
  <span class="label-large">{{ 'analysis.recyclabilityAnalysis.nonRecyclableTotal' | translate }}</span>
  <span class="label-large red">{{dataSourceForCountry.nonRecyclableTotal}}%</span>
</div>
<div class="inner-container">
  <div class="chart-container">
    <div class="loaded-chart-container">
      <app-recyclability-chart [chartRawData]="dataSourceForCountry" [chartHeight]="600" [showLabels]="true"
        [showExport]="true" [minimizeLegend]="false" [showTitle]="true" [isDarkTheme]="isDarkTheme" [chartWidth]="1500">
      </app-recyclability-chart>
    </div>
  </div>

  <div *ngIf="selectedNodeType === packagingPart.Unit">
    <app-packaging-unit-components-table [dataSource]="dataSourceForCountryAsUnit" [recyclingStreams]="recyclingStreams">
    </app-packaging-unit-components-table>
  </div>

  <div *ngIf="selectedNodeType === packagingPart.Component">
    <app-component-materials-table [dataSource]="dataSourceForCountryAsComponent" [recyclingStreams]="recyclingStreams">
    </app-component-materials-table>
  </div>

  <app-packaging-unit-ratings-table [dataSource]="dataSourceForCountryAsUnit">
  </app-packaging-unit-ratings-table>
</div>
