<app-component-html-template [packagingComponent]="mainBody" [form]="form" [componentSubtypes]="componentSubtypes"
  [callerId]="callerId" [packagingUnitTypeId]="mainBody.packagingComponentSubtypeId" [isPreview]="isPreview"
  [isTracked]="isTracked" [isFormLocked]="isFormLocked" [historyVersions]="historyVersions"
  [spinnerActive]="spinnerActive" [canEditForm]="canEditForm" [isUserValidator]="isUserValidator"
  [isEditPermitted]="isEditPermitted" [imageSource]="imageSource" [isMainBody]="true"
  [componentTypeSelectionLabel]="'component.fields.ofMainBody' | translate"
  [changedMaterialsInfo]="changedMaterialsInfo" (submitted)="onComponentSubmit($event)"
  (historicVersionClicked)="loadHistoryVersions(mainBody.id)" (historicVersionChanged)="onHistoryVersionChange($event)"
  (versionRenamed)="changeMainBodyVersionName($event)" (materialChangeHandled)="handleMaterialChange($event)">

  <h1 header>{{ 'component.mainBody' | translate }}</h1>

  <div image>
    <app-image-upload [canEditForm]="canEditForm" [images]="mainBody.images" (filesEdited)="onFilesEdited()">
    </app-image-upload>
  </div>

  <div pdf>
    <app-pdf-upload [canEditForm]="canEditForm" [pdfs]="mainBody.documents" (filesEdited)="onFilesEdited()">
    </app-pdf-upload>
  </div>

</app-component-html-template>