import { RatingsHandler } from 'src/app/util/analyses-util/ratings-handler';
import { CountriesService } from './../../../../navigation/services/countries-service';
import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import * as Highcharts from 'highcharts/highmaps';
import Exporting from 'highcharts/modules/exporting';
Exporting(Highcharts);
import ExportData from 'highcharts/modules/export-data';
import {
  MAP_DARK_THEME_DEFAULT, MAP_LIGHT_THEME_DEFAULT, MAP_DARK_THEME_OUTLINE,
  MAP_LIGHT_THEME_OUTLINE, DARK_THEME_FONT, LIGHT_THEME_FONT, DARK_THEME_BACKGROUND,
  LIGHT_THEME_BACKGROUND, DARK_THEME_BORDER, LIGHT_THEME_BORDER
} from 'src/app/model/charts-color-collection';
ExportData(Highcharts);
import Accessibility from 'highcharts/modules/accessibility';
import { RecyclabilityResult } from 'src/app/model/evaluations/recyclability-result';
Accessibility(Highcharts);

const worldMap = require('@highcharts/map-collection/custom/europe.geo.json');

@Component({
  selector: 'app-map-recyclability-chart',
  templateUrl: './map-recyclability-chart.component.html',
  styleUrls: ['./map-recyclability-chart.component.scss']
})
export class MapRecyclabilityChartComponent implements OnInit, OnChanges {

  @Input() chartDataSource: RecyclabilityResult[] = [];
  @Input() isDarkTheme = false;
  @Output() countryClicked = new EventEmitter();

  updateFlag = false;
  highcharts = Highcharts;
  chartConstructor = 'mapChart';
  chartOptions: Highcharts.Options = {
    chart: {
      map: worldMap,
      width: 1500,
      height: 1000,
      style: {
        fontFamily: 'Helsinki, sans-serif',
        fontWeight: 'normal'
      }
    },
    title: {},
    plotOptions: {
      series: {
        point: {
          events: {}
        }
      }
    },
    mapNavigation: {
      enabled: true,
      buttonOptions: {
        align: 'right',
        verticalAlign: 'top',
        theme: {},
        style: {}
      }
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: ['viewFullscreen', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF'],
        }
      }
    },
    legend: {
      layout: 'vertical',
      align: 'left',
      verticalAlign: 'middle',
      itemMarginBottom: 20,
      symbolHeight: 20,
    },
    credits: {
      enabled: false
    },
    tooltip: {
      distance: 10
    },
    lang: {
      viewFullscreen: this.translateService.instant('analysis.recyclabilityAnalysis.fullscreen'),
      exitFullscreen: this.translateService.instant('analysis.recyclabilityAnalysis.exitFullscreen'),
      downloadJPEG: this.translateService.instant('analysis.recyclabilityAnalysis.downloadJpeg'),
      downloadPNG: this.translateService.instant('analysis.recyclabilityAnalysis.downloadPng'),
      downloadPDF: this.translateService.instant('analysis.recyclabilityAnalysis.downloadPdf'),
    },
    navigation: {
      buttonOptions: {
        width: 28,
        height: 28,
        symbolX: 14,
        symbolY: 14,
        theme: {}
      },
      menuStyle: {},
      menuItemStyle: {},
      menuItemHoverStyle: {}
    }
  };

  constructor(
    private translateService: TranslateService,
    private countriesService: CountriesService,
    private ratingsHandler: RatingsHandler
  ) { }

  ngOnInit(): void {
    this.setOriginDependentChartOptions();
    this.setChartSeries();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isDarkTheme && changes.isDarkTheme.previousValue !== undefined &&
      changes.isDarkTheme.currentValue !== changes.isDarkTheme.previousValue) {
      this.setOriginDependentChartOptions();
      this.setChartSeries();
      this.updateFlag = true;
    }
    if(changes.chartDataSource && changes.chartDataSource.currentValue !== undefined) {
      this.setOriginDependentChartOptions();
      this.setChartSeries();
      this.updateFlag = true;
    }
  }

  private setOriginDependentChartOptions() {
    if (this.chartOptions.title) {
      this.chartOptions.title.text = this.translateService.instant('analysis.recyclabilityAnalysis.overviewTitle');
      this.chartOptions.title.style = {
        fontSize: '30px',
        'font-family': '\'Helsinki\', sans-serif',
        color: this.isDarkTheme ? DARK_THEME_FONT : LIGHT_THEME_FONT
      };
    }
    if (this.chartOptions.chart) {
      this.chartOptions.chart.backgroundColor = this.isDarkTheme ? DARK_THEME_BACKGROUND : LIGHT_THEME_BACKGROUND;
    }
    if (this.chartOptions.navigation) {
      if (this.chartOptions.navigation.buttonOptions && this.chartOptions.navigation.buttonOptions.theme) {
        this.chartOptions.navigation.buttonOptions.theme.fill = this.isDarkTheme ? DARK_THEME_BACKGROUND : LIGHT_THEME_BACKGROUND;
        this.chartOptions.navigation.buttonOptions.theme.stroke = this.isDarkTheme ? DARK_THEME_BORDER : LIGHT_THEME_BORDER;
      }
      if (this.chartOptions.navigation.menuStyle) {
        this.chartOptions.navigation.menuStyle.backgroundColor = this.isDarkTheme ? DARK_THEME_BACKGROUND : LIGHT_THEME_BACKGROUND;
      }
      if (this.chartOptions.navigation.menuItemStyle) {
        this.chartOptions.navigation.menuItemStyle.color = this.isDarkTheme ? DARK_THEME_FONT : LIGHT_THEME_FONT;
      }
      if (this.chartOptions.navigation.menuItemHoverStyle) {
        this.chartOptions.navigation.menuItemHoverStyle.backgroundColor = this.isDarkTheme ? DARK_THEME_BORDER : LIGHT_THEME_BORDER;
      }
    }
    if (this.chartOptions.mapNavigation && this.chartOptions.mapNavigation.buttonOptions) {
      if (this.chartOptions.mapNavigation.buttonOptions.theme) {
        this.chartOptions.mapNavigation.buttonOptions.theme.fill = this.isDarkTheme ? DARK_THEME_BACKGROUND : LIGHT_THEME_BACKGROUND;
        this.chartOptions.mapNavigation.buttonOptions.theme.stroke = this.isDarkTheme ? DARK_THEME_BORDER : LIGHT_THEME_BORDER;
      }
      if (this.chartOptions.mapNavigation.buttonOptions.style) {
        this.chartOptions.mapNavigation.buttonOptions.style.color = this.isDarkTheme ? DARK_THEME_BORDER : LIGHT_THEME_BORDER;
      }
    }
    if (this.chartOptions.legend) {
      this.chartOptions.legend.itemStyle = {
        'font-family': '\'Helsinki\', sans-serif',
        fontSize: '15px',
        color: this.isDarkTheme ? DARK_THEME_FONT : LIGHT_THEME_FONT
      };
    }
    if (this.chartOptions.plotOptions && this.chartOptions.plotOptions.series && this.chartOptions.plotOptions.series.point) {
      // eslint-disable-next-line @typescript-eslint/no-this-alias
      const parentScope = this;
      this.chartOptions.plotOptions.series.point.events = {
        click() {
          parentScope.countryClicked.emit(parentScope.countriesService.translateCountryName(this.name));
        }
      };
    }
  }

  private setChartSeries() {
    const chartData: any[] = [];
    for (const country of this.chartDataSource) {
      let countryCode = country.evaluatedCountryCode;
      if (countryCode === 'UK') { countryCode = 'GB'; }
      if (countryCode) {
        chartData.push([countryCode.toLowerCase(), country.recyclableTotal]);
      }
    }
    this.chartOptions.colorAxis = {
      dataClasses: [
        {
          from: 95,
          to: 100,
          name: this.translateService.instant('analysis.recyclabilityAnalysis.gradeA'),
          color: this.ratingsHandler.getRatingColorRecyclabilityMap('A')
        }, {
          from: 80,
          to: 95,
          name: this.translateService.instant('analysis.recyclabilityAnalysis.gradeB'),
          color: this.ratingsHandler.getRatingColorRecyclabilityMap('B')
        }, {
          from: 70,
          to: 80,
          name: this.translateService.instant('analysis.recyclabilityAnalysis.gradeC'),
          color: this.ratingsHandler.getRatingColorRecyclabilityMap('C')
        }, {
          from: 0,
          to: 70,
          name: this.translateService.instant('analysis.recyclabilityAnalysis.gradeNotRecyclable'),
          color: this.ratingsHandler.getRatingColorRecyclabilityMap('NotRecyclable')
        }]
    };
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const parentScope = this;
    this.chartOptions.series = [{
      type: 'map',
      name: 'Recyclability',
      dataLabels: {
        enabled: true,
        formatter() {
          return parentScope.countriesService.translateCountryName(this.point.name);
        },
        style: {
          fontFamily: 'Helsinki, sans-serif',
          fontWeight: '400'
        }
      },
      allAreas: true,
      data: chartData,
      nullColor: this.isDarkTheme ? MAP_DARK_THEME_DEFAULT : MAP_LIGHT_THEME_DEFAULT,
      borderColor: this.isDarkTheme ? MAP_DARK_THEME_OUTLINE : MAP_LIGHT_THEME_OUTLINE
    }];
    this.chartOptions.tooltip = {
      formatter() {
        return `${parentScope.countriesService.translateCountryName(this.point.name)}: <b>${this.point.value}%</b><br/>`;
      }
    };
  }
}
