import { OrganizationDto } from 'src/app/data-transfer/entities/organization-dto';
import { Component, Inject, LOCALE_ID } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogActions } from 'src/app/model/dictionary';

@Component({
  selector: 'app-organizations-visibility-dialog',
  templateUrl: './organizations-visibility-dialog.component.html',
  styleUrls: ['./organizations-visibility-dialog.component.scss']
})
export class OrganizationsVisibilityDialogComponent {

  allOrganizations: OrganizationDto[] = [];
  targetOrganization: OrganizationDto;

  selectedOrganizationsIds: number[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    @Inject(LOCALE_ID) public locale: string,
    private dialogRef: MatDialogRef<OrganizationsVisibilityDialogComponent>
  ) {
    const dataOrganizations: OrganizationDto[] = this.data.allOrganizations;
    this.allOrganizations = dataOrganizations.filter(x => x.id !== this.data.targetOrganization.id);
    this.allOrganizations =
      this.allOrganizations.sort((a, b) => a.name.toLocaleLowerCase(this.locale).localeCompare(b.name.toLocaleLowerCase(this.locale)));
    this.targetOrganization = this.data.targetOrganization;
    const selectedOrganizations: OrganizationDto[] = this.data.selectedOrganizations;
    this.selectedOrganizationsIds = selectedOrganizations.map(x => x.id);
  }

  selectAll() {
    this.selectedOrganizationsIds = this.allOrganizations.map(x => x.id);
  }

  selectNone() {
    this.selectedOrganizationsIds = [];
  }

  acceptSelection() {
    const selectedOrganizations = this.allOrganizations.filter(org => this.selectedOrganizationsIds.includes(org.id));
    this.dialogRef.close({ event: DialogActions.CONFIRM, data: selectedOrganizations });
  }

  discardSelection() {
    this.dialogRef.close({ event: DialogActions.REJECT });
  }
}
