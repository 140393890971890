import { TagDto } from './../../data-transfer/entities/tag-dto';
import { HttpErrorResponse } from '@angular/common/http';
import { MainBodyComponent } from './../../model/components/main-body-component';
import { DictionaryHandler } from './../../model/dictionary';
import { DirectoryDto } from './../../data-transfer/entities/directory-dto';
import { InlayComponent } from '../../model/components/inlay-component';
import { SimpleAlertDialogComponent, SimpleDialogData } from '../../components/dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { MaterialManifestationDto } from '../../data-transfer/entities/material-manifestation-dto';
import { PackagingAidComponent } from 'src/app/model/components/packaging-aid-component';
import { DecorationComponent } from '../../model/components/decoration-component';
import { ClosureComponent } from '../../model/components/closure-component';
import { TranslateService } from '@ngx-translate/core';
import { CountryDto } from '../../data-transfer/entities/country-dto';
import { Injectable } from '@angular/core';
import { MaterialDataHandler } from '../material-services/material-data-handler';
import { PackagingComponentPrototype } from '../../model/components/packaging-component-prototype';
import { ColorDto } from '../../data-transfer/entities/color-dto';
import { MaterialRoutingData } from 'src/app/components/packaging-component-material/create-update/common/material-routing-wrapper/material-routing-wrapper.component';
import { ComponentRoutingData } from 'src/app/components/packaging-component/create-update/common/component-routing-wrapper/component-routing-wrapper.component';
import { MatDialog } from '@angular/material/dialog';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { PackagingCategoryMaterialFunctionDto } from 'src/app/data-transfer/entities/material-function-dto';
import { CountriesService } from 'src/app/navigation/services/countries-service';
import { ComponentSubtypeDto } from 'src/app/data-transfer/entities/component-subtype-dto';
import { ComponentTypeService } from 'src/app/navigation/services/component-type-service';
import { PackagingComponentTypesEnum } from 'src/app/model/packaging-component-types-enum';

@Injectable({ providedIn: 'root' })
export class ComponentHandler {

  components!: { [key: number]: PackagingComponentPrototype };

  allColors!: ColorDto[];
  materialFunctions!: PackagingCategoryMaterialFunctionDto[];
  manufacturingCountries!: CountryDto[];
  distributionCountries!: CountryDto[];
  allManifestations!: MaterialManifestationDto[];
  rootDirectory!: DirectoryDto;
  allTags!: TagDto[];

  constructor(
    private materialDataHandler: MaterialDataHandler,
    private dictionaryHandler: DictionaryHandler,
    public translateService: TranslateService
  ) { }

  setComponentsData(data: ComponentRoutingData, countriesService: CountriesService, componentTypeService: ComponentTypeService) {
    this.components = {
      [PackagingComponentTypesEnum.MainBody]:
        new MainBodyComponent(this.dictionaryHandler, data.mainBodySubtypes ?? [], countriesService),
      [PackagingComponentTypesEnum.Closure]:
        new ClosureComponent(this.dictionaryHandler, data.closureSubtypes ?? [], countriesService),
      [PackagingComponentTypesEnum.Decoration]:
        new DecorationComponent(this.dictionaryHandler, data.decorationSubtypes ?? [], data.removabilityConditions ?? [],
          countriesService, componentTypeService),
      [PackagingComponentTypesEnum.Inlay]:
        new InlayComponent(this.dictionaryHandler, data.inlaySubtypes ?? [], data.removabilityConditions ?? [],
          countriesService),
      [PackagingComponentTypesEnum.PackagingAid]:
        new PackagingAidComponent(this.dictionaryHandler, data.packagingAidSubtypes ?? [], countriesService)
    };
  }

  setMaterialData(data: MaterialRoutingData) {
    this.materialFunctions = data.materialFunctions ?? [];
    this.manufacturingCountries = data.manufacturingCountries ?? [];
    this.distributionCountries = data.distributionCountries ?? [];
    this.allManifestations = data.allManifestations ?? [];
    this.allColors = data.allColors ?? [];
    this.rootDirectory = data.rootDirectory;
    this.allTags = data.allTags;
  }

  private translation = (text: string, params?: any) => this.translateService.instant(text, params);

  multiplyWithHundred(value: number): number {
    return +(value * 100).toFixed(2);
  }

  getComponentSucessDialogData(): SimpleDialogData {
    return {
      title: this.translation('common.text.saved'),
      messages: [this.translation('component.messages.componentSaved')], icon: 'save'
    };
  }

  // Same as in packaging handler
  getFailureDialogData(error: HttpErrorResponse): SimpleDialogData {
    return {
      title: this.translation(error.status >= 500 ? 'errors.serverCommunicationError' : 'common.text.error'),
      messages: [this.translation('packagingUnit.errors.packagingUnitNotSaved'), error?.error?.error], icon: 'error'
    };
  }

  getInvalidDialogData(): SimpleDialogData {
    return {
      title: this.translation('common.text.error'),
      messages: [this.translation('errors.notAllFieldsFilled')], icon: 'error'
    };
  }

  showLockedDialog(dialog: MatDialog) {
    const alertDialogConfig = getDialogConfig(this.getLockedDialogData(), '350px');
    dialog.open(SimpleAlertDialogComponent, alertDialogConfig);
  }

  private getLockedDialogData(): SimpleDialogData {
    return {
      title: this.translation('common.text.locked'),
      messages: [this.translation('packagingUnit.messages.componentLocked')],
      icon: 'desktop_access_disabled'
    };
  }

  getCreateNewVersionDialogData(): SimpleDialogData {
    return {
      title: this.translation('common.text.information'),
      messages: [this.translation('messages.createNewVersion')],
      icon: 'info'
    };
  }

  getOverwriteConfirmationDialogData(): SimpleDialogData {
    return {
      title: this.translation('common.text.information'),
      messages: [
        this.translation('messages.changesOnOverwriteComponent'),
        this.translation('messages.deleteAnalysesOnOverwrite')
      ],
      icon: 'info'
    };
  }

  getMaterialFunctions(packagingTypeId?: number, componentTypeId?: number) {
    return this.materialDataHandler.getMaterialFunctions(this.materialFunctions, packagingTypeId, componentTypeId);
  }

  getComponentSubtypesSelection(componentType: number): ComponentSubtypeDto[] {
    return this.components[componentType].getComponentSubtypes();
  }
}
