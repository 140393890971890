import { MaterialManifestationDto } from './../../data-transfer/entities/material-manifestation-dto';
import { FormGroup } from '@angular/forms';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SimpleDialogData } from 'src/app/components/dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { DeleteDialogData } from 'src/app/components/dialogs/delete-item-dialog/delete-item-dialog.component';
import { PackagingCategoryMaterialFunctionDto } from 'src/app/data-transfer/entities/material-function-dto';
import { MultiMaterialLayerDto } from 'src/app/data-transfer/entities/material-entities/multi-material-layer-dto';

export const MINIMAL_WEIGHT = 0.001;
export const MINIMAL_TOTAL_WEIGHT = 0.001;
export const MINIMAL_THICKNESS = 0.001;
export const MINIMAL_GRAMMAGE = 0.001;
export const MINIMAL_MASS_PERCENTAGE = 0.001;
export const MINIMAL_DENSITY = 0.001;
export const STANDARD_DENSITY = 1;
export const MINIMAL_SURFACE = 0.001;
export const MINIMAL_PERCENTAGE = 0;
export const MAXIMAL_PERCENTAGE = 100;

export const SHOULDER_FUNCTION_ID = 8;

@Injectable({ providedIn: 'root' })
export class MaterialDataHandler {

  private translation = (text: string) => this.translateService.instant(text);

  constructor(public translateService: TranslateService) { }

  setControlsValueAndEditability(
    form: FormGroup, control: string, editable: boolean, value: number | undefined, defaultValue: number
  ) {
    if (editable) {
      form.get(control)?.patchValue(value);
      form.get(control)?.enable();
    } else {
      form.get(control)?.patchValue(defaultValue);
      form.get(control)?.disable();
    }
  }

  isDetectionLayerCandidate(functionId: number) {
    if (functionId == null) { return false; }
    const detectionLayerCandidates = [1, 2, 4]; // Material layer, Barrier layer, Sealing
    return detectionLayerCandidates.includes(functionId);
  }

  getMaterialSucessDialogData(): SimpleDialogData {
    return {
      title: this.translation('common.text.saved'),
      messages: [this.translation('material.messages.materialSaved')], icon: 'save'
    };
  }

  getDissolveMaterialDialogData(): SimpleDialogData {
    return {
      title: this.translation('common.text.warning'),
      messages: [this.translation('material.messages.dissolveMaterial')], icon: 'warning'
    };
  }

  getMaterialFunctions(materialFunctions: PackagingCategoryMaterialFunctionDto[], packagingTypeId?: number, componentTypeId?: number) {
    return materialFunctions
      .find(funcForPackagingUnits => funcForPackagingUnits.packagingTypeId === packagingTypeId)?.componentCategoryMaterialFunctions
      .find(funcForComponents => funcForComponents.packagingComponentCategoryId === componentTypeId)?.materialFunctions ?? [];
  }

  getDeleteMaterialDialogData(): DeleteDialogData {
    const dialogHeader = this.translation('material.deleteMaterialHeader');
    const dialogText = this.translation('material.deleteMaterialsText');
    return { dialogHeader, dialogText };
  }
}
