export class CreditsDto {
    lcaCredits :number = 0;
    recyclabilityCredits :number = 0;

    constructor(recyclabilityCredits :number = 0, lcaCredits: number = 0 ) {
        this.recyclabilityCredits = recyclabilityCredits;
        this.lcaCredits = lcaCredits;
      }

    hasNegativValue(type : CreditType) : boolean {
      switch (type) {
        case CreditType.RECYCLABILITY:
          return this.recyclabilityCredits < 0
        case CreditType.LCA:
          return this.lcaCredits < 0
      }
    }
    
}

export enum CreditType {
  RECYCLABILITY,
  LCA
}