<div id="dimensions-overlay" [style.background]="isDarkTheme ? '#424242' : 'white'" [style.color]="isDarkTheme ? 'white' : 'black'">
  <h3>{{ 'dimensionsOverlay.title' | translate }}</h3>
  <div class="flex-row text-image-container">
    <div>
      <h4>{{ 'dimensionsOverlay.subtitleFlexible' | translate }}</h4>
      <p>{{ 'dimensionsOverlay.textFlexible' | translate }}</p>
      <ul>
        <li>{{ 'dimensionsOverlay.lengthFlexible' | translate }}</li>
        <li>{{ 'dimensionsOverlay.widthFlexible' | translate }}</li>
        <li>{{ 'dimensionsOverlay.heightFlexible' | translate }}</li>
      </ul>
    </div>
    <img class="image" [src]="image2D">
  </div>
  <div class="flex-row text-image-container">
    <img class="image" [src]="image3D">
    <div>
      <h4>{{ 'dimensionsOverlay.subtitleRigid' | translate }}</h4>
      <p>{{ 'dimensionsOverlay.textRigid' | translate }}</p>
      <ul>
        <li>{{ 'dimensionsOverlay.lengthRigid' | translate }}</li>
        <li>{{ 'dimensionsOverlay.widthRigid' | translate }}</li>
        <li>{{ 'dimensionsOverlay.heightRigid' | translate }}</li>
      </ul>
    </div>
  </div>
  <div class="flex-row text-image-container">
    <div>
      <h4>{{ 'dimensionsOverlay.subtitleCylindrical' | translate }}</h4>
      <p>{{ 'dimensionsOverlay.textCylindrical' | translate }}</p>
      <ul>
        <li>{{ 'dimensionsOverlay.heightCylindrical' | translate }}</li>
        <li>{{ 'dimensionsOverlay.widthLengthCylindrical' | translate }}</li>
      </ul>
    </div>
    <img class="image" [src]="imageCylindrical">
  </div>
</div>