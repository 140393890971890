<app-packaging-navigation [itemId]="packagingSystem.id" [callerLevelId]="packagingPart.System"
  [currentDirectoryId]="currentDirectoryId" [brandName]="packagingSystem.brandName"
  [productName]="packagingSystem.productName" [articleNumber]="packagingSystem.articleNumber"
  [versionId]="packagingSystem.version" [isCurrentVersion]="packagingSystem.isCurrentVersion" [isPreview]="isPreview"
  [hasRecyclabilityResult]="packagingSystem.hasRecyclabilityResult"
  [hasLifeCycleResult]="packagingSystem.hasLifeCycleResult" [creationTimestamp]="packagingSystem.creationTimestamp">

  <div class="outer-container">

    <div id="type-id-date-container">
      <h1>{{ 'packagingSystem.packagingSystem' | translate }}</h1>
      <span *ngIf="packagingSystem.id != null; else elseHeader">
        <span class="grey">({{ 'commonPackagingFields.id' | translate }}:
          {{ packagingSystem.id }},
          {{ 'commonPackagingFields.creationDate' | translate }}:
          {{ getLocaleDate(this.packagingSystem.creationTimestamp) }})
        </span>
      </span>
      <ng-template #elseHeader>
        <div class="grey">{{ 'packagingSystem.newPackagingSystem' | translate }}</div>
      </ng-template>
    </div>

    <div *ngIf="isFormLocked">
      <alert type="info">
        <div class="icon-text-container">
          <mat-icon class="gray-icon-18">info</mat-icon>
          {{ 'packagingUnit.messages.packagingUnitLocked' | translate }}
        </div>
      </alert>
    </div>

    <div *ngIf="isTracked && !isPreview">
      <alert type="info">
        <div class="icon-text-container">
          <mat-icon class="gray-icon-18">info</mat-icon>
          {{ 'packagingSystem.messages.packagingTracked' | translate }}
        </div>
      </alert>
    </div>

    <div *ngIf="!isEditPermitted">
      <alert type="info">
        <div class="icon-text-container">
          <mat-icon class="gray-icon-18">info</mat-icon>
          {{ 'warnings.editNotPermitted' | translate }}
        </div>
      </alert>
    </div>

    <form [formGroup]="packagingSystemForm">

      <div class="flex-row flex-space-between version-tags-container">
        <div id="versions-container">
          <div (click)="loadHistoryVersions()" *ngIf="packagingSystem.id != null && !isPreview && !isUserValidator">
            <mat-form-field class="form-field-versions">
              <mat-label class="label-large">{{ 'home.packagingUnitHome.historyVersion' | translate }}</mat-label>
              <mat-select [(value)]="packagingSystem.version" (selectionChange)="onHistoryVersionChange($event.value)">
                <mat-select-trigger>
                  Version {{ packagingSystem.version }}
                  <span *ngIf="packagingSystem.versionName">({{ packagingSystem.versionName }})</span>
                  {{ getDateTimeString(packagingSystem.lastModificationTimestamp) }}
                  <strong *ngIf="packagingSystem.isCurrentVersion">({{ 'common.text.current' | translate
                    }})</strong>
                </mat-select-trigger>
                <mat-option *ngFor="let historyVersion of historyVersions" [value]="historyVersion.versionNumber">
                  Version {{ historyVersion.versionNumber }} <span *ngIf="historyVersion.versionName">({{
                    historyVersion.versionName }})</span>
                  {{ getDateTimeString(historyVersion.creationTimestamp) }}
                  <strong *ngIf="historyVersion.isCurrentVersion">({{ 'common.text.current' | translate
                    }})</strong>
                  <span class="spinner-container">
                    <mat-spinner diameter="24" color="accent" *ngIf="spinnerActive">
                    </mat-spinner>
                  </span>
                  <button mat-icon-button color="primary"
                    (click)="$event.stopPropagation(); editVersionName(historyVersion)">
                    <mat-icon class="version-edit-icon">edit</mat-icon>
                  </button>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="packaging-tag-container">
          <app-select-tags [allTags]="allTags" [selectedTags]="selectedTags" *ngIf="!isPreview && !isUserValidator"
            (tagsChanged)="setTags()">
          </app-select-tags>
        </div>
      </div>

      <alert type="warning" *ngIf="changedPackagingPartsInfo.length > 0 && packagingSystem.isCurrentVersion">
        <p>{{ 'packagingSystem.messages.unitsChanged' | translate }}</p>
        <div *ngFor="let packagingPart of changedPackagingPartsInfo" class="changed-component">
          <span>{{ 'dataManagement.directory.toolbar.create.' + packagingPart.type | translate }}, ID {{
            packagingPart.id }}</span>
          <span *ngIf="packagingPart.name"> ({{ packagingPart.name }})</span>
          <button mat-raised-button (click)="acceptPackagingPartChange(packagingPart)" class="custom-button-side">
            {{ 'common.buttons.apply' | translate }}
          </button>
          <button mat-raised-button (click)="declinePackagingPartChange(packagingPart)" class="custom-button-side">
            {{ 'common.buttons.discard' | translate }}
          </button>
        </div>
      </alert>

      <app-packaging-system-html-template [formControl]="packagingSystemFieldsControl"
        [displayErrors]="formSubmitted" [distributionCountries]="createUpdateHandler.distributionCountries"
        [assemblyCountries]="createUpdateHandler.assemblyCountries">
      </app-packaging-system-html-template>

      <div>
        <app-image-upload [canEditForm]="canEditForm" [images]="packagingSystem.images" (filesEdited)="onFilesEdited()">
        </app-image-upload>
      </div>

      <div>
        <app-pdf-upload [canEditForm]="canEditForm" [pdfs]="packagingSystem.documents" (filesEdited)="onFilesEdited()">
        </app-pdf-upload>
      </div>

      <!-- Hierarchy -->
      <mat-card id="hierarchy-card">
        <button mat-raised-button color="primary" (click)="importPackagingSystem()" class="custom-button margin-bottom"
          [disabled]="!canEditForm" type="button">
          <mat-icon>save_alt</mat-icon>
          {{ 'packagingSystem.importPackagingSystem' | translate }}
        </button>

        <button mat-raised-button color="primary" (click)="importPackagingUnit()" class="custom-button margin-bottom"
          [disabled]="!canEditForm" type="button">
          <mat-icon>save_alt</mat-icon>
          {{ 'packagingUnit.importPackagingUnit' | translate }}
        </button>

        <div id="hierarchy-div"
          *ngIf="packagingSystem.packagingSystems.length > 0 || packagingSystem.packagingUnits.length > 0">
          <div id="tree-container">
            <app-packaging-system-tree [packagingSystem]="packagingSystem" [isDarkTheme]="isDarkTheme"
              [selectedNode]="selectedSystemNode || selectedUnitNode" (nodeSelected)="onNodeSelected($event)">
            </app-packaging-system-tree>
          </div>
          <div id="details-container">
            <div *ngIf="selectedSystemNode || selectedUnitNode" class="flex-distance">
              <mat-form-field>
                <mat-label>{{ 'dataManagement.quantities.quantity' | translate}}</mat-label>
                <input matInput type="number" [formControl]="currentHierarchyFormGroupQuantityControl">
              </mat-form-field>
              <button mat-raised-button color="warn" [disabled]="!isTopLevelNode() || !canEditForm"
                (click)="deleteNode()">
                <mat-icon>delete</mat-icon>
                {{ selectedSystemNode ? ('packagingSystem.deletePackagingSystem' | translate) :
                ('packagingUnit.deletePackagingUnit' | translate) }}
              </button>
            </div>
          </div>
        </div>
      </mat-card>

      <mat-card *ngIf="selectedSystemNode" class="overview-card">
        <div [formGroup]="currentHierarchyFormGroup">
          <mat-card-header>
            <h1>{{ 'packagingSystem.packagingSystem' | translate}}</h1>
          </mat-card-header>
          <app-packaging-system-html-template [formControl]="currentHierarchyFormGroupCurrentSystemControl"
            [distributionCountries]="createUpdateHandler.distributionCountries"
            [assemblyCountries]="createUpdateHandler.assemblyCountries">
          </app-packaging-system-html-template>
        </div>
      </mat-card>

      <mat-card *ngIf="selectedUnitNode" class="overview-card">
        <div [formGroup]="currentHierarchyFormGroup">
          <mat-card-header>
            <h1>{{ 'packagingUnit.packagingUnit' | translate}}</h1>
          </mat-card-header>

          <app-packaging-unit-html-template [formControl]="currentHierarchyFormGroupCurrentUnitControl"
            [distributionCountries]="createUpdateHandler.distributionCountries"
            [fillingGoodTypes]="createUpdateHandler.fillingGoodTypes"
            [fillingGoodCategories]="createUpdateHandler.fillingGoodCategories"
            [fillingGoods]="createUpdateHandler.fillingGoods"
            [assemblyCountries]="createUpdateHandler.assemblyCountries" [packagingUnitTypeId]="12">
          </app-packaging-unit-html-template>

          <mat-card *ngIf="currentHierarchyFormGroup.controls.mainBody">
            <mat-card-header>
              <h3>{{ 'component.mainBody' | translate }}</h3>
            </mat-card-header>
            <mat-card-content>
              <app-additional-packaging-component
                [componentEntries]="currentHierarchyFormGroup.controls.mainBody.value"
                [componentType]="componentTypes.MainBody" [handler]="createUpdateHandler.componentHandler"
                [packagingTypeId]="currentHierarchyFormGroup.controls.currentUnit.value.packagingTypeId"
                [canEditForm]="false" [isSingleComponent]="true" [displayComponentTypeImage]="false">
              </app-additional-packaging-component>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="currentHierarchyFormGroup.controls.closures">
            <mat-card-header>
              <h3>{{ 'component.closure' | translate }}</h3>
            </mat-card-header>
            <mat-card-content>
              <app-additional-packaging-component
                [componentEntries]="currentHierarchyFormGroup.controls.closures.value"
                [componentType]="componentTypes.Closure" [handler]="createUpdateHandler.componentHandler"
                [packagingTypeId]="currentHierarchyFormGroup.controls.currentUnit.value.packagingTypeId"
                [canEditForm]="false" [displayComponentTypeImage]="false">
              </app-additional-packaging-component>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="currentHierarchyFormGroup.controls.decorations">
            <mat-card-header>
              <h3>{{ 'component.decoration' | translate }}</h3>
            </mat-card-header>
            <mat-card-content>
              <app-additional-packaging-component
                [componentEntries]="currentHierarchyFormGroup.controls.decorations.value"
                [componentType]="componentTypes.Decoration" [handler]="createUpdateHandler.componentHandler"
                [packagingTypeId]="currentHierarchyFormGroup.controls.currentUnit.value.packagingTypeId"
                [canEditForm]="false" [displayComponentTypeImage]="false">
              </app-additional-packaging-component>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="currentHierarchyFormGroup.controls.inlays">
            <mat-card-header>
              <h3>{{ 'component.inlay' | translate }}</h3>
            </mat-card-header>
            <mat-card-content>
              <app-additional-packaging-component
                [componentEntries]="currentHierarchyFormGroup.controls.inlays.value"
                [componentType]="componentTypes.Inlay" [handler]="createUpdateHandler.componentHandler"
                [packagingTypeId]="currentHierarchyFormGroup.controls.currentUnit.value.packagingTypeId"
                [canEditForm]="false" [displayComponentTypeImage]="false">
              </app-additional-packaging-component>
            </mat-card-content>
          </mat-card>

          <mat-card *ngIf="currentHierarchyFormGroup.controls.packagingAids">
            <mat-card-header>
              <h3>{{ 'component.packagingAid' | translate }}</h3>
            </mat-card-header>
            <mat-card-content>
              <app-additional-packaging-component
                [componentEntries]="currentHierarchyFormGroup.controls.packagingAids.value"
                [componentType]="componentTypes.PackagingAid" [handler]="createUpdateHandler.componentHandler"
                [packagingTypeId]="currentHierarchyFormGroup.controls.currentUnit.value.packagingTypeId"
                [canEditForm]="false" [displayComponentTypeImage]="false">
              </app-additional-packaging-component>
            </mat-card-content>
          </mat-card>
        </div>
      </mat-card>

      <div *ngIf="!isPreview">
        <div id="version-name-container" *ngIf="canEditForm">
          <div id="version-name-label" class="label-large">
            {{ 'packagingUnit.messages.nameVersionLabel' | translate }}
          </div>
          <div id="version-name-input">
            <mat-form-field class="form-field-medium">
              <mat-label class="label-large">{{ 'commonPackagingFields.versionName' | translate }}</mat-label>
              <input formControlName="newVersionName" matInput autocomplete="off">
            </mat-form-field>
          </div>
        </div>

        <button mat-raised-button color="primary" (click)="onSubmit()" class="custom-button" [disabled]="!canEditForm"
          type="button">
          <mat-icon>save_alt</mat-icon>
          {{ 'common.buttons.save' | translate }}
        </button>

        <button mat-raised-button color="primary" [matMenuTriggerFor]="analyses.analysesMenu"
          (click)="analyses.loadAnalysesForPackagingSystem()" [disabled]="packagingSystem.id == null || isUserValidator"
          *ngIf="!isGeneric" class="custom-button" type="button">
          <mat-icon svgIcon="analysis-icon"></mat-icon>
          {{'home.packagingUnitHome.evaluations' | translate}}
        </button>
        <app-analyses-menu-packaging-systems #analyses [packagingSystemId]="packagingSystem.id"
          [versionId]="packagingSystem.version" [isUserValidator]="isUserValidator" [caller]="callerPageId"
          [hasRecyclabilityResult]="packagingSystem.hasRecyclabilityResult"
          [hasLifeCycleResult]="packagingSystem.hasLifeCycleResult"
          [creationTimestamp]="packagingSystem.creationTimestamp">
        </app-analyses-menu-packaging-systems>
      </div>
    </form>
  </div>
</app-packaging-navigation>
