import { TranslateService } from '@ngx-translate/core';
import { PathResolveService } from '../../../services/path-resolve.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AnalysisType, ParentNavigationService } from './parent-navigation-service';
import { MatDialog } from '@angular/material/dialog';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { CreditsService } from 'src/app/services/credits-service';
import { AnalysisApiService } from 'src/app/data-transfer/services/analysis-api-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { SimpleDialogData } from 'src/app/components/dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { DialogActions } from 'src/app/model/dictionary';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { SimpleConfirmDialogComponent } from 'src/app/components/dialogs/simple-confirm-dialog/simple-confirm-dialog.component';
import { Observable } from 'rxjs';
import { EvaluationGenerationDto } from 'src/app/data-transfer/entities/evaluation-entities/evaluation-generation-dto';
import { AnalysisNotPossibleDialogComponent } from 'src/app/components/dialogs/analysis-not-possible-dialog/analysis-not-possible-dialog.component';
import { RecyclabilityResultDto } from 'src/app/data-transfer/entities/evaluation-entities/recyclability-result-dto';
import { LifeCycleResultDto } from 'src/app/data-transfer/entities/evaluation-entities/life-cycle-result-dto';
import { ExpenseResultDto } from 'src/app/data-transfer/entities/evaluation-entities/expense-result-dto';

@Injectable({ providedIn: 'root' })
export class PackagingSystemNavigationService extends ParentNavigationService {

  constructor(
    protected dialog: MatDialog,
    protected translateService: TranslateService,
    protected creditsService: CreditsService,
    private router: Router,
    private analysisApiService: AnalysisApiService,
    private spinner: NgxSpinnerService
  ) {
    super(dialog, translateService, creditsService);
  }

  navigateToPackagingSystem(packagingSystemId: number, versionId?: number) {
    const path = versionId == null ?
      PathResolveService.getUpdatePackagingSystemPath(true, packagingSystemId) :
      PathResolveService.getPackagingSystemHistoryPath(true, packagingSystemId, versionId);
    this.router.navigate([path]);
  }

  navigateToPackagingSystemPreview(candidateId: number, isChange = false, seePackagingSystemBeforeChange = false) {
    this.router.navigate([PathResolveService.getPackagingSystemPreviewPath(true, candidateId, isChange, seePackagingSystemBeforeChange)]);
  }

  navigateToAnalysisWithCostConfirmation(
    analysisType: AnalysisType, packagingSystemId: number, versionId: number,
    isAnalysisAllowed: boolean, minimalCreditsRequirement: number
  ) {
    if (!isAnalysisAllowed) {
      this.stopWhenAnalysisNotPossible();
    } else {
      if (minimalCreditsRequirement === 0 || !this.areSpecificCreditsRunningLow(analysisType)) {
        this.navigateToAnalysis(analysisType, packagingSystemId, versionId, minimalCreditsRequirement);
      } else {
        const data: SimpleDialogData = {
          title: this.translateService.instant('common.text.information'),
          messages: [this.translateService.instant('dataEvaluation.performEvaluations.messages.evalCost',
          { count: minimalCreditsRequirement })], icon: 'info' };
        const dialogRef = this.dialog.open(SimpleConfirmDialogComponent, getDialogConfig(data));
        this.dialogSubscription = dialogRef.afterClosed().subscribe(creditsConfirmation => {
          if (creditsConfirmation.event === DialogActions.CONFIRM) {
            this.navigateToAnalysis(analysisType, packagingSystemId, versionId, minimalCreditsRequirement);
          } else {
            this.spinner.hide();
          }
        });
      }
    }
  }

  private navigateToAnalysis(
    analysisType: AnalysisType, packagingSystemId: number, versionId: number, minimalCreditsRequirement: number
  ) {
    let observableAnalysis: Observable<LifeCycleResultDto[] | RecyclabilityResultDto[] | ExpenseResultDto[]> | null = null;
    const dto: EvaluationGenerationDto = { id: packagingSystemId, version: versionId };
    if (analysisType === AnalysisType.Recyclability) {
      observableAnalysis = this.analysisApiService.getRecyclabilityEvaluationResult(dto, PackagingPart.System);
    } else if (analysisType === AnalysisType.Lifecycle) {
      observableAnalysis = this.analysisApiService.getLifeCycleEvaluationResult(dto, PackagingPart.System);
    } else if (analysisType === AnalysisType.PlasticTax) {
      this.router.navigate([PathResolveService.getTempPlasticTaxSystemPath(true, packagingSystemId, versionId)]);
    } else if (analysisType === AnalysisType.LicenseFee) {
      this.router.navigate([PathResolveService.getTempLicenseFeeSystemPath(true, packagingSystemId, versionId)]);
    }
    if (!observableAnalysis) { return; }

    if (this.areInsufficientCreditsAvailable(analysisType,minimalCreditsRequirement)) {
      this.spinner.hide();
      this.stopWhenCreditsInsufficient();
    } else {
      this.evaluationSubscription = observableAnalysis.subscribe(analyses => {
        this.navigateToHistoryAnalysis(analysisType, packagingSystemId, versionId, analyses[0].id ?? '');
      });
    }
  }

  private stopWhenAnalysisNotPossible(): void {
    this.spinner.hide();
    const alertDialogConfig = getDialogConfig({ packagingPart: PackagingPart.System }, '600px');
    this.dialog.open(AnalysisNotPossibleDialogComponent, alertDialogConfig);
  }

  navigateToHistoryAnalysis(
    analysisType: AnalysisType, packagingId: number,
    packagingVersionId: number, analysisVersionId: string
  ) {
    let path = '';
    switch (analysisType) {
      case AnalysisType.Recyclability: {
        path = PathResolveService.getRecyclabilityAnalysisHistoryPathSystem(true, packagingId, packagingVersionId, analysisVersionId);
        break;
      }
      case AnalysisType.Lifecycle: {
        path = PathResolveService.getLifecycleAnalysisHistoryPathSystem(true, packagingId, packagingVersionId, analysisVersionId);
        break;
      }
    }
    this.router.navigate([path]);
  }

  navigateToComparison(listOfIdsToCompare: number[], creditsRequired: number) {
    //No individual Credits for Compare available
    if (this.creditsService.creditsCount.recyclabilityCredits < creditsRequired) {
      this.stopWhenCreditsInsufficient();
    } else {
      this.router.navigate([PathResolveService.getCompareRecyclabilityAnalysisPathSystem(true, listOfIdsToCompare)]);
    }
  }
}
