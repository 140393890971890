import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';
import { LifeCycleReportDto, PackagingUnitReportDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-report-dto';
import { ComponentMasterDataDependent, ComponentReportDependentDto, ComponentReportDto } from 'src/app/data-transfer/entities/component-entities/component-report-dto';
import { InclusionMap } from '../templates/report-template-wizard/report-template-wizard.component';

export enum InclusionMapContentType {
  List,
  Table,
  TableList,
  DiagramPie,
  Image,
  Annex,
  Analysis
}

@Injectable({ providedIn: 'root' })
export class DtoToMapService {

  constructor(private translateService: TranslateService) { }

  getPackagingUnitSpecMapFromDto(template: PackagingUnitReportDto): InclusionMap {
    const inclusionMap = {
      name: 'includeSpec', included: true,
      subitems: [
        {
          name: 'primary', included: template.masterData != null,
          subitems: [
            { name: 'images', included: template.images, contentType: InclusionMapContentType.Image },
            { name: 'documents', included: template.documents, contentType: InclusionMapContentType.Annex },
            this.getMasterDataSubitem(template.masterData),
            { name: 'componentsSummary', included: template.componentsSummary, contentType: InclusionMapContentType.Table }
          ]
        },
        this.getPackagingComponentSubitem('mainBody', template),
        this.getPackagingComponentSubitem('closures', template),
        this.getPackagingComponentSubitem('decorations', template),
        this.getPackagingComponentSubitem('inlays', template),
        this.getPackagingComponentSubitem('packagingAids', template),
        {
          name: 'recyclabilityResult', included: template.recyclabilityResult != null,
          contentType: InclusionMapContentType.Analysis,
          subitems: [
            {
              name: 'diagram', included: template.recyclabilityResult?.diagram,
              contentType: InclusionMapContentType.DiagramPie
            },
            { name: 'ratingTable', included: template.recyclabilityResult?.ratingTable },
            { name: 'percentageTable', included: template.recyclabilityResult?.percentageTable },
            {
              name: 'methodology', included: template.recyclabilityResult?.methodology,
              contentType: InclusionMapContentType.Annex
            }
          ]
        },
        {
          name: 'lifeCycleResult', included: template.lifeCycleResult != null,
          contentType: InclusionMapContentType.Analysis,
          subitems: [
            this.getLcaEffectsSubitem('table', template.lifeCycleResult),
            this.getLcaEffectsSubitem('lifeCycleStageDiagram', template.lifeCycleResult),
            // this.getLcaEffectsSubitem('componentsDiagram', template.lifeCycleResult),
            {
              name: 'methodology', included: template.lifeCycleResult?.methodology,
              contentType: InclusionMapContentType.Annex
            }
          ]
        }
      ]
    };
    this.setLabelsRecursively(inclusionMap);
    return inclusionMap;
  }

  getComponentSpecMapFromDto(template: ComponentReportDto): InclusionMap {
    const inclusionMap = {
      name: 'includeSpecComponent', included: true,
      subitems: [
        {
          name: '', included: true,
          subitems: [
            { name: 'images', included: template.images, contentType: InclusionMapContentType.Image },
            this.getMasterDataSubitem(template.masterData, undefined, true),
            this.getMaterialTableSubitem(template?.multiMaterialTable),
            { name: 'documents', included: template.documents, contentType: InclusionMapContentType.Annex }
          ]
        }
      ]
    };
    this.setLabelsRecursively(inclusionMap);
    return inclusionMap;
  }

  private getPackagingComponentSubitem(componentName: string, template: PackagingUnitReportDto) {
    const defaultTemplate = new PackagingUnitReportDto(true);
    const templateComponent = template[componentName as keyof PackagingUnitReportDto] as ComponentReportDependentDto;
    return {
      name: componentName, included: templateComponent != null,
      subitems: [
        this.getMasterDataSubitem(templateComponent?.masterData, defaultTemplate.inlays.masterData),
        this.getMaterialTableSubitem(templateComponent?.multiMaterialTable)]
    };
  }

  private getMasterDataSubitem(masterData: any | null, fallback?: ComponentMasterDataDependent, isIndependentComponent?: boolean) {
    const transPath = 'fieldNamesForExport.';
    return {
      name: 'masterData',
      included: masterData != null,
      contentType: InclusionMapContentType.List,
      associatedObject: this.getFields(masterData ?? fallback, transPath, isIndependentComponent)
    };
  }

  private getMaterialTableSubitem(masterData: any | null) {
    return {
      name: 'multiMaterialTable',
      included: masterData != null,
      contentType: InclusionMapContentType.TableList
    };
  }

  private setLabelsRecursively(inclusionMap: InclusionMap) {
    if (inclusionMap.name !== '') {
      inclusionMap.label = this.translateService.instant('report.templateSection.' + inclusionMap.name);
    }
    if (inclusionMap.subitems) {
      for (const subitem of inclusionMap.subitems) {
        this.setLabelsRecursively(subitem);
      }
    }
  }

  private getLcaEffectsSubitem(name: string, lcaReport: LifeCycleReportDto) {
    const transPathAnalysis = 'analysis.lifecycleAnalysis.';
    const effects = lcaReport ? lcaReport[name as keyof LifeCycleReportDto] : null;
    return {
      name,
      included: effects != null,
      contentType: InclusionMapContentType.List,
      associatedObject: this.getFields(effects, transPathAnalysis)
    };
  }

  private getFields(
    object: any, translationPath: string, isIndependentComponent?: boolean
  ): { name: string, label: string, included: boolean }[] {
    if (!object) { return []; }
    const packagingFieldKeys = Object.getOwnPropertyNames(object);
    const packagingFieldValues: { name: string, label: string, included: boolean }[] = [];
    packagingFieldKeys.forEach(key => {
      let label: string;
      if (key === 'isRigidComponent' && isIndependentComponent) {
        label = this.translateService.instant(translationPath + 'isRigidComponentIndependent');
      } else {
        label = this.translateService.instant(translationPath + key);
      }
      const included = object[key];
      packagingFieldValues.push({ name: key, label, included });
    });
    return packagingFieldValues;
  }
}
