import { SnackbarService } from './snackbar.service';
import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

  constructor(
    private snackbar: SnackbarService
  ) { }

  handleError(error: Error | HttpErrorResponse) {

    let message;

    if (error instanceof HttpErrorResponse) {
      // Server error
      message = error.message;
    } else {
      // Client Error
      message = error.message ? error.message : error.toString();
    }

    const messageToShow = this.prepareMessageToShow(message);
    this.snackbar.showError(messageToShow);

    // Always log errors
    console.error(error);
  }

  private prepareMessageToShow(message: string) {
    // HACK: Put spaces after commas to have message wrapped
    message = message.replace(/,/g, ', ');

    return message;
  }
}
