import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RecyclabilityResultPackagingSystem } from 'src/app/model/evaluations/recyclability-result-packaging-system';
import { PackagingSystemComponentTableData } from 'src/app/util/analyses-util/recyclability-table-definitions';

class PackagingSystemComponentsTableColumn {
  columnDef = '';
  header = '';
  cell = (_: PackagingSystemComponentTableData) => '';
  tooltip = (_: PackagingSystemComponentTableData) => '';
}

@Component({
  selector: 'app-packaging-system-components-table',
  templateUrl: './packaging-system-components-table.component.html',
  styleUrls: ['./packaging-system-components-table.component.scss']
})
export class PackagingSystemComponentsTableComponent implements OnChanges {

  @Input() dataSource!: RecyclabilityResultPackagingSystem;

  dataColumnsComponents: PackagingSystemComponentsTableColumn[] = [];
  displayedColumnsComponents: string[] = [];
  componentsTableDataSource: PackagingSystemComponentTableData[] = [];

  constructor(
    private translateService: TranslateService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource && changes.dataSource.currentValue != null) {
      this.getDataSourceForVersion();
    }
  }

  private getDataSourceForVersion() {
    this.componentsTableDataSource = this.getComponentsTableData(this.dataSource);
    this.dataColumnsComponents = this.getComponentsTableColumns();
    this.displayedColumnsComponents = this.dataColumnsComponents.map(x => x.columnDef);
  }

  private getComponentsTableData(dataSource: RecyclabilityResultPackagingSystem): PackagingSystemComponentTableData[] {
    const result: PackagingSystemComponentTableData[] = [];
    for (const component of dataSource.childSystemResults) {
      result.push({
        index: component.analyzedPackagingSystemEntry.index,
        name: `${component.analyzedPackagingSystemEntry.underlyingPackagingSystem.brandName}
          (${component.analyzedPackagingSystemEntry.underlyingPackagingSystem.productName})`,
        quantity: component.analyzedPackagingSystemEntry.quantity,
        totalWeight: component.totalWeight,
        recyclablePercentage: +(component.percentageRatingA + component.percentageRatingB).toFixed(2)
      })
    }
    for (const component of dataSource.childUnitResults) {
      result.push({
        index: component.analyzedPackagingUnitEntry.index,
        name: `${component.analyzedPackagingUnitEntry.underlyingPackagingUnit.brandName}
          (${component.analyzedPackagingUnitEntry.underlyingPackagingUnit.productName})`,
        quantity: component.analyzedPackagingUnitEntry.quantity,
        totalWeight: component.totalWeight,
        recyclablePercentage: +(component.percentageRatingA + component.percentageRatingB).toFixed(2)
      })
    }
    return result.sort((a, b) => a.index - b.index);
  }

  private getComponentsTableColumns(): PackagingSystemComponentsTableColumn[] {
    const dataColumns: PackagingSystemComponentsTableColumn[] = [];
    const resultTableData = new PackagingSystemComponentTableData();
    const columnNames = Object.keys(resultTableData);
    columnNames.shift();
    for (const columnName of columnNames) {
      dataColumns.push({
        columnDef: columnName,
        header: this.translateService.instant('analysis.recyclabilityAnalysis.tableColumnsSystems.' + columnName),
        cell: (element: PackagingSystemComponentTableData) => `${element[columnName as keyof PackagingSystemComponentTableData]}`,
        tooltip: (_) => '',
      });
    }
    return dataColumns;
  }
}
