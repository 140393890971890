export const IdParam = ':id';
export const DecorationTypeParam = ':decorationType';
export const VersionIdParam = ':versionId';
export const CandidateIdParam = ':candidateId';
export const TypeParam = ':type';
export const PackagingTypeParam = ':packagingType';
export const FolderNameParam = ':folderName';
export const AnalysisVersionIdParam = ':analysisVersionId';
export const PackagingText = 'packaging';
export const CompareText = 'compare';
export const ChangeText = 'change';
export const OriginalText = 'original';
export const PackagingSystemText = 'packaging-system';
export const PackagingUnitText = 'packaging-unit';
export const ComponentText = 'component';
export const MaterialText = 'material';

const CockpitText = 'cockpit';
const RecyclabilityText = 'recyclability';
const LcaText = 'life_cycle';
const PlasticTaxText = 'plastic_tax';
const LicenseFeeText = 'license_fee';
const AfterChange = 'updated';
const HomeText = 'home';
const DataManagement = 'data-management';
const DataEvaluation = 'data-evaluation';
const PreviewText = 'preview';
const CreateText = 'create';
const UpdateText = 'update';
const ReportText = 'report';
const ReportsText = 'reports';


export const PathsDirectory = {
  login: ['login'],
  cockpit: [CockpitText],
  start: [CockpitText, 'start'],
  error: [CockpitText, 'error'],
  // Packaging System
  cockpitPackagingSystem: [CockpitText, PackagingText, PackagingSystemText],
  packagingSystem: [CockpitText, PackagingText, PackagingSystemText, HomeText],
  packagingSystemType: [CockpitText, PackagingText, PackagingSystemText, HomeText, TypeParam],
  createPackagingSystem: [CockpitText, PackagingText, PackagingSystemText, CreateText],
  updatePackagingSystem: [CockpitText, PackagingText, PackagingSystemText, UpdateText, IdParam],
  packagingSystemVersion: [CockpitText, PackagingText, PackagingSystemText, UpdateText, IdParam, VersionIdParam],
  previewSystem: [CockpitText, PackagingText, PackagingSystemText, PreviewText, CandidateIdParam],
  previewSystemBeforeChange: [CockpitText, PackagingText, PackagingSystemText, PreviewText, CandidateIdParam, ChangeText, OriginalText],
  previewSystemAfterChange: [CockpitText, PackagingText, PackagingSystemText, PreviewText, CandidateIdParam, ChangeText, AfterChange],
  recyclabilitySystem: [CockpitText, PackagingText, PackagingSystemText, RecyclabilityText, IdParam],
  recyclabilitySystemVersion: [CockpitText, PackagingText, PackagingSystemText, RecyclabilityText, IdParam, VersionIdParam],
  recyclabilitySystemHistory:
    [CockpitText, PackagingText, PackagingSystemText, RecyclabilityText, IdParam, VersionIdParam, AnalysisVersionIdParam],
  lifecycleSystem: [CockpitText, PackagingText, PackagingSystemText, LcaText, IdParam],
  lifecycleSystemVersion: [CockpitText, PackagingText, PackagingSystemText, LcaText, IdParam, VersionIdParam],
  lifecycleSystemHistory: [CockpitText, PackagingText, PackagingSystemText, LcaText, IdParam, VersionIdParam, AnalysisVersionIdParam],
  plasticTaxSystemHistory: [CockpitText, PackagingText, PackagingSystemText,
    PlasticTaxText, IdParam, VersionIdParam, AnalysisVersionIdParam],
  licenseFeeSystemHistory: [CockpitText, PackagingText, PackagingSystemText,
    LicenseFeeText, IdParam, VersionIdParam, AnalysisVersionIdParam],
  compareSystems: [CockpitText, PackagingText, PackagingSystemText, CompareText],
  tempPlasticTaxSystemPath: [CockpitText, PackagingText, PackagingSystemText, PlasticTaxText, IdParam, VersionIdParam],
  tempLicenseFeeSystemPath: [CockpitText, PackagingText, PackagingSystemText, LicenseFeeText, IdParam, VersionIdParam],
  // Packaging Unit
  cockpitPackagingUnit: [CockpitText, PackagingText, PackagingUnitText],
  packagingUnit: [CockpitText, PackagingText, PackagingUnitText, HomeText],
  packagingUnitType: [CockpitText, PackagingText, PackagingUnitText, HomeText, TypeParam],
  createPackagingUnit: [CockpitText, PackagingText, PackagingUnitText, CreateText],
  updatePackagingUnit: [CockpitText, PackagingText, PackagingUnitText, UpdateText, IdParam],
  packagingUnitVersion: [CockpitText, PackagingText, PackagingUnitText, UpdateText, IdParam, VersionIdParam],
  previewUnit: [CockpitText, PackagingText, PackagingUnitText, PreviewText, CandidateIdParam],
  previewUnitBeforeChange: [CockpitText, PackagingText, PackagingUnitText, PreviewText, CandidateIdParam, ChangeText, OriginalText],
  previewUnitAfterChange: [CockpitText, PackagingText, PackagingUnitText, PreviewText, CandidateIdParam, ChangeText, AfterChange],
  recyclabilityUnit: [CockpitText, PackagingText, PackagingUnitText, RecyclabilityText, IdParam],
  recyclabilityUnitVersion: [CockpitText, PackagingText, PackagingUnitText, RecyclabilityText, IdParam, VersionIdParam],
  recyclabilityUnitHistory:
    [CockpitText, PackagingText, PackagingUnitText, RecyclabilityText, IdParam, VersionIdParam, AnalysisVersionIdParam],
  lifecycleUnit: [CockpitText, PackagingText, PackagingUnitText, LcaText, IdParam],
  lifecycleUnitVersion: [CockpitText, PackagingText, PackagingUnitText, LcaText, IdParam, VersionIdParam],
  lifecycleUnitHistory: [CockpitText, PackagingText, PackagingUnitText, LcaText, IdParam, VersionIdParam, AnalysisVersionIdParam],
  plasticTaxUnitHistory: [CockpitText, PackagingText, PackagingUnitText, PlasticTaxText, IdParam, VersionIdParam, AnalysisVersionIdParam],
  licenseFeeUnitHistory: [CockpitText, PackagingText, PackagingUnitText, LicenseFeeText, IdParam, VersionIdParam, AnalysisVersionIdParam],
  compareUnits: [CockpitText, PackagingText, PackagingUnitText, CompareText],
  tempPlasticTaxUnitPath: [CockpitText, PackagingText, PackagingUnitText, PlasticTaxText, IdParam, VersionIdParam],
  tempLicenseFeeUnitPath: [CockpitText, PackagingText, PackagingUnitText, LicenseFeeText, IdParam, VersionIdParam],
  // Components
  cockpitComponent: [CockpitText, PackagingText, ComponentText],
  component: [CockpitText, PackagingText, ComponentText, HomeText],
  componentType: [CockpitText, PackagingText, ComponentText, HomeText, TypeParam],
  createComponent: [CockpitText, PackagingText, ComponentText, TypeParam, CreateText],
  createMainBody: [CockpitText, PackagingText, ComponentText, TypeParam, CreateText, PackagingTypeParam],
  updateComponent: [CockpitText, PackagingText, ComponentText, TypeParam, UpdateText, IdParam],
  updateDecoration: [CockpitText, PackagingText, ComponentText, TypeParam, UpdateText, IdParam, DecorationTypeParam],
  componentVersion: [CockpitText, PackagingText, ComponentText, TypeParam, UpdateText, IdParam, VersionIdParam],
  decorationVersion:
    [CockpitText, PackagingText, ComponentText, TypeParam, UpdateText, IdParam, VersionIdParam, DecorationTypeParam],
  previewComponent: [CockpitText, PackagingText, ComponentText, TypeParam, PreviewText, CandidateIdParam],
  previewComponentBeforeChange:
    [CockpitText, PackagingText, ComponentText, TypeParam, PreviewText, CandidateIdParam, ChangeText, OriginalText],
  previewComponentAfterChange:
    [CockpitText, PackagingText, ComponentText, TypeParam, PreviewText, CandidateIdParam, ChangeText, AfterChange],
  previewDecoration: [CockpitText, PackagingText, ComponentText, TypeParam, PreviewText, CandidateIdParam, DecorationTypeParam],
  previewDecorationBeforeChange: [CockpitText, PackagingText, ComponentText, TypeParam, PreviewText, CandidateIdParam, ChangeText,
    OriginalText, DecorationTypeParam],
  previewDecorationAfterChange: [CockpitText, PackagingText, ComponentText, TypeParam, PreviewText, CandidateIdParam, ChangeText,
    AfterChange, DecorationTypeParam],
  // Materials
  cockpitMaterial: [CockpitText, PackagingText, MaterialText],
  material: [CockpitText, PackagingText, MaterialText, HomeText],
  createMaterial: [CockpitText, PackagingText, MaterialText, CreateText],
  updateMaterial: [CockpitText, PackagingText, MaterialText, UpdateText, IdParam],
  materialVersion: [CockpitText, PackagingText, MaterialText, UpdateText, IdParam, VersionIdParam],
  previewMaterial: [CockpitText, PackagingText, MaterialText, PreviewText, CandidateIdParam],
  previewMaterialBeforeChange: [CockpitText, PackagingText, MaterialText, PreviewText, CandidateIdParam, ChangeText, OriginalText],
  previewMaterialAfterChange: [CockpitText, PackagingText, MaterialText, PreviewText, CandidateIdParam, ChangeText, AfterChange],
  // Data Management
  userManagement: [CockpitText, DataManagement, 'users'],
  dependencyVersions: [CockpitText, DataManagement, 'dependencies'],
  uiChangelog: [CockpitText, DataManagement, 'ui-changelog'],
  exportProfiles: [CockpitText, DataManagement, 'export-profiles'],
  organizationsManagement: [CockpitText, DataManagement, 'organizations'],
  lcaDataExcelImport: [CockpitText, DataManagement, 'lca-data-excel-import'],
  validatorViewSystems: [CockpitText, DataManagement, 'validator', PackagingSystemText],
  validatorViewUnits: [CockpitText, DataManagement, 'validator', PackagingUnitText],
  directoryManagement: [CockpitText, DataManagement, 'directory-tree', FolderNameParam],
  packagingSystemImport: [CockpitText, DataManagement, 'packaging-system-import'],
  packagingUnitImport: [CockpitText, DataManagement, 'packaging-unit-import'],
  componentImport: [CockpitText, DataManagement, 'component-import'],
  materialImport: [CockpitText, DataManagement, 'material-import'],
  createPackagingUnitReportTemplate: [CockpitText, DataManagement, ReportText, PackagingUnitText],
  updatePackagingUnitReportTemplate: [CockpitText, DataManagement, ReportText, PackagingUnitText, IdParam],
  createComponentReportTemplate: [CockpitText, DataManagement, ReportText, ComponentText],
  updateComponentReportTemplate: [CockpitText, DataManagement, ReportText, ComponentText, IdParam],
  createMaterialReportTemplate: [CockpitText, DataManagement, ReportText, MaterialText],
  updateMaterialReportTemplate: [CockpitText, DataManagement, ReportText, MaterialText, IdParam],
  reportTemplatesHome: [CockpitText, DataManagement, ReportsText, 'report-templates-home'],
  reportsHome: [CockpitText, DataManagement, ReportsText, 'reports-home'],
  internationalInfos: [CockpitText, DataManagement, 'international-infos'],
  // Data Evaluation
  dataPerformEvaluation: [CockpitText, DataEvaluation, 'perform'],
  dataViewEvaluation: [CockpitText, DataEvaluation, 'view'],
  tagsManagement: [CockpitText, 'tags']
};
