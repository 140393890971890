import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class SimpleDialogData {
  title = '';
  messages: string[] = [];
  icon = '';
}

@Component({
  selector: 'app-simple-alert-dialog',
  templateUrl: './simple-alert-dialog.component.html',
  styleUrls: ['./simple-alert-dialog.component.scss']
})
export class SimpleAlertDialogComponent {

  title: string;
  messages: string[];
  icon: string;

  constructor(
    public dialogRef: MatDialogRef<SimpleAlertDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SimpleDialogData
  ) {
    this.title = data.title;
    this.messages = data.messages;
    this.icon = data.icon;
  }
}
