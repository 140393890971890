<span class="fill-space">
  <span id="buttons-container">
    <img *ngIf="!isMinimalLayout" class="image" [src]="imageSource">
    <span class="toolbar-button">
      <button mat-button class="menu-button" (click)="clickHome()" type="button"
        [ngClass]="getButtonClass('/cockpit/start')">
        <mat-icon *ngIf="!isMinimalLayout" class="toolbar-icon">home</mat-icon>
        {{ 'home.title' | translate }}
      </button>
    </span>
    <span *ngIf="!isAdmin && !isValidator && isContractSigned" class="toolbar-button">
      <button mat-button class="menu-button" [matMenuTriggerFor]="packagingMenu" type="button"
        [ngClass]="getButtonClass('/cockpit/packaging')">
        <mat-icon *ngIf="!isMinimalLayout" class="toolbar-icon">bento</mat-icon>
        {{ 'common.text.packaging' | translate}}
      </button>
    </span>
    <span *ngIf="isContractSigned || isAdmin" class="toolbar-button">
      <button mat-button class="menu-button" [matMenuTriggerFor]="dataMenu.menu" type="button"
        [ngClass]="getButtonClass('/cockpit/data-management')">
        <mat-icon *ngIf="!isMinimalLayout" class="toolbar-icon">settings</mat-icon>
        {{ 'dataManagement.title' | translate }}
      </button>
    </span>
    <span *ngIf="!isAdmin && !isValidator && isContractSigned" class="toolbar-button">
      <button mat-button class="menu-button" [matMenuTriggerFor]="evalMenu" type="button"
        [ngClass]="getButtonClass('/cockpit/data-evaluation')">
        <mat-icon *ngIf="!isMinimalLayout" class="toolbar-icon">analytics</mat-icon>
        {{ 'dataEvaluation.title' | translate }}
      </button>
    </span>
    <span class="toolbar-button">
      <button mat-button class="menu-button" [matMenuTriggerFor]="downloadsMenu" type="button">
        <mat-icon *ngIf="!isMinimalLayout" class="toolbar-icon">download</mat-icon>
        {{ 'downloads.title' | translate }}
      </button>
    </span>
  </span>
  <span id="user-container">
    <span *ngIf="!isMinimalLayout" class="toolbar-text">
      {{ 'dataManagement.userData.texts.signedInAs' | translate}}
    </span>
    <button mat-button class="menu-button user-button" [matMenuTriggerFor]="userMenu" type="button">
      <mat-icon *ngIf="!isMinimalLayout" class="color-icon">account_circle</mat-icon>
      {{ username }}
    </button>
    <button mat-button class="menu-button user-button" (click)="clickContactInfo()" type="button">
      <mat-icon *ngIf="!isMinimalLayout" class="color-icon">info</mat-icon>
      Info
    </button>

    <button mat-button class="menu-button user-button" [matMenuTriggerFor]="creditsMenu" type="button">
      <mat-icon *ngIf="!isMinimalLayout" class="toolbar-icon">local_atm</mat-icon>
      {{'creditsNavBox.creditsTitle' | translate}}
    </button>
  </span>

  <app-data-menu #dataMenu></app-data-menu>

  <mat-menu #evalMenu="matMenu" [overlapTrigger]="false">
    <app-evaluations-menu></app-evaluations-menu>
  </mat-menu>

  <mat-menu #downloadsMenu="matMenu" [overlapTrigger]="false">
    <app-downloads-menu></app-downloads-menu>
  </mat-menu>

  <mat-menu #userMenu>
    <button mat-menu-item (click)="clickChangePassword()" type="button">
      <mat-icon class="toolbar-icon">lock_open</mat-icon>
      {{ 'dataManagement.userData.texts.changePassword' | translate }}
    </button>
<!--
    <button mat-menu-item [matMenuTriggerFor]="themeMenu" type="button">
      <mat-icon class="toolbar-icon">invert_colors</mat-icon>
      <span>{{ 'dataManagement.userData.texts.colorTheme.menuItem' | translate}}</span>
    </button>
-->
    <button mat-menu-item [matMenuTriggerFor]="languageMenu" type="button">
      <mat-icon class="toolbar-icon">language</mat-icon>
      <span>{{ 'language.language' | translate}}</span>
    </button>
    <button mat-menu-item (click)="clickSignOut()" #signOut type="button">
      <mat-icon class="toolbar-icon">exit_to_app</mat-icon>
      {{ 'dataManagement.userData.texts.signOut' | translate }}
    </button>
    <button mat-menu-item disabled id="version"><app-version></app-version></button>
  </mat-menu>

  <mat-menu #themeMenu>
    <button *ngFor="let colorTheme of colorThemes" mat-menu-item (click)="setColorTheme(colorTheme.id)" type="button">
      {{ colorTheme.name }}
    </button>
  </mat-menu>

  <mat-menu #languageMenu>
    <button *ngFor="let countryCode of countryCodes" mat-menu-item (click)="setLanguage(countryCode)"
      id="language-{{countryCode}}">
      <span class="flag-icon flag-icon-{{countryCode}} space-right"></span>
      {{'language.'+ countryCode | translate}}
    </button>
  </mat-menu>

  <mat-menu #packagingMenu>
    <button mat-menu-item (click)="clickMaterialHome()" type="button">
      <img class="image-icon" [src]="getPackagingPartImage(packagingPart.Material)">
      <span>{{ 'home.materialHome.title' | translate }}</span>
    </button>
    <button mat-menu-item (click)="clickComponentsHome()" type="button">
      <img class="image-icon" [src]="getPackagingPartImage(packagingPart.Component)">
      <span>{{ 'home.componentHome.title' | translate }}</span>
    </button>
    <button mat-menu-item (click)="clickPackagingUnitHome()" type="button">
      <img class="image-icon" [src]="getPackagingPartImage(packagingPart.Unit)">
      <span>{{ 'home.packagingUnitHome.title' | translate }}</span>
    </button>
    <button mat-menu-item (click)="clickPackagingSystemHome()" type="button">
      <img class="image-icon" [src]="getPackagingPartImage(packagingPart.System)">
      <span>{{ 'home.packagingSystemHome.title' | translate }}</span>
    </button>
  </mat-menu>
  <mat-menu #creditsMenu>
    <p mat-menu-item>
      <mat-icon *ngIf="!isMinimalLayout" class="toolbar-icon">local_atm</mat-icon>
      <span *ngIf="creditsCount.recyclabilityCredits !== -1; else eternityRecyclability">{{ 'creditsNavBox.recyclabilityCredits' | translate }} {{ creditsCount.recyclabilityCredits.toFixed(2) }}</span>
    </p>
    <p mat-menu-item>
      <mat-icon *ngIf="!isMinimalLayout" class="toolbar-icon">local_atm</mat-icon>
      <span *ngIf="creditsCount.lcaCredits !== -1; else eternityLca">{{'creditsNavBox.lcaCredits' | translate }} {{ creditsCount.lcaCredits.toFixed(2) }}</span>
    </p>
    <ng-template #eternityRecyclability><span>{{'creditsNavBox.recyclabilityCredits' | translate }} <span class="eternity">∞</span></span></ng-template>
    <ng-template #eternityLca><span>{{'creditsNavBox.lcaCredits' | translate }} <span class="eternity">∞</span></span></ng-template>
  </mat-menu>
