<div *ngIf="version">
  <h2 mat-dialog-title> 🎉{{ 'dataManagement.uiChangelog.newVersion' | translate }}🎉: {{ version.id }}
    ({{ version.releaseDate | date }}) </h2>
  <mat-dialog-content class="mat-typography">
    <markdown emoji [src]="getFileName(version.id)" (error)="onError($event)">
    </markdown>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button mat-raised-button color="primary" mat-dialog-close type="button"> {{ 'common.buttons.close' | translate }}
    </button>
  </mat-dialog-actions>
</div>
