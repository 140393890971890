import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { PackagingPart } from 'src/app/model/packaging-part-enum';

@Component({
  selector: 'app-analysis-not-possible-dialog',
  templateUrl: './analysis-not-possible-dialog.component.html',
  styleUrls: ['./analysis-not-possible-dialog.component.scss']
})
export class AnalysisNotPossibleDialogComponent implements OnInit {

  generalExplanation!: string;
  sectionsWithBulletpoints: { title: string, items: string[] }[] = [];
  sections!: { title: string, text: string }[];
  hints!: { text: string }[];
  private packagingPart: number;

  tr = (key: string, params?: object | undefined) => this.translateService.instant(key, params);

  constructor(
    public dialogRef: MatDialogRef<AnalysisNotPossibleDialogComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: any,
    private translateService: TranslateService
  ) {
    this.packagingPart = data.packagingPart;
  }

  ngOnInit(): void {
    switch (this.packagingPart) {
      case PackagingPart.System:
        this.setPackagingSystemInfo();
        break;
      case PackagingPart.Unit:
        this.setPackagingUnitInfo();
        break;
      default:
        break;
    }
  }

  private setPackagingSystemInfo() {
    this.generalExplanation = this.tr('analysisNotPossibleInfo.generalConditionsSystem');
    this.sectionsWithBulletpoints = [
      {
        title: this.tr('analysisNotPossibleInfo.sectionTitle.reasons'),
        items: [
          this.tr('analysisNotPossibleInfo.distributionCountry'),
          this.tr('analysisNotPossibleInfo.unitInSystem'),
          this.tr('analysisNotPossibleInfo.subSystems')
        ]
      }
    ];
    this.sections = [];
    this.hints = [];
  }

  private setPackagingUnitInfo() {
    this.generalExplanation = this.tr('analysisNotPossibleInfo.generalConditionsUnit');
    this.sectionsWithBulletpoints = [
      {
        title: this.tr('analysisNotPossibleInfo.sectionTitle.reasons'),
        items: [
          this.tr('analysisNotPossibleInfo.mainBody'),
          this.tr('analysisNotPossibleInfo.distributionCountry'),
          this.tr('analysisNotPossibleInfo.materialLayerAvailableComponent'),
          this.tr('analysisNotPossibleInfo.LwhAvailableComponent')
        ]
      }
    ];
    this.sections = [];
    this.hints = [{ text: this.tr('analysisNotPossibleInfo.additionalInformation') }];
  }
}
