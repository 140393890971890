<form [formGroup]="calculatorForm">
  <div id="top-card">
    <mat-form-field class="form-field-wide">
      <mat-label class="label-large">
        {{ 'material.layer.massCalculator.totalMass' | translate }}
        {{ 'material.layer.massCalculator.' + callerName  | translate }}
        {{ 'material.layer.massCalculator.massUnit' | translate }}
      </mat-label>
      <input required matInput type="number" formControlName="calculatorTotalMass" min="0.001" autocomplete="off">
      <mat-icon matSuffix color="warn" *ngIf="showTotalWeightWarning"
        matTooltip="{{ 'warnings.totalWeightDiffers' | translate }}">
        warning_amber
      </mat-icon>
      <mat-error *ngIf="formControls.calculatorTotalMass.errors">
        <span *ngIf="formControls.calculatorTotalMass.errors.required">
          {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
        </span>
        <span *ngIf="formControls.calculatorTotalMass.errors.min">
          {{ 'dataManagement.userData.userDialog.validation.valueCannotBeLessThan' | translate: {value: '0,001'} }}
        </span>
      </mat-error>
    </mat-form-field>
    <mat-form-field class="form-field-wide" *ngIf="!isPercentageInputOpen">
      <mat-label class="label-large">
        {{ 'material.layer.massCalculator.totalGrammage' | translate }}
        {{ 'material.layer.massCalculator.' + callerName  | translate }}
        {{ 'material.layer.massCalculator.grammageUnit' | translate }}
      </mat-label>
      <input [required]="!isPercentageInputOpen" matInput type="number" formControlName="calculatorTotalGrammage"
        min="0.001" autocomplete="off">
      <mat-icon matSuffix color="warn" *ngIf="showTotalGrammageWarning"
        matTooltip="{{ 'warnings.totalGrammageDiffers' | translate }}">
        warning_amber
      </mat-icon>
      <mat-error *ngIf="formControls.calculatorTotalGrammage.errors">
        <span *ngIf="formControls.calculatorTotalGrammage.errors.required">
          {{ 'dataManagement.userData.userDialog.validation.required' | translate }}</span>
        <span *ngIf="formControls.calculatorTotalGrammage.errors.min">
          {{ 'dataManagement.userData.userDialog.validation.valueCannotBeLessThan' | translate: {value: '0,001'} }}
        </span>
        <span *ngIf="formControls.calculatorTotalGrammage.hasError('grammageError')">
          {{ 'dataManagement.userData.userDialog.validation.layerGrammageError' | translate }}</span>
      </mat-error>
    </mat-form-field>
  </div>

  <mat-accordion>
    <mat-expansion-panel [expanded]="isGrammageInputOpen" (opened)="grammageInputOpened()" class="grammage-exp-panel">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'material.layer.massCalculator.grammageKnown' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-form-field class="form-field-wide">
        <mat-label class="label-large">
          {{ 'material.layer.massCalculator.layerGrammage' | translate }}
        </mat-label>
        <input [required]="isGrammageInputOpen" matInput type="number" formControlName="calculatorLayerGrammage"
          min="0.001" autocomplete="off">
        <mat-error *ngIf="formControls.calculatorLayerGrammage.errors">
          <span *ngIf="formControls.calculatorLayerGrammage.errors.required">
            {{ 'dataManagement.userData.userDialog.validation.required' | translate }}</span>
          <span *ngIf="formControls.calculatorLayerGrammage.errors.min">
            {{ 'dataManagement.userData.userDialog.validation.valueCannotBeLessThan' | translate: {value: '0,001'} }}
          </span>
          <span *ngIf="formControls.calculatorTotalGrammage.hasError('grammageError')">
            {{ 'dataManagement.userData.userDialog.validation.layerGrammageError' | translate }}</span>
        </mat-error>
      </mat-form-field>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="isThicknessInputOpen" (opened)="thicknessInputOpened()" class="grammage-exp-panel">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'material.layer.massCalculator.thicknessKnown' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div>
        <label>{{ 'material.layer.grammage' | translate }} = </label>
        <mat-form-field class="form-field-short">
          <mat-label>{{ 'material.layer.massCalculator.thickness' | translate }}</mat-label>
          <input [required]="isThicknessInputOpen" matInput type="number" formControlName="calculatorLayerThickness"
            autocomplete="off">
        </mat-form-field>
        <label> * </label>
        <mat-form-field class="form-field-short">
          <mat-label>{{ 'material.layer.massCalculator.density' | translate }}</mat-label>
          <input [required]="isThicknessInputOpen" matInput type="number" formControlName="calculatorLayerDensity"
            autocomplete="off">
        </mat-form-field>
      </div>
    </mat-expansion-panel>
    <mat-expansion-panel [expanded]="isPercentageInputOpen" (opened)="percentageInputOpened()"
      class="grammage-exp-panel">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ 'material.layer.massCalculator.percentageKnown' | translate }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <mat-form-field class="form-field-wide">
        <mat-label class="label-large">
          {{ 'material.layer.massCalculator.layerPercentage' | translate }}
        </mat-label>
        <input [required]="isPercentageInputOpen" matInput type="number" formControlName="calculatorLayerPercentage"
          min="0" max="100" autocomplete="off">
        <mat-error *ngIf="formControls.calculatorLayerPercentage.errors">
          <span *ngIf="formControls.calculatorLayerPercentage.errors.required">
            {{ 'dataManagement.userData.userDialog.validation.required' | translate }}</span>
          <span *ngIf="formControls.calculatorLayerPercentage.errors.min">
            {{ 'dataManagement.userData.userDialog.validation.valueCannotBeLessThan' | translate: {value: '0%'} }}
          </span>
          <span *ngIf="formControls.calculatorLayerPercentage.errors.max">
            {{ 'dataManagement.userData.userDialog.validation.valueCannotBeGreaterThan' | translate: {value: '100%'} }}
          </span>
        </mat-error>
      </mat-form-field>
    </mat-expansion-panel>
  </mat-accordion>
  <div>
    <button mat-raised-button color="primary" (click)="calculateMass()" class="calc-button" type="button">
      {{ 'material.layer.massCalculator.calculateButton' | translate }}
    </button>
  </div>
</form>
