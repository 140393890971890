import { TranslateService } from '@ngx-translate/core';

export class EnvironmentalEffectTexts {
    climateChange!: EnvironmentalEffectClarification;
    acidification!: EnvironmentalEffectClarification;
    ecotoxicityFreshwater!: EnvironmentalEffectClarification;
    eutrophicationFreshwater!: EnvironmentalEffectClarification;
    eutrophicationMarine!: EnvironmentalEffectClarification;
    eutrophicationTerrestrial!: EnvironmentalEffectClarification;
    cancerogenicEffects!: EnvironmentalEffectClarification;
    noncancerogenicEffects!: EnvironmentalEffectClarification;
    ionizingRadiation!: EnvironmentalEffectClarification;
    ozoneLayerDepletion!: EnvironmentalEffectClarification;
    photochemicalOzoneCreation!: EnvironmentalEffectClarification;
    respiratoryEffects!: EnvironmentalEffectClarification;
    waterScarcity!: EnvironmentalEffectClarification;
    fossils!: EnvironmentalEffectClarification;
    landUse!: EnvironmentalEffectClarification;
    mineralsAndMetals!: EnvironmentalEffectClarification;
}

export interface EnvironmentalEffectClarification {
    description: string;
    unit: string;
}

export class LifeCycleStageTexts {
    primaryMaterials = '';
    secondaryMaterials = '';
}

export class TextsLcaData {

    environmentalEffectsTexts: EnvironmentalEffectTexts;
    lifeCycleStagesTexts: LifeCycleStageTexts;
    language = '';

    constructor(public translate: TranslateService) {
        this.environmentalEffectsTexts = new EnvironmentalEffectTexts();
        this.lifeCycleStagesTexts = new LifeCycleStageTexts();
        this.language = translate.store.currentLang;
        this.setTooltips();
    }

    setTooltips() {
        if (this.language === 'en') {
            this.setEnvironmentalEffectsTooltipsEnglish();
            this.setLifecycleStageTooltipsEnglish();
        } else if (this.language === 'de') {
            this.setEnvironmentalEffectsTooltipsGerman();
            this.setLifecycleStageTooltipsGerman();
        }
    }

    private setLifecycleStageTooltipsEnglish() {
        this.lifeCycleStagesTexts.primaryMaterials = 'Environmental impacts arising from the use of virgin material';
        this.lifeCycleStagesTexts.secondaryMaterials = 'Environmental impacts arising from the acquisition of recycled material';
    }

    private setLifecycleStageTooltipsGerman() {
        this.lifeCycleStagesTexts.primaryMaterials = 'Umweltauswirkungen, die durch den Einsatz von Neuware entstehen';
        this.lifeCycleStagesTexts.secondaryMaterials = 'Umweltauswirkungen, die durch den Einsatz von Recyclingmaterial entstehen';
    }


    private setEnvironmentalEffectsTooltipsGerman() {
        this.environmentalEffectsTexts.climateChange = {
            description: 'Summe aller Treibhausgasemissionen aus In- und Outputs. Folgen ' +
                ' sind eine erhöhte globale Durchschnittstemperatur und regionale Klimaveränderungen.',
            unit: 'Kilogramm Kohlendioxidäquivalent (kg CO₂-Äquivalent). Alle Treibhausgasemissionen werden mit der ' +
                'Menge des globalen Erwärmungspotentials von 1 kg CO₂ verglichen.'
        };
        this.environmentalEffectsTexts.acidification = {
            description: 'Versauerung (z. B. durch sauren Regen) hat zu Waldsterben und zu ' +
                'einem Anstieg der Fischsterblichkeit geführt. Versauerung kann durch Emissionen in Luft, Wasser und ' +
                'Boden verursacht werden. Die wichtigsten Quellen sind Verbrennungsprozesse in Elektrizität, ' +
                'Wärmeerzeugung und Transport.',
            unit: 'Mol Hydronäquivalent (Mol H + Äq.). Die potenzielle ' +
                'Auswirkung von Substanzen, die zur Versauerung beitragen, wird auf ein Mol Hydron bezogen.'
        };
        this.environmentalEffectsTexts.ecotoxicityFreshwater = {
            description: 'Mögliche toxische Auswirkungen auf das Ökosystem, die einzelne Arten schädigen können.',
            unit: 'Vergleichende toxische Einheit für Ökosysteme (CTUe), basierend auf dem Modell USEtox.'
        };
        this.environmentalEffectsTexts.eutrophicationFreshwater = {
            description: 'Eutrophierung wirkt sich aufgrund von Substanzen, die ' +
                'Stickstoff (N) oder Phosphor (P) enthalten, auf die Ökosysteme aus. Wenn Algen zu schnell wachsen,' +
                ' kann das Wasser ohne genügend Sauerstoff zurückbleiben, damit die Fische überleben können. ' +
                'Stickstoffemissionen in die aquatische Umwelt werden hauptsächlich durch Düngemittel in der ' +
                'Landwirtschaft, aber auch durch Verbrennungsprozesse verursacht.',
            unit: 'Kilogramm Phosphoräquivalent (g P Äq.). Die potenziellen Auswirkungen von Substanzen, die zur ' +
                'Süßwasser-Eutrophierung beitragen, werden auf das Äquivalent von einem Kilogramm Phosphor bezogen.'
        };
        this.environmentalEffectsTexts.eutrophicationMarine = {
            description: 'Die Eutrophierung wirkt sich aufgrund von Substanzen, die ' +
                'Stickstoff (N) oder Phosphor (P) enthalten, auf die Ökosysteme aus. In der Regel ist die ' +
                'Verfügbarkeit eines dieser Nährstoffe ein begrenzender Faktor für das Wachstum im Ökosystem, und ' +
                'wenn dieser Nährstoff hinzugefügt wird, wird das Wachstum von Algen oder bestimmten Pflanzen erhöht. ' +
                'Für die Meeresumwelt wird dies hauptsächlich auf einen Anstieg des Stickstoffs (N) zurückzuführen ' +
                'sein. Stickstoffemissionen werden hauptsächlich durch die landwirtschaftliche Nutzung verursacht.',
            unit: 'Kilogramm Stickstoffäquivalent (g N Äq.). Die potenziellen Auswirkungen von Substanzen, ' +
                'die zur marinen Eutrophierung beitragen, werden in Kilogramm Stickstoff umgerechnet.'
        };
        this.environmentalEffectsTexts.eutrophicationTerrestrial = {
            description: 'Eutrophierung wirkt sich aufgrund von Substanzen, die ' +
                'Stickstoff (N) oder Phosphor (P) enthalten, auf die Ökosysteme aus. Diese Nährstoffe verursachen ' +
                'ein Wachstum von Algen oder bestimmten Pflanzen und begrenzen das Wachstum im ursprünglichen ' +
                'Ökosystem.',
            unit: 'Mol Stickstoffäquivalent (Mol N Äq.). Die potenziellen Auswirkungen von ' +
                'Substanzen, die zur terrestrischen Eutrophierung beitragen, werden auf das Äquivalent von Mol ' +
                'Stickstoff bezogen.'
        };
        this.environmentalEffectsTexts.cancerogenicEffects = {
            description: 'Mögliche Auswirkungen auf die menschliche Gesundheit durch ' +
                'Aufnahme von Substanzen über Luft, Wasser und Boden, die Krebsfälle beim Menschen erhöhen können. ' +
                'Direkte Auswirkungen von Produkten auf den Menschen sind dabei nicht enthalten.',
            unit: 'Vergleichende toxische Einheit für den Menschen (CTUh), basierend auf dem Modell USEtox.'
        };
        this.environmentalEffectsTexts.noncancerogenicEffects = {
            description: 'Mögliche Auswirkungen auf die menschliche Gesundheit durch ' +
                'Aufnahme von Substanzen durch Luft, Wasser und Boden, die andere Krankheiten als Krebs verursachen. ' +
                'Direkte Auswirkungen von Produkten auf den Menschen sind dabei nicht enthalten.',
            unit: 'Vergleichende toxische Einheit für den Menschen (CTUh), basierend auf dem Modell USEtox.'
        };
        this.environmentalEffectsTexts.ionizingRadiation = {
            description: 'Mögliche Auswirkungen auf die menschliche Gesundheit durch Exposition gegenüber ionisierender ' +
                'Strahlung (Radioaktivität). Dabei werden Unfälle in Kernkraftwerken nicht berücksichtigt.',
            unit: 'Kilobequerel-Äquivalent (kBq U235 Äq.). Ionisierende Strahlung von ' +
                'verschiedenen Substanzen wird auf das Äquivalent von Kilobequerel Uran 235 bezogen.'
        };
        this.environmentalEffectsTexts.ozoneLayerDepletion = {
            description: 'Die stratosphärische Ozonschicht schützt die Menschheit vor gefährlicher ultravioletter ' +
                'Strahlung (UV-B). Ihre Zerstörung kann Hautkrebsfälle beim Menschen und Schäden an Pflanzen fördern.',
            unit: 'Kilogramm FCKW-11-Äquivalent (g FCKW-11 Äq.). Während der Berechnungen werden die potenziellen ' +
                'Auswirkungen aller für den Ozonabbau relevanten Substanzen in Kilogramm Trichlorfluormethan ' +
                '(auch Freon-11 und R-11 genannt) umgerechnet.'
        };
        this.environmentalEffectsTexts.photochemicalOzoneCreation = {
            description: 'Während uns stratosphärisches Ozon schützt, ist Ozon am ' +
                'Boden schädlich: Es greift organische Verbindungen in Tieren und Pflanzen an und erhöht die Häufigkeit ' +
                'von Atemproblemen, wenn in Städten photochemischer Smog („Sommersmog“) vorhanden ist.',
            unit: 'Kilogramm Äquivalent von flüchtigen organischen Verbindungen ohne Methan (g NMVOC Äq.). Mögliche ' +
                'Auswirkungen von Substanzen, die zur photochemischen Ozonbildung beitragen, werden auf das Äquivalent ' +
                'von Kilogramm NMVOC (z. B. Alkohole, Aromaten usw.) bezogen.'
        };
        this.environmentalEffectsTexts.respiratoryEffects = {
            description: 'Auswirkungen auf die menschliche Gesundheit durch Emissionen von Feinstaub und seinen Vorläufern ' +
                '(z. B. NOx, SO2). Je kleiner die Partikel sind, desto gefährlicher sind sie normalerweise, da ihre '
                + 'Lungengängigkeit zunimmt.',
            unit: 'Anzahl der durch Feinstaub verursachten Krankheitsfälle, basierend auf einem von UNEP empfohlenen Modell.'
        };
        this.environmentalEffectsTexts.waterScarcity = {
            description: 'Die Entnahme von Wasser aus Seen, Flüssen oder Grundwasser kann zur „Erschöpfung“ des verfügbaren ' +
                'Wassers beitragen. Die Wirkungskategorie berücksichtigt die Verfügbarkeit oder Knappheit von Wasser in den ' +
                'Regionen, in denen die Aktivität stattfindet, sofern diese Informationen bekannt sind.',
            unit: 'Kubikmeter (m3) Wasserverbrauch bezogen auf die lokale Wasserknappheit.'
        };
        this.environmentalEffectsTexts.fossils = {
            description: 'Die Erde enthält eine begrenzte Menge nicht erneuerbarer Ressourcen wie Metalle, ' +
                'Mineralien und fossile Brennstoffe wie Kohle, Öl und Gas. Das Extrahieren einer hohen Konzentration von ' +
                'Ressourcen heute wird zukünftige Generationen dazu zwingen, Ressourcen mit geringerer Konzentration oder ' +
                'geringerem Wert zu extrahieren. Beispielsweise kann die Erschöpfung fossiler Brennstoffe dazu führen, dass ' +
                'fossile Brennstoffe für zukünftige Generationen nicht verfügbar sind.',
            unit: 'MJ. Summe fossiler Energieträger, basierend auf ihrem Heizwert.'
        };
        this.environmentalEffectsTexts.landUse = {
            description: 'Nutzung und Umwandlung von Land für Land- und Forstwirtschaft, Straßen, Wohnen, ' +
                'Bergbau oder andere Zwecke. Die Auswirkungen können variieren und umfassen den Verlust von Arten, den ' +
                'Gehalt an organischer Substanz im Boden oder den Verlust des Bodens selbst (Erosion).',
            unit: 'dimensionslos (pt). Dies ist ein aggregierter Indikator, der einen Bodenqualitätsindex darstellt, welcher ' +
                'auf dem LANCA-Modell basiert.'
        };
        this.environmentalEffectsTexts.mineralsAndMetals = {
            description: 'Die Erde enthält eine begrenzte Menge nicht erneuerbarer Ressourcen ' +
                'wie Metalle, Mineralien und fossile Brennstoffe wie Kohle, Öl und Gas. Das Extrahieren einer hohen ' +
                'Konzentration von Ressourcen heute wird zukünftige Generationen dazu zwingen, Ressourcen mit geringerer ' +
                'Konzentration oder geringerem Wert zu extrahieren. Beispielsweise kann die Erschöpfung fossiler Brennstoffe ' +
                'dazu führen, dass fossile Brennstoffe für zukünftige Generationen nicht verfügbar sind.',
            unit: 'Kilogramm Antimonäquivalent (g Sb Äq.). Die Menge an Materialien, die zur Erschöpfung der Ressourcen ' +
                'beiträgt, wird auf das Äquivalent von 1 Kilogramm Antimon bezogen.'
        };
    }

    private setEnvironmentalEffectsTooltipsEnglish() {
        this.environmentalEffectsTexts.climateChange = {
            description: 'Sum of all greenhouse gas emissions resulting from inputs and outputs. ' +
                'Consequences include increased average global temperatures and regional climatic changes.',
            unit: 'Kilogram of Carbon Dioxide equivalent (kg CO₂ eq). All greenhouse gas emissions ' +
                'are compared to the amount of the global warming potential of 1 kg of CO₂.'
        };
        this.environmentalEffectsTexts.acidification = {
            description: 'Acidification (e.g. by acid rain) has led to forest dieback and an increase ' +
                'in fish mortality. Acidification can be caused by emissions getting into the air, water and soil. The ' +
                'most significant sources are combustion processes in electricity, heating production and transport.',
            unit: 'Mole of Hydron equivalent (mol H+ eq). The potential impact of substances ' +
                'contributing to acidification is converted to the equivalent of moles of Hydron.'
        };
        this.environmentalEffectsTexts.ecotoxicityFreshwater = {
            description: 'Potential toxic impacts on an ecosystem, which may damage individual species.',
            unit: 'Comparative Toxic Unit for ecosystems (CTUe), based on the model USEtox.'
        };
        this.environmentalEffectsTexts.eutrophicationFreshwater = {
            description: 'Eutrophication impacts ecosystems due to substances containing ' +
                'nitrogen (N) or phosphorus (P). If algae grows too rapidly, it can leave water without enough oxygen ' +
                'for fish to survive. Nitrogen emissions into the aquatic environment are caused largely by fertilisers ' +
                'used in agriculture, but also by combustion processes.',
            unit: 'kilograms of Phosphorus equivalent (g P eq). The potential impact of substances contributing to ' +
                'freshwater eutrophication is converted to the equivalent of Kilograms of Phosphorus.'
        };
        this.environmentalEffectsTexts.eutrophicationMarine = {
            description: 'Eutrophication impacts ecosystems due to substances containing ' +
                'nitrogen (N) or phosphorus (P). As a rule, the availability of one of these nutrients will be a ' +
                'limiting factor for growth in the ecosystem, and if this nutrient is added, the growth of algae or ' +
                'specific plants will be increased. For the marine environment this will be mainly due to an increase ' +
                'of nitrogen (N). Nitrogen emissions are caused largely by the agricultural use of fertilisers, but ' +
                'also by combustion processes.',
            unit: 'kilogram of Nitrogen equivalent (g N eq). The potential impact of substances contributing to marine ' +
                'eutrophication is converted to the equivalent of kilograms of Nitrogen.'
        };
        this.environmentalEffectsTexts.eutrophicationTerrestrial = {
            description: 'Eutrophication impacts ecosystems due to substances containing nitrogen (N) or phosphorus (P). ' +
                'These nutrients cause a growth of algae or specific plants and limit growth in the original ecosystem.',
            unit: 'Mole of Nitrogen equivalent (mol N eq). The potential impact of substances contributing to terrestrial ' +
                'eutrophication is converted to the equivalent of moles of Nitrogen.'
        };
        this.environmentalEffectsTexts.cancerogenicEffects = {
            description: 'Potential impacts on human health caused by absorbing substances through the air, water and ' +
                'soil which can increase cancer cases in humans. Direct effects of products on humans are thereby not included.',
            unit: 'Comparative Toxic Unit for humans ' +
                '(CTUh), based on the model USEtox.'
        };
        this.environmentalEffectsTexts.noncancerogenicEffects = {
            description: 'Potential impacts on human health caused by absorbing substances through the air, water and soil ' +
                'causing diseases other than cancer. Direct effects of products on humans are thereby not included.',
            unit: 'Comparative Toxic Unit for humans (CTUh), based on the model USEtox.'
        };
        this.environmentalEffectsTexts.ionizingRadiation = {
            description: 'Potential impacts on human health by exposure to ionising radiation ' +
                '(radioactivity). Thereby, no accidents in nuclear power plants are considered.',
            unit: 'Kilobequerel equivalent (kBq U235 eq). Ionising radiation from different substances are converted to ' +
                'the equivalent of kilobequerels of Uranium 235.'
        };
        this.environmentalEffectsTexts.ozoneLayerDepletion = {
            description: 'The stratospheric Ozone layer protects mankind from hazardous ' +
                'ultraviolet radiation (UV-B). Its depletion can increase skin cancer cases in humans and damage to plants.',
            unit: 'kilogram of CFC-11 equivalent (g CFC-11 eq). During the calculations, ' +
                'the potential impacts of all relevant substances for ozone depletion are converted to their equivalent ' +
                'of kilograms of Trichlorofluoromethane (also called Freon-11 and R-11).'
        };
        this.environmentalEffectsTexts.photochemicalOzoneCreation = {
            description: 'While stratospheric ozone protects us, ozone on the ground is ' +
                'harmful: it attacks organic compounds in animals and plants, it increases the frequency of respiratory ' +
                'problems when photochemical smog (“summer smog”) is present in cities.',
            unit: 'kilogram of Non-Methane Volatile Organic Compound equivalent (g NMVOC eq). The potential impact of substances ' +
                'contributing to photochemical ozone formation are converted into the equivalent of kilograms of ' +
                'Non-Methane Volatile Organic Compounds (e.g. alcohols, aromatics, etc.).'
        };
        this.environmentalEffectsTexts.respiratoryEffects = {
            description: 'Impacts on human health caused by emissions of Particulate Matter (PM) and its precursors (e.g. NOx, ' +
                'SO2). Usually, the smaller the particles are, the more dangerous they are, since their respirability increases.',
            unit: 'number of disease incidences caused by particulate matter, based on a model recommended by UNEP.'
        };
        this.environmentalEffectsTexts.waterScarcity = {
            description: 'The withdrawal of water from lakes, rivers or groundwater can contribute ' +
                'to the ‘depletion’ of available water. The impact category considers the availability or scarcity of ' +
                'water in the regions where the activity takes place, if this information is known.',
            unit: 'cubic metres (m3) of water use related to the local scarcity of water.'
        };
        this.environmentalEffectsTexts.fossils = {
            description: 'The earth contains a finite amount of non-renewable resources, such as metals, ' +
                'minerals and fossil fuels like coal, oil and gas. Extracting a high concentration of resources today ' +
                'will force future generations to extract lower concentration or lower value resources. For example, ' +
                'the depletion of fossil fuels may lead to the non-availability of fossil fuels for future generations.',
            unit: 'MJ. Sum of fossil based energy carriers characterized by their net calorific value.'
        };
        this.environmentalEffectsTexts.landUse = {
            description: 'Use and transformation of land for agriculture, forestry, roads, housing, mining ' +
                'or other purposes. The impacts can vary and include loss of species, of the organic matter content of ' +
                'soil, or loss of the soil itself (erosion).',
            unit: 'dimensionless (pt). This is an aggregated indicator represent a Soil Quality Index based on the LANCA model.'
        };
        this.environmentalEffectsTexts.mineralsAndMetals = {
            description: 'The earth contains a finite amount of non-renewable resources, such as ' +
                'metals, minerals and fossil fuels like coal, oil and gas. Extracting a high concentration of resources ' +
                'today will force future generations to extract lower concentration or lower value resources. For example, ' +
                'the depletion of fossil fuels may lead to the non-availability of fossil fuels for future generations.',
            unit: 'kilogram of Antimony equivalent (g Sb eq). The amount of materials contributing to ' +
                'resource depletion are converted into equivalents of kilograms of Antimony.'
        };
    }
}
