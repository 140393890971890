import { getDialogConfig } from 'src/app/util/dialog-util';
import { CompositeMaterialExportProfileDto } from '../../../../data-transfer/entities/material-entities/composite-material-export-profile-dto';
import { MultiMaterialCompositeDto } from 'src/app/data-transfer/entities/material-entities/multi-material-composite-dto';
import { TranslateService } from '@ngx-translate/core';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Observable } from 'rxjs';
import { ExportProfileDialogData } from './../../components/export-profiles/export-profiles/export-profiles.component';
import { SecurityPrincipalDto } from './../../../../data-transfer/entities/security-principal-dto';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { Component, OnInit, Inject, ElementRef, ViewChild } from '@angular/core';
import { PackagingUnitDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-dto';
import { PackagingComponentDto } from 'src/app/data-transfer/entities/component-entities/packaging-component-dto';
import { PackagingUnitExportProfileDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-export-profile-dto';
import { ExportProfileDialogComponent } from '../../components/export-profiles/export-profile-dialog/export-profile-dialog.component';
import { PackagingComponentExportProfileDto } from 'src/app/data-transfer/entities/component-entities/packaging-component-export-profile-dto';
import { map, startWith } from 'rxjs/operators';
import { SimpleConfirmDialogComponent } from 'src/app/components/dialogs/simple-confirm-dialog/simple-confirm-dialog.component';
import { SimpleDialogData } from 'src/app/components/dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { PackagingSystemExportProfileDto } from 'src/app/data-transfer/entities/packaging-system-entities/packaging-system-export-profile-dto';
import { PackagingSystemDto } from 'src/app/data-transfer/entities/packaging-system-entities/packaging-system-dto';
import { DialogActions } from 'src/app/model/dictionary';
import { ExportProfileDto } from 'src/app/data-transfer/entities/export-profile-dto';
import { ExportProfilesDataSource } from '../../components/export-profiles/export-profiles-data-source';

const SELECTED_ITEMS_CONTROL_NAME = 'selectedItemsControl';

@Component({
  selector: 'app-export-dialog',
  templateUrl: './export-dialog.component.html',
  styleUrls: ['./export-dialog.component.scss']
})
export class ExportDialogComponent implements OnInit {

  @ViewChild('userGroupInput') userGroupInput!: ElementRef<HTMLInputElement>;

  separatorKeysCodes: number[] = [ENTER, COMMA];
  exportForm!: FormGroup;

  filteredPrincipals!: Observable<SecurityPrincipalDto[]>;
  allPrincipals!: SecurityPrincipalDto[];
  selectedPrincipals: SecurityPrincipalDto[] = [];

  packagingSystems!: PackagingSystemDto[];
  packagingUnits!: PackagingUnitDto[];
  components!: PackagingComponentDto[];
  materials!: MultiMaterialCompositeDto[];

  isPackagingSystemsExport = false;
  isPackagingUnitsExport = false;
  isComponentsExport = false;
  isMaterialExport = false;

  selectedProfile?: ExportProfileDto;
  selectedSubProfile?: ExportProfileDto;

  profilesDataSource!: ExportProfilesDataSource;
  subProfilesDataSource!: ExportProfilesDataSource;
  displayedColumns = ['profileName', 'viewProfile'];

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<ExportDialogComponent>,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private translateService: TranslateService
  ) {
    if (this.data) {
      this.allPrincipals = this.data.allPrincipals;
      this.profilesDataSource = new ExportProfilesDataSource();
      this.profilesDataSource.data = this.data.allProfiles;
      if (this.data.packagingSystems) {
        this.packagingSystems = this.data.packagingSystems;
        this.isPackagingSystemsExport = true;
        this.subProfilesDataSource = new ExportProfilesDataSource();
        this.subProfilesDataSource.data = data.allSubProfiles;
      } else if (this.data.packagingUnits) {
        this.packagingUnits = this.data.packagingUnits;
        this.isPackagingUnitsExport = true;
      } else if (this.data.components) {
        this.components = this.data.components;
        this.isComponentsExport = true;
      } else if (data.materials) {
        this.materials = this.data.materials;
        this.isMaterialExport = true;
      }
    }
  }

  get principalCtrl() {
    return this.exportForm?.controls.selectedPrincipalsControl as FormControl;
  }

  ngOnInit(): void {
    this.exportForm = this.formBuilder.group({
      selectedPrincipalsControl: [null],
    });
    if (this.isPackagingSystemsExport) {
      this.exportForm.addControl(SELECTED_ITEMS_CONTROL_NAME, this.formBuilder.control(this.packagingSystems));
    } else if (this.isPackagingUnitsExport) {
      this.exportForm.addControl(SELECTED_ITEMS_CONTROL_NAME, this.formBuilder.control(this.packagingUnits));
    } else if (this.isComponentsExport) {
      this.exportForm.addControl(SELECTED_ITEMS_CONTROL_NAME, this.formBuilder.control(this.components));
    } else if (this.isMaterialExport) {
      this.exportForm.addControl(SELECTED_ITEMS_CONTROL_NAME, this.formBuilder.control(this.materials));
    }
    this.filteredPrincipals = this.principalCtrl.valueChanges.pipe(
      startWith(''),
      map(value => (typeof value === 'string' ? value : value.name)),
      map(principal => (principal ? this._filter(principal) : this.allPrincipals.filter(pr =>
        this.selectedPrincipals.find(selectedPrincipal => selectedPrincipal.name === pr.name) == null).slice())),
    );
  }

  async export() {
    this.exportForm.markAllAsTouched();

    if (this.exportForm.invalid || this.selectedPrincipals.length === 0) { return; }
    if (!this.areMinimalProfileRequirementsMet()) {
      const dialogConfig = getDialogConfig(this.getProfileConfirmationDialogData());
      const dialogRef = this.dialog.open(SimpleConfirmDialogComponent, dialogConfig);
      const dialogResult = await dialogRef.afterClosed().toPromise();
      if (dialogResult.event === DialogActions.REJECT) { return; }
    }
    const profileId =
      this.isPackagingSystemsExport ? (this.selectedProfile as PackagingSystemExportProfileDto)?.id :
        this.isPackagingUnitsExport ? (this.selectedProfile as PackagingUnitExportProfileDto)?.id :
          this.isComponentsExport ? (this.selectedProfile as PackagingComponentExportProfileDto)?.id :
            this.isMaterialExport ? (this.selectedProfile as CompositeMaterialExportProfileDto)?.id : null;
    const subprofileId = this.selectedSubProfile?.id ?? null;
    this.dialogRef.close({
      event: DialogActions.CONFIRM,
      selectedPrincipalIds: this.selectedPrincipals.map(x => x.id),
      selectedIds: this.exportForm.get(SELECTED_ITEMS_CONTROL_NAME)?.value?.map((x: any) => x.id),
      profileId,
      subprofileId
    });
  }

  private areMinimalProfileRequirementsMet() {
    if (!this.selectedProfile) { return true; }
    if (this.isPackagingSystemsExport) {
      const profile = this.selectedProfile as PackagingSystemExportProfileDto;
      return profile.brandName === true && profile.productName === true && profile.gtin === true;
    } else if (this.isPackagingUnitsExport) {
      const profile = this.selectedProfile as PackagingUnitExportProfileDto;
      return profile.brandName === true && profile.productName === true && profile.gtin === true;
    } else if (this.isComponentsExport) {
      const profile = this.selectedProfile as PackagingComponentExportProfileDto;
      return profile.articleName && profile.gtin === true;
    } else if (this.isMaterialExport) {
      const profile = this.selectedProfile as CompositeMaterialExportProfileDto;
      return profile.articleName && profile.manufacturerName === true;
    }
  }

  private getProfileConfirmationDialogData(): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.information'),
      messages: [this.translateService.instant('dataManagement.directory.dialog.export.profileIncomplete')],
      icon: 'info'
    };
  }

  cancel() {
    this.dialogRef.close({ event: DialogActions.REJECT });
  }

  viewProfile(
    profile: ExportProfileDto
  ) {
    let packagingSystemProfile: PackagingSystemExportProfileDto | null = null;
    let packagingUnitProfile: PackagingUnitExportProfileDto | null = null;
    let componentProfile: PackagingComponentExportProfileDto | null = null;
    let materialProfile: CompositeMaterialExportProfileDto | null = null;
    if (this.isPackagingSystemsExport) {
      packagingSystemProfile = profile as PackagingSystemExportProfileDto;
    } else if (this.isPackagingUnitsExport) {
      packagingUnitProfile = profile as PackagingUnitExportProfileDto;
    } else if (this.isComponentsExport) {
      componentProfile = profile as PackagingComponentExportProfileDto;
    } else if (this.isMaterialExport) {
      materialProfile = profile as CompositeMaterialExportProfileDto;
    } else { return; }
    const dialogData: ExportProfileDialogData = {
      packagingSystemProfile,
      packagingUnitProfile,
      componentProfile,
      materialProfile,
      namesUsed: new Set<string>(),
      isReadOnly: true
    };
    const dialogConfig = getDialogConfig(dialogData);
    this.dialog.open(ExportProfileDialogComponent, dialogConfig);
  }

  viewSubProfile(profile: PackagingUnitExportProfileDto) {
    const dialogData: ExportProfileDialogData = {
      packagingSystemProfile: null,
      packagingUnitProfile: profile,
      componentProfile: null,
      materialProfile: null,
      namesUsed: new Set<string>(),
      isReadOnly: true
    };
    const dialogConfig = getDialogConfig(dialogData);
    this.dialog.open(ExportProfileDialogComponent, dialogConfig);
  }

  selectedProfileChanged(profile: any) {
    this.selectedProfile = profile;
    if (!this.isPackagingSystemsExport || !profile) { return; }
    const subProfileId = (profile as PackagingSystemExportProfileDto).defaultPackagingUnitExportProfileId;
    if (!this.subProfilesDataSource || this.subProfilesDataSource.data.length == 0 || subProfileId == null) { return; }
    this.selectedSubProfile = this.subProfilesDataSource.data.find(x => x.id === subProfileId) as PackagingUnitExportProfileDto;
  }

  selectedSubprofileChanged(profile: any) {
    this.selectedSubProfile = profile;
  }

  removePrincipal(principal: SecurityPrincipalDto): void {
    const index = this.selectedPrincipals.indexOf(principal);
    if (index >= 0) {
      this.selectedPrincipals.splice(index, 1);
      this.userGroupInput.nativeElement.value = '';
      this.principalCtrl.setValue('');
    }
  }

  addPrincipal(principal: SecurityPrincipalDto): void {
    this.selectedPrincipals.push(principal);
    this.userGroupInput.nativeElement.value = '';
    this.principalCtrl.setValue('');
  }

  private _filter(value: string): SecurityPrincipalDto[] {
    const filterValue = value.toLowerCase();
    const filteredPrincipals = this.allPrincipals
      .filter(principal => this.selectedPrincipals.find(selectedPrincipal => selectedPrincipal.name === principal.name) == null);
    return filteredPrincipals.filter(principal => principal.name.toLowerCase().includes(filterValue));
  }
}
