import { CountryDto } from './../../data-transfer/entities/country-dto';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class CountriesService {

    private countries: CountryDto[] = [];
    private countriesEng: CountryDto[] = [];

    setCountries(countries: CountryDto[]) {
        this.countries = countries;
    }

    setCountriesEng(countries: CountryDto[]) {
        this.countriesEng = countries;
    }

    getCountries(): CountryDto[] {
        return this.countries;
    }

    getCountriesEng(): CountryDto[] {
        return this.countriesEng;
    }

    getCountryNameByCode(countryCode: string): string {
        const countryDto = this.countries.find(x => x.code === countryCode);
        return countryDto?.name ?? '';
    }

    translateCountryName(countryName: string): string {
        const countryCode = this.getCountriesEng().find(x => x.name === countryName)?.code;
        const translatedName = this.getCountries().find(y => y.code === countryCode)?.name;
        return translatedName ?? countryName;
    }
}
