<table mat-table [dataSource]="dataSource" id="components-table" matSort>

  <ng-container matColumnDef="id">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.filterMenu"
          [matMenuTriggerData]="{ caller: 'id' }" type="button">
          {{ 'commonPackagingFields.id' | translate }}
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('id')">filter_alt</mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
  </ng-container>

  <ng-container matColumnDef="tracking">
    <th mat-header-cell *matHeaderCellDef [hidden]="!displayTrackedColumn"></th>
    <td mat-cell *matCellDef="let element" [hidden]="!displayTrackedColumn">
      <mat-icon *ngIf="element.hasExternalTracking">track_changes</mat-icon>
    </td>
  </ng-container>

  <ng-container matColumnDef="tags">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.tagFilterMenu"
          [matMenuTriggerData]="{ caller: 'associatedTagIdentifiers', possibleTags: getPossibleTags(dataSource) }"
          type="button">
          Tags
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('associatedTagIdentifiers')">filter_alt
          </mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <app-tag-directive [tags]="getTagsFromIds(element.associatedTagIdentifiers)" [maxDisplayed]="2">
      </app-tag-directive>
    </td>
  </ng-container>

  <ng-container matColumnDef="packagingComponentCategoryName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.checkboxFilterMenu"
          [matMenuTriggerData]="{ caller: 'packagingComponentCategoryName', categories: getComponentCategoryNames() }"
          type="button">
          {{ 'component.componentType' | translate }}
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('packagingComponentCategoryName')">filter_alt
          </mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <img [src]="getComponentTypeImage(element.packagingComponentCategoryId)" class="component-type-image"
        [matTooltip]="element.packagingComponentCategoryName">
    </td>
  </ng-container>

  <ng-container matColumnDef="packagingComponentCategoryNameNoFilter">
    <th mat-header-cell *matHeaderCellDef>
      <span class="header-text">{{ 'component.componentType' | translate }}</span>
    </th>
    <td mat-cell *matCellDef="let element"> {{ element.packagingComponentCategoryName }} </td>
  </ng-container>

  <ng-container matColumnDef="packagingComponentSubtypeName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.checkboxFilterMenu"
          [matMenuTriggerData]="{ caller: 'packagingComponentSubtypeName', categories: getSpecificComponentTypeNames() }"
          type="button">
          {{ 'component.componentSpecificType' | translate }}
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('packagingComponentSubtypeName')">filter_alt
          </mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element"> {{ element.packagingComponentSubtypeName }} </td>
  </ng-container>

  <ng-container matColumnDef="articleName">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.filterMenu"
          [matMenuTriggerData]="{ caller: 'articleName' }" type="button">
          {{ 'component.fields.articleName' | translate }}
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('articleName')">filter_alt</mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element"> {{element.articleName}} </td>
  </ng-container>

  <ng-container matColumnDef="articleNumber">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.filterMenu"
          [matMenuTriggerData]="{ caller: 'articleNumber' }" type="button">
          {{ 'component.fields.articleNumber' | translate }}
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('articleNumber')">filter_alt</mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element"> {{element.articleNumber}} </td>
  </ng-container>

  <ng-container matColumnDef="creationTimestamp">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.dateFilterMenu"
          [matMenuTriggerData]="{ caller: 'creationTimestamp' }" type="button">
          {{ 'commonPackagingFields.creationDate' | translate }}
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('creationTimestamp')">filter_alt
          </mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      {{formatDateToString(element.creationTimestamp)}}
    </td>
  </ng-container>

  <ng-container matColumnDef="lastModificationTimestamp">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.dateFilterMenu"
          [matMenuTriggerData]="{ caller: 'lastModificationTimestamp' }" type="button">
          {{ 'commonPackagingFields.lastModificationDate' | translate }}
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('lastModificationTimestamp')">filter_alt
          </mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      {{formatDateToString(element.lastModificationTimestamp)}}
    </td>
  </ng-container>

  <ng-container matColumnDef="manufacturer">
    <th mat-header-cell *matHeaderCellDef mat-sort-header>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" [matMenuTriggerFor]="filterComponent.filterMenu"
          [matMenuTriggerData]="{ caller: 'manufacturer' }" type="button">
          {{ 'component.fields.manufacturer' | translate }}
          <mat-icon class="filter-icon" *ngIf="filteredColumns.includes('manufacturer')">filter_alt
          </mat-icon>
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element"> {{element.manufacturer}} </td>
  </ng-container>

  <ng-container matColumnDef="action">
    <th mat-header-cell *matHeaderCellDef>
      <span class="header-text">{{ 'dataManagement.actions' | translate }}</span>
    </th>
    <td mat-cell *matCellDef="let element">
      <button *ngIf="!isRecyclingBinSelected" mat-icon-button (click)="navigateToComponent(element)"
        matTooltip="{{ 'component.editComponent' | translate }}" type="button">
        <mat-icon>edit</mat-icon>
      </button>
      <button mat-icon-button (click)="deletePackagingPart([element])"
        matTooltip="{{ 'component.deleteComponent' | translate }}" type="button">
        <mat-icon>delete_forever</mat-icon>
      </button>
      <button mat-icon-button [matMenuTriggerFor]="reports.reportsMenu" (click)="reports.loadReports()"
        matTooltip="{{ 'report.tooltips.buttonTooltip' | translate }}" *ngIf="!isRecyclingBinSelected" type="button">
        <mat-icon>topic</mat-icon>
      </button>

      <app-reports-menu-for-packaging-part #reports [callerItemId]="element.id" [callerPage]="callerPageId"
        [callerLevel]="callerLevelId">
      </app-reports-menu-for-packaging-part>
    </td>
  </ng-container>

  <ng-container matColumnDef="select">
    <th mat-header-cell *matHeaderCellDef>
      <div (click)="$event.stopPropagation()">
        <button mat-button class="filter-button" (click)="selectAllComponents()" type="button">
          {{ 'common.buttons.selectAll' | translate }} <br> ({{selectedComponents.length}}
          {{ 'common.text.selected' | translate }})
        </button>
      </div>
    </th>
    <td mat-cell *matCellDef="let element">
      <mat-checkbox [checked]="isItemSelected(element)" (change)="setMultipleItemsSelection($event.checked, element)">
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="single-select">
    <th mat-header-cell *matHeaderCellDef>
      <span class="header-text"> {{ 'common.buttons.select' | translate }} </span>
    </th>
    <td mat-cell *matCellDef="let element">
      <mat-checkbox [checked]="isItemSelected(element)" (change)="setSingleItemSelection($event.checked, element)">
      </mat-checkbox>
    </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>

  <app-filter-menu #filterComponent="columnFilterComponent" [dataSource]="dataSource"></app-filter-menu>
</table>
