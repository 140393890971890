import { Component, Input } from '@angular/core';
import { PackagingSystemLifeCycleResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-system-life-cycle-result-dto';
import { PackagingSystemDto } from 'src/app/data-transfer/entities/packaging-system-entities/packaging-system-dto';
import { LifeCycleService } from 'src/app/util/analyses-util/live-cycle/life-cycle-service';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { AnalysisApiService } from 'src/app/data-transfer/services/analysis-api-service';
import { TreeLevelParent } from '../../shared-components/parent-components/packaging-part-tree/packaging-part-tree.component';
import { AnalysisLifeCyclePackagingPartComponent } from '../analysis-life-cycle-packaging-part/analysis-life-cycle-packaging-part.component';
import {LicenseService} from 'src/app/services/licensing-service'
import {DomSanitizer} from '@angular/platform-browser';
import { BuyType } from 'src/app/components/shared-components/shop/buying-from-shop/buying-from-shop.component'
@Component({
  selector: 'app-analysis-life-cycle-packaging-system',
  templateUrl: './analysis-life-cycle-packaging-system.component.html',
  styleUrls: ['./analysis-life-cycle-packaging-system.component.scss']
})
export class AnalysisLifeCyclePackagingSystemComponent extends AnalysisLifeCyclePackagingPartComponent {

  @Input() selectedAnalysisVersion!: PackagingSystemLifeCycleResultDto;
  @Input() packagingSystem!: PackagingSystemDto;

  buyType = BuyType;

  constructor(
    private lcaService: LifeCycleService,
    protected translateService: TranslateService,
    protected analysisApiService: AnalysisApiService,
    protected dialog: MatDialog,
    protected licenseService: LicenseService,
    protected sanitizer: DomSanitizer
  ) {
    super(translateService, analysisApiService, dialog, licenseService,sanitizer);
    this.selectedNodeType = PackagingPart.System;
  }

  onNodeSelected(node: TreeLevelParent | null) {
    if (!node) { return; }
    this.selectedNodeType = node.type;
    if (node.type === PackagingPart.System) {
      this.onSystemNodeChanged(node.id);
    } else if (node.type === PackagingPart.Unit) {
      this.onUnitNodeChanged(node.id);
    } else if (node.type === PackagingPart.Component) {
      this.onComponentNodeChanged(node.id);
    }
    super.onNodeChanged();
  }

  private onSystemNodeChanged(systemId: number | null) {
    if (systemId == null) { return; }
    const selectedResult = this.lcaService.findSystemResultRecursively(this.selectedAnalysisVersion, systemId);
    if (!selectedResult) { return; }
    this.dataSourceForCountry = this.lcaService.getPackagingSystemLcaResultFromDto(selectedResult);
    this.dataSourceForCountry.effectRelevances = this.filterRelevantEffects(this.dataSourceForCountry.effectRelevances);
    super.setOriginalLcaData();
    this.volume = null;
    this.weight = null;
  }

  private onUnitNodeChanged(unitId: number | null) {
    if (unitId == null) { return; }
    const selectedResult = this.lcaService.findUnitResultRecursively(this.selectedAnalysisVersion, unitId);
    if (!selectedResult) { return; }
    this.dataSourceForCountry = this.lcaService.getPackagingUnitLcaResultFromDto(selectedResult);
    this.dataSourceForCountry.effectRelevances = this.filterRelevantEffects(this.dataSourceForCountry.effectRelevances);
    super.setOriginalLcaData();
    this.volume = selectedResult.analyzedPackagingUnit.packagingVolume;
    this.weight = selectedResult.analyzedPackagingUnit.packagingQuantity;
  }

  private onComponentNodeChanged(componentId: number | null) {
    if (componentId == null) { return; }
    const selectedResult = this.lcaService.findComponentResultRecursively(this.selectedAnalysisVersion, componentId);
    if (!selectedResult) { return; }
    this.dataSourceForCountry = this.lcaService.getComponentLcaResultFromDto(selectedResult);
    this.dataSourceForCountry.effectRelevances = this.filterRelevantEffects(this.dataSourceForCountry.effectRelevances);
    super.setOriginalLcaData();
    this.volume = null;
    this.weight = null;
  }
}
