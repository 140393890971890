import { RemovabilityConditionDto } from 'src/app/data-transfer/entities/removability-condition-dto';
import { AdditionalPackagingComponentsParentDialogComponent, ComponentDialogData } from '../additional-packaging-components-parent-dialog/additional-packaging-components-parent-dialog.component';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { PackagingComponentTypesEnum } from 'src/app/model/packaging-component-types-enum';
import { GtinCheckService } from 'src/app/services/component-services/gtin-check-service';
import { AttachedToMainBody, RemovabilityConditionId } from 'src/app/services/component-services/field-name-constants';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface InlayDialogData extends ComponentDialogData {
  removabilityConditions?: RemovabilityConditionDto[];
}

@Component({
  selector: 'app-inlay-dialog',
  templateUrl: './inlay-dialog.component.html',
  styleUrls: ['./inlay-dialog.component.scss']
})
export class InlayDialogComponent extends AdditionalPackagingComponentsParentDialogComponent implements OnInit {

  addEditInlayForm: FormGroup;
  removabilityConditions: RemovabilityConditionDto[];

  constructor(
    protected dialogRef: MatDialogRef<InlayDialogComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: any,
    protected gtinCheckService: GtinCheckService
  ) {
    super(dialogRef, gtinCheckService);
    this.callerId = PackagingComponentTypesEnum.Inlay;
    super.setCommonComponentData(this.data);
    this.removabilityConditions = data.removabilityConditions;
    this.addEditInlayForm = this.createFormCopy(data.baseForm);
    super.subscribeToValueChanges(this.addEditInlayForm);
  }

  ngOnInit(): void {
    this.checkInlayTypeDisableFields(this.addEditInlayForm.controls.isAdhesiveUsed.value);
    if (!this.canEditForm) {
      this.addEditInlayForm.disable();
    }
    this.selectedManufacturingCountry = this.addEditInlayForm.controls.manufacturingCountry.value ?? '';
  }

  async doAction() { super.doAction(this.addEditInlayForm); }

  onAdhesivesOptionChange(isAdhesiveUsed: boolean | null) {
    this.checkInlayTypeDisableFields(isAdhesiveUsed);
  }

  private checkInlayTypeDisableFields(isAdhesiveUsed: boolean | null) {
    const requireRemovabilityCondition = isAdhesiveUsed ?? false;
    if (requireRemovabilityCondition) {
      this.addEditInlayForm.get(RemovabilityConditionId)?.enable();
    } else {
      this.addEditInlayForm.get(RemovabilityConditionId)?.disable();
      this.addEditInlayForm.get(RemovabilityConditionId)?.patchValue(null);
    }
    const requireAttachedToMainBody = !isAdhesiveUsed;
    if (requireAttachedToMainBody) {
      this.addEditInlayForm.get(AttachedToMainBody)?.enable();
    } else {
      this.addEditInlayForm.get(AttachedToMainBody)?.disable();
      this.addEditInlayForm.get(AttachedToMainBody)?.patchValue(true);
    }
  }
}
