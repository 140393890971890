import { TranslateService } from '@ngx-translate/core';
import { RatingsHandler } from './../../../../util/analyses-util/ratings-handler';
import { Component, Input, OnInit, OnChanges, SimpleChanges, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import * as  Highcharts from 'highcharts';
import { PlotSeriesDataLabelsOptions } from 'highcharts';
import Drilldown from 'highcharts/modules/drilldown';
Drilldown(Highcharts);
import Exporting from 'highcharts/modules/exporting';
Exporting(Highcharts);
import ExportData from 'highcharts/modules/export-data';
import {
  DARK_THEME_FONT, LIGHT_THEME_FONT, DARK_THEME_BACKGROUND, LIGHT_THEME_BACKGROUND,
  DARK_THEME_BORDER, LIGHT_THEME_BORDER
} from 'src/app/model/charts-color-collection';
import { RecyclabilityAggregatedEvaluationResult } from 'src/app/util/analyses-util/recyclability/recyclability-aggregated-evaluation-result';
ExportData(Highcharts);
require('highcharts/modules/pattern-fill')(Highcharts);
import Accessibility from 'highcharts/modules/accessibility';
import { RecyclabilityResult } from 'src/app/model/evaluations/recyclability-result';
Accessibility(Highcharts);

@Component({
  selector: 'app-recyclability-chart',
  templateUrl: './recyclability-chart.component.html',
  styleUrls: ['./recyclability-chart.component.scss']
})
export class RecyclabilityChartComponent implements OnInit, OnChanges {

  @ViewChild('minimalDiv') minimalDiv!: ElementRef;

  @Input() chartRawData!: RecyclabilityResult | RecyclabilityAggregatedEvaluationResult;
  @Input() chartHeight = -1;
  @Input() chartWidth = -1;
  @Input() showLabels = false;
  @Input() minimizeLegend = false;
  @Input() showExport = false;
  @Input() showTitle = false;
  @Input() isDarkTheme = false;
  @Input() hasDrilldown = true;

  @Output() newAnalysisRequested = new EventEmitter();

  isRecPossible = true;

  updateFlag = false;
  highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    chart: {
      type: 'pie',
      marginBottom: 100,
      style: {
        fontFamily: 'Helsinki, sans-serif',
        fontWeight: 'normal'
      }
    },
    title: {
      style: {
        fontSize: '30px',
        'font-family': '\'Helsinki\', sans-serif',
      }
    },
    lang: {
      viewFullscreen: this.translateService.instant('analysis.recyclabilityAnalysis.fullscreen'),
      exitFullscreen: this.translateService.instant('analysis.recyclabilityAnalysis.exitFullscreen'),
      downloadJPEG: this.translateService.instant('analysis.recyclabilityAnalysis.downloadJpeg'),
      downloadPNG: this.translateService.instant('analysis.recyclabilityAnalysis.downloadPng'),
      downloadPDF: this.translateService.instant('analysis.recyclabilityAnalysis.downloadPdf'),
    },
    exporting: {
      buttons: {
        contextButton: {
          menuItems: ['viewFullscreen', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF']
        }
      }
    },
    navigation: {
      buttonOptions: {
        width: 28,
        height: 28,
        symbolX: 14,
        symbolY: 14,
        theme: {}
      },
      menuStyle: {},
      menuItemStyle: {},
      menuItemHoverStyle: {}
    },
    credits: {
      enabled: false
    },
    plotOptions: {
      series: {
        dataLabels: {
          format: '{point.name}: {point.y:.2f}%',
          style: {
            fontSize: '20px',
            'font-family': '\'Helsinki\', sans-serif',
            fontWeight: '400'
          }
        },
      },
      pie: {
        showInLegend: true
      }
    },
    tooltip: {
      headerFormat: '<span style="font-size:11px">{series.name}</span><br>',
      formatter() {
        if (this.point.options.drilldown === '?') {
          return `<span style="color:black">${this.point.name}</span>: <b>${this.point.y}%</b><br/>`;
        } else {
          return `<span style="color:${this.point.color}">${this.point.name}</span>: <b>${this.point.y}%</b><br/>`;
        }
      }
    },
    legend: {
      layout: 'horizontal',
      itemStyle: {
        'font-family': '\'Helsinki\', sans-serif'
      }
    }
  };

  constructor(
    private ratingsHandler: RatingsHandler,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.setOriginDependentChartOptions();
    this.setChartSeries();
    if (this.hasDrilldown) {
      this.setChartDrilldown();
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.chartRawData && changes.chartRawData.currentValue !== changes.chartRawData.previousValue) ||
      (changes.isDarkTheme && changes.isDarkTheme.previousValue !== undefined &&
        changes.isDarkTheme.currentValue !== changes.isDarkTheme.previousValue)
    ) {
      this.setOriginDependentChartOptions();
      this.setChartSeries();
      if (this.hasDrilldown) {
        this.setChartDrilldown();
      }
      this.updateFlag = true;
    }
  }

  private setOriginDependentChartOptions() {
    if (this.chartOptions.chart) {
      if (this.chartWidth) { this.chartOptions.chart.width = this.chartWidth; }
      this.chartOptions.chart.height = this.chartHeight;
      this.chartOptions.chart.backgroundColor = this.isDarkTheme ? DARK_THEME_BACKGROUND : LIGHT_THEME_BACKGROUND;
    }
    if (this.chartOptions.navigation) {
      if (this.chartOptions.navigation.buttonOptions && this.chartOptions.navigation.buttonOptions.theme) {
        this.chartOptions.navigation.buttonOptions.theme.fill = this.isDarkTheme ? DARK_THEME_BACKGROUND : LIGHT_THEME_BACKGROUND;
        this.chartOptions.navigation.buttonOptions.theme.stroke = this.isDarkTheme ? DARK_THEME_BORDER : LIGHT_THEME_BORDER;
      }
      if (this.chartOptions.navigation.menuStyle) {
        this.chartOptions.navigation.menuStyle.backgroundColor = this.isDarkTheme ? DARK_THEME_BACKGROUND : LIGHT_THEME_BACKGROUND;
      }
      if (this.chartOptions.navigation.menuItemStyle) {
        this.chartOptions.navigation.menuItemStyle.color = this.isDarkTheme ? DARK_THEME_FONT : LIGHT_THEME_FONT;
      }
      if (this.chartOptions.navigation.menuItemHoverStyle) {
        this.chartOptions.navigation.menuItemHoverStyle.backgroundColor = this.isDarkTheme ? DARK_THEME_BORDER : LIGHT_THEME_BORDER;
      }
    }
    if (this.chartOptions.plotOptions && this.chartOptions.plotOptions.series) {
      (this.chartOptions.plotOptions.series.dataLabels as PlotSeriesDataLabelsOptions).enabled = this.showLabels;
      const dataLabelsStyle = (this.chartOptions.plotOptions.series.dataLabels as PlotSeriesDataLabelsOptions).style;
      if (dataLabelsStyle) {
        dataLabelsStyle.color = this.isDarkTheme ? DARK_THEME_FONT : LIGHT_THEME_FONT;
      }
    }
    if (!this.minimizeLegend) {
      this.chartOptions.legend = {
        verticalAlign: 'top',
        itemMarginBottom: 20,
        itemMarginTop: 20,
        itemStyle: {
          fontSize: '18px',
          color: this.isDarkTheme ? DARK_THEME_FONT : LIGHT_THEME_FONT
        }
      };
    } else {
      this.chartOptions.legend = {
        verticalAlign: 'bottom',
        itemMarginBottom: 0,
        itemMarginTop: 0,
        itemStyle: {
          fontSize: '14px',
          color: this.isDarkTheme ? DARK_THEME_FONT : LIGHT_THEME_FONT
        }
      };
    }
    if (this.chartOptions.exporting) {
      this.chartOptions.exporting.enabled = this.showExport;
      if (this.chartOptions.exporting.buttons && this.chartOptions.exporting.buttons.contextButton) {
        this.chartOptions.exporting.buttons.contextButton.x = 0;
      }
    }
    if (this.chartOptions.title) {
      if (this.showTitle) {
        this.chartOptions.title.text = this.translateService.instant('analysis.recyclabilityAnalysis.chartTitle');
        if (this.chartOptions.title.style) {
          this.chartOptions.title.style.color = this.isDarkTheme ? DARK_THEME_FONT : LIGHT_THEME_FONT;
        }
      } else {
        this.chartOptions.title.text = '';
      }
    }
    if (this.hasDrilldown && this.chartOptions.chart) {
      this.chartOptions.chart.events = {
        drilldown(e) {
          this.setTitle({ text: e.point.name });
        },
        drillup(e) {
          this.setTitle({ text: e.seriesOptions ? e.seriesOptions.name : '' });
        }
      };
    }
    this.isRecPossible = (this.chartRawData as RecyclabilityResult).isRecPossible;
  }

  private setChartSeries() {
    const chartData: any[] = [];
    if (this.chartRawData.dataSource) {
      for (const chartSection of this.chartRawData.dataSource) {
        if (chartSection.value !== 0) {
          chartData.push({
            name: chartSection.name,
            y: chartSection.value,
            drilldown: this.hasDrilldown ? chartSection.label : null,
            color: this.ratingsHandler.getRatingCellColorRecyclability(chartSection.label)
          });
        }
      }
    }
    this.chartOptions.series = [{
      type: 'pie',
      name: this.translateService.instant('analysis.recyclabilityAnalysis.chartTitle'),
      data: chartData
    }];
  }

  private setChartDrilldown() {
    const drilldownSeries = [];
    if (this.chartRawData.dataSource) {
      for (const chartSection of this.chartRawData.dataSource) {
        const seriesData = [];
        for (const componentSection of chartSection.components) {
          seriesData.push({
            name: componentSection.name,
            y: componentSection.weightPercentage,
            color: this.ratingsHandler.getRatingCellColorRecyclability(chartSection.label)
          });
        }
        drilldownSeries.push({
          name: chartSection.name,
          id: chartSection.label,
          type: 'pie',
          data: seriesData
        });
      }
    }
    this.chartOptions.drilldown = {
      /* drillUpButton: {
        relativeTo: 'spacingBox',
        position: this.showLabels ? {
          align: 'center',
          y: this.chartHeight - 70
        } : {
          align: 'left',
          verticalAlign: 'top',
          x: 10
        },
        theme: {
          fill: this.isDarkTheme ? DARK_THEME_BUTTON : LIGHT_THEME_BUTTON,
          'stroke-width': 1,
          stroke: 'lightgrey',
          r: 5,
          padding: 10,
          style: {
            'font-size': '14px',
            'font-family': '\'Helsinki\', sans-serif',
            color: this.isDarkTheme ? LIGHT_THEME_FONT : DARK_THEME_FONT
          }
        }
      }, */
      series: drilldownSeries as any
    };
  }

  createNewAnalysis() {
    if (!(this.chartRawData instanceof RecyclabilityResult)) { return; }
    this.newAnalysisRequested.emit({
      id: (this.chartRawData as RecyclabilityResult).packagingPartId,
      version: (this.chartRawData as RecyclabilityResult).packagingPartVersion
    });
  }
}
