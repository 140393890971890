import { Subscription } from 'rxjs';
import { FileUploadComponent, MAX_FILES_COUNT } from './../file-upload/file-upload.component';
import { DocumentDto } from '../../../../data-transfer/entities/document-dto';
import { DeleteDialogData, DeleteItemDialogComponent } from '../../../dialogs/delete-item-dialog/delete-item-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Input, OnDestroy, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { FileApiService } from 'src/app/data-transfer/services/file-api-service';
import { SimpleDialogData } from 'src/app/components/dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { DialogActions } from 'src/app/model/dictionary';

@Component({
  selector: 'app-pdf-upload',
  templateUrl: './pdf-upload.component.html',
  styleUrls: ['./pdf-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PdfUploadComponent extends FileUploadComponent implements OnInit, OnChanges, OnDestroy {

  @Input() pdfs!: DocumentDto[];

  newPdfs: File[] = [];

  private idSubscription?: Subscription;

  constructor(
    protected dialog: MatDialog,
    private translateService: TranslateService,
    private fileApiService: FileApiService
  ) {
    super(dialog);
    this.minFieldWidth = 300;
    this.maxFieldWidth = 1000;
    super.setInitialNamesLength();
  }

  ngOnInit(): void {
    super.setExistingNamesLength(this.pdfs);
    this.maxCountReached = this.pdfs.length >= MAX_FILES_COUNT;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.pdfs) {
      super.setExistingNamesLength(this.pdfs);
      this.maxCountReached = this.pdfs?.length >= MAX_FILES_COUNT;
    }
  }

  onDeletePdfClick(pdf: DocumentDto) {
    if (!pdf) { return; }
    const dialogData: DeleteDialogData = {
      dialogHeader: this.translateService.instant('pdf.deleteHeader'),
      dialogText: this.translateService.instant('pdf.deleteText')
    };
    const dialogRef = this.dialog.open(DeleteItemDialogComponent, getDialogConfig(dialogData, '500px'));
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.DELETE) {
        this.deletePdf(pdf);
      }
    });
  }

  private deletePdf(pdf: DocumentDto) {
    if (!pdf) { return; }

    if (pdf.documentId === null) {
      this.newPdfs = this.newPdfs.filter(x => x.name !== pdf.name);
    }

    const index = this.pdfs.findIndex(x => x.name === pdf.name);
    this.pdfs.splice(index, 1);

    if (this.pdfs.length < MAX_FILES_COUNT) {
      this.maxCountReached = false;
    }
    super.setExistingNamesLength(this.pdfs);
    this.filesEdited.emit(true);
    console.log('New PDFs count after delete: ' + this.newPdfs.length);
    console.log('Pack PDFs count after delete: ' + this.pdfs.length);
  }

  onUploadPdfsClick(pdfFiles: FileList | null) {
    if (!this.pdfs) { this.pdfs = []; }
    if (pdfFiles && pdfFiles.length > 0) {
      for (const pdfFile of pdfFiles) {
        const fileType = pdfFile.type;
        if (fileType.match(/application\/pdf/) === null) {
          const dialogData = this.getInvalidPdfDialogData(pdfFile.name);
          this.showDialog(dialogData);
          continue;
        }
        if (this.pdfs.length >= MAX_FILES_COUNT) {
          this.maxCountReached = true;
          const dialogData = this.getMaxPdfsDialogData();
          this.showDialog(dialogData);
          return;
        }
        let assignedName = pdfFile.name;
        const listOfNames = this.pdfs.map(x => x.name);
        if (listOfNames.includes(pdfFile.name)) {
          assignedName = this.appendTextToDuplicateNameRecursively(listOfNames, pdfFile.name, 0);
          const newFile = new File([pdfFile], assignedName, { type: pdfFile.type });
          this.newPdfs.push(newFile);
        } else {
          this.newPdfs.push(pdfFile);
        }
        const position = this.pdfs.length;
        this.resizeInput(position);
        this.pdfs.push({ documentId: undefined, index: position, name: assignedName, description: '' });
        this.maxCountReached = this.pdfs.length >= MAX_FILES_COUNT;
        this.filesEdited.emit(true);
      }
    }
  }

  onNameEditClicked(index: number) {
    super.onNameEdited(this.pdfs, index, this.getDuplicatePdfNamesDialogData());
  }

  private getDuplicatePdfNamesDialogData(): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.warning'),
      messages: [this.translateService.instant('warnings.duplicatePdfNames')], icon: 'warning'
    };
  }

  private getInvalidPdfDialogData(fileName: string): SimpleDialogData {
    return {
      title: fileName,
      messages: [this.translateService.instant('errors.formatNotSupported')], icon: 'error'
    };
  }

  private getMaxPdfsDialogData(): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.information'),
      messages: [this.translateService.instant('errors.maxFilesReached')], icon: 'error'
    };
  }

  public getNewPdfsFormData() {
    return super.getNewFilesFormData(this.newPdfs, 'files');
  }

  onViewPdfClick(pdfDocument: DocumentDto) {
    let pdfUrl: string;
    if (pdfDocument.documentId == null) {
      const pdfFile = this.newPdfs.find(x => x.name === pdfDocument.name);
      if (!pdfFile) { return; }
      pdfUrl = window.URL.createObjectURL(pdfFile);
      window.open(pdfUrl, '_blank');
    } else {
      this.idSubscription = this.fileApiService.getPdfById(pdfDocument.documentId).subscribe(pdfFile => {
        pdfUrl = window.URL.createObjectURL(pdfFile);
        window.open(pdfUrl, '_blank');
      });
    }
  }

  ngOnDestroy(): void {
    this.idSubscription?.unsubscribe();
  }
}
