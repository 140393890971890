<form [formGroup]="packagingSystemForm">

  <mat-form-field class="full-width comment-form-field" appearance="outline">
    <mat-label>{{ 'packagingUnit.fields.comment' | translate }}</mat-label>
    <textarea class="no-resize-textarea" matInput formControlName="comment" [maxlength]="maxCommentLength"
      placeholder="{{ 'packagingUnit.fields.commentMaxLength' | translate: {length: maxCommentLength} }}">
      </textarea>
  </mat-form-field>

  <div class="grid-container">
    <mat-card class="grid-item">
      <mat-card-title class="grid-item-title">
        {{ 'packagingUnit.fields.primaryPackagingSection' | translate }}
      </mat-card-title>

      <mat-card-content class="inner-container">
        <mat-form-field class="form-field-wide"
          matTooltip="{{ 'packagingUnit.fields.tooltips.brandName' | translate }}">
          <mat-label class="label-large">{{ 'packagingUnit.fields.brandName' | translate }}</mat-label>
          <input matInput formControlName="brandName" required type="text" autocomplete="off">
          <mat-error *ngIf="packagingSystemForm.controls.brandName.errors">
            <span *ngIf="packagingSystemForm.controls.brandName.errors.required">
              {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
            </span>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="form-field-wide"
          matTooltip="{{ 'packagingUnit.fields.tooltips.productName' | translate }}">
          <mat-label class="label-large">{{ 'packagingUnit.fields.productName' | translate }}
          </mat-label>
          <input matInput formControlName="productName" required type="text" autocomplete="off">
          <mat-error *ngIf="packagingSystemForm.controls.productName.errors">
            <span *ngIf="packagingSystemForm.controls.productName.errors.required">
              {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
            </span>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="form-field-wide"
          matTooltip="{{ 'packagingUnit.fields.tooltips.articleNumber' | translate }}">
          <mat-label class="label-large">{{ 'packagingUnit.fields.articleNumber' | translate }}
          </mat-label>
          <input matInput formControlName="articleNumber" type="text" autocomplete="off">
        </mat-form-field>

        <mat-form-field class="form-field-wide" matTooltip="{{ 'packagingUnit.fields.tooltips.gtin' | translate }}">
          <mat-label class="label-large">{{ 'packagingUnit.fields.gtin' | translate }}</mat-label>
          <input matInput formControlName="gtin" type="text" autocomplete="off">
          <mat-error *ngIf="packagingSystemForm.controls.gtin.errors">
            <span *ngIf="packagingSystemForm.controls.gtin.errors.gtinError">
              {{ 'dataManagement.userData.userDialog.validation.gtinError' | translate }}
            </span>
          </mat-error>
        </mat-form-field>
      </mat-card-content>
    </mat-card>

    <mat-card class="grid-item">
      <mat-card-title class="grid-item-title">
        {{ 'packagingUnit.fields.manufacturingSection' | translate }}
      </mat-card-title>

      <mat-card-content class="inner-container">
        <mat-form-field class="form-field-wide"
          *ngIf="!packagingForm.distributionCountries.disabled; else disabledDistrCountries"
          matTooltip="{{ 'packagingUnit.fields.tooltips.countriesOfDistribution' | translate }}">
          <mat-label class="label-large">
            {{ 'packagingUnit.fields.countriesOfDistribution' | translate }}
          </mat-label>
          <mat-select class="chip-list-select" formControlName="distributionCountries" multiple>
            <mat-option *ngFor="let country of distributionCountries" [value]="country.code">
              {{ country.name }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="packagingForm.distributionCountries.errors">
            <span *ngIf="packagingForm.distributionCountries.errors.required">
              {{ 'dataManagement.userData.userDialog.validation.atLeastOneSelectionRequired' | translate }}
            </span>
          </mat-error>
        </mat-form-field>
        <ng-template #disabledDistrCountries>
          <mat-form-field class="form-field-wide"
            [ngStyle]="{'height': (allDistrCountryNames.length < 100 ? 100 : allDistrCountryNames.length) + 'px'}">
            <mat-label class="label-large">
              {{ 'packagingUnit.fields.countriesOfDistribution' | translate }}
            </mat-label>
            <textarea matInput disabled class="full-height">{{allDistrCountryNames}}</textarea>
          </mat-form-field>
        </ng-template>

        <mat-form-field class="form-field-wide"
          matTooltip="{{ 'packagingUnit.fields.tooltips.assemblyCountry' | translate }}">
          <mat-label class="label-large">
            {{ 'packagingUnit.fields.assemblyCountry' | translate }}
          </mat-label>
          <mat-select formControlName="assemblyCountry">
            <mat-option> {{ 'common.text.noSelection' | translate }} </mat-option>
            <mat-option *ngFor="let country of assemblyCountries" [value]="country.code">
              {{country.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="packagingSystemForm.controls.assemblyCountry.errors">
            <span *ngIf="packagingSystemForm.controls.assemblyCountry.errors.required">
              {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
            </span>
          </mat-error>
        </mat-form-field>
      </mat-card-content>
    </mat-card>

    <mat-card class="grid-item">
      <mat-card-title class="grid-item-title">
        <div id="dimensions-header">
          {{ 'packagingUnit.fields.packagingDimensions' | translate }}
          <button mat-icon-button matSuffix (click)="showDimensionsInfo = !showDimensionsInfo" type="button"
            cdkOverlayOrigin #dimensionsTrigger="cdkOverlayOrigin" color="primary">
            <mat-icon>info</mat-icon>
          </button>
        </div>
      </mat-card-title>
      <mat-card-content class="inner-container">
        <mat-form-field class="form-field-wide" matTooltip="{{ 'component.fields.tooltips.length' | translate }}">
          <mat-label class="label-large">
            {{ 'component.fields.length' | translate }}
          </mat-label>
          <input matInput type="number" formControlName="length" autocomplete="off">
          <mat-error *ngIf="packagingSystemForm.controls.length.errors">
            <span *ngIf="packagingSystemForm.controls.length.errors.min">
              {{ 'dataManagement.userData.userDialog.validation.valueCannotBeLessThan' | translate: {value:
              minimalValue} }}
            </span>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="form-field-wide" matTooltip="{{ 'component.fields.tooltips.width' | translate }}">
          <mat-label class="label-large">
            {{ 'component.fields.width' | translate }}
          </mat-label>
          <input matInput type="number" formControlName="width" autocomplete="off">
          <mat-error *ngIf="packagingSystemForm.controls.width.errors">
            <span *ngIf="packagingSystemForm.controls.width.errors.min">
              {{ 'dataManagement.userData.userDialog.validation.valueCannotBeLessThan' | translate: {value:
              minimalValue} }}
            </span>
          </mat-error>
        </mat-form-field>

        <mat-form-field class="form-field-wide" matTooltip="{{ 'component.fields.tooltips.height' | translate }}">
          <mat-label class="label-large">
            {{ 'component.fields.height' | translate }}
          </mat-label>
          <input matInput type="number" formControlName="height" autocomplete="off">
          <mat-error *ngIf="packagingSystemForm.controls.height.errors">
            <span *ngIf="packagingSystemForm.controls.height.errors.min">
              {{ 'dataManagement.userData.userDialog.validation.valueCannotBeLessThan' | translate: {value:
              minimalValue} }}
            </span>
          </mat-error>
        </mat-form-field>
      </mat-card-content>
    </mat-card>

    <ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="dimensionsTrigger"
      cdkConnectedOverlayHasBackdrop="true" (backdropClick)="showDimensionsInfo=false"
      [cdkConnectedOverlayOpen]="showDimensionsInfo">
      <app-dimensions-info></app-dimensions-info>
    </ng-template>
  </div>
</form>