import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

export enum BuyType {
  EnvironmentallEffect = 0,
  Country = 1,
  AllCountries = 2
}

@Component({
  selector: 'app-buying-from-shop',
  templateUrl: './buying-from-shop.component.html',
  styleUrls: ['./buying-from-shop.component.scss']
})

export class BuyingFromShopComponent {
  @Input() buyType : BuyType = BuyType.EnvironmentallEffect;
  @Input() contentValue : string = '';

  showBuyText : boolean = false;
  emailString!: string;

  constructor(
    private translateService: TranslateService
  ) {
    this.emailString = "mailto:"+this.translateService.instant('shop.emailWorkaround.email')
  }

  protected getTitleString () : string {
    switch(this.buyType) {
      case BuyType.EnvironmentallEffect:
        return this.translateService.instant('analysis.lifecycleAnalysis.warnings.impactNotAuthorized', {name: this.contentValue})
      case BuyType.Country:
        return this.translateService.instant('analysis.lifecycleAnalysis.warnings.countryNotAuthorized', {name: this.contentValue})
      case BuyType.AllCountries:
        return this.translateService.instant('analysis.lifecycleAnalysis.warnings.noCountriesAvailable')
    }
  }

  protected getInfoString() : string {
    switch(this.buyType) {
      case BuyType.EnvironmentallEffect:
        return this.translateService.instant('shop.emailWorkaround.environmentalEffects.info')
      case BuyType.Country:
      case BuyType.AllCountries:
        return this.translateService.instant('shop.emailWorkaround.countries.info')
    }
  }

  protected ContentString() : string {
    switch(this.buyType) {
      case BuyType.EnvironmentallEffect:
        return this.translateService.instant('shop.emailWorkaround.environmentalEffects.info')
      case BuyType.Country:
      case BuyType.AllCountries:
        return this.translateService.instant('shop.emailWorkaround.countries.info')
    }
  }

  protected getSubjectContent() : string {
    switch(this.buyType) {
      case BuyType.EnvironmentallEffect:
        return this.translateService.instant('shop.emailWorkaround.environmentalEffects.subjectContent');
      case BuyType.Country:
      case BuyType.AllCountries:
        return this.translateService.instant('shop.emailWorkaround.countries.subjectContent')
    }
  }

  protected getEmailString() : string {
    switch(this.buyType) {
      case BuyType.EnvironmentallEffect:
        return this.emailString+'?Subject=' +
          this.translateService.instant('shop.emailWorkaround.environmentalEffects.subjectContent')+'&body=' +
          this.translateService.instant('shop.emailWorkaround.environmentalEffects.content', {content:this.contentValue})
      case BuyType.Country:
        return this.emailString+'?Subject=' +
          this.translateService.instant('shop.emailWorkaround.countries.subjectContent')+'&body=' +
          this.translateService.instant('shop.emailWorkaround.countries.content', {content:this.contentValue})
      case BuyType.AllCountries:
        return this.emailString+'?Subject=' +
          this.translateService.instant('shop.emailWorkaround.countries.subjectContent')+'&body=' +
          this.translateService.instant('shop.emailWorkaround.countries.contentUndefinedCountry')
    }
  }

  protected getEmailStringForBrowser() : string {
    switch(this.buyType) {
      case BuyType.EnvironmentallEffect:
       return this.translateService.instant('shop.emailWorkaround.environmentalEffects.content',{content:this.contentValue})
       .replace(/%0D%0A/g,'\n')
      case BuyType.Country:
        return this.translateService.instant('shop.emailWorkaround.countries.content',{content:this.contentValue}).replace(/%0D%0A/g,'\n')
        case BuyType.AllCountries:
          return this.translateService.instant('shop.emailWorkaround.countries.contentUndefinedCountry').replace(/%0D%0A/g,'\n')
    }
  }

  protected invertBuyText () {
    this.showBuyText = !this.showBuyText;
  }
}
