<mat-card *ngIf="recEvaluation" label="Recyclability Evaluation" id="recyclability-card">
  <div class="total-values-container">
    <span class="label-large">{{ 'analysis.recyclabilityAnalysis.recyclableTotal' | translate }}</span>
    <span class="label-large green">{{ recChartDataSource.recyclableTotal }}%</span>
    <span class="label-large">{{ 'analysis.recyclabilityAnalysis.nonRecyclableTotal' | translate }}</span>
    <span class="label-large red">{{ recChartDataSource.nonRecyclableTotal }}%</span>
  </div>
  <app-recyclability-chart style="display: inline-block;" [chartRawData]="recChartDataSource" [chartWidth]="1000" [chartHeight]="450"
    [showLabels]="true" [minimizeLegend]="true" [showExport]="true" [showTitle]="false" [isDarkTheme]="isDarkTheme"
    [hasDrilldown]="false">
  </app-recyclability-chart>
  <div *ngIf="isScrollingEnabled" class="scroll-button">
    <button mat-raised-button color="primary" (click)="scrollToTable()">
      {{ 'dataEvaluation.evaluationType.scrollToTable' | translate }}
    </button>
  </div>
  <table mat-table [dataSource]="recChartDataSource.tableDataSource" class="mat-elevation-z8 table-wide" *ngIf="showTable">
    <ng-container matColumnDef="material">
      <th mat-header-cell *matHeaderCellDef id="header">
        {{ 'analysis.recyclabilityAnalysis.tableColumnsUnits.material' | translate }}
      </th>
      <td mat-cell *matCellDef="let element" class="cell">
        <div>{{ element.material }}</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="rating">
      <th mat-header-cell *matHeaderCellDef id="header">
        {{ 'analysis.recyclabilityAnalysis.tableColumnsUnits.rating' | translate }}
      </th>
      <td mat-cell *matCellDef="let element" class="cell">
        <div class="circle" [style.background]="getBackgorundColor(element)" [style.color]="getFontColor(element)" [matTooltip]="element.rating">
          {{ element.label }}
        </div>
      </td>
    </ng-container>
    <ng-container matColumnDef="weight">
      <th mat-header-cell *matHeaderCellDef id="header">
        {{ 'analysis.recyclabilityAnalysis.tableColumnsUnits.massKg' | translate }}
      </th>
      <td mat-cell *matCellDef="let element" class="cell">
        <div>{{ element.weight / 1000 }}</div>
      </td>
    </ng-container>
    <ng-container matColumnDef="weightPercentage">
      <th mat-header-cell *matHeaderCellDef id="header">
        {{ 'analysis.recyclabilityAnalysis.tableColumnsUnits.weightPercentage' | translate }}
      </th>
      <td mat-cell *matCellDef="let element" class="cell">
        <div>{{ element.weightPercentage | percent: '1.0-2' }}</div>
      </td>
    </ng-container>
    <tr mat-header-row *matHeaderRowDef="displayedColumnsMassAnalysis"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumnsMassAnalysis"></tr>
  </table>
</mat-card>
