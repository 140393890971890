import { AuthService } from './../../../services/auth-service';
import { CreateUserDto } from './../../../data-transfer/entities/create-user-dto';
import { Validators, FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { Component, OnInit, Inject, Optional } from '@angular/core';
import { UserDetailsDialogData } from 'src/app/util/user-dialog-handler';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogActions } from 'src/app/model/dictionary';

@Component({
  selector: 'app-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.scss']
})
export class UserDialogComponent implements OnInit {

  addEditUserForm!: FormGroup;
  action!: number;
  name = '';
  email = '';
  password = '';
  existingNames: Set<string>;
  submitted = false;
  isAdmin = false;
  dialogActions = DialogActions;

  minLength = 3;
  maxLength = 100;

  public isEditing: boolean;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) dialogData: UserDetailsDialogData,
    private dialogRef: MatDialogRef<UserDialogComponent>,
    private formBuilder: FormBuilder,
    private authService: AuthService,
  ) {
    this.action = dialogData.action;
    this.isEditing = dialogData.action === DialogActions.EDIT;
    const user = dialogData.getUser();
    if (this.isEditing && user) {
      this.name = user.userName;
      this.email = user.email;
      this.password = user.password;
    }
    this.existingNames = dialogData.getNamesUsedLowercase();
    this.isAdmin = this.authService.isUserAdmin();
  }

  ngOnInit() {
    if (this.action !== DialogActions.DELETE) {
      this.addEditUserForm = this.formBuilder.group({
        name: [this.name, [
          Validators.required,
          Validators.pattern('[a-zA-Z0-9._-]*[@]?[a-zA-Z0-9._-]*'),
          Validators.minLength(this.minLength),
          Validators.maxLength(this.maxLength),
          this.conditionalValidator(!this.isEditing, this.userNameAlreadyUsed.bind(this))]],
        email: [this.email, [Validators.required, Validators.email]],
        newPassword: [this.password]
      });
    } else {
      this.addEditUserForm = this.formBuilder.group({});
    }
  }

  doAction() {
    this.submitted = true;

    if (this.action === DialogActions.DELETE) {
      this.dialogRef.close({ event: this.action });
      return;
    }

    Object.keys(this.addEditUserForm.controls).forEach(field => {
      const control = this.addEditUserForm.get(field);
      control?.markAsTouched({ onlySelf: true });
    });

    this.addEditUserForm.updateValueAndValidity();
    if (this.addEditUserForm.invalid) {
      return;
    } else {
      const user: CreateUserDto = {
        userName: this.addEditUserForm.value.name,
        email: this.addEditUserForm.value.email,
        password: this.addEditUserForm.value.newPassword.password,
        userGroupId: -1
      };
      this.dialogRef.close({ event: this.action, data: user });
    }
  }

  closeDialog() {
    this.dialogRef.close({ event: DialogActions.REJECT });
  }

  private userNameAlreadyUsed(formControl: FormControl) {
    if (!formControl.value) {
      return null;
    }
    if (this.existingNames.has(formControl.value.toLowerCase())) {
      return { userNameIsAlreadyUsed: true };
    }
    return null;
  }

  private conditionalValidator(condition: boolean, validatorMethod: any) {
    return ((formControl: any) => {
      if (!formControl.parent) {
        return null;
      }
      if (condition) {
        return validatorMethod(formControl);
      }
      return null;
    });
  }

  onReset() {
    this.submitted = false;
    this.addEditUserForm.reset();
  }

  get formControls() { return this.addEditUserForm.controls; }
}
