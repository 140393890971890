import { MainBodyDialogComponent } from './../../components/dialogs/main-body-dialog/main-body-dialog.component';
import { PackagingComponentTypesEnum } from './../packaging-component-types-enum';
import { MainBodyEntryDto } from 'src/app/data-transfer/entities/component-entities/main-body-entry-dto';
import { FormBuilder, Validators, FormGroup } from '@angular/forms';
import { ComponentHandler } from 'src/app/services/component-services/component-handler';
import { ComponentTableInfo } from 'src/app/model/components/packaging-component-prototype';
import { DictionaryHandler } from './../dictionary';
import { PackagingComponentPrototype } from './packaging-component-prototype';
import { CountriesService } from 'src/app/navigation/services/countries-service';
import { MainBodyDto } from 'src/app/data-transfer/entities/component-entities/main-body-dto';
import { ComponentSubtypeDto } from 'src/app/data-transfer/entities/component-subtype-dto';
import { ComponentDialogData } from 'src/app/components/dialogs/additional-packaging-components-parent-dialog/additional-packaging-components-parent-dialog.component';

export const MAIN_BODY_NAME = 'mainBody';

export class MainBodyComponent extends PackagingComponentPrototype {

  constructor(
    protected dictionaryHandler: DictionaryHandler,
    private mainBodySubtypes: ComponentSubtypeDto[],
    private countriesService: CountriesService
  ) {
    super(dictionaryHandler);
  }

  getComponentTableInfo(translate: (text: string, params?: any) => string): ComponentTableInfo {
    return {
      id: PackagingComponentTypesEnum.MainBody,
      label: MAIN_BODY_NAME,
      text: translate('component.mainBody'),
      columns: [
        {
          id: 'packagingComponentSubtypeName',
          text: translate('component.fields.type', { ofComponent: translate('component.fields.ofMainBody') })
        },
        { id: 'manufacturer', text: translate('component.fields.manufacturer') },
        { id: 'articleName', text: translate('component.fields.articleName') },
        { id: 'manifestationOverview', text: translate('component.fields.mainMaterialLayer') }
      ]
    };
  }

  wrapComponentInComponentEntry(componentDto: MainBodyDto): MainBodyEntryDto {
    const returnEntry = new MainBodyEntryDto();
    returnEntry.packagingVolume = 0;
    returnEntry.underlyingComponent = componentDto;
    return returnEntry;
  }

  getFormFromComponentEntries(
    mainBodyEntryDtos: MainBodyEntryDto[], handler: ComponentHandler, formBuilder: FormBuilder, isTracked: boolean
  ): FormGroup {
    return formBuilder.group({
      packagingComponents: formBuilder.array(mainBodyEntryDtos.map(mainBodyEntryDto =>
        this.getFormFromSingleComponentEntry(mainBodyEntryDto, handler, formBuilder, isTracked)))
    });
  }

  getFormFromSingleComponentEntry(
    mainBodyEntryDto: MainBodyEntryDto, handler: ComponentHandler, formBuilder: FormBuilder, isTracked: boolean
  ): FormGroup {
    const mainBodyDto = mainBodyEntryDto.underlyingComponent;
    const baseForm = super.createBaseForm(mainBodyDto, formBuilder, this.mainBodySubtypes,
      isTracked || mainBodyEntryDto.hasInternalTracking);
    const mainBodyFormGroup: FormGroup = formBuilder.group({
      ...baseForm.controls,
      entryId: [mainBodyEntryDto.entryId],
      manifestationOverview: [super.createManifestationOverview(mainBodyDto.multiMaterial?.layers ?? [])],
      manufacturingCountryName: [this.countriesService.getCountryNameByCode(mainBodyDto.manufacturingCountry ?? '')],
      hasInternalTracking: [mainBodyEntryDto.hasInternalTracking],
      errorCount: [0],
      multiMaterial: mainBodyDto.multiMaterial,
      printingCoverage: [mainBodyDto.printingCoverage != null ? +(mainBodyDto.printingCoverage * 100).toFixed(2) : null,
        [Validators.required, Validators.min(0), Validators.max(100)]],
      isRigidComponent: [mainBodyDto.isRigidComponent ?? null, [Validators.required]],
      volume: [mainBodyDto.packagingVolume, Validators.min(0.001)],
    });
    super.setErrorCountInPackagingComponent(mainBodyFormGroup);
    return mainBodyFormGroup;
  }

  getEmptyComponentEntry(): MainBodyEntryDto {
    const mainBodyEntry = new MainBodyEntryDto();
    mainBodyEntry.underlyingComponent = new MainBodyDto();
    mainBodyEntry.underlyingComponent.packagingComponentSubtypeId = this.mainBodySubtypes[0].id;
    return mainBodyEntry;
  }

  getComponentEntriesFromForm(formValues: any): MainBodyEntryDto[] {
    const mainBodies: MainBodyEntryDto[] = [];
    formValues.forEach((mainBodyGroup: any) => {
      mainBodies.push(this.getSingleComponentEntryFromForm(mainBodyGroup, false));
    });
    return mainBodies;
  }

  getSingleComponentEntryFromForm(mainBodyFormValue: any, forInternalUse: boolean): MainBodyEntryDto {
    const mainBodyEntry = new MainBodyEntryDto();
    this.setComponentEntryDataFromForm(mainBodyEntry, mainBodyFormValue);
    mainBodyEntry.underlyingComponent = this.getSingleComponentFromForm(mainBodyFormValue, forInternalUse);
    return mainBodyEntry;
  }

  private setComponentEntryDataFromForm(mainBodyEntry: MainBodyEntryDto, mainBodyFormValue: any) {
    mainBodyEntry.entryId = mainBodyFormValue?.entryId ?? -1;
    mainBodyEntry.hasInternalTracking = mainBodyFormValue?.hasInternalTracking;
    mainBodyEntry.packagingVolume = mainBodyFormValue?.volume;
  }

  getSingleComponentFromForm(mainBodyFormValue: any, forInternalUse: boolean, component?: MainBodyDto): MainBodyDto {
    const mainBodyDto = component ?? new MainBodyDto();
    mainBodyDto.id = mainBodyFormValue?.id;
    mainBodyDto.versionName = mainBodyFormValue?.newVersionName;
    mainBodyDto.packagingComponentSubtypeId = mainBodyFormValue?.packagingComponentSubtypeId;
    mainBodyDto.comment = mainBodyFormValue?.comment;
    mainBodyDto.manufacturer = mainBodyFormValue?.manufacturer;
    mainBodyDto.manufacturingCountry = mainBodyFormValue?.manufacturingCountry;
    mainBodyDto.articleName = mainBodyFormValue?.articleName;
    mainBodyDto.articleNumber = mainBodyFormValue?.articleNumber;
    mainBodyDto.gtin = mainBodyFormValue?.gtin;
    mainBodyDto.isRigidComponent = mainBodyFormValue?.isRigidComponent ?? null;
    mainBodyDto.length = mainBodyFormValue?.length;
    mainBodyDto.width = mainBodyFormValue?.width;
    mainBodyDto.height = mainBodyFormValue?.height;
    mainBodyDto.printingCoverage = forInternalUse ? mainBodyFormValue?.printingCoverage :
      mainBodyFormValue?.printingCoverage / 100;
    mainBodyDto.packagingVolume = mainBodyFormValue?.volume;
    mainBodyDto.multiMaterial = mainBodyFormValue?.multiMaterial;
    mainBodyDto.containsNearInfraredBarrier = mainBodyFormValue?.containsNearInfraredBarrier ?? null;
    if (mainBodyDto.multiMaterial) {
      mainBodyDto.multiMaterial.totalWeight = mainBodyFormValue?.totalWeight;
      mainBodyDto.multiMaterial.totalGrammage = mainBodyFormValue?.totalGrammage;
    }
    console.log(mainBodyFormValue)
    console.log('-------------')
    console.log(mainBodyDto)
    return mainBodyDto;
  }

  getDialogType() { return MainBodyDialogComponent; }

  getComponentSubtypes(): ComponentSubtypeDto[] { return this.mainBodySubtypes; }

  setComponentSpecificDialogData(_: ComponentDialogData): void { }

  setComponentSpecificTableData(formGroup: FormGroup): void {
    formGroup.controls.manifestationOverview.patchValue(
      super.createManifestationOverview((formGroup.controls.multiMaterial as FormGroup).value.layers ?? []));
  }
}
