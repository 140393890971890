import { UiVersion } from './../../../model/ui-version';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-changelog-dialog',
  templateUrl: './changelog-dialog.component.html'
})
export class ChangelogDialogComponent implements OnInit {

  version!: UiVersion;

  constructor(
    @Inject(MAT_DIALOG_DATA) private uiVersion: UiVersion
  ) { }

  ngOnInit(): void {
    this.version = this.uiVersion;
  }

  onError(event: any) {
    console.log(event);
  }

  getFileName(version: string) {
    const isGerman = localStorage.getItem('language') === 'de';
    if (isGerman) {
      return `assets/changelog/de/changelog-${version}.md`;
    } else {
      return `assets/changelog/en/changelog-${version}.md`;
    }
  }
}
