<h1 mat-dialog-title>{{ 'dataManagement.userData.texts.' + (isEditing ? 'edit' : 'add') | translate }}</h1>

<mat-dialog-content [class.dialog-content]="action !== dialogActions.DELETE">
  <form [formGroup]="addEditUserForm">

    <div *ngIf="action !== dialogActions.DELETE; else deleteTemplate">
      <div class="grid-container">
        <mat-card>

          <mat-card-content>
            <div class="left">
              <mat-form-field class="input-field">
                <mat-label class="label-large">
                  {{ 'dataManagement.userData.columns.name' | translate }}
                </mat-label>
                <input matInput formControlName="name" autocomplete="off" type="text"
                  [ngClass]="{ 'is-invalid': submitted && formControls.name.errors }">
              </mat-form-field>
              <div class="error" *ngIf="formControls.name.errors">
                <mat-error *ngIf="formControls.name.hasError('required') && submitted">
                  {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
                </mat-error>
                <mat-error *ngIf="formControls.name.hasError('pattern')">
                  {{ 'dataManagement.userData.userDialog.validation.pattern' | translate }}
                </mat-error>
                <mat-error *ngIf="formControls.name.hasError('maxlength')">
                  {{ 'dataManagement.userData.userDialog.validation.maxLength' | translate: {maxLength: maxLength} }}
                </mat-error>
                <mat-error *ngIf="formControls.name.hasError('minlength')">
                  {{ 'dataManagement.userData.userDialog.validation.minLength' | translate: {minLength: minLength} }}
                </mat-error>
                <mat-error *ngIf="formControls.name.hasError('userNameIsAlreadyUsed')">
                  {{ 'dataManagement.userData.userDialog.validation.userNameIsAlreadyUsed' | translate }}
                </mat-error>
              </div>

              <mat-form-field class="input-field">
                <mat-label class="label-large">
                  {{ 'dataManagement.userData.columns.email' | translate }}
                </mat-label>
                <input matInput formControlName="email" autocomplete="off" type="text"
                  [ngClass]="{ 'is-invalid': submitted && formControls.email.errors }">
              </mat-form-field>
              <div class="error" *ngIf="formControls.email.errors">
                <mat-error *ngIf="formControls.email.hasError('required') && submitted">
                  {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
                </mat-error>
                <mat-error *ngIf="formControls.email.hasError('email')">
                  {{ 'dataManagement.userData.userDialog.validation.email' | translate }}
                </mat-error>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <mat-card>
          <mat-card-content>
            <div class="left">
              <app-password-form formControlName="newPassword" [submitted]="submitted" [isAdmin]="isAdmin">
              </app-password-form>
            </div>
          </mat-card-content>
        </mat-card>

      </div>
    </div>
    <ng-template #deleteTemplate>
      {{ 'dataManagement.userData.texts.deleteConcreteItemConfirmation' | translate }}
    </ng-template>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button *ngIf="action===dialogActions.ADD" (click)="doAction()" mat-raised-button type="submit"
    color="primary">{{ 'common.buttons.add' | translate }}</button>
  <button *ngIf="action===dialogActions.EDIT" (click)="doAction()" mat-raised-button type="submit"
    color="primary">{{ 'common.buttons.edit' | translate }}</button>
  <button *ngIf="action===dialogActions.DELETE" (click)="doAction()" mat-raised-button type="submit"
    color="primary">{{ 'common.buttons.delete' | translate }}</button>
  <button (click)="closeDialog()" mat-raised-button type="button">
    {{ 'common.buttons.cancel' | translate }}</button>
</mat-dialog-actions>
