<div class="local-container">
  <div class="filter-group">
    <mat-form-field class="range-form">
      <mat-label>{{ 'dataManagement.validator.dateRange.enter' | translate }}</mat-label>
      <mat-date-range-input [formGroup]="rangeControl" [rangePicker]="picker">
        <input class="date-input" matStartDate formControlName="start"
          placeholder="{{ 'dataManagement.validator.dateRange.startDate' | translate }}">
        <input class="date-input" matEndDate formControlName="end"
          placeholder="{{ 'dataManagement.validator.dateRange.endDate' | translate }}">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
      <mat-error *ngIf="rangeControl.controls.start.hasError('matStartDateInvalid')">{{
        'dataManagement.validator.dateRange.invalidStart' | translate }}</mat-error>
      <mat-error *ngIf="rangeControl.controls.end.hasError('matEndDateInvalid')">{{
        'dataManagement.validator.dateRange.invalidEnd' | translate }}</mat-error>
    </mat-form-field>
    <button class="range-button" mat-raised-button color="accent" (click)="changeDateRangeByDays(-7)"
      [disabled]="rangeControl.invalid">
      {{ 'dataManagement.validator.minusOneWeek' | translate }}
    </button>
    <button class="range-button" mat-raised-button color="accent" (click)="changeDateRangeByDays(7)"
      [disabled]="rangeControl.invalid">
      {{ 'dataManagement.validator.plusOneWeek' | translate }}
    </button>
    <button class="filter-button" mat-raised-button color="primary" (click)="applyFilter()"
      [disabled]="rangeControl.invalid || rangeControl.pristine">
      {{ 'dataManagement.validator.filter' | translate }}
    </button>
  </div>
  <mat-tab-group color="accent" [backgroundColor]="" class="outer-tab-group">
    <mat-tab label="{{ 'dataManagement.validator.analysisType.recyclability' | translate }}">
      <mat-tab-group color="accent" [backgroundColor]="" class="tab-group" #recTabs>
        <mat-tab label="{{ 'dataManagement.validator.validationStatus.notValidated' | translate }}">
          <div class="local-scrolling-wrapper" *ngIf="isSystemView">
            <app-packaging-systems-overview [dataSource]="recDataSourceNotValidated" [displayedColumns]="columnsNotRec"
              [isUserValidator]="true" [analysisWrappersRec]="analysisWrappersRec" sortByColumnName="creationTimestamp">
            </app-packaging-systems-overview>
          </div>
          <div class="local-scrolling-wrapper" *ngIf="!isSystemView">
            <app-packaging-units-overview [dataSource]="recDataSourceNotValidated" [displayedColumns]="columnsNotRec"
              [isUserValidator]="true" [analysisWrappersRec]="analysisWrappersRec" sortByColumnName="creationTimestamp">
            </app-packaging-units-overview>
          </div>
        </mat-tab>
        <mat-tab label="{{ 'dataManagement.validator.validationStatus.validatedPos' | translate }}">
          <div class="local-scrolling-wrapper" *ngIf="isSystemView">
            <app-packaging-systems-overview [dataSource]="recDataSourceValidatedPos" [displayedColumns]="columnsPosRec"
              [isUserValidator]="true" [analysisWrappersRec]="analysisWrappersRec" sortByColumnName="creationTimestamp">
            </app-packaging-systems-overview>
          </div>
          <div class="local-scrolling-wrapper" *ngIf="!isSystemView">
            <app-packaging-units-overview [dataSource]="recDataSourceValidatedPos" [displayedColumns]="columnsPosRec"
              [isUserValidator]="true" [analysisWrappersRec]="analysisWrappersRec" sortByColumnName="creationTimestamp">
            </app-packaging-units-overview>
          </div>
        </mat-tab>
        <mat-tab label="{{ 'dataManagement.validator.validationStatus.validatedNeg' | translate }}">
          <div class="local-scrolling-wrapper" *ngIf="isSystemView">
            <app-packaging-systems-overview [dataSource]="recDataSourceValidatedNeg" [displayedColumns]="columnsNegRec"
              [isUserValidator]="true" [analysisWrappersRec]="analysisWrappersRec" sortByColumnName="creationTimestamp">
            </app-packaging-systems-overview>
          </div>
          <div class="local-scrolling-wrapper" *ngIf="!isSystemView">
            <app-packaging-units-overview [dataSource]="recDataSourceValidatedNeg" [displayedColumns]="columnsNegRec"
              [isUserValidator]="true" [analysisWrappersRec]="analysisWrappersRec" sortByColumnName="creationTimestamp">
            </app-packaging-units-overview>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-tab>

    <mat-tab label="{{ 'dataManagement.validator.analysisType.lifecycle' | translate }}">
      <mat-tab-group color="accent" [backgroundColor]="" class="tab-group" #lcaTabs>
        <mat-tab label="{{ 'dataManagement.validator.validationStatus.notValidated' | translate }}" selected="true">
          <div class="local-scrolling-wrapper" *ngIf="isSystemView">
            <app-packaging-systems-overview [dataSource]="lcaDataSourceNotValidated" [displayedColumns]="columnsNotLca"
              [isUserValidator]="true" [analysisWrappersLca]="analysisWrappersLca" sortByColumnName="creationTimestamp">
            </app-packaging-systems-overview>
          </div>
          <div class="local-scrolling-wrapper" *ngIf="!isSystemView">
            <app-packaging-units-overview [dataSource]="lcaDataSourceNotValidated" [displayedColumns]="columnsNotLca"
              [isUserValidator]="true" [analysisWrappersLca]="analysisWrappersLca" sortByColumnName="creationTimestamp">
            </app-packaging-units-overview>
          </div>
        </mat-tab>
        <mat-tab label="{{ 'dataManagement.validator.validationStatus.validatedPos' | translate }}">
          <div class="local-scrolling-wrapper" *ngIf="isSystemView">
            <app-packaging-systems-overview [dataSource]="lcaDataSourceValidatedPos" [displayedColumns]="columnsPosLca"
              [isUserValidator]="true" [analysisWrappersLca]="analysisWrappersLca" sortByColumnName="creationTimestamp">
            </app-packaging-systems-overview>
          </div>
          <div class="local-scrolling-wrapper" *ngIf="!isSystemView">
            <app-packaging-units-overview [dataSource]="lcaDataSourceValidatedPos" [displayedColumns]="columnsPosLca"
              [isUserValidator]="true" [analysisWrappersLca]="analysisWrappersLca" sortByColumnName="creationTimestamp">
            </app-packaging-units-overview>
          </div>
        </mat-tab>
        <mat-tab label="{{ 'dataManagement.validator.validationStatus.validatedNeg' | translate }}">
          <div class="local-scrolling-wrapper" *ngIf="isSystemView">
            <app-packaging-systems-overview [dataSource]="lcaDataSourceValidatedNeg" [displayedColumns]="columnsNegLca"
              [isUserValidator]="true" [analysisWrappersLca]="analysisWrappersLca" sortByColumnName="creationTimestamp">
            </app-packaging-systems-overview>
          </div>
          <div class="local-scrolling-wrapper" *ngIf="!isSystemView">
            <app-packaging-units-overview [dataSource]="lcaDataSourceValidatedNeg" [displayedColumns]="columnsNegLca"
              [isUserValidator]="true" [analysisWrappersLca]="analysisWrappersLca" sortByColumnName="creationTimestamp">
            </app-packaging-units-overview>
          </div>
        </mat-tab>
      </mat-tab-group>
    </mat-tab>
  </mat-tab-group>
</div>
