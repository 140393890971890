import { ComponentMaterialsTableData, ComponentTableData, RecyclabilityTableData } from './recyclability-table-definitions';

export class RowSpanComputer {

  /**
   * https://stackblitz.com/edit/angular-gevqvq?file=src%2Fapp%2Frow-span-computer.ts
   * @param data table data
   * @param columns names of left-to-right located columns row spans must be computed for
   */
  static compute(data: ComponentTableData[], columns: string[]): Array<Span[]> {
    const spans: Array<Span[]> = this.initSpans(columns);
    const spanColumnContexts: { span: Span, spannedRow: ComponentTableData }[] = new Array(columns.length);
    for (const row of data) {
      let thinBorder = false;
      for (let iCol = 0; iCol < columns.length; iCol++) {
        const column = columns[iCol];
        const spanColumnContext = spanColumnContexts[iCol];
        if (spanColumnContext &&
          spanColumnContext.spannedRow[column as keyof ComponentTableData] === row[column as keyof ComponentTableData]
          && (column === 'component' || column === 'recyclingStream')
          && spanColumnContext.spannedRow.index === row.index) {
          ++spanColumnContext.span.span;
          thinBorder = true;
          spans[iCol].push({ span: 0 });
        } else {
          const borderLine = thinBorder ? '1px solid lightgrey' : '3px solid lightgrey';
          const span = { span: 1, border: borderLine };
          spanColumnContexts[iCol] = { span, spannedRow: row };
          spans[iCol].push(span);
        }
      }
    }
    return spans;
  }

  static computeRecyclability(data: RecyclabilityTableData[], columns: string[]): Array<Span[]> {
    const spans: Array<Span[]> = this.initSpans(columns);
    const spanColumnContexts: { span: Span, spannedRow: RecyclabilityTableData }[] = new Array(columns.length);
    for (const row of data) {
      let thinBorder = false;
      for (let iCol = 0; iCol < columns.length; iCol++) {
        const column = columns[iCol];
        const spanColumnContext = spanColumnContexts[iCol];
        if (spanColumnContext &&
          spanColumnContext.spannedRow[column as keyof RecyclabilityTableData] ===
          row[column as keyof RecyclabilityTableData] && column === 'ratingName') {
          ++spanColumnContext.span.span;
          spans[iCol].push({ span: 0 });
        } else if (spanColumnContext && column === 'ratingPercentage' && spanColumnContexts[iCol - 1] &&
          spanColumnContexts[iCol - 1].span.span > 1) {
          ++spanColumnContext.span.span;
          thinBorder = true;
          spans[iCol].push({ span: 0 });
        } else {
          const borderLine = thinBorder ? '1px solid lightgrey' : '3px solid lightgrey';
          const span = { span: 1, border: borderLine };
          spanColumnContexts[iCol] = { span, spannedRow: row };
          spans[iCol].push(span);
          spanColumnContexts.slice(iCol + 1).forEach(c => c.spannedRow = new RecyclabilityTableData());
        }
      }
    }
    return spans;
  }

  static computeMaterials(data: ComponentMaterialsTableData[], columns: string[]): Array<Span[]> {
    const spans: Array<Span[]> = this.initSpans(columns);
    const spanColumnContexts: { span: Span, spannedRow: ComponentMaterialsTableData }[] = new Array(columns.length);
    for (const row of data) {
      let thinBorder = false;
      for (let iCol = 0; iCol < columns.length; iCol++) {
        const column = columns[iCol];
        const spanColumnContext = spanColumnContexts[iCol];
        if (spanColumnContext &&
          spanColumnContext.spannedRow[column as keyof ComponentMaterialsTableData] === row[column as keyof ComponentMaterialsTableData]
          && column === 'recyclingStream') {
          ++spanColumnContext.span.span;
          thinBorder = true;
          spans[iCol].push({ span: 0 });
        } else {
          const borderLine = thinBorder ? '1px solid lightgrey' : '3px solid lightgrey';
          const span = { span: 1, border: borderLine };
          spanColumnContexts[iCol] = { span, spannedRow: row };
          spans[iCol].push(span);
        }
      }
    }
    return spans;
  }

  private static initSpans(columns: string[]): Array<Span[]> {
    const spans: Array<Span[]> = [];
    columns.forEach(p => spans.push([]));
    return spans;
  }
}

export interface Span {
  span: number;
  border?: string;
}
