<h1 mat-dialog-title>{{ 'dataEvaluation.viewEvaluations.quantities.dialogTitle' | translate }}</h1>

<mat-dialog-content id="dialog-content">
  <table mat-table [dataSource]="quantitiesDataSource" id="quantities-table" matSort>

    <ng-container matColumnDef="packagingUnitId">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'dataEvaluation.viewEvaluations.quantities.table.packagingId' | translate }}
      </th>
      <td mat-cell *matCellDef="let element"> {{element.packagingUnitId}} </td>
    </ng-container>

    <ng-container matColumnDef="countryName">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'dataEvaluation.viewEvaluations.quantities.table.countryName' | translate }}
      </th>
      <td mat-cell *matCellDef="let element"> {{element.countryName}} </td>
    </ng-container>

    <ng-container matColumnDef="date">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'dataEvaluation.viewEvaluations.quantities.table.date' | translate }}
      </th>
      <td mat-cell *matCellDef="let element"> {{element.date}} </td>
    </ng-container>

    <ng-container matColumnDef="quantity">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>
        {{ 'dataEvaluation.viewEvaluations.quantities.table.quantity' | translate }}
      </th>
      <td mat-cell *matCellDef="let element">{{element.quantity}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
</mat-dialog-content>

<mat-dialog-actions>
  <button (click)="closeDialog()" mat-raised-button type="button">{{ 'common.buttons.close' | translate }}</button>
</mat-dialog-actions>
