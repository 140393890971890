import { IsAdhesiveUsed, RemovabilityConditionId, RemovedBeforeUsage, RemovedByConsumer } from '../../../services/component-services/field-name-constants';
import { AdditionalPackagingComponentsParentDialogComponent, ComponentDialogData } from './../additional-packaging-components-parent-dialog/additional-packaging-components-parent-dialog.component';
import { Component, Inject, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { RemovabilityConditionDto } from 'src/app/data-transfer/entities/removability-condition-dto';
import { PackagingComponentTypesEnum } from 'src/app/model/packaging-component-types-enum';
import { GtinCheckService } from 'src/app/services/component-services/gtin-check-service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ComponentTypeService } from 'src/app/navigation/services/component-type-service';

export interface DecorationDialogData extends ComponentDialogData {
  removabilityConditions?: RemovabilityConditionDto[];
}

@Component({
  selector: 'app-decoration-dialog',
  templateUrl: './decoration-dialog.component.html',
  styleUrls: ['./decoration-dialog.component.scss']
})
export class DecorationDialogComponent extends AdditionalPackagingComponentsParentDialogComponent implements OnInit {

  addEditDecorationForm: FormGroup;
  removabilityConditions: RemovabilityConditionDto[];

  constructor(
    protected dialogRef: MatDialogRef<DecorationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: any,
    protected gtinCheckService: GtinCheckService,
    private componentTypeService: ComponentTypeService
  ) {
    super(dialogRef, gtinCheckService);
    this.callerId = PackagingComponentTypesEnum.Decoration;

    super.setCommonComponentData(this.data);
    this.removabilityConditions = this.data.removabilityConditions ?? [];
    this.addEditDecorationForm = this.createFormCopy(data.baseForm);
    super.subscribeToValueChanges(this.addEditDecorationForm);
  }

  ngOnInit(): void {
    this.checkDecorationTypeDisableFields(this.addEditDecorationForm.controls.packagingComponentSubtypeId.value,
      this.addEditDecorationForm.controls.isAdhesiveUsed?.value);
    if (!this.canEditForm) {
      this.addEditDecorationForm.disable();
    }
    this.selectedManufacturingCountry = this.addEditDecorationForm.controls.manufacturingCountry.value ?? '';
  }

  private checkDecorationTypeDisableFields(type: number, adhesiveUsed: boolean) {
    const removedBeforeUsage = this.addEditDecorationForm.get(RemovedBeforeUsage)?.value ?? false;

    if (type == null || this.componentTypeService.isDecorationOfTypeSleeve(type)) {
      this.addEditDecorationForm.get(IsAdhesiveUsed)?.disable();
      this.addEditDecorationForm.get(RemovabilityConditionId)?.disable();
      this.addEditDecorationForm.get(RemovedBeforeUsage)?.enable();
      if (!removedBeforeUsage) {
        this.addEditDecorationForm.get(RemovedByConsumer)?.enable();
      } else {
        this.addEditDecorationForm.get(RemovedByConsumer)?.disable();
        this.addEditDecorationForm.get(RemovedByConsumer)?.patchValue(true);
      }
      this.addEditDecorationForm.get(IsAdhesiveUsed)?.patchValue(null);
      this.addEditDecorationForm.get(RemovabilityConditionId)?.patchValue(null);
    }

    if (this.componentTypeService.isDecorationOfTypeInMold(type)) {
      this.addEditDecorationForm.get(IsAdhesiveUsed)?.disable();
      this.addEditDecorationForm.get(RemovabilityConditionId)?.disable();
      this.addEditDecorationForm.get(RemovedBeforeUsage)?.disable();
      this.addEditDecorationForm.get(RemovedByConsumer)?.disable();

      this.addEditDecorationForm.get(RemovedBeforeUsage)?.patchValue(false);
      this.addEditDecorationForm.get(RemovedByConsumer)?.patchValue(false);
    }

    const requireAdhesives = this.componentTypeService.isDecorationOfTypeLabel(type);
    const requireRemovabilityCondition = requireAdhesives && adhesiveUsed;

    if (this.componentTypeService.isDecorationOfTypeLabel(type)) {
      this.addEditDecorationForm.get(IsAdhesiveUsed)?.enable();
      this.addEditDecorationForm.get(RemovedBeforeUsage)?.enable();
      if (!removedBeforeUsage) {
        this.addEditDecorationForm.get(RemovedByConsumer)?.enable();
      } else {
        this.addEditDecorationForm.get(RemovedByConsumer)?.disable();
        this.addEditDecorationForm.get(RemovedByConsumer)?.patchValue(true);
      }
      if (requireRemovabilityCondition) {
        this.addEditDecorationForm.get(RemovabilityConditionId)?.enable();
      } else {
        this.addEditDecorationForm.get(RemovabilityConditionId)?.disable();
        this.addEditDecorationForm.get(RemovabilityConditionId)?.patchValue(null);
      }
    }
  }

  onDecorationTypeChange(decoType: number) {
    this.checkDecorationTypeDisableFields(decoType, this.addEditDecorationForm.get(IsAdhesiveUsed)?.value);
  }

  onAdhesivesOptionChange(isAdhesiveUsed: boolean) {
    this.checkDecorationTypeDisableFields(this.addEditDecorationForm.controls.packagingComponentSubtypeId.value, isAdhesiveUsed);
  }

  async doAction() { super.doAction(this.addEditDecorationForm); }
}
