import { PackagingUnitLifeCycleResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-unit-life-cycle-result-dto';
import { LcaStageInfo, LcaEffectInfo } from '../life-cycle-data-information';
import { LifeCycleResult } from './life-cycle-result';

export class LifeCycleResultPackagingUnit extends LifeCycleResult {

  lifeCycleDataSource!: PackagingUnitLifeCycleResultDto;

  constructor(
    private template: PackagingUnitLifeCycleResultDto,
    private stagesInfo: LcaStageInfo[],
    private effectsInfo: LcaEffectInfo[],
    private countryName: string
  ) {
    super();

    const unit = this.template.analyzedPackagingUnit;
    this.packagingPartId = unit.id;
    this.packagingPartVersion = unit.version ?? 0;
    this.packagingPartName = `${unit.brandName}/${unit.productName}`;
    this.packagingVolume = unit.packagingVolume ?? null;
    this.packagingWeight = unit.packagingQuantity ?? null;

    this.lifeCycleDataSource = template;
    this.analysisId = this.template.id;
    this.evaluatedCountryCode = this.template.evaluatedCountry;
    this.validationState = this.template.validationState;

    this.evaluatedCountry = this.countryName;
    this.effectRelevances = super.createEffectRelevances(template, this.effectsInfo);
    if (this.lifeCycleDataSource.id !== '') {
      super.initChartData(this.lifeCycleDataSource, this.effectRelevances, this.stagesInfo);
    }
  }
}