<h1 mat-dialog-title>{{ 'dataManagement.exportProfiles.title' | translate }}</h1>

<mat-dialog-content id="dialog-content">
  <form [formGroup]="addEditProfileForm">
    <div formGroupName="profileData">
      <mat-form-field class="form-field-medium margin-bottom">
        <mat-label>{{ 'dataManagement.exportProfiles.dialog.nameLabel' | translate }}</mat-label>
        <input matInput formControlName="profileName" required autocomplete="off">
        <mat-error *ngIf="profileNameControl.errors">
          <span *ngIf="profileNameControl.errors.required">
            {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
          </span>
          <span *ngIf="profileNameControl.hasError('nameExists')">
            {{ 'dataManagement.exportProfiles.errors.nameUsed' | translate }}
          </span>
        </mat-error>
      </mat-form-field>
    </div>
    <div *ngIf="!isReadOnly">
      <button mat-stroked-button (click)="setAllValues(true)" class="selection-button" color="primary">
        {{ 'dataManagement.exportProfiles.buttons.setAllTrue' | translate }}
      </button>
      <button mat-stroked-button (click)="setAllValues(false)" class="selection-button" color="primary">
        {{ 'dataManagement.exportProfiles.buttons.setAllFalse' | translate }}
      </button>
      <span *ngIf="isPackagingUnitExportProfile">
        <button mat-stroked-button (click)="setEvaluationValues()" class="selection-button" color="primary">
          {{ 'dataManagement.exportProfiles.buttons.setRecTrue' | translate }}
        </button>
        <button mat-stroked-button (click)="setEvaluationValues(true)" class="selection-button" color="primary">
          {{ 'dataManagement.exportProfiles.buttons.setLcaTrue' | translate }}
        </button>
      </span>
      <button *ngIf="filterApplied" mat-icon-button (click)="resetOriginalValues()" color="primary"
        matTooltip="{{ 'dataManagement.exportProfiles.buttons.tooltips.reset' | translate }}" id="reset-button">
        <mat-icon>undo</mat-icon>
      </button>
    </div>
    <ng-container *ngTemplateOutlet="nodeTemplateRef; context:{ nodes: [rootNode], expanded: true }"></ng-container>
  </form>

  <div *ngIf="subProfilesDataSource">
    <mat-label class="label-large">
      {{ 'dataManagement.directory.dialog.export.defaultUnitExportProfileLabel' | translate }}
    </mat-label>
    <app-export-profiles-table [dataSource]="subProfilesDataSource" [displayedColumns]="['profileName', 'viewProfile']"
      [(selectedProfile)]="selectedSubProfile" [canSelectRows]="true" (viewProfileEvent)="viewSubProfile($event)">
    </app-export-profiles-table>
    <mat-label class="label-hint">
      {{ 'dataManagement.directory.dialog.export.selectedProfile' | translate: {profileName:
      selectedSubProfile?.profileName ?? '--'} }}
    </mat-label>
  </div>
</mat-dialog-content>

<ng-template #nodeTemplateRef let-nodes="nodes" , let-expanded="expanded">
  <ng-container *ngFor="let node of nodes">
    <mat-expansion-panel class="margin-panel" [expanded]="expanded">
      <mat-expansion-panel-header>
        <mat-panel-title>
          {{ node.name }}
        </mat-panel-title>
      </mat-expansion-panel-header>
      <div *ngFor="let item of node.items">
        <div [ngTemplateOutlet]="isReadOnly ? uneditableFields : editableFields"
          [ngTemplateOutletContext]="{item: item}">
        </div>
      </div>
      <div class="flex-row-container">
        <div *ngFor="let card of node.cards" class="flex-item">
          <mat-card class="node-card">
            <mat-card-content>
              <div *ngFor="let item of card.items">
                <div [ngTemplateOutlet]="isReadOnly ? uneditableFields : editableFields"
                  [ngTemplateOutletContext]="{item: item}">
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </div>
      </div>
      <mat-accordion *ngIf="node.children.length > 0">
        <ng-container *ngTemplateOutlet="nodeTemplateRef; context:{ nodes: node.children, expanded: false}">
        </ng-container>
      </mat-accordion>
    </mat-expansion-panel>
  </ng-container>
</ng-template>

<mat-dialog-actions>
  <button *ngIf="!isReadOnly" (click)="onSaveClicked()" mat-raised-button color="primary" type="submit">
    {{ 'common.buttons.save' | translate }}
  </button>
  <button (click)="closeDialog()" mat-raised-button type="button">
    {{ 'common.buttons.cancel' | translate }}</button>
</mat-dialog-actions>

<ng-template #uneditableFields let-item='item'>
  <div id="icon-text">
    <mat-icon *ngIf="item.form.value" [ngClass]="isDarkTheme ? 'green-dark-theme' : 'green-light-theme'">check
    </mat-icon>
    <mat-icon *ngIf="!item.form.value" [ngClass]="isDarkTheme ? 'red-dark-theme' : 'red-light-theme'">close</mat-icon>
    <span id="field-name-text">{{ item.name }}</span>
  </div>
</ng-template>

<ng-template #editableFields let-item='item'>
  <mat-checkbox [formControl]="item.form">{{ item.name }}</mat-checkbox>
</ng-template>
