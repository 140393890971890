<mat-card class="full-height-container">
  <mat-tab-group color="accent" [backgroundColor]="" class="full-height-container">

    <mat-tab label="{{ 'dataManagement.import.titleImport' | translate }}">
      <div class="full-height-container tab-container">
        <div class="settings-container">
          <h2 class="table-header">{{ 'dataManagement.import.titleImportSettings' | translate }}</h2>
          <div class="margin-div buttons-container">
            <mat-button-toggle-group class="inner-button-container" name="importType"
              #importTypeGroup="matButtonToggleGroup" vertical [value]="selectedImportType">
              <mat-button-toggle (click)="importWithTracking()" [value]="importTypeEnum.WithTracking"
                class="long-text-label">
                {{ 'dataManagement.import.import.importType.packagingSystem.withTracking' | translate }}
              </mat-button-toggle>
              <mat-button-toggle (click)="importWithoutTracking()" [value]="importTypeEnum.WithoutTracking"
                class="long-text-label">
                {{ 'dataManagement.import.import.importType.packagingSystem.withoutTracking' | translate }}
              </mat-button-toggle>
            </mat-button-toggle-group>
            <span class="hint-container">
              <span *ngIf="importTypeGroup.value === importTypeEnum.WithTracking" class="hint">
                {{ 'dataManagement.import.import.importType.packagingSystem.withTrackingHint' | translate }}
              </span>
              <span *ngIf="importTypeGroup.value === importTypeEnum.WithoutTracking" class="hint">
                {{ 'dataManagement.import.import.importType.packagingSystem.withoutTrackingHint' | translate }}
              </span>
            </span>
          </div>
          <mat-divider></mat-divider>
          <div class="margin-div buttons-container">
            <div class="inner-button-container">
              <button mat-raised-button color="primary" (click)="proceedWithImport()"
                [disabled]="importTypeGroup.value == null || selectedCandidates.length < 1" type="button">
                {{ 'dataManagement.import.buttons.proceed' | translate }} <mat-icon>arrow_forward</mat-icon>
              </button>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="margin-div buttons-container">
            <div class="inner-button-container">
              <button mat-raised-button color="warn" (click)="removeCandidates()"
                [disabled]="selectedCandidates.length < 1" type="button">
                {{ 'dataManagement.import.buttons.remove' | translate }}
              </button>
            </div>
          </div>
        </div>
        <mat-divider [vertical]="true"></mat-divider>
        <div class="import-table-container">
          <h2 class="table-header">{{ 'dataManagement.import.import.tableTitlePackagingSystem' | translate }}</h2>
          <div class="local-scrolling-wrapper">
            <app-import-html #importHtml [displayedColumns]="displayedColumns" [dataSource]="dataSourceImports"
              [selectedCandidates]="selectedCandidates" [isPackagingSystem]="true" [isChange]="false"
              (candidatesSelected)="setSelectedImportCandidates($event)"
              (navigationClicked)="navigateToCandidate($event.candidate)">
            </app-import-html>
          </div>
        </div>
      </div>
    </mat-tab>

    <mat-tab label="{{ 'dataManagement.import.titleChangeTracking' | translate }}">
      <div class="full-height-container tab-container">
        <div class="settings-container">
          <h2 class="table-header">{{ 'dataManagement.import.titleImportSettings' | translate }}</h2>
          <div class="margin-div buttons-container">
            <mat-button-toggle-group class="inner-button-container" name="changesType"
              #changesTypeGroup="matButtonToggleGroup" vertical [value]="selectedChangeType">
              <mat-button-toggle (click)="acceptChangesWithTrackingOverwrite()"
                [value]="changeTypeEnum.WithTrackingOverwrite" class="long-text-label">
                {{ 'dataManagement.import.changes.importType.packagingSystem.withTrackingOverwrite' | translate }}
              </mat-button-toggle>
              <mat-button-toggle (click)="acceptChangesWithTrackingNew()" [value]="changeTypeEnum.WithTrackingNew"
                class="long-text-label">
                {{ 'dataManagement.import.changes.importType.packagingSystem.withTrackingNew' | translate }}
              </mat-button-toggle>
              <mat-button-toggle (click)="acceptChangesWithoutTracking()" [value]="changeTypeEnum.WithoutTracking"
                class="long-text-label">
                {{ 'dataManagement.import.changes.importType.packagingSystem.withoutTracking' | translate }}
              </mat-button-toggle>
            </mat-button-toggle-group>
            <span class="hint-container">
              <span *ngIf="changesTypeGroup.value === changeTypeEnum.WithTrackingOverwrite" class="hint">
                {{ 'dataManagement.import.changes.importType.packagingSystem.withTrackingOverwriteHint' | translate }}
              </span>
              <span *ngIf="changesTypeGroup.value === changeTypeEnum.WithTrackingNew" class="hint">
                {{ 'dataManagement.import.changes.importType.packagingSystem.withTrackingNewHint' | translate }}
              </span>
              <span *ngIf="changesTypeGroup.value === changeTypeEnum.WithoutTracking" class="hint">
                {{ 'dataManagement.import.changes.importType.packagingSystem.withoutTrackingHint' | translate }}
              </span>
            </span>
          </div>
          <mat-divider></mat-divider>
          <div class="margin-div buttons-container">
            <div class="inner-button-container">
              <button mat-raised-button color="primary" (click)="proceedWithChange()"
                [disabled]="changesTypeGroup.value == undefined || selectedChanges.length < 1" type="button">
                {{ 'dataManagement.import.buttons.proceed' | translate }} <mat-icon>arrow_forward</mat-icon>
              </button>
            </div>
          </div>
          <mat-divider></mat-divider>
          <div class="margin-div buttons-container">
            <div class="inner-button-container">
              <button mat-raised-button color="warn" (click)="removeChanges()" [disabled]="selectedChanges.length < 1"
                type="button">
                {{ 'dataManagement.import.buttons.remove' | translate }}
              </button>
            </div>
          </div>
        </div>
        <mat-divider [vertical]="true"></mat-divider>
        <div class="import-table-container">
          <h2 class="table-header">{{ 'dataManagement.import.changes.tableTitlePackagingSystem' | translate }}</h2>
          <div class="local-scrolling-wrapper">
            <app-import-html #changeHtml [displayedColumns]="displayedColumns" [dataSource]="dataSourceChanges"
              [selectedCandidates]="selectedChanges" [isPackagingSystem]="true" [isChange]="true"
              (candidatesSelected)="setSelectedImportChanges($event)"
              (navigationClicked)="navigateToChange($event.candidate, $event.navigateToCurrentVersion)">
            </app-import-html>
          </div>
        </div>
      </div>
    </mat-tab>
  </mat-tab-group>
</mat-card>
