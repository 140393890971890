import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

// https://medium.com/grensesnittet/dynamic-themes-in-angular-material-b6dc0c88dfd7
// https://stackoverflow.com/questions/37541783/how-to-subscribe-an-item-from-localstorage-in-angular-2-and-when-changed-get-va

export const COLOR_THEME_LIGHT = 'light';
export const COLOR_THEME_DARK = 'dark';
export const COLOR_THEME_NAME = 'colorTheme';

@Injectable({ providedIn: 'root' })
export class ColorThemeService {
  colorThemeSubject = new BehaviorSubject(this.colorTheme);

  set colorTheme(themeString: string) {
    this.colorThemeSubject.next(themeString);
    localStorage.setItem(COLOR_THEME_NAME, themeString);
  }

  get colorTheme() {
    return localStorage.getItem(COLOR_THEME_NAME) ?? COLOR_THEME_LIGHT;
  }

  isDarkTheme() {
    return this.colorTheme === COLOR_THEME_DARK;
  }
}
