<app-packaging-system-tree [packagingSystem]="packagingSystem" [isDarkTheme]="isDarkTheme" [includeRoot]="true"
  [includeComponents]="true" [deselectionAllowed]="false" (nodeSelected)="onNodeSelected($event)">
</app-packaging-system-tree>

<div *ngIf="dataSourceForCountry != null">

  <div class="total-values-container">
    <span class="label-large">{{ 'analysis.recyclabilityAnalysis.recyclableTotal' | translate }}</span>
    <span class="label-large green">{{dataSourceForCountry.recyclableTotal}}%</span>
    <span class="label-large">{{ 'analysis.recyclabilityAnalysis.nonRecyclableTotal' | translate }}</span>
    <span class="label-large red">{{dataSourceForCountry.nonRecyclableTotal}}%</span>
  </div>
  <div class="inner-container">
    <div class="chart-container">
      <app-recyclability-chart [chartRawData]="dataSourceForCountry" [chartHeight]="600" [showLabels]="true"
        [showExport]="true" [minimizeLegend]="false" [showTitle]="true" [isDarkTheme]="isDarkTheme" [chartWidth]="1500">
      </app-recyclability-chart>
    </div>

    <div *ngIf="selectedNodeType === packagingPart.System">
      <app-packaging-system-components-table [dataSource]="dataSourceForCountryAsSystem">
      </app-packaging-system-components-table>
    </div>

    <div *ngIf="selectedNodeType === packagingPart.Unit">
      <app-packaging-unit-components-table [dataSource]="dataSourceForCountryAsUnit" [recyclingStreams]="recyclingStreams">
      </app-packaging-unit-components-table>

      <app-packaging-unit-ratings-table [dataSource]="dataSourceForCountryAsUnit">
      </app-packaging-unit-ratings-table>
    </div>

    <div *ngIf="selectedNodeType === packagingPart.Component">
      <app-component-materials-table [dataSource]="dataSourceForCountryAsComponent" [recyclingStreams]="recyclingStreams">
      </app-component-materials-table>

      <app-packaging-unit-ratings-table [dataSource]="dataSourceForCountryAsUnit">
      </app-packaging-unit-ratings-table>
    </div>
  </div>
</div>
