import { Subscription } from 'rxjs';
import { PackagingUnitTypeService } from '../../services/packaging-unit-type-service';
import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, NavigationError, RouterEvent, NavigationCancel, ActivatedRoute, Scroll } from '@angular/router';
import { MatSidenav } from '@angular/material/sidenav';
import { CountriesService } from '../../services/countries-service';
import { ComponentTypeService } from '../../services/component-type-service';
import { NgxSpinnerService } from 'ngx-spinner';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-nav',
  templateUrl: './app-nav.component.html',
  styleUrls: ['./app-nav.component.scss']
})
export class AppNavComponent implements OnInit, OnDestroy {

  @ViewChild('sidenav') sidenav!: MatSidenav;
  syncronizing = false;
  isMinimalLayout = false;

  private routerEventSubscription: Subscription;
  private routeDataSubscription?: Subscription;

  constructor(
    private router: Router,
    private spinner: NgxSpinnerService,
    private packagingUnitTypeService: PackagingUnitTypeService,
    private componentTypeService: ComponentTypeService,
    private countriesService: CountriesService,
    private route: ActivatedRoute,
    private responsive: BreakpointObserver
  ) {
    this.routerEventSubscription = this.router.events.subscribe(routerEvent => {
      this.showHideSpinner(routerEvent as RouterEvent);
    });
  }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.data.subscribe(data => {
      this.packagingUnitTypeService.setPackagingUnitTypes(data.packagingUnitTypes);
      this.packagingUnitTypeService.setPackagingUnitTypesEnglish(data.packagingUnitTypesEng);

      this.componentTypeService.setComponentTypes(data.componentTypes);
      this.componentTypeService.setComponentTypesEnglish(data.componentTypesEng);
      this.componentTypeService.setDecorationTypesEnglish(data.decorationTypesEnglish);

      this.countriesService.setCountries(data.allCountries);
      this.countriesService.setCountriesEng(data.allCountriesEng);
    });
    this.responsive.observe(Breakpoints.WebLandscape)
      .subscribe(result => {
        this.isMinimalLayout = !result.matches;
      });
  }

  private showHideSpinner(routerEvent: RouterEvent): void {
    if (routerEvent instanceof NavigationEnd ||
      routerEvent instanceof NavigationCancel ||
      routerEvent instanceof NavigationError) {
      this.spinner.hide();
    } else if (routerEvent instanceof Scroll && routerEvent.routerEvent instanceof NavigationEnd) {
      // Since Angular 15 Scroll event is triggered on navigation.
      // If show spinner on Scroll, causes infinite spinner.
      // If hide spinner on Scroll, spinner in subcomponents is always canceled.
      return;
    } else {
      this.spinner.show();
    }
  }

  ngOnDestroy(): void {
    this.routerEventSubscription?.unsubscribe();
    this.routeDataSubscription?.unsubscribe();
  }
}
