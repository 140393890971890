<mat-card id="outer-card">
  <mat-card-content>
    <h3 *ngIf="defaultTags.length > 0">{{ 'dataManagement.tags.tagsManagement.defaultTags' | translate}}</h3>
    <div class="tags-container">
      <mat-card *ngFor="let tag of defaultTags" class="single-tag-container">
        <mat-card-content>
          <app-tag-directive [tags]="[tag]"></app-tag-directive>
          <div *ngIf="isUserAdmin">
            <button mat-icon-button (click)="editTag(tag)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button (click)="deleteTag(tag)">
              <mat-icon>delete_forever</mat-icon>
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>

    <button *ngIf="isUserAdmin" mat-raised-button (click)="createTag(true)" color="primary" type="button" id="add-button">
      <mat-icon>add</mat-icon>
      {{ 'dataManagement.tags.tagsManagement.add' | translate}}
    </button>

    <mat-divider class="custom-divider"></mat-divider>

    <h3>{{ 'dataManagement.tags.tagsManagement.customTags' | translate}}</h3>
    <div class="tags-container">
      <mat-card *ngFor="let tag of customTags" class="single-tag-container">
        <mat-card-content>
          <app-tag-directive [tags]="[tag]"></app-tag-directive>
          <div>
            <button mat-icon-button (click)="editTag(tag)">
              <mat-icon>edit</mat-icon>
            </button>
            <button mat-icon-button (click)="deleteTag(tag)">
              <mat-icon>delete_forever</mat-icon>
            </button>
          </div>
        </mat-card-content>
      </mat-card>
    </div>
    <button mat-raised-button (click)="createTag()" color="primary" type="button" id="add-button">
      <mat-icon>add</mat-icon>
      {{ 'dataManagement.tags.tagsManagement.add' | translate}}
    </button>
  </mat-card-content>
</mat-card>
