import { Component, EventEmitter, Input, OnInit, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, Form, FormControl, NgControl } from '@angular/forms';
import { MatSelectChange } from '@angular/material/select';
import { TriStateDictionary } from 'src/app/model/dictionary';

export const NOOP_VALUE_ACCESSOR: ControlValueAccessor = {
  writeValue(): void {},
  registerOnChange(): void {},
  registerOnTouched(): void {},
};

@Component({
  selector: 'app-tri-state-selector',
  templateUrl: './tri-state-selector.component.html',
  styleUrls: ['./tri-state-selector.component.scss'],
})
export class TriStateSelectorComponent implements OnInit {
  @Input({ required: true }) options!: TriStateDictionary[];
  @Input() label: string = '';

  @Output() readonly selectionChange: EventEmitter<MatSelectChange> = new EventEmitter<MatSelectChange>();

  placeholder: string = '';

  get control() {
    return this.ngControl.control as FormControl;
  }

  constructor(@Optional() @Self() public ngControl: NgControl) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = NOOP_VALUE_ACCESSOR;
    }
  }

  onSelectionChange(event: MatSelectChange): void {
    this.selectionChange.emit(event);
  }

  ngOnInit() {
    for (const entry of this.options) {
      if (entry.key == null) {
        this.placeholder = entry.value;
        break;
      }
    }
  }
}
