import { CdkAccordionModule } from '@angular/cdk/accordion';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { CdkTableModule } from '@angular/cdk/table';
import { CommonModule, DecimalPipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClient, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, Injector, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { HighchartsChartModule } from 'highcharts-angular';
import { AlertModule } from 'ngx-bootstrap/alert';
import { LightboxModule } from 'ngx-lightbox';
import { MarkdownModule } from 'ngx-markdown';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PerformAggregateEvaluationsComponent } from './components/analyses/aggregate-evaluations/perform-aggregate-evaluations/perform-aggregate-evaluations.component';
import { ViewAggregateEvaluationsComponent } from './components/analyses/aggregate-evaluations/view-aggregate-evaluations/view-aggregate-evaluations.component';
import { ViewLifecycleAggregateEvaluationComponent } from './components/analyses/aggregate-evaluations/view-lifecycle-aggregate-evaluation/view-lifecycle-aggregate-evaluation.component';
import { ViewMassAggregateEvaluationComponent } from './components/analyses/aggregate-evaluations/view-mass-aggregate-evaluation/view-mass-aggregate-evaluation.component';
import { ViewRecyclabilityAggregateEvaluationComponent } from './components/analyses/aggregate-evaluations/view-recyclability-aggregate-evaluation/view-recyclability-aggregate-evaluation.component';
import { AnalysisLifeCycleComponent } from './components/analyses/analysis-life-cycle/analysis-life-cycle.component';
import { AnalysisParentComponent } from './components/analyses/analysis-parent/analysis-parent.component';
import { AnalysisRecyclabilityComponent } from './components/analyses/analysis-recyclability/analysis-recyclability.component';
import { LifeCycleParentChartComponent } from './components/analyses/charts/life-cycle-chart/life-cycle-chart.component';
import { LifeCycleComparisonChartComponent } from './components/analyses/charts/life-cycle-comparison-chart/life-cycle-comparison-chart.component';
import { MapRecyclabilityChartComponent } from './components/analyses/charts/map-recyclability-chart/map-recyclability-chart.component';
import { MassChartComponent } from './components/analyses/charts/mass-chart/mass-chart.component';
import { RecyclabilityChartComponent } from './components/analyses/charts/recyclability-chart/recyclability-chart.component';
import { AddOrganizationDialogComponent } from './components/dialogs/add-organization-dialog/add-organization-dialog.component';
import { UserDialogComponent } from './components/dialogs/add-user-dialog/add-user-dialog.component';
import { AggregatedQuantitiesDialogComponent } from './components/dialogs/aggregated-quantities-dialog/aggregated-quantities-dialog.component';
import { AnalysisNotPossibleDialogComponent } from './components/dialogs/analysis-not-possible-dialog/analysis-not-possible-dialog.component';
import { AssignCreditsDialogComponent } from './components/dialogs/assign-credits-dialog/assign-credits-dialog.component';
import { ChangePasswordDialogComponent } from './components/dialogs/change-password-dialog/change-password-dialog.component';
import { ChangelogDialogComponent } from './components/dialogs/changelog-dialog/changelog-dialog.component';
import { ClosureDialogComponent } from './components/dialogs/closure-dialog/closure-dialog.component';
import { CompositeMaterialDialogComponent } from './components/dialogs/composite-material-dialog/composite-material-dialog.component';
import { CopyWithTrackingComponent } from './components/dialogs/copy-with-tracking/copy-with-tracking.component';
import { DecorationDialogComponent } from './components/dialogs/decoration-dialog/decoration-dialog.component';
import { DeleteItemDialogComponent } from './components/dialogs/delete-item-dialog/delete-item-dialog.component';
import { DisclaimerDialogComponent } from './components/dialogs/disclaimer-dialog/disclaimer-dialog.component';
import { EvaluationAuthorizationsDialogComponent } from './components/dialogs/evaluation-authorizations-dialog/evaluation-authorizations-dialog.component';
import { InlayDialogComponent } from './components/dialogs/inlay-dialog/inlay-dialog.component';
import { LifeCycleTableDialogComponent } from './components/dialogs/life-cycle-table-dialog/life-cycle-table-dialog.component';
import { MainBodyDialogComponent } from './components/dialogs/main-body-dialog/main-body-dialog.component';
import { MaterialDialogComponent } from './components/dialogs/material-dialog/material-dialog.component';
import { OrganizationsVisibilityDialogComponent } from './components/dialogs/organizations-visibility-dialog/organizations-visibility-dialog.component';
import { PackagingAidDialogComponent } from './components/dialogs/packaging-aid-dialog/packaging-aid-dialog.component';
import { PackagingUnitUsageInEvalDialogComponent } from './components/dialogs/packaging-unit-usage-in-eval-dialog/packaging-unit-usage-in-eval-dialog.component';
import { RenameDialogComponent } from './components/dialogs/rename-dialog/rename-dialog.component';
import { SelectionDialogComponentsComponent } from './components/dialogs/selection-dialog-components/selection-dialog-components.component';
import { SelectionDialogMaterialsComponent } from './components/dialogs/selection-dialog-materials/selection-dialog-materials.component';
import { SelectionDialogPackagingSystemsComponent } from './components/dialogs/selection-dialog-packaging-systems/selection-dialog-packaging-systems.component';
import { SelectionDialogPackagingUnitsComponent } from './components/dialogs/selection-dialog-packaging-units/selection-dialog-packaging-units.component';
import { SimpleAlertDialogComponent } from './components/dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { SimpleConfirmDialogComponent } from './components/dialogs/simple-confirm-dialog/simple-confirm-dialog.component';
import { StopScrollingDirective } from './components/directives/stop-scrolling.directive';
import { CompositeMaterialsOverviewComponent } from './components/directory-tree/components/composite-materials-overview/composite-materials-overview.component';
import { DirectoryManagementComponent } from './components/directory-tree/components/directory-management/directory-management.component';
import { DirectoryTreeComponent } from './components/directory-tree/components/directory-tree/directory-tree.component';
import { ExportProfileDialogComponent } from './components/directory-tree/components/export-profiles/export-profile-dialog/export-profile-dialog.component';
import { ExportProfilesTableComponent } from './components/directory-tree/components/export-profiles/export-profiles-table/export-profiles-table.component';
import { ExportProfilesComponent } from './components/directory-tree/components/export-profiles/export-profiles/export-profiles.component';
import { ImportComponentComponent } from './components/directory-tree/components/import/import-component/import-component.component';
import { ImportHtmlComponent } from './components/directory-tree/components/import/import-html/import-html.component';
import { ImportMaterialComponent } from './components/directory-tree/components/import/import-material/import-material.component';
import { ImportPackagingSystemComponent } from './components/directory-tree/components/import/import-packaging-system/import-packaging-system.component';
import { ImportPackagingUnitComponent } from './components/directory-tree/components/import/import-packaging-unit/import-packaging-unit.component';
import { ImportParentComponent } from './components/directory-tree/components/import/import-parent/import-parent.component';
import { PackagingComponentsOverviewComponent } from './components/directory-tree/components/packaging-components-overview/packaging-components-overview.component';
import { PackagingSystemsOverviewComponent } from './components/directory-tree/components/packaging-systems-overview/packaging-systems-overview.component';
import { PackagingUnitsOverviewComponent } from './components/directory-tree/components/packaging-units-overview/packaging-units-overview.component';
import { EditQuantitiesDialogComponent } from './components/directory-tree/components/quantities/edit-quantities-dialog/edit-quantities-dialog.component';
import { EditQuantityDialogComponent } from './components/directory-tree/components/quantities/edit-quantity-dialog/edit-quantity-dialog.component';
import { MonthTableComponent } from './components/directory-tree/components/quantities/month-table/month-table.component';
import { SelectTagsDialogComponent } from './components/directory-tree/components/tags/select-tags-dialog/select-tags-dialog.component';
import { SelectTagsComponent } from './components/directory-tree/components/tags/select-tags/select-tags.component';
import { ToolbarDirectoryComponent } from './components/directory-tree/components/toolbar-directory/toolbar-directory.component';
import { AddEditDirectoryDialogComponent } from './components/directory-tree/dialogs/add-edit-directory-dialog/add-edit-directory-dialog.component';
import { ExportDialogComponent } from './components/directory-tree/dialogs/export-dialog/export-dialog.component';
import { PermissionsDialogComponent } from './components/directory-tree/dialogs/permissions-dialog/permissions-dialog.component';
import { SelectLocationDialogComponent } from './components/directory-tree/dialogs/select-location-dialog/select-location-dialog.component';
import { CompositeMaterialHtmlTemplateComponent } from './components/packaging-component-material/create-update/common/composite-material-html-template/composite-material-html-template.component';
import { MaterialRoutingWrapperComponent } from './components/packaging-component-material/create-update/common/material-routing-wrapper/material-routing-wrapper.component';
import { CompositeMaterialComponent } from './components/packaging-component-material/create-update/composite-material/composite-material.component';
import { MaterialHomeComponent } from './components/packaging-component-material/material-home/material-home.component';
import { ComponentHomeComponent } from './components/packaging-component/component-home/component-home.component';
import { ComponentCreateUpdateParentComponent } from './components/packaging-component/create-update/common/component-create-update-parent/component-create-update-parent.component';
import { ComponentHtmlTemplateComponent } from './components/packaging-component/create-update/common/component-html-template/component-html-template.component';
import { ComponentRoutingWrapperComponent } from './components/packaging-component/create-update/common/component-routing-wrapper/component-routing-wrapper.component';
import { ComponentSubHtmlTemplateComponent } from './components/packaging-component/create-update/common/component-sub-html-template/component-sub-html-template.component';
import { CreateUpdateClosureComponent } from './components/packaging-component/create-update/create-update-closure/create-update-closure.component';
import { CreateUpdateDecorationComponent } from './components/packaging-component/create-update/create-update-decoration/create-update-decoration.component';
import { CreateUpdateInlayComponent } from './components/packaging-component/create-update/create-update-inlay/create-update-inlay.component';
import { CreateUpdateMainBodyComponent } from './components/packaging-component/create-update/create-update-main-body/create-update-main-body.component';
import { CreateUpdatePackagingAidComponent } from './components/packaging-component/create-update/create-update-packaging-aid/create-update-packaging-aid.component';
import { PackagingSystemHtmlTemplateComponent } from './components/packaging-system/create-update/common/packaging-system-html-template/packaging-system-html-template.component';
import { PackagingSystemTreeComponent } from './components/packaging-system/create-update/common/packaging-system-tree/packaging-system-tree.component';
import { CreateUpdatePackagingSystemComponent } from './components/packaging-system/create-update/create-update-packaging-system/create-update-packaging-system.component';
import { PackagingSystemHomeComponent } from './components/packaging-system/packaging-system-home/packaging-system-home.component';
import { ComparePackagingUnitsComponent } from './components/packaging-unit/compare/compare-packaging-units.component';
import { PackagingRoutingWrapperComponent } from './components/packaging-unit/create-update/common/packaging-routing-wrapper/packaging-routing-wrapper.component';
import { PackagingUnitHtmlTemplateComponent } from './components/packaging-unit/create-update/common/packaging-unit-html-template/packaging-unit-html-template.component';
import { CreateUpdatePackagingUnitComponent } from './components/packaging-unit/create-update/create-update-packaging-unit/create-update-packaging-unit.component';
import { PackagingUnitHomeComponent } from './components/packaging-unit/packaging-unit-home/packaging-unit-home.component';
import { ReportGenerationDialogComponent } from './components/reports/report-generation-dialog/report-generation-dialog.component';
import { ReportsHomeComponent } from './components/reports/reports-home/reports-home.component';
import { MaterialLayersReportTemplateComponent } from './components/reports/templates/material-layers-report-template/material-layers-report-template.component';
import { ReportTemplateWizardComponent } from './components/reports/templates/report-template-wizard/report-template-wizard.component';
import { ReportTemplatesHomeComponent } from './components/reports/templates/report-templates-home/report-templates-home.component';
import { ReportTemplatesOverviewComponent } from './components/reports/templates/report-templates-overview/report-templates-overview.component';
import { AdditionalPackagingComponent } from './components/shared-components/additional-packaging/additional-packaging.component';
import { MassCalculatorComponent } from './components/shared-components/mass-calculator/mass-calculator.component';
import { MaterialLayersViewComponent } from './components/shared-components/material-layers-view/material-layers-view.component';
import { FilterMenuComponent } from './components/shared-components/menus/filter-menu/filter-menu.component';
import { MultiMaterialTableComponent } from './components/shared-components/multi-material-table/multi-material-table.component';
import { NormalizationComponent } from './components/shared-components/normalization/normalization.component';
import { CreateUpdatePackagingPartComponent } from './components/shared-components/parent-components/create-update-packaging-part/create-update-packaging-part.component';
import { PasswordFormComponent } from './components/shared-components/password-form/password-form.component';
import { FileUploadComponent } from './components/shared-components/upload/file-upload/file-upload.component';
import { ImageUploadComponent } from './components/shared-components/upload/image-upload/image-upload.component';
import { LcaDataExcelImportComponent } from './components/shared-components/upload/lca-data-excel-import/lca-data-excel-import.component';
import { PdfUploadComponent } from './components/shared-components/upload/pdf-upload/pdf-upload.component';
import { UsersTableComponent } from './components/shared-components/users-table/users-table.component';
import { ChangelogComponent } from './components/various-components/changelog/changelog.component';
import { DependenciesComponent } from './components/various-components/dependencies/dependencies.component';
import { ErrorPageComponent } from './components/various-components/error-page/error-page.component';
import { HomepageComponent } from './components/various-components/homepage/homepage.component';
import { InternationalInfosComponent } from './components/various-components/international-infos/international-infos.component';
import { LoginComponent } from './components/various-components/login/login.component';
import { OrganizationManagementComponent } from './components/various-components/organization-management/organization-management.component';
import { SplitterComponent } from './components/various-components/splitter/splitter.component';
import { TagDirectiveComponent } from './components/various-components/tags/tag-directive/tag-directive.component';
import { TagsDialogComponent } from './components/various-components/tags/tags-dialog/tags-dialog.component';
import { TagsManagementComponent } from './components/various-components/tags/tags-management/tags-management.component';
import { UserManagementComponent } from './components/various-components/user-management/user-management.component';
import { ValidatorViewComponent } from './components/various-components/validator-view/validator-view.component';
import { NavigationModule } from './navigation/navigation.module';
import { ApiVersionInterceptor } from './services/api-version-interceptor';
import { appInitializerFactory, httpLoaderFactory } from './services/app-module-service';
import { AuthInterceptor } from './services/auth-interceptor';
import { AuthService } from './services/auth-service';
import { EnvironmentHelperService } from './services/environment-helper.service';
import { GlobalErrorHandler } from './services/global-error-handler';
import { LanguageInterceptor } from './services/language-interceptor';
import { CreateUpdateHandler } from './services/packaging-services/create-update-handler';
import { ServerErrorInterceptor } from './services/server-error.interceptor';
import { ComparePackagingSystemsComponent } from './components/packaging-system/compare/compare-packaging-systems.component';
import { ComparePackagingPartComponent } from './components/shared-components/parent-components/compare-packaging-part/compare-packaging-part.component';
import { DimensionsInfoComponent } from './components/shared-components/dimensions-info/dimensions-info.component';
import { SurfaceRatioInfoComponent } from './components/shared-components/surface-ratio-info/surface-ratio-info.component';
import { AnalysisRecyclabilityPackagingSystemComponent } from './components/analyses/analysis-recyclability-packaging-system/analysis-recyclability-packaging-system.component';
import { PackagingUnitComponentsTableComponent } from './components/analyses/tables/packaging-unit-components-table/packaging-unit-components-table.component';
import { PackagingUnitRatingsTableComponent } from './components/analyses/tables/packaging-unit-ratings-table/packaging-unit-ratings-table.component';
import { PackagingSystemComponentsTableComponent } from './components/analyses/tables/packaging-system-components-table/packaging-system-components-table.component';
import { AnalysisRecyclabilityPackagingUnitComponent } from './components/analyses/analysis-recyclability-packaging-unit/analysis-recyclability-packaging-unit.component';
import { AnalysisLifeCyclePackagingUnitComponent } from './components/analyses/analysis-life-cycle-packaging-unit/analysis-life-cycle-packaging-unit.component';
import { AnalysisLifeCyclePackagingSystemComponent } from './components/analyses/analysis-life-cycle-packaging-system/analysis-life-cycle-packaging-system.component';
import { PackagingUnitTreeComponent } from './components/packaging-unit/create-update/common/packaging-unit-tree/packaging-unit-tree.component';
import { PackagingPartTreeComponent } from './components/shared-components/parent-components/packaging-part-tree/packaging-part-tree.component';
import { AnalysisLifeCyclePackagingPartComponent } from './components/analyses/analysis-life-cycle-packaging-part/analysis-life-cycle-packaging-part.component';
import { AnalysisRecyclabilityPackagingPartComponent } from './components/analyses/analysis-recyclability-packaging-part/analysis-recyclability-packaging-part.component';
import { ComponentMaterialsTableComponent } from './components/analyses/tables/component-materials-table/component-materials-table.component';
import { OptionalVersionDialogComponent } from './components/dialogs/optional-version-dialog/optional-version-dialog.component';
import { ComparisonHtmlTemplateComponent } from './components/shared-components/comparison-html-template/comparison-html-template.component';
import { MapExpenseCalculationComponent } from './components/analyses/charts/map-expense-calculation/map-expense-calculation.component';
import { PlasticTaxPackagingUnitComponent } from './components/analyses/plastic-tax-packaging-unit/plastic-tax-packaging-unit.component';
import { LicenseFeePackagingUnitComponent } from './components/analyses/license-fee-packaging-unit/license-fee-packaging-unit.component';
import { LicenseFeePackagingSystemComponent } from './components/analyses/license-fee-packaging-system/license-fee-packaging-system.component';
import { PlasticTaxPackagingSystemComponent } from './components/analyses/plastic-tax-packaging-system/plastic-tax-packaging-system.component';
import { ExpenseCalculationParentComponent } from './components/analyses/expense-calculation-parent/expense-calculation-parent.component';
import { ExpenseCalculationHtmlTemplateComponent } from './components/analyses/expense-calculation-html-template/expense-calculation-html-template.component';
import { AppRouteReuseStrategy } from './services/app-route-reuse-strategy';
import { RouteReuseStrategy } from '@angular/router';
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { BuyingFromShopComponent } from './components/shared-components/shop/buying-from-shop/buying-from-shop.component';
import {ContactInfoDialogComponent} from 'src/app/components/dialogs/contact-info-dialog/contact-info-dialog.component'
import { TriStateSelectorComponent } from './components/various-components/tri-state-selector/tri-state-selector.component';
import { BulkUserUploadDialogComponent } from './components/dialogs/bulk-user-upload-dialog/bulk-user-upload-dialog.component';
import { AuthorizationDialogComponent } from './components/dialogs/authorization-dialog/authorization-dialog.component';
import { EditAuthorizationDialogComponent } from './components/dialogs/edit-authorization-dialog/edit-authorization-dialog.component';
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    PackagingUnitHomeComponent,
    HomepageComponent,
    PackagingRoutingWrapperComponent,
    MaterialRoutingWrapperComponent,
    CreateUpdatePackagingPartComponent,
    CreateUpdatePackagingUnitComponent,
    CreateUpdateClosureComponent,
    CreateUpdateInlayComponent,
    CreateUpdateDecorationComponent,
    CreateUpdatePackagingAidComponent,
    ComponentHomeComponent,
    ComponentCreateUpdateParentComponent,
    AnalysisRecyclabilityComponent,
    AnalysisLifeCycleComponent,
    LifeCycleTableDialogComponent,
    ClosureDialogComponent,
    DeleteItemDialogComponent,
    DecorationDialogComponent,
    PackagingAidDialogComponent,
    SimpleAlertDialogComponent,
    SimpleConfirmDialogComponent,
    AdditionalPackagingComponent,
    UserManagementComponent,
    UserDialogComponent,
    ChangePasswordDialogComponent,
    PasswordFormComponent,
    ErrorPageComponent,
    DisclaimerDialogComponent,
    ComparePackagingUnitsComponent,
    DirectoryManagementComponent,
    DirectoryTreeComponent,
    InlayDialogComponent,
    PackagingUnitsOverviewComponent,
    PackagingComponentsOverviewComponent,
    CompositeMaterialsOverviewComponent,
    FileUploadComponent,
    ImageUploadComponent,
    PdfUploadComponent,
    FilterMenuComponent,
    PermissionsDialogComponent,
    AddEditDirectoryDialogComponent,
    SelectLocationDialogComponent,
    ExportDialogComponent,
    ImportParentComponent,
    ImportPackagingUnitComponent,
    ImportComponentComponent,
    ImportMaterialComponent,
    ComponentHtmlTemplateComponent,
    MaterialDialogComponent,
    MultiMaterialTableComponent,
    MassCalculatorComponent,
    ToolbarDirectoryComponent,
    SelectionDialogComponentsComponent,
    SelectionDialogMaterialsComponent,
    ImportHtmlComponent,
    DependenciesComponent,
    NormalizationComponent,
    RecyclabilityChartComponent,
    LifeCycleParentChartComponent,
    OrganizationManagementComponent,
    AddOrganizationDialogComponent,
    AuthorizationDialogComponent,
    EditAuthorizationDialogComponent,
    BulkUserUploadDialogComponent,
    ValidatorViewComponent,
    MapRecyclabilityChartComponent,
    LifeCycleComparisonChartComponent,
    AnalysisParentComponent,
    ChangelogComponent,
    ChangelogDialogComponent,
    CopyWithTrackingComponent,
    StopScrollingDirective,
    AnalysisNotPossibleDialogComponent,
    EditQuantitiesDialogComponent,
    EditQuantityDialogComponent,
    EvaluationAuthorizationsDialogComponent,
    OrganizationsVisibilityDialogComponent,
    AssignCreditsDialogComponent,
    ComponentSubHtmlTemplateComponent,
    ComponentRoutingWrapperComponent,
    MonthTableComponent,
    ComponentRoutingWrapperComponent,
    ExportProfilesComponent,
    ExportProfileDialogComponent,
    ExportProfilesTableComponent,
    PerformAggregateEvaluationsComponent,
    ViewAggregateEvaluationsComponent,
    ViewRecyclabilityAggregateEvaluationComponent,
    MassChartComponent,
    ViewMassAggregateEvaluationComponent,
    ViewLifecycleAggregateEvaluationComponent,
    AggregatedQuantitiesDialogComponent,
    RenameDialogComponent,
    PackagingUnitUsageInEvalDialogComponent,
    CreateUpdateMainBodyComponent,
    CompositeMaterialHtmlTemplateComponent,
    CompositeMaterialComponent,
    MaterialLayersViewComponent,
    MaterialHomeComponent,
    CompositeMaterialDialogComponent,
    MainBodyDialogComponent,
    UsersTableComponent,
    ReportTemplateWizardComponent,
    LcaDataExcelImportComponent,
    ReportGenerationDialogComponent,
    MaterialLayersReportTemplateComponent,
    ReportTemplatesOverviewComponent,
    ReportTemplatesHomeComponent,
    LcaDataExcelImportComponent,
    PackagingSystemHomeComponent,
    CreateUpdatePackagingSystemComponent,
    PackagingSystemsOverviewComponent,
    PackagingSystemTreeComponent,
    PackagingSystemHtmlTemplateComponent,
    TagsManagementComponent,
    TagDirectiveComponent,
    TagsDialogComponent,
    SelectTagsDialogComponent,
    SelectTagsComponent,
    ReportsHomeComponent,
    SelectionDialogPackagingSystemsComponent,
    SelectionDialogPackagingUnitsComponent,
    SplitterComponent,
    ImportPackagingSystemComponent,
    InternationalInfosComponent,
    PackagingUnitHtmlTemplateComponent,
    ComparePackagingSystemsComponent,
    ComparePackagingPartComponent,
    DimensionsInfoComponent,
    SurfaceRatioInfoComponent,
    AnalysisRecyclabilityPackagingSystemComponent,
    PackagingUnitComponentsTableComponent,
    PackagingUnitRatingsTableComponent,
    PackagingSystemComponentsTableComponent,
    AnalysisRecyclabilityPackagingUnitComponent,
    AnalysisLifeCyclePackagingUnitComponent,
    AnalysisLifeCyclePackagingSystemComponent,
    PackagingUnitTreeComponent,
    PackagingPartTreeComponent,
    AnalysisLifeCyclePackagingPartComponent,
    AnalysisRecyclabilityPackagingPartComponent,
    ComponentMaterialsTableComponent,
    OptionalVersionDialogComponent,
    ComparisonHtmlTemplateComponent,
    ExpenseCalculationHtmlTemplateComponent,
    MapExpenseCalculationComponent,
    PlasticTaxPackagingUnitComponent,
    LicenseFeePackagingUnitComponent,
    LicenseFeePackagingSystemComponent,
    PlasticTaxPackagingSystemComponent,
    ExpenseCalculationParentComponent,
    LandingPageComponent,
    BuyingFromShopComponent,
    ContactInfoDialogComponent,
    TriStateSelectorComponent
  ],
  imports: [
    CommonModule,
    MarkdownModule.forRoot({ loader: HttpClient }),
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CdkTableModule,
    HttpClientModule,
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-XSRF-TOKEN'
    }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: httpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatTabsModule,
    // Material design:
    MatTableModule,
    MatButtonModule,
    MatDialogModule,
    MatToolbarModule,
    MatMenuModule,
    MatCheckboxModule,
    MatSelectModule,
    MatIconModule,
    MatInputModule,
    MatCardModule,
    MatPaginatorModule,
    MatSnackBarModule,
    MatSidenavModule,
    MatSortModule,
    MatDividerModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatButtonToggleModule,
    MatSlideToggleModule,
    MatChipsModule,
    MatBadgeModule,
    AlertModule.forRoot(),
    LightboxModule,
    MatDatepickerModule,
    DragDropModule,
    NavigationModule,
    MatExpansionModule,
    MatListModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    HighchartsChartModule,
    MatTreeModule,
    CdkAccordionModule,
    MatStepperModule,
    MatAutocompleteModule,
    OverlayModule,
    NgxSpinnerModule,
    ClipboardModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    {
      provide: LOCALE_ID,
      useFactory: (translate: TranslateService) => {
        return translate.currentLang || 'de';
      },
      deps: [TranslateService, APP_INITIALIZER],
    },
    { provide: APP_INITIALIZER, useFactory: appInitializerFactory, deps: [TranslateService, Injector], multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: ServerErrorInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: CachingInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ApiVersionInterceptor, multi: true },
    {provide: RouteReuseStrategy, useClass: AppRouteReuseStrategy},
    CreateUpdateHandler,
    AuthService,
    EnvironmentHelperService,
    DecimalPipe
  ],
  bootstrap: [AppComponent]
})

export class AppModule {
  constructor() {
    registerLocaleData(localeDe, localeDeExtra);
  }
}
