<mat-menu #menu="matMenu" [overlapTrigger]="false">
  <button mat-menu-item *ngIf="!isValidator && !isAdmin" (click)="clickDirectoryTree()" type="button">
    <mat-icon>folder</mat-icon>
    <span>{{ 'dataManagement.directory.title' | translate }}</span>
  </button>
  <button mat-menu-item *ngIf="isValidator" (click)="clickValidatorSystems()" type="button">
    <mat-icon>folder</mat-icon>
    <span>{{ 'dataManagement.validator.titleSystems' | translate }}</span>
  </button>
  <button mat-menu-item *ngIf="isValidator" (click)="clickValidatorUnits()" type="button">
    <mat-icon>folder</mat-icon>
    <span>{{ 'dataManagement.validator.titleUnits' | translate }}</span>
  </button>
  <button *ngIf="!isValidator && !isAdmin" mat-menu-item (click)="clickPackagingSystemImport()" type="button">
    <mat-icon>input</mat-icon>
    <span>{{ 'dataManagement.import.titlePackagingSystem' | translate }}</span>
  </button>
  <button *ngIf="!isValidator && !isAdmin" mat-menu-item (click)="clickPackagingUnitImport()" type="button">
    <mat-icon>input</mat-icon>
    <span>{{ 'dataManagement.import.titlePackagingUnit' | translate }}</span>
  </button>
  <button *ngIf="!isValidator && !isAdmin" mat-menu-item (click)="clickPackagingComponentImport()" type="button">
    <mat-icon>input</mat-icon>
    <span>{{ 'dataManagement.import.titleComponent' | translate }}</span>
  </button>
  <button *ngIf="!isValidator && !isAdmin" mat-menu-item (click)="clickCompositeMateiralImport()" type="button">
    <mat-icon>input</mat-icon>
    <span>{{ 'dataManagement.import.titleMaterial' | translate }}</span>
  </button>
  <button *ngIf="!isValidator" mat-menu-item (click)="clickTags()" type="button">
    <mat-icon>signpost</mat-icon>
    <span>{{ 'dataManagement.tags.titleTags' | translate }}</span>
  </button>
  <button mat-menu-item (click)="clickUsers()" type="button">
    <mat-icon>supervisor_account</mat-icon>
    <span>{{ 'dataManagement.userData.title' | translate }}</span>
  </button>
  <button mat-menu-item *ngIf="isAdmin" (click)="clickManageOrganizations()" type="button">
    <mat-icon>account_balance</mat-icon>
    <span>{{ 'dataManagement.manageOrganizations.menuTitle' | translate }}</span>
  </button>
  <button mat-menu-item *ngIf="isAdmin" (click)="clickImportLcaExcelData()" type="button">
    <mat-icon>rocket_launch</mat-icon>
    <span>{{ 'dataManagement.lcaExcelDataImport.title' | translate }}</span>
  </button>
  <button mat-menu-item *ngIf="isAdmin" (click)="clickSync()" type="button">
    <mat-icon>sync</mat-icon>
    <span>{{ 'dataManagement.synchronizeDb.title' | translate }}</span>
  </button>
  <button mat-menu-item *ngIf="!isValidator" (click)="clickExportProfiles()" type="button">
    <mat-icon>view_module</mat-icon>
    <span>{{ 'dataManagement.exportProfiles.title' | translate }}</span>
  </button>
  <button mat-menu-item *ngIf="!isAdmin && !isValidator" type="button" [matMenuTriggerFor]="reportsMenu.menu">
    <mat-icon>topic</mat-icon>
    <span>{{ 'report.title' | translate }}</span>
  </button>
  <!-- <button mat-menu-item type="button" (click)="clickInternationalInfos()">
    <mat-icon>public</mat-icon>
    <span>{{ 'dataManagement.internationalInfos.title' | translate }}</span>
  </button> -->
  <app-reports-menu #reportsMenu></app-reports-menu>
  <button mat-menu-item (click)="clickVersions()" type="button">
    <mat-icon>grading</mat-icon>
    <span>{{ 'dataManagement.versions.title' | translate }}</span>
  </button>
  <button mat-menu-item (click)="clickUiChangelog()" type="button">
    <mat-icon>history</mat-icon>
    <span>{{ 'dataManagement.uiChangelog.title' | translate }}</span>
  </button>
</mat-menu>
