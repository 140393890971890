import { TagDto } from './../../../../data-transfer/entities/tag-dto';
import { DialogActions } from './../../../../model/dictionary';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export function forbiddenNamesValidator(names: string[]): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    const forbidden = names.map(_ => _.toLowerCase().trim()).includes(control.value.toLowerCase().trim());
    return forbidden ? { forbiddenName: { value: control.value } } : null;
  };
}

@Component({
  selector: 'app-tags-dialog',
  templateUrl: './tags-dialog.component.html',
  styleUrls: ['./tags-dialog.component.scss'],
})
export class TagsDialogComponent implements OnInit {
  action: number = DialogActions.ADD;
  isPickerOpen = false;
  form: FormGroup;

  allDefaultColors = [
    '#FFCDD2',
    '#F8BBD0',
    '#E1BEE7',
    '#D1C4E9',
    '#C5CAE9',
    '#BBDEFB',
    '#B3E5FC',
    '#B2EBF2',
    '#B2DFDB',
    '#C8E6C9',
    '#DCEDC8',
    '#F0F4C3',
    '#FFF9C4',
    '#FFECB3',
    '#FFE0B2',
    '#FFCCBC',
    '#D7CCC8',
    '#F5F5F5',
    '#CFD8DC',
    '#FFFFFF',
  ];

  constructor(
    protected dialogRef: MatDialogRef<TagsDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: { tag: TagDto; allTagNames: string[] },
    protected formBuilder: FormBuilder
  ) {
    const forbiddenNames = data.allTagNames.filter(name => name !== (data.tag?.name ?? ''));
    const nameValidators = [Validators.required, Validators.maxLength(20), forbiddenNamesValidator(forbiddenNames)];
    if (this.data?.tag) {
      this.action = DialogActions.EDIT;
      this.form = this.formBuilder.group({
        id: [data.tag.id],
        name: [data.tag.name, nameValidators],
        description: [data.tag.description, Validators.maxLength(40)],
        color: [data.tag.colorCode],
      });
    } else {
      this.form = this.formBuilder.group({
        id: [null],
        name: ['', nameValidators],
        description: ['', Validators.maxLength(40)],
        color: [this.allDefaultColors[0]],
      });
    }
  }

  ngOnInit(): void {}

  selectDefaultColor(color: string) {
    this.form.controls.color.patchValue(color);
  }

  doAction() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      return;
    }
    const tag: TagDto = {
      name: this.form.controls.name.value,
      colorCode: this.form.controls.color.value,
      description: this.form.controls.description.value,
      isDefault: false,
    };
    if (this.action === DialogActions.EDIT) {
      tag.id = this.form.controls.id.value;
    }
    this.dialogRef.close({ event: DialogActions.CONFIRM, data: tag });
  }

  closeDialog() {
    this.dialogRef.close({ event: DialogActions.REJECT });
  }
}
