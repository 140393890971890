<mat-stepper orientation="horizontal" [linear]="false" #stepper id="stepper">

  <mat-step [stepControl]="packagingUnitsFormGroup" completed="false">
    <form [formGroup]="packagingUnitsFormGroup" class="form-class">
      <ng-template matStepLabel>
        {{ 'dataEvaluation.performEvaluations.stepLabels.packagingUnitsSelection' | translate }}
      </ng-template>
      <div>
        {{ 'dataEvaluation.performEvaluations.stepDescriptions.packagingUnitsSelection' | translate }}
        <button (click)="selectAllPackagingUnits()" mat-stroked-button type="button" color="primary" class="select-button">
          {{ 'dataEvaluation.performEvaluations.buttons.selectAll' | translate }}
        </button>
        <button (click)="selectNoPackagingUnits()" mat-stroked-button type="button" color="primary" class="select-button">
          {{ 'dataEvaluation.performEvaluations.buttons.selectNone' | translate }}
        </button>
      </div>
      <div class="scrolling-wrapper">
        <app-packaging-units-overview #packagintUnitsTable [dataSource]="packagingUnitsDataSource"
          [displayedColumns]="displayedColumnsPackagingUnits" (packagingPartsSelected)="packagingUnitsSelected($event)">
        </app-packaging-units-overview>
      </div>
      <div>
        <button mat-raised-button matStepperNext>
          {{ 'dataEvaluation.performEvaluations.buttons.next' | translate }}
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </div>
    </form>
  </mat-step>

  <mat-step [stepControl]="countriesFormGroup" completed="false">
    <form [formGroup]="countriesFormGroup" class="form-class">
      <ng-template matStepLabel>
        {{ 'dataEvaluation.performEvaluations.stepLabels.countriesSelection' | translate }}
      </ng-template>
      <div>
        {{ 'dataEvaluation.performEvaluations.stepDescriptions.countriesSelection' | translate }}
        <button (click)="selectAllCountries()" mat-stroked-button type="button" color="primary" class="select-button">
          {{ 'dataEvaluation.performEvaluations.buttons.selectAll' | translate }}
        </button>
        <button (click)="selectNoCountries()" mat-stroked-button type="button" color="primary" class="select-button">
          {{ 'dataEvaluation.performEvaluations.buttons.selectNone' | translate }}
        </button>
      </div>
      <div class="scrolling-wrapper">
        <app-buying-from-shop *ngIf="allCountries.length <= 0" [buyType]="buyType.AllCountries">
        </app-buying-from-shop>
        <mat-selection-list *ngIf="allCountries.length > 0" #countriesSelectionList formControlName="countriesList" class="narrow-list">
          <mat-list-option *ngFor="let country of allCountries" [value]="country" [disabled]="!this.isCountryAuthorized(country.code)"
            [matTooltip]="this.getTooltipName(country.name, country.code)" matTooltipPosition = "right">
            <mat-icon *ngIf="!this.isCountryAuthorized(country.code)">lock</mat-icon>
            {{ country.name }}
          </mat-list-option>
        </mat-selection-list>
      </div>
      <div>
        <button mat-raised-button matStepperPrevious>
          <mat-icon>arrow_back</mat-icon>
          {{ 'dataEvaluation.performEvaluations.buttons.back' | translate }}
        </button>
        <button mat-raised-button matStepperNext>
          {{ 'dataEvaluation.performEvaluations.buttons.next' | translate }}
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </div>
    </form>
  </mat-step>

  <mat-step [stepControl]="datesFormGroup" completed="false">
    <form [formGroup]="datesFormGroup" class="form-class">
      <ng-template matStepLabel>{{ 'dataEvaluation.performEvaluations.stepLabels.datesSelection' | translate }}
      </ng-template>
      <div>
        {{ 'dataEvaluation.performEvaluations.stepDescriptions.datesSelection' | translate }}
      </div>
      <mat-form-field class="date-field">
        <mat-label>{{ 'dataEvaluation.performEvaluations.datePicker.startDate' | translate }}</mat-label>
        <input matInput autocomplete="off" [matDatepicker]="dpStart" [formControl]="startDateControl">
        <mat-hint>{{ 'dataEvaluation.performEvaluations.datePicker.hint' | translate }}</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="dpStart"></mat-datepicker-toggle>
        <mat-datepicker #dpStart startView="multi-year"
          (monthSelected)="setMonthAndYear($event, dpStart, datesFormGroup.controls.startDate)"
          panelClass="example-month-picker">
        </mat-datepicker>
        <mat-error *ngIf="datesFormGroup.controls.startDate.errors">
          <span *ngIf="datesFormGroup.controls.startDate.errors.required">
            {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
          </span>
          <span *ngIf="datesFormGroup.controls.startDate.errors.endBeforeStart">
            {{ 'dataEvaluation.performEvaluations.errors.endBeforeStart' | translate }}
          </span>
          <span *ngIf="datesFormGroup.controls.startDate.errors.outOfRange">
            {{ 'dataEvaluation.performEvaluations.errors.outOfRange' | translate: {range: maxDatesRangeInMonths} }}
          </span>
        </mat-error>
      </mat-form-field>

      <mat-form-field class="date-field">
        <mat-label>{{ 'dataEvaluation.performEvaluations.datePicker.endDate' | translate }}</mat-label>
        <input matInput autocomplete="off" [matDatepicker]="dpEnd" [formControl]="endDateControl">
        <mat-hint>{{ 'dataEvaluation.performEvaluations.datePicker.hint' | translate }}</mat-hint>
        <mat-datepicker-toggle matSuffix [for]="dpEnd"></mat-datepicker-toggle>
        <mat-datepicker #dpEnd startView="multi-year"
          (monthSelected)="setMonthAndYear($event, dpEnd, datesFormGroup.controls.endDate)"
          panelClass="example-month-picker">
        </mat-datepicker>
        <mat-error *ngIf="datesFormGroup.controls.endDate.errors">
          <span *ngIf="datesFormGroup.controls.startDate.errors?.required">
            {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
          </span>
          <span *ngIf="datesFormGroup.controls.endDate.errors.endBeforeStart">
            {{ 'dataEvaluation.performEvaluations.errors.endBeforeStart' | translate }}
          </span>
          <span *ngIf="datesFormGroup.controls.endDate.errors.outOfRange">
            {{ 'dataEvaluation.performEvaluations.errors.outOfRange' | translate: {range: maxDatesRangeInMonths} }}
          </span>
        </mat-error>
      </mat-form-field>
      <div>
        <button mat-raised-button matStepperPrevious>
          <mat-icon>arrow_back</mat-icon>
          {{ 'dataEvaluation.performEvaluations.buttons.back' | translate }}
        </button>
        <button mat-raised-button matStepperNext>
          {{ 'dataEvaluation.performEvaluations.buttons.next' | translate }}
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </div>
    </form>
  </mat-step>

  <mat-step [stepControl]="evalTypesFormGroup" completed="false">
    <form [formGroup]="evalTypesFormGroup" class="form-class">
      <ng-template matStepLabel>{{ 'dataEvaluation.performEvaluations.stepLabels.evalTypesSelection' | translate }}
      </ng-template>
      <mat-selection-list #evaluationsSelectionList formControlName="evaluationsList" class="narrow-list">
        <mat-list-option *ngFor="let evaluationType of evaluationTypes" [value]="evaluationType" [disabled]="isEvaluationDeactivated(evaluationType.key)"
          [matTooltip]="isEvaluationDeactivated(evaluationType.key)? ('warnings.NoPremiumUser' | translate) : ''"
          matTooltipPosition = "right">
          {{ evaluationType.value }}
        </mat-list-option>
      </mat-selection-list>
      <div>
        <button mat-raised-button matStepperPrevious>
          <mat-icon>arrow_back</mat-icon>
          {{ 'dataEvaluation.performEvaluations.buttons.back' | translate }}
        </button>
        <button mat-raised-button matStepperNext>
          {{ 'dataEvaluation.performEvaluations.buttons.next' | translate }}
          <mat-icon>arrow_forward</mat-icon>
        </button>
      </div>
    </form>
  </mat-step>

  <mat-step completed="false">
    <ng-template matStepLabel>{{ 'dataEvaluation.performEvaluations.stepLabels.performEvaluation' | translate }}
    </ng-template>
    <div>
      <p>{{ 'dataEvaluation.performEvaluations.stepDescriptions.performEvaluation' | translate }}</p>
      <br>
      <p [ngClass]="{'red': packagingUnitsCount === 0}">
        {{ 'dataEvaluation.performEvaluations.messages.packagingUnitsCount' | translate: {count: packagingUnitsCount } }}
      </p>
      <p [ngClass]="{'red': countriesCount === 0}">
        {{ 'dataEvaluation.performEvaluations.messages.countriesCount' | translate: {count: countriesCount} }}
      </p>
      <p [ngClass]="{'red': !datesFormGroup.controls.startDate.value || !datesFormGroup.controls.endDate.value }">
        {{ 'dataEvaluation.performEvaluations.messages.dateRange' | translate: {from: startDate, to: endDate} }}
      </p>
      <p [ngClass]="{'red': evaluationsCount === 0}">
        {{ 'dataEvaluation.performEvaluations.messages.evalTypes' | translate: {types: getSelectedEvalTypeNames()} }}
      </p>
    </div>
    <div>
      <mat-form-field class="form-field-medium">
        <mat-label>{{ 'dataEvaluation.performEvaluations.evalName' | translate }}</mat-label>
        <input matInput autocomplete="off" [formControl]="evalNameFormControl">
      </mat-form-field>
    </div>
    <button mat-raised-button color="primary" (click)="performEvaluations(stepper)">
      {{ 'dataEvaluation.performEvaluations.buttons.evaluate' | translate }}
    </button>
    <button mat-raised-button color="warn" (click)="resetForm(stepper)">
      {{ 'dataEvaluation.performEvaluations.buttons.reset' | translate }}
    </button>
    <div>
      <button mat-raised-button matStepperPrevious>
        <mat-icon>arrow_back</mat-icon>
        {{ 'dataEvaluation.performEvaluations.buttons.back' | translate }}
      </button>
    </div>
  </mat-step>

  <mat-step completed="false">
    <ng-template matStepLabel>{{ 'dataEvaluation.performEvaluations.stepLabels.viewEvaluation' | translate }}
    </ng-template>
    <div class="full-height" *ngIf="showEvaluations; else emptyTemplate">
      <mat-tab-group color="accent" [backgroundColor]="" class="full-height">
        <mat-tab label="{{ 'dataEvaluation.evaluationType.recLabel' | translate }}"
          *ngIf="recEvaluation">
          <div class="tab-content" #recContent>
            <app-view-recyclability-aggregate-evaluation [recEvaluation]="recEvaluation" [isDarkTheme]="isDarkTheme"
              [isScrollingEnabled]="isRecScrollingEnabled" (scrollToTableEvent)="scrollToRecTable()">
            </app-view-recyclability-aggregate-evaluation>
          </div>
        </mat-tab>
        <mat-tab label="{{ 'dataEvaluation.evaluationType.lcaLabel' | translate }}" *ngIf="lcaEvaluation">
          <div class="full-height" class="tab-content">
            <app-view-lifecycle-aggregate-evaluation [lcaEvaluation]="lcaEvaluation" [isDarkTheme]="isDarkTheme">
            </app-view-lifecycle-aggregate-evaluation>
          </div>
        </mat-tab>
        <mat-tab bodyClass="mass-tab" label="{{ 'dataEvaluation.evaluationType.massLabel' | translate }}"
          *ngIf="massEvaluation">
          <div class="tab-content" #massContent>
            <app-view-mass-aggregate-evaluation [massEvaluation]="massEvaluation" [isDarkTheme]="isDarkTheme"
              [isScrollingEnabled]="isMassScrollingEnabled" (scrollToTableEvent)="scrollToMassTable()">
            </app-view-mass-aggregate-evaluation>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>
    <ng-template #emptyTemplate>
      <div id="placeholder-card">
        <span class="hint margin">{{ 'dataEvaluation.performEvaluations.messages.noEvaluationYet' | translate }}</span>
      </div>
    </ng-template>
    <div>
      <button *ngIf="showEvaluations" mat-raised-button color="primary" (click)="startNewEvaluation(stepper)">
        {{ 'dataEvaluation.performEvaluations.buttons.newEvaluation' | translate }}
      </button>
    </div>
  </mat-step>
</mat-stepper>
