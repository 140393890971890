import { Injector } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LOCATION_INITIALIZED } from '@angular/common';

// AoT requires an exported function for factories
export function httpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient);
}

export function appInitializerFactory(translateService: TranslateService, injector: Injector) {
    return () => new Promise<any>((resolve: any) => {
        const locationInitialized = injector.get(LOCATION_INITIALIZED, Promise.resolve(null));
        locationInitialized.then(() => {
            const supportedLangs = ['en', 'de'];
            const defaultLang = 'de';
            translateService.addLangs(supportedLangs);
            translateService.setDefaultLang(defaultLang);

            if (localStorage.getItem('language') === null) {
                let localStorageLang = defaultLang;
                const browserLang = translateService.getBrowserLang();
                if (browserLang) {
                    if (supportedLangs.includes(browserLang)) {
                        localStorageLang = browserLang;
                    }
                }
                const language: string = (localStorageLang === 'gb' ? 'en' : localStorageLang);
                localStorage.setItem('language', language);
            }

            const langToSet = localStorage.getItem('language');
            translateService.use(langToSet == null ? defaultLang : langToSet).subscribe(
                () => { },
                _ => {
                    console.error(`Problem with ${langToSet} language initialization.`);
                },
                () => { resolve(null); });
        });
    });
}
