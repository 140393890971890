import { ExportProfileComponentParent, ExportProfileMultiMaterialLayerDto } from '../../../../../data-transfer/entities/packaging-unit-entities/packaging-unit-export-profile-dto';
import { Validators, AbstractControlOptions, FormGroup, FormBuilder } from '@angular/forms';
import { Injectable, OnDestroy } from '@angular/core';
import { ExportProfileDocuments, ExportProfileImages, PackagingUnitExportProfileDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-export-profile-dto';
import { PackagingComponentExportProfileDto } from 'src/app/data-transfer/entities/component-entities/packaging-component-export-profile-dto';
import { CompositeMaterialExportProfileDto } from 'src/app/data-transfer/entities/material-entities/composite-material-export-profile-dto';
import { PackagingSystemExportProfileDto } from 'src/app/data-transfer/entities/packaging-system-entities/packaging-system-export-profile-dto';
import { Subscription } from 'rxjs';

function profileNameExists(namesUsed: Set<string>) {
  return (formGroup: FormGroup) => {
    const nameControl = formGroup.controls.profileName;
    if (!nameControl) { return; }
    const currentName: string = nameControl.value;
    if (!namesUsed || namesUsed.size < 1 || !currentName) { return; }
    if (namesUsed.has(currentName)) {
      nameControl.setErrors({ nameExists: true });
    }
  };
}

@Injectable({ providedIn: 'root' })
export class DtoToFormService implements OnDestroy {

  namesUsed = new Set<string>();

  private fillingGoodSubscription?: Subscription;
  private fillingGoodCategorySubscription?: Subscription;
  private fillingGoodTypeSubscription?: Subscription;
  private materialSubscription?: Subscription;
  private manifestationSubscription?: Subscription;
  private manufTypeSubscription?: Subscription;
  private colorSubscription?: Subscription;

  constructor(private formBuilder: FormBuilder) { }

  // ------------------------
  // --- PACKAGING SYSTEM ---
  // ------------------------

  createPackagingSystemFormGroup(profile: PackagingSystemExportProfileDto) {
    return this.getPackagingSystemFormGroup(profile);
  }

  private getPackagingSystemFormGroup(profile: PackagingSystemExportProfileDto) {
    return this.formBuilder.group({
      profileData: this.getProfileData(profile),
      comment: profile.comment,
      productInfo: this.getProductInfo(profile),
      manufacturingInfo: this.getManufacturingInfo(profile),
      images: this.getImagesForm(profile.images),
      documents: this.getDocumentsForm(profile.documents)
    });
  }

  // ----------------------
  // --- PACKAGING UNIT ---
  // ----------------------

  createPackagingUnitFormGroup(profile: PackagingUnitExportProfileDto) {
    const packagingUnitsForm = this.getPackagingUnitFormGroup(profile);
    this.setFillingGoodsInfoListeners(packagingUnitsForm);
    return packagingUnitsForm;
  }

  private getPackagingUnitFormGroup(profile: PackagingUnitExportProfileDto) {
    return this.formBuilder.group({
      profileData: this.getProfileData(profile),
      comment: profile.comment,
      productInfo: this.getProductInfo(profile),
      fillingGoodInfo: this.getFillingGoodInfo(profile),
      manufacturingInfo: this.getManufacturingInfo(profile),
      images: this.getImagesForm(profile.images),
      documents: this.getDocumentsForm(profile.documents),
      mainBody: this.getMainBodyForm(profile),
      closures: this.getClosuresForm(profile),
      decorations: this.getDecorationsForm(profile),
      inlays: this.getInlaysForm(profile),
      packagingAids: this.getPackagingAidsForm(profile)
    });
  }

  private setFillingGoodsInfoListeners(packagingUnitForm: FormGroup) {
    const fillingGoodForm = packagingUnitForm.controls.fillingGoodInfo as FormGroup;
    this.fillingGoodSubscription = fillingGoodForm.controls.fillingGood.valueChanges
      .subscribe(value => this.onFillingGoodsChanged(fillingGoodForm, value));
    this.fillingGoodCategorySubscription = fillingGoodForm.controls.fillingGoodCategory.valueChanges
      .subscribe(value => this.onFillingGoodsCategoryChanged(fillingGoodForm, value));
    this.fillingGoodTypeSubscription = fillingGoodForm.controls.fillingGoodType.valueChanges
      .subscribe(value => this.onFillingGoodsTypeChanged(fillingGoodForm, value));
  }

  private onFillingGoodsChanged(fillingGoodsForm: FormGroup, value: boolean) {
    if (!value) { return; }
    fillingGoodsForm.controls.fillingGoodCategory.patchValue(value);
    fillingGoodsForm.controls.fillingGoodType.patchValue(value);
  }

  private onFillingGoodsCategoryChanged(fillingGoodsForm: FormGroup, value: boolean) {
    if (value) {
      fillingGoodsForm.controls.fillingGoodType.patchValue(value);
    } else {
      fillingGoodsForm.controls.fillingGood.patchValue(value);
    }
  }

  private onFillingGoodsTypeChanged(fillingGoodsForm: FormGroup, value: boolean) {
    if (value) { return; }
    fillingGoodsForm.controls.fillingGoodCategory.patchValue(value);
    fillingGoodsForm.controls.fillingGood.patchValue(value);
  }

  setNamesUsed(namesUsed: Set<string>) {
    this.namesUsed = namesUsed;
  }

  private getProductInfo(profile: PackagingSystemExportProfileDto | PackagingUnitExportProfileDto) {
    return this.formBuilder.group({
      brandName: profile.brandName,
      productName: profile.productName,
      articleNumber: profile.articleNumber,
      gtin: profile.gtin
    });
  }

  private getFillingGoodInfo(profile: PackagingUnitExportProfileDto) {
    return this.formBuilder.group({
      fillingGoodType: profile.fillingGoodType,
      fillingGoodCategory: profile.fillingGoodCategory,
      fillingGood: profile.fillingGood,
      packagingQuantity: profile.packagingQuantity,
      packagingVolume: profile.packagingVolume
    });
  }

  private getManufacturingInfo(profile: PackagingSystemExportProfileDto | PackagingUnitExportProfileDto) {
    return this.formBuilder.group({
      distributionCountries: profile.distributionCountries,
      assemblyCountry: profile.assemblyCountry,
      length: profile.length,
      width: profile.width,
      height: profile.height
    });
  }

  private getMainBodyForm(profile: PackagingUnitExportProfileDto): FormGroup {
    return this.formBuilder.group({
      manufacturingInfo: this.formBuilder.group({
        manufacturer: profile.mainBody.manufacturer,
        articleName: profile.mainBody.articleName,
        articleNumber: profile.mainBody.articleNumber,
        manufacturingCountry: profile.mainBody.manufacturingCountry,
        gtin: profile.mainBody.gtin,
        printingCoverage: profile.mainBody.printingCoverage,
        isRigidComponent: profile.mainBody.isRigidComponent,
        containsNearInfraredBarrier: profile.mainBody.containsNearInfraredBarrier,
      }),
      massInfo: this.formBuilder.group({
        length: profile.mainBody.length,
        width: profile.mainBody.width,
        height: profile.mainBody.height,
        totalGrammage: profile.mainBody.multiMaterial.totalGrammage
      }),
      multiMaterial: this.getMultiMaterialForm(profile.mainBody.multiMaterial.layers)
    });
  }

  private getClosuresForm(profile: PackagingUnitExportProfileDto): FormGroup {
    const closuresForm = this.formBuilder.group({
      productInfo: this.getComponentProductInfo(profile.closures),
      manufacturingInfo: this.formBuilder.group({
        manufacturer: profile.closures.manufacturer,
        manufacturingCountry: profile.closures.manufacturingCountry,
        length: profile.closures.length,
        width: profile.closures.width,
        height: profile.closures.height,
        removedByConsumer: profile.closures.removedByConsumer,
        removedBeforeUsage: profile.closures.removedBeforeUsage,
        totalGrammage: profile.closures.multiMaterial.totalGrammage
      }),
      multiMaterial: this.getMultiMaterialForm(profile.closures.multiMaterial.layers)
    });
    (closuresForm.controls.productInfo as FormGroup).addControl(
      'isRigidComponent', this.formBuilder.control(profile.closures.isRigidComponent));
    return closuresForm;
  }

  private getDecorationsForm(profile: PackagingUnitExportProfileDto) {
    return this.formBuilder.group({
      labels: this.getLabelsForm(profile),
      sleeves: this.getSleevesForm(profile),
      inMoldLabels: this.getInMoldLabelsForm(profile)
    });
  }

  private getLabelsForm(profile: PackagingUnitExportProfileDto): FormGroup {
    const labelsForm = this.formBuilder.group({
      productInfo: this.getComponentProductInfo(profile.labels),
      manufacturingInfo: this.formBuilder.group({
        manufacturer: profile.labels.manufacturer,
        manufacturingCountry: profile.labels.manufacturingCountry,
        length: profile.labels.length,
        width: profile.labels.width,
        height: profile.labels.height,
        removedByConsumer: profile.labels.removedByConsumer,
        removedBeforeUsage: profile.labels.removedBeforeUsage,
        isAdhesiveUsed: profile.labels.isAdhesiveUsed,
        removabilityCondition: profile.labels.removabilityCondition,
        totalGrammage: profile.labels.multiMaterial.totalGrammage
      }),
      multiMaterial: this.getMultiMaterialForm(profile.labels.multiMaterial.layers)
    });
    (labelsForm.controls.productInfo as FormGroup).addControl('surfaceRatio', this.formBuilder.control(profile.labels.surfaceRatio));
    return labelsForm;
  }

  private getSleevesForm(profile: PackagingUnitExportProfileDto): FormGroup {
    const sleevesForm = this.formBuilder.group({
      productInfo: this.getComponentProductInfo(profile.sleeves),
      manufacturingInfo: this.formBuilder.group({
        manufacturer: profile.sleeves.manufacturer,
        manufacturingCountry: profile.sleeves.manufacturingCountry,
        length: profile.sleeves.length,
        width: profile.sleeves.width,
        height: profile.sleeves.height,
        removedByConsumer: profile.sleeves.removedByConsumer,
        removedBeforeUsage: profile.sleeves.removedBeforeUsage,
        totalGrammage: profile.sleeves.multiMaterial.totalGrammage
      }),
      multiMaterial: this.getMultiMaterialForm(profile.sleeves.multiMaterial.layers)
    });
    (sleevesForm.controls.productInfo as FormGroup).addControl(
      'surfaceRatio', this.formBuilder.control(profile.sleeves.surfaceRatio));
    return sleevesForm;
  }

  private getInMoldLabelsForm(profile: PackagingUnitExportProfileDto): FormGroup {
    const inMoldsForm = this.formBuilder.group({
      productInfo: this.getComponentProductInfo(profile.inMoldLabels),
      manufacturingInfo: this.formBuilder.group({
        manufacturer: profile.inMoldLabels.manufacturer,
        manufacturingCountry: profile.inMoldLabels.manufacturingCountry,
        length: profile.inMoldLabels.length,
        width: profile.inMoldLabels.width,
        height: profile.inMoldLabels.height,
        removedByConsumer: profile.inMoldLabels.removedByConsumer,
        removedBeforeUsage: profile.inMoldLabels.removedBeforeUsage,
        totalGrammage: profile.inMoldLabels.multiMaterial.totalGrammage
      }),
      multiMaterial: this.getMultiMaterialForm(profile.inMoldLabels.multiMaterial.layers)
    });
    (inMoldsForm.controls.productInfo as FormGroup).addControl(
      'surfaceRatio', this.formBuilder.control(profile.inMoldLabels.surfaceRatio));
    return inMoldsForm;
  }

  private getPackagingAidsForm(profile: PackagingUnitExportProfileDto): FormGroup {
    const packAidsForm = this.formBuilder.group({
      productInfo: this.getComponentProductInfo(profile.packagingAids),
      manufacturingInfo: this.formBuilder.group({
        manufacturer: profile.packagingAids.manufacturer,
        manufacturingCountry: profile.packagingAids.manufacturingCountry,
        length: profile.packagingAids.length,
        width: profile.packagingAids.width,
        height: profile.packagingAids.height,
        removedByConsumer: profile.packagingAids.removedByConsumer,
        removedBeforeUsage: profile.packagingAids.removedBeforeUsage,
        totalGrammage: profile.packagingAids.multiMaterial.totalGrammage
      }),
      multiMaterial: this.getMultiMaterialForm(profile.packagingAids.multiMaterial.layers)
    });
    (packAidsForm.controls.productInfo as FormGroup).addControl(
      'isRigidComponent', this.formBuilder.control(profile.packagingAids.isRigidComponent));
    return packAidsForm;
  }

  private getInlaysForm(profile: PackagingUnitExportProfileDto): FormGroup {
    const inlaysForm = this.formBuilder.group({
      productInfo: this.getComponentProductInfo(profile.inlays),
      manufacturingInfo: this.formBuilder.group({
        manufacturer: profile.inlays.manufacturer,
        manufacturingCountry: profile.inlays.manufacturingCountry,
        length: profile.inlays.length,
        width: profile.inlays.width,
        height: profile.inlays.height,
        isAdhesiveUsed: profile.inlays.isAdhesiveUsed,
        removabilityCondition: profile.inlays.removabilityCondition,
        isPhysicallyAttached: profile.inlays.isPhysicallyAttached,
        totalGrammage: profile.inlays.multiMaterial.totalGrammage
      }),
      multiMaterial: this.getMultiMaterialForm(profile.inlays.multiMaterial.layers)
    });
    (inlaysForm.controls.productInfo as FormGroup).addControl(
      'isRigidComponent', this.formBuilder.control(profile.inlays.isRigidComponent));
    return inlaysForm;
  }

  private getImagesForm(images: ExportProfileImages): FormGroup {
    return this.formBuilder.group({
      exportImages: images.imageId && images.name && images.index && images.isMainImage
    });
  }

  private getDocumentsForm(documents: ExportProfileDocuments): FormGroup {
    return this.formBuilder.group({
      exportDocuments: documents.documentId && documents.name && documents.index
    });
  }

  private getMultiMaterialForm(layer: ExportProfileMultiMaterialLayerDto): FormGroup {
    const multiMaterialForm = this.formBuilder.group({
      materialInfo: this.formBuilder.group({
        function: layer.function,
        material: layer.material,
        materialManifestation: layer.materialManifestation,
        manufacturingType: layer.manufacturingType,
        recyclatePercentage: layer.recyclingMaterialPercentage,
        color: layer.color
      }),
      massInfo: this.formBuilder.group({
        grammage: layer.grammage,
        thickness: layer.thickness,
        density: layer.density,
        massPercentage: layer.massPercentage,
        mass: layer.mass
      })
    });
    this.setMaterialInfoListener(multiMaterialForm);
    return multiMaterialForm;
  }

  // -----------------
  // --- COMPONENT ---
  // -----------------

  createComponentFormGroup(profile: PackagingComponentExportProfileDto) {
    return this.formBuilder.group({
      profileData: this.getProfileData(profile),
      productInfo: this.getComponentProductInfo(profile),
      manufacturingInfo: this.getComponentManufacturingInfo(profile),
      multiMaterial: this.getMultiMaterialForm(profile.multiMaterial.layers),
      images: this.getImagesForm(profile.images),
      documents: this.getDocumentsForm(profile.documents),
    });
  }

  private getProfileData(
    profile: PackagingSystemExportProfileDto | PackagingComponentExportProfileDto |
      PackagingUnitExportProfileDto | CompositeMaterialExportProfileDto
  ): FormGroup {
    return this.formBuilder.group({
      id: [profile.id],
      profileName: [profile.profileName, Validators.required],
      owningOrganizationId: profile.owningOrganizationId,
      owningOrganizationName: profile.owningOrganizationName,
      isDefaultProfile: profile.isDefaultProfile,
    }, { validator: profileNameExists(this.namesUsed) } as AbstractControlOptions);
  }

  private getComponentProductInfo(profile: ExportProfileComponentParent) {
    return this.formBuilder.group({
      articleName: profile.articleName,
      articleNumber: profile.articleNumber,
      gtin: profile.gtin,
      printingCoverage: profile.printingCoverage,
      containsNearInfraredBarrier: profile.containsNearInfraredBarrier
    });
  }

  private getComponentManufacturingInfo(profile: PackagingComponentExportProfileDto) {
    return this.formBuilder.group({
      manufacturer: profile.manufacturer,
      manufacturingCountry: profile.manufacturingCountry,
      distributionCountries: profile.distributionCountries,
      length: profile.length,
      width: profile.width,
      height: profile.height,
      totalGrammage: profile.multiMaterial.totalGrammage
    });
  }

  // ----------------
  // --- MATERIAL ---
  // ----------------

  createMaterialFormGroup(profile: CompositeMaterialExportProfileDto) {
    return this.formBuilder.group({
      profileData: this.getProfileData(profile),
      productInfo: this.getMaterialProductInfo(profile),
      manufacturingInfo: this.getMaterialManufacturingInfo(profile),
      layers: this.getMultiMaterialForm(profile.layers),
      images: this.getImagesForm(profile.images),
      documents: this.getDocumentsForm(profile.documents),
    });
  }

  private setMaterialInfoListener(materialForm: FormGroup) {
    const materialInfo = materialForm.controls.materialInfo as FormGroup;
    this.materialSubscription = materialInfo.controls.material.valueChanges.subscribe(value =>
      this.onMaterialIdChanged(materialInfo, value));
    this.manifestationSubscription = materialInfo.controls.materialManifestation.valueChanges.subscribe(value =>
      value && materialInfo.controls.material.patchValue(true));
    this.manufTypeSubscription = materialInfo.controls.manufacturingType.valueChanges.subscribe(value =>
      value && materialInfo.controls.material.patchValue(true));
    this.colorSubscription = materialInfo.controls.color.valueChanges.subscribe(value =>
      value && materialInfo.controls.material.patchValue(true));
  }

  private onMaterialIdChanged(materialInfo: FormGroup, value: boolean) {
    if (value) { return; }
    materialInfo.controls.materialManifestation.patchValue(value);
    materialInfo.controls.manufacturingType.patchValue(value);
    materialInfo.controls.color.patchValue(value);
  }

  private getMaterialProductInfo(profile: CompositeMaterialExportProfileDto) {
    return this.formBuilder.group({
      articleName: profile.articleName,
      articleNumber: profile.articleNumber,
      manufacturer: profile.manufacturerName,
      manufacturingCountry: profile.manufacturingCountry
    });
  }

  private getMaterialManufacturingInfo(profile: CompositeMaterialExportProfileDto) {
    return this.formBuilder.group({
      gtin: profile.gtin,
      totalGrammage: profile.totalGrammage,
    });
  }

  ngOnDestroy(): void {
    this.fillingGoodSubscription?.unsubscribe();
    this.fillingGoodCategorySubscription?.unsubscribe();
    this.fillingGoodTypeSubscription?.unsubscribe();
    this.materialSubscription?.unsubscribe();
    this.manifestationSubscription?.unsubscribe();
    this.manufTypeSubscription?.unsubscribe();
    this.colorSubscription?.unsubscribe();
  }
}
