<h1 mat-dialog-title>
  <mat-icon class="margin-icon">{{icon}}</mat-icon> {{title}}
</h1>

<mat-dialog-content>
  <div *ngIf="exceptionReasons.length > 0; else genericMessage">
    <div *ngIf="exceptionReasons.length === 1; else multiplePackagingUnitsMessage">
      <p>{{ 'packagingUnit.messages.packagingInUseSingle' | translate }}</p>
    </div>
    <ng-template #multiplePackagingUnitsMessage>
      <p>{{ 'packagingUnit.messages.packagingInUseMultiple' | translate }}</p>
    </ng-template>
    <div class="scrolling-wrapper">
      <div *ngFor="let exceptionReason of exceptionReasons">
        <p><strong>
            {{ 'packagingUnit.messages.packagingInUseId' | translate: {packagingId: exceptionReason.packagingElementId} }}
          </strong></p>
        <div *ngIf="exceptionReason.referringRecyclabilityAggregations.length > 0">
          <p>
            {{ 'packagingUnit.messages.packagingInUseRec' | translate }}
          </p>
          <ul>
            <li *ngFor="let recReason of exceptionReason.referringRecyclabilityAggregations">
              <span class="margin-text"><strong>{{ recReason.name }}</strong></span>
              {{ parseDate(recReason.evaluationReferenceTimestamp) }}
            </li>
          </ul>
        </div>
        <div *ngIf="exceptionReason.referringLifeCycleAggregations.length > 0">
          <p>
            {{ 'packagingUnit.messages.packagingInUseLca' | translate }}
          </p>
          <ul>
            <li *ngFor="let lcaReason of exceptionReason.referringLifeCycleAggregations">
              <span class="margin-text"><strong>{{ lcaReason.name }}</strong></span>
              {{ parseDate(lcaReason.evaluationReferenceTimestamp) }}
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <ng-template #genericMessage>
    {{ 'packagingUnit.messages.usedInAggrEval' | translate }}
  </ng-template>
</mat-dialog-content>

<mat-dialog-actions>
  <button matDialogClose="confirm" mat-raised-button color="primary" type="submit">
    {{ 'common.buttons.ok' | translate }}
  </button>
</mat-dialog-actions>
