import { MaterialManifestationDto } from '../entities/material-manifestation-dto';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { CountryDto } from '../entities/country-dto';
import { Injectable } from '@angular/core';
import { ColorDto } from '../entities/color-dto';
import { FillingGoodTypeDto } from '../entities/filling-good-type-dto';
import { ManufacturingTypeDto } from '../entities/manufacturing-type-dto';
import { MaterialDto } from '../entities/material-dto';
import { FillingGoodDto } from '../entities/filling-good-dto';
import { FillingGoodCategoryDto } from '../entities/filling-good-category-dto';
import { RemovabilityConditionDto } from '../entities/removability-condition-dto';
import { ParentApiService } from './parent-api-service';
import { environment } from 'src/environments/environment';
import { PackagingCategoryMaterialFunctionDto } from '../entities/material-function-dto';
import { CreditsDto } from '../entities/credits-dto';
import { EnvironmentalEffectDto } from '../entities/environmental-effect-dto';
import { ClientDto } from '../entities/client-dto';
import { ContractDocumentDto } from '../entities/contract-document-dto';
import { ReportTemplateDto } from '../entities/report-template-dto';
import { ContractPackageDto } from '../entities/contract-package-dto';

@Injectable({ providedIn: 'root' })
export class BaseDataApiService extends ParentApiService {

    constructor(private http: HttpClient) { super(); }

    public getCountries(): Observable<CountryDto[]> {
        const url = `${environment.webApiAddress}/api/countries`;
        return this.http.get<CountryDto[]>(url);
    }

    public getCountriesEng(): Observable<CountryDto[]> {
        const url = `${environment.webApiAddress}/api/countries`;
        return this.http.get<CountryDto[]>(url, { headers: this.acceptEnglishHeader });
    }

    public getManufacturingCountries(): Observable<CountryDto[]> {
        const url = `${environment.webApiAddress}/api/countries/manufacturing-countries`;
        return this.http.get<CountryDto[]>(url);
    }

    public getDistributionCountries(stayOnPageIfError?: boolean): Observable<CountryDto[]> {
        const url = `${environment.webApiAddress}/api/countries/distribution-countries`;
        return this.http.get<CountryDto[]>(url, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
    }

    public getEnvironmentalEffects(): Observable<EnvironmentalEffectDto[]> {
        const url = `${environment.webApiAddress}/api/environmental-effects`;
        return this.http.get<EnvironmentalEffectDto[]>(url);
    }

    public getColors(): Observable<ColorDto[]> {
        const url = `${environment.webApiAddress}/api/colors`;
        return this.http.get<ColorDto[]>(url);
    }

    public getClients(): Observable<ClientDto[]> {
        const url = `${environment.webApiAddress}/api/clients`;
        return this.http.get<ClientDto[]>(url);
    }

    public getContractDocuments(): Observable<ContractDocumentDto[]> {
        const url = `${environment.webApiAddress}/api/documents/contracts`;
        return this.http.get<ContractDocumentDto[]>(url);
    }

    public getPackagingComponentReportTemplates(): Observable<ReportTemplateDto[]> {
        const url = `${environment.webApiAddress}/api/packaging-component-reports/templates`;
        return this.http.get<ReportTemplateDto[]>(url);
    }

    public getPackagingUnitReportTemplates(): Observable<ReportTemplateDto[]> {
        const url = `${environment.webApiAddress}/api/packaging-unit-reports/templates`;
        return this.http.get<ReportTemplateDto[]>(url);
    }

    public getFillingGoodTypes(): Observable<FillingGoodTypeDto[]> {
        const url = `${environment.webApiAddress}/api/filling-good-types`;
        return this.http.get<FillingGoodTypeDto[]>(url);
    }

    public getFillingGoodCategories(): Observable<FillingGoodCategoryDto[]> {
        const url = `${environment.webApiAddress}/api/filling-good-categories`;
        return this.http.get<FillingGoodCategoryDto[]>(url);
    }

    public getFillingGoods(): Observable<FillingGoodDto[]> {
        const url = `${environment.webApiAddress}/api/filling-goods`;
        return this.http.get<FillingGoodDto[]>(url);
    }

    public getManufacturingTypes(): Observable<ManufacturingTypeDto[]> {
        const url = `${environment.webApiAddress}/api/manufacturing-types`;
        return this.http.get<ManufacturingTypeDto[]>(url);
    }

    public getAllMaterials(): Observable<MaterialDto[]> {
        const url = `${environment.webApiAddress}/api/materials`;
        return this.http.get<MaterialDto[]>(url);
    }

    public getValidMaterialFunctions(): Observable<PackagingCategoryMaterialFunctionDto[]> {
        const url = `${environment.webApiAddress}/api/material-functions/valid-material-functions`;
        return this.http.get<PackagingCategoryMaterialFunctionDto[]>(url);
    }

    public getManifestationById(manifestationId: number): Observable<MaterialManifestationDto> {
        const url = `${environment.webApiAddress}/api/material-manifestations/${manifestationId}`;
        return this.http.get<MaterialManifestationDto>(url);
    }

    public getAllManifestations(): Observable<MaterialManifestationDto[]> {
        const url = `${environment.webApiAddress}/api/material-manifestations`;
        return this.http.get<MaterialManifestationDto[]>(url);
    }

    public getRemovabilityConditions(): Observable<RemovabilityConditionDto[]> {
        const url = `${environment.webApiAddress}/api/removability-conditions`;
        return this.http.get<RemovabilityConditionDto[]>(url);
    }

    public synchronizeDatabase() {
        const url = `${environment.webApiAddress}/api/masterdata/synchronize`;
        return this.http.get(url, { observe: 'response' });
    }

    public getCreditsCount() {
        const url = `${environment.webApiAddress}/api/account/credits`;
        return this.http.get<CreditsDto>(url);
    }

    public addDistributionCountry(packagingUnitId: number, version: number, countryCode: string, stayOnPageIfError?: boolean) {
        let params: HttpParams = new HttpParams();
        params = params.set('id', packagingUnitId);
        params = params.set('version', version);
        params = params.set('country', countryCode);
        const url = `${environment.webApiAddress}/api/packaging-units/add-distribution-country`;
        return this.http.put<any>(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
    }

    public checkGtinValidity(gtin: string, stayOnPageIfError?: boolean) {
        const url = `${environment.webApiAddress}/api/packaging-units/verify-global-trade-item-number?input=${gtin}`;
        return this.http.post<boolean>(url, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
    }

    public getContractPackages(): Observable<ContractPackageDto[]> {
      const url = `${environment.webApiAddress}/api/contractPackages`;
      return this.http.get<ContractPackageDto[]>(url);
  }
}
