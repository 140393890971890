<mat-tree [dataSource]="dataSource" [treeControl]="nestedTreeControl" class="packaging-system-tree">
  <mat-nested-tree-node *matTreeNodeDef="let node">
    <div class="mat-tree-node cursor" (click)="setSelectedLevel(node)"
      [ngClass]="(selectedNode?.id === node.id && selectedNode?.index === node.index) ? isDarkTheme ? 'background-highlight-dark' : 'background-highlight' : ''">
      <button mat-icon-button matTreeNodeToggle [attr.aria-label]="'Toggle ' + node.name"
        [ngClass]="{'invisible': !hasChild(node)}">
        <mat-icon>
          {{nestedTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
      <img class="image-icon" [src]="getPackagingPartImage(node)" [matTooltip]="getImageTooltip(node)">
      {{node.name}} (x{{node.quantity}})
    </div>
    <div [class.packaging-system-tree-invisible]="!nestedTreeControl.isExpanded(node)" role="group">
      <ng-container matTreeNodeOutlet></ng-container>
    </div>
  </mat-nested-tree-node>
</mat-tree>