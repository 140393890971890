import { Injectable } from '@angular/core';
import { LcaEffectInfo, LcaStageInfo, LifeCycleAnalysisInformation } from 'src/app/model/life-cycle-data-information';
import { CountriesService } from 'src/app/navigation/services/countries-service';
import { ComponentLifeCycleResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/component-life-cycle-result-dto';
import { PackagingUnitLifeCycleResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-unit-life-cycle-result-dto';
import { PackagingSystemLifeCycleResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-system-life-cycle-result-dto';
import { LifeCycleResultPackagingUnit } from 'src/app/model/evaluations/life-cycle-result-packaging-unit';
import { LifeCycleResultPackagingSystem } from 'src/app/model/evaluations/life-cycle-result-packaging-system';
import { LifeCycleResultComponent } from 'src/app/model/evaluations/life-cycle-result-component';
import { AnalysesParentService } from '../analyses-parent-service';

export const TOTAL_VALUE_INDEX = 4; // TODO set back to 6 when materials separated again
export const TOTAL_VALUE_INDEX_COMPONENT = 5;

export class EffectInfosDictionary {
  key = '';
  name = '';
  impact = -1;
  unit = '';
  description = '';
  unitDescription = '';
  isRelevant?: boolean;
}

export class ChartSeriesItem {
  name = '';
  value = -1;
}

export class ChartSeries {
  name = '';
  label = '';
  value = -1;
  series: ChartSeriesItem[] = [];
}

@Injectable({ providedIn: 'root' })
export class LifeCycleService extends AnalysesParentService {

  private lifeCycleStages: LcaStageInfo[];
  private environEffects: LcaEffectInfo[];

  constructor(
    protected lcaInfo: LifeCycleAnalysisInformation,
    protected countriesService: CountriesService
  ) {
    super();
    this.lifeCycleStages = this.lcaInfo.lifeCycleStageInformation;
    this.environEffects = this.lcaInfo.environmentalEffectsInformation;
  }

  getPackagingSystemLcaResultFromDto(lcaResult: PackagingSystemLifeCycleResultDto): LifeCycleResultPackagingSystem {
    const evaluatedCountry = this.countriesService.getCountryNameByCode(lcaResult.evaluatedCountry);
    return new LifeCycleResultPackagingSystem(lcaResult, this.lifeCycleStages, this.environEffects, evaluatedCountry);
  }

  getPackagingUnitLcaResultFromDto(lcaResult: PackagingUnitLifeCycleResultDto): LifeCycleResultPackagingUnit {
    const evaluatedCountry = this.countriesService.getCountryNameByCode(lcaResult.evaluatedCountry);
    return new LifeCycleResultPackagingUnit(lcaResult, this.lifeCycleStages, this.environEffects, evaluatedCountry);
  }

  getComponentLcaResultFromDto(lcaResult: ComponentLifeCycleResultDto): LifeCycleResultComponent {
    const evaluatedCountry = this.countriesService.getCountryNameByCode(lcaResult.evaluatedCountry);
    return new LifeCycleResultComponent(lcaResult, this.lifeCycleStages, this.environEffects, evaluatedCountry);
  }
}
