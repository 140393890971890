import { NestedTreeControl } from '@angular/cdk/tree';
import { Component, Input, OnChanges } from '@angular/core';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { PackagingSystemTreeLevel, PackagingUnitTreeLevel, ComponentTreeLevel, PackagingPartTreeComponent } from 'src/app/components/shared-components/parent-components/packaging-part-tree/packaging-part-tree.component';
import { PackagingSystemDto, PackagingSystemChildSystem, PackagingSystemChildUnit } from 'src/app/data-transfer/entities/packaging-system-entities/packaging-system-dto';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { ComponentTypeService } from 'src/app/navigation/services/component-type-service';
import { PackagingUnitTypeService } from 'src/app/navigation/services/packaging-unit-type-service';

@Component({
  selector: 'app-packaging-system-tree',
  templateUrl: './packaging-system-tree.component.html',
  styleUrls: ['./packaging-system-tree.component.scss']
})
export class PackagingSystemTreeComponent extends PackagingPartTreeComponent implements OnChanges {

  @Input() packagingSystem!: PackagingSystemDto;
  @Input() public includeRoot = false;
  @Input() public includeComponents = false;
  @Input() public deselectionAllowed = true;

  selectedNode: PackagingSystemTreeLevel | PackagingUnitTreeLevel | ComponentTreeLevel | null = null;
  nestedTreeControl = new NestedTreeControl<PackagingSystemTreeLevel>(node => node.children);

  constructor(
    protected packagingUnitTypeService: PackagingUnitTypeService,
    protected componentTypeService: ComponentTypeService
  ) {
    super(packagingUnitTypeService, componentTypeService);
    this.dataSource = new MatTreeNestedDataSource<PackagingSystemTreeLevel>();
  }

  /**
   * It is not enough to implement OnInit, because PS tree is also used for dynamic
   * data (on PS update), and not only on static data as is the case with the analyses
   */
  ngOnChanges(): void {
    this.initTree();
  }

  private initTree() {
    let treeData: PackagingSystemTreeLevel[];
    const childrenNodes = this.buildTree(this.packagingSystem);
    if (this.includeRoot) {
      const rootNode: PackagingSystemTreeLevel = this.getRootNode(childrenNodes);
      treeData = [rootNode];
      this.setSelectedLevel(rootNode);
    } else {
      treeData = childrenNodes;
    }
    this.dataSource.data = null;
    this.dataSource.data = treeData;
    this.nestedTreeControl.dataNodes = treeData;
    this.nestedTreeControl.expandAll();
    this.collapseUnitNodesRecursively(treeData);
  }

  private getRootNode(childrenNodes: (PackagingSystemTreeLevel | PackagingUnitTreeLevel)[]) {
    return {
      id: this.packagingSystem.id ?? -1,
      name: `${this.packagingSystem.brandName} - ${this.packagingSystem.productName}`,
      quantity: 1,
      type: PackagingPart.System,
      index: 0,
      children: childrenNodes
    };
  }

  setSelectedLevel(node: PackagingSystemTreeLevel | PackagingUnitTreeLevel | ComponentTreeLevel) {
    if (this.selectedNode && this.selectedNode.id === node.id && this.selectedNode.index === node.index) {
      if (this.deselectionAllowed) {
        this.selectedNode = null;
      } else {
        return;
      }
    } else {
      this.selectedNode = node;
    }
    this.nodeSelected.emit(this.selectedNode);
  }

  getPackagingPartImage(node: PackagingSystemTreeLevel | PackagingUnitTreeLevel) {
    return super.getPackagingPartImage(node);
  }

  getImageTooltip(node: PackagingSystemTreeLevel | PackagingUnitTreeLevel) {
    return super.getImageTooltip(node);
  }

  private buildTree(packagingSystem: PackagingSystemDto): (PackagingSystemTreeLevel | PackagingUnitTreeLevel)[] {
    let children: (PackagingSystemChildSystem | PackagingSystemChildUnit)[] = [];
    packagingSystem.packagingSystems.forEach((child: PackagingSystemChildSystem) => children.push(child));
    packagingSystem.packagingUnits.forEach((child: PackagingSystemChildUnit) => children.push(child));
    children = children.sort((a, b) => a.index - b.index);
    const childNodes: (PackagingSystemTreeLevel | PackagingUnitTreeLevel)[] = [];
    children.forEach(child => {
      let level: PackagingSystemTreeLevel | PackagingUnitTreeLevel | null = null;
      if ((child as PackagingSystemChildSystem).underlyingPackagingSystem) {
        const systemChild = child as PackagingSystemChildSystem;
        level = {
          id: systemChild.underlyingPackagingSystem.id ?? -1,
          name: `${systemChild.underlyingPackagingSystem.brandName} - ${systemChild.underlyingPackagingSystem.productName}`,
          quantity: systemChild.quantity,
          type: PackagingPart.System,
          index: systemChild.index,
          children: []
        };
        level.children = this.buildTree(systemChild.underlyingPackagingSystem);
      }
      if ((child as PackagingSystemChildUnit).underlyingPackagingUnit) {
        const unitChild = child as PackagingSystemChildUnit;
        let unitChildren: ComponentTreeLevel[] = [];
        if (this.includeComponents) {
          unitChildren = this.getComponentTreeLevelsForUnit(unitChild.underlyingPackagingUnit);
        }
        level = {
          id: unitChild.underlyingPackagingUnit.id ?? -1,
          name: `${unitChild.underlyingPackagingUnit.brandName} - ${unitChild.underlyingPackagingUnit.productName}`,
          quantity: unitChild.quantity,
          type: PackagingPart.Unit,
          index: unitChild.index,
          packagingUnitTypeId: unitChild.underlyingPackagingUnit.packagingTypeId,
          children: unitChildren
        };
      }
      if (level != null) {
        childNodes.push(level);
      }
    });
    return childNodes;
  }

  private collapseUnitNodesRecursively(treeLevels: PackagingSystemTreeLevel[] | PackagingUnitTreeLevel[]): void {
    for (const treeLevel of treeLevels) {
      if (treeLevel.type === PackagingPart.Unit) {
        this.nestedTreeControl.collapse(treeLevel);
      } else if (treeLevel.type === PackagingPart.System) {
        const children = (treeLevel as PackagingSystemTreeLevel).children;
        if (children) {
          this.collapseUnitNodesRecursively(children);
        }
      }
    }
  }
}
