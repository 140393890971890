<div class="outer-container">
  <h1 class="mat-headline-3">{{ 'home.materialHome.title' | translate }}</h1>

  <div id="image-container">
    <div id="material-button-container">
      <button id="material-button" mat-raised-button (click)="createCompositeMaterial()" color="primary" type="button">
        <mat-icon>add</mat-icon>
        {{ 'home.materialHome.addMaterialButton' | translate }}
      </button>
    </div>
    <div id="material-image-container">
      <img id="material-image" [src]="imageSource">
    </div>
  </div>

  <div class="inner-container">
    <div class="table-container">
      <app-composite-materials-overview [dataSource]="materialsDataSource" [displayedColumns]="displayedColumnsMaterials"
        [isUserValidator]="isUserValidator">
      </app-composite-materials-overview>
      <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
    </div>
  </div>
</div>
