export const LIGHT_THEME_BACKGROUND = 'white';
export const DARK_THEME_BACKGROUND = '#424242';
export const LIGHT_THEME_FONT = 'black';
export const DARK_THEME_FONT = 'white';
export const LIGHT_THEME_BORDER = 'lightgrey';
export const DARK_THEME_BORDER = 'darkgrey';
export const LIGHT_THEME_BUTTON = '#2A6299';
export const DARK_THEME_BUTTON = '#b0cede';

// Special colors
export const REC_LIGHT_THEME_BACKGROUND = '#fafafa';
export const REC_DARK_THEME_BACKGROUND = '#303030';
export const MAP_LIGHT_THEME_DEFAULT = '#EEEEEE';
export const MAP_DARK_THEME_DEFAULT = '#757575';
export const MAP_LIGHT_THEME_OUTLINE = '#BDBDBD';
export const MAP_DARK_THEME_OUTLINE = '#424242';
export const IMPORTANT_INFO_COLOR = '#FF5722';