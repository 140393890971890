<div class="table-container-wide">
  <div class="data-container">
    <div id="image-container" *ngIf="displayComponentTypeImage"><img [src]="componentTypeImageSource"></div>
    <div id="table-container">
      <mat-table [dataSource]="dataSource" class="mat-elevation-z8" id="additional-packaging-table">
        <ng-container *ngFor="let column of displayedColumns" [matColumnDef]="column.text">
          <mat-header-cell *matHeaderCellDef>
            {{column.text}}
          </mat-header-cell>
          <mat-cell *matCellDef="let element; let i = index">
            <div *ngIf="column.id !== 'tracked' && column.id !== 'action'">
              {{element.value[column.id]}}
            </div>
            <div *ngIf="column.id === 'tracked'">
              <mat-icon *ngIf="element.value.hasInternalTracking">track_changes</mat-icon>
            </div>
            <div *ngIf="column.id === 'action'">
              <ng-container *ngTemplateOutlet="actionColumn; context:{ row: element, i: i }"></ng-container>
            </div>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumnNames"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumnNames;"></mat-row>
      </mat-table>
    </div>
  </div>
  <div id="buttons-container">
    <button class="add-button" (click)="openAddEditDialog(dialogActions.ADD,-1)" mat-raised-button color="primary"
      *ngIf="canEditForm" type="button" [disabled]="this.isSingleComponent && componentsFormArray.controls.length > 0">
      <mat-icon>add</mat-icon>
      {{ 'component.' + tableInfo.label + 'AddNew' | translate }}
    </button>
    <button class="add-button" (click)="openAddExistingDialog()" mat-raised-button color="primary" *ngIf="canEditForm"
      type="button" [disabled]="this.isSingleComponent && componentsFormArray.controls.length > 0">
      <mat-icon>menu_open</mat-icon>
      {{ 'component.' + tableInfo.label + 'AddExisting' | translate }}
    </button>
  </div>
</div>

<ng-template #actionColumn let-row="row" let-i="i">
  <span *ngIf="canEditForm; else viewComponent">
    <button mat-icon-button *ngIf="!row.invalid; else invalidButton" color="primary"
      (click)="openAddEditDialog(dialogActions.EDIT,i)" type="button">
      <mat-icon>edit</mat-icon>
    </button>
    <ng-template #invalidButton>
      <button mat-icon-button color="primary" (click)="openAddEditDialog(dialogActions.EDIT,i)"
        matTooltip="{{ 'errors.notAllFieldsFilled' | translate }}" type="button">
        <mat-icon matBadge="{{row.get('errorCount').value}}" matBadgeColor="warn" matBadgePosition="before" aria-hidden="false">edit
        </mat-icon>
      </button>
    </ng-template>
  </span>
  <ng-template #viewComponent>
    <button mat-icon-button (click)="openAddEditDialog(dialogActions.EDIT,i)" color="primary" type="button">
      <mat-icon>preview</mat-icon>
    </button>
  </ng-template>
  <button mat-icon-button (click)="openDeleteDialog(i)" color="primary" *ngIf="canEditForm" type="button">
    <mat-icon>delete_forever</mat-icon>
  </button>
  <button mat-icon-button (click)="saveComponent(i)" color="primary" *ngIf="canEditForm" type="button">
    <mat-icon>save_alt</mat-icon>
  </button>
</ng-template>
