import { EvaluationResultDto } from './evaluation-result-dto';

export class RecyclabilityResultDto extends EvaluationResultDto {
  percentageRatingA = -1;
  percentageRatingB = -1;
  percentageRatingC = -1;
  percentageRatingD = -1;
  percentageRatingX = -1;
  percentageRatingUnknown = -1;
  totalWeight = -1;
  totalRecyclateWeight = -1;
}