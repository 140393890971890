import {Injectable} from '@angular/core';
import {HttpEvent, HttpInterceptor, HttpHandler, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const languageCode = localStorage.getItem('language');
    if (languageCode != null && !req.headers.has('Accept-Language')) {
      req = req.clone({
        setHeaders: {
          'Accept-Language': `${languageCode}`
        }
      });
    }
    return next.handle(req);
  }
}
