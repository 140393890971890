import { MultiMaterialCompositeDto } from 'src/app/data-transfer/entities/material-entities/multi-material-composite-dto';
import { MultiMaterialLayerDto } from 'src/app/data-transfer/entities/material-entities/multi-material-layer-dto';

export class MappedMaterialLayer extends MultiMaterialLayerDto {
  isDetectionLayer?: boolean;
  parentMaterialId?: number | null;
  parentMaterialIndex?: number;
  isValidMaterial?: boolean;
  internalId?: number;
  color?: string;
  isFirstLayerInGroup?: boolean;
  isTracked?: boolean;
}

export class CompositeMaterialSevice {

  public static getMaterialLayersRecursively(
    material: MultiMaterialCompositeDto, returnItemsList: MappedMaterialLayer[] = []
  ): MappedMaterialLayer[] {
    let allItemsList = ([] as Array<MultiMaterialLayerDto | MultiMaterialCompositeDto>).concat(material.layers);
    allItemsList = allItemsList.concat(material.subMultiMaterials);
    allItemsList.sort((a, b) => a.index != null && b.index != null ? a.index - b.index : 0);
    allItemsList.forEach((item: MultiMaterialLayerDto | MultiMaterialCompositeDto) => {
      if (Object.prototype.hasOwnProperty.call(item, 'layers')) {
        this.getMaterialLayersRecursively(item as MultiMaterialCompositeDto, returnItemsList);
      } else {
        returnItemsList.push(item as MultiMaterialLayerDto);
      }
    });
    return returnItemsList;
  }

  public static createEmptyCompositeMaterial(): MultiMaterialCompositeDto {
    return Object.assign(new MultiMaterialCompositeDto(), {
      articleName: '',
      articleNumber: undefined,
      gtin: '',
      manufacturerName: '',
      manufacturingCountry: undefined,
      layers: [],
      subMultiMaterials: [],
      images: [],
      documents: [],
      totalGrammage: undefined,
      totalWeight: undefined,
      associatedTagIdentifiers: [],
      creationTimestamp: new Date(),
      lastModificationTimestamp: new Date(),
      isCurrentVersion: true
    });
  }
}
