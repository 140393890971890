import { Component, Inject, Optional, OnDestroy } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { DialogActions } from 'src/app/model/dictionary';
import { CreditsDto } from 'src/app/data-transfer/entities/credits-dto';
import { AbstractControl, FormControl, ValidationErrors, Validators } from '@angular/forms';
import { ClientDto } from 'src/app/data-transfer/entities/client-dto';
import { FileUploadComponent } from '../../shared-components/upload/file-upload/file-upload.component';
import { TranslateService } from '@ngx-translate/core';
import { SimpleAlertDialogComponent, SimpleDialogData } from '../simple-alert-dialog/simple-alert-dialog.component';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { FileApiService } from 'src/app/data-transfer/services/file-api-service';
import { CreateOrganizationSettingsDto } from 'src/app/data-transfer/entities/create-organization-settings-dto';
import { OrganizationDto } from 'src/app/data-transfer/entities/organization-dto';
import { UserCreationRowErrorDto } from 'src/app/data-transfer/entities/user-creation-row-error-dto';
import { ReportTemplateDto } from 'src/app/data-transfer/entities/report-template-dto';
import { ContractPackageDto } from 'src/app/data-transfer/entities/contract-package-dto';

@Component({
  selector: 'app-bulk-user-upload-dialog',
  templateUrl: './bulk-user-upload-dialog.component.html',
  styleUrls: ['./bulk-user-upload-dialog.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: {
      parse: {
        dateInput: 'DD/MM/YYYY',
      },
      display: {
        dateInput: 'DD/MM/YYYY',
        monthYearLabel: 'MMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
      }
    } }
  ]
})
export class BulkUserUploadDialogComponent extends FileUploadComponent implements OnDestroy {
  excelFile: File | null = null;

  creditsCount : CreditsDto = new CreditsDto();
  existingOrganizationsNames: Set<string>;

  spinnerActive: boolean = false;
  createButtonDisabled: boolean = true;
  changePasswordsButtonDisabled: boolean = true;
  allClients: ClientDto[] = [];
  allContractPackages: ContractPackageDto[] = [];
  allOrganizations: OrganizationDto[] = [];
  allPackagingUnitReportTemplates: ReportTemplateDto[] = [];
  allPackagingComponentReportTemplates: ReportTemplateDto[] = [];

  allowedClientsDropdown: FormControl<number[] | null> = new FormControl([]);
  parentOrganizationDropdown: FormControl<number | null> = new FormControl(null);
  contractPackagesDropdown: FormControl<number[] | null> = new FormControl([]);
  visibleOrganizationsDropdown: FormControl<number[] | null> = new FormControl([]);
  packagingUnitReportTemplatesDropdown: FormControl<number | null> = new FormControl(-1, [Validators.required]);
  packagingComponentReportTemplatesDropdown: FormControl<number | null> = new FormControl(-1, [Validators.required]);

  constructor(
    private dialogRef: MatDialogRef<BulkUserUploadDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: any,
    protected dialog: MatDialog,
    private translateService: TranslateService,
    private fileApiService: FileApiService
  ) {
    super(dialog);
    this.allClients = data.allClients;
    this.allOrganizations = data.allOrganizations;
    this.allContractPackages = data.allContractPackages;
    this.allPackagingUnitReportTemplates = data.allPackagingUnitReportTemplates;
    this.allPackagingComponentReportTemplates = data.allPackagingComponentReportTemplates;

    this.existingOrganizationsNames = new Set<string>(data.existingOrganizationsNames);
  }

  validate(control: AbstractControl): ValidationErrors | null {
    throw new Error('Method not implemented.');
  }

  registerOnValidatorChange?(fn: () => void): void {
    throw new Error('Method not implemented.');
  }

  openExcelFile(event: Event) {
    const target = event.target as HTMLInputElement;
    if (target.files) {
      const excelFile = target.files[0];
      if (!excelFile) { return; }
      const fileType = excelFile.type;
      if (fileType.match('application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') === null) {
        const dialogData = this.getInvalidFormatDialogData(excelFile.name);
        this.showDialog(dialogData);
        return;
      }
      this.excelFile = excelFile;
    }

    this.refreshButtonDisabled();
  }

  private getErrorDialogData(errors: UserCreationRowErrorDto[]): SimpleDialogData {
    const errorMessages = ['The given Excel file has the wrong format:'];
    for (const error of errors){
      errorMessages.push("• Row " + error.row + ", Column: " + error.column + ": " + error.description);
    }

    return {
      title: 'Error',
      messages: errorMessages,
      icon: 'error'
    };
  }

  private getInvalidFormatDialogData(fileName: string): SimpleDialogData {
    return {
      title: fileName,
      messages: [this.translateService.instant('errors.formatNotSupported')],
      icon: 'error'
    };
  }

  closeDialog() {
    this.dialogRef.close({ event: DialogActions.REJECT });
  }

  refreshButtonDisabled() {
    this.createButtonDisabled = this.spinnerActive ||  this.excelFile == null || this.packagingUnitReportTemplatesDropdown.value == -1 || this.packagingComponentReportTemplatesDropdown.value == -1;
    this.changePasswordsButtonDisabled = this.spinnerActive || this.excelFile == null;
  }

  uploadCreate() {
    if (!this.excelFile) { return; }

    const settings: CreateOrganizationSettingsDto =  {
      allowedClientIds: this.allowedClientsDropdown.value ?? [],
      visibleOrganizationIds: this.visibleOrganizationsDropdown.value ?? [],
      packagingUnitReportTemplateId: this.packagingUnitReportTemplatesDropdown.value ?? -1,
      packagingComponentReportTemplateId: this.packagingComponentReportTemplatesDropdown.value ?? -1,
      initialCredits: this.creditsCount,
      contractPackageIds: this.contractPackagesDropdown.value ?? [],
      parentOrganizationId: this.parentOrganizationDropdown.value,
    };

    const formData: FormData = super.getNewFilesFormData([this.excelFile], 'file');
    formData.append("settings", JSON.stringify(settings));

    this.spinnerActive = true;

    this.excelFile = null;

    this.fileApiService.putExcelUsers(formData, true).subscribe(excelFile => {
      const excelResultUrl = window.URL.createObjectURL(excelFile);
      window.open(excelResultUrl, '_blank');
      this.spinnerActive = false;
      this.refreshButtonDisabled();
    }, error => {
      console.log(error);
      console.log(typeof(error));

      this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(this.getErrorDialogData(error as UserCreationRowErrorDto[])));
      this.spinnerActive = false
      this.refreshButtonDisabled();
    });
  }

  uploadChangePasswords() {
    if (!this.excelFile) { return; }

    const formData: FormData = super.getNewFilesFormData([this.excelFile], 'file');

    this.spinnerActive = true;

    this.excelFile = null;

    this.fileApiService.changePasswordsExcel(formData, true).subscribe(excelFile => {
      const excelResultUrl = window.URL.createObjectURL(excelFile);
      window.open(excelResultUrl, '_blank');
      this.spinnerActive = false;
      this.refreshButtonDisabled();
    }, error => {
      console.log(error);
      console.log(typeof(error));

      this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(this.getErrorDialogData(error as UserCreationRowErrorDto[])));
      this.spinnerActive = false
      this.refreshButtonDisabled();
    });
  }
}
