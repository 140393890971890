import { Subscription } from 'rxjs';
import { CreateUpdateHandler } from '../../../../../services/packaging-services/create-update-handler';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { CountryDto } from 'src/app/data-transfer/entities/country-dto';
import { FillingGoodCategoryDto } from 'src/app/data-transfer/entities/filling-good-category-dto';
import { FillingGoodDto } from 'src/app/data-transfer/entities/filling-good-dto';
import { FillingGoodTypeDto } from 'src/app/data-transfer/entities/filling-good-type-dto';
import { ComponentRoutingData } from 'src/app/components/packaging-component/create-update/common/component-routing-wrapper/component-routing-wrapper.component';

export class PackagingRoutingData {
  fillingGoods: FillingGoodDto[] = [];
  fillingGoodCategories: FillingGoodCategoryDto[] = [];
  fillingGoodTypes: FillingGoodTypeDto[] = [];
  assemblyCountries: CountryDto[] = [];
}

@Component({
  selector: 'app-packaging-routing-wrapper',
  templateUrl: './packaging-routing-wrapper.component.html',
  styleUrls: ['./packaging-routing-wrapper.component.scss']
})
export class PackagingRoutingWrapperComponent implements OnInit, OnDestroy {

  private routeDataSubscription?: Subscription;

  constructor(
    public createUpdateHandler: CreateUpdateHandler,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.data.subscribe(data => {
      const packagingData = new PackagingRoutingData();
      packagingData.fillingGoods = data.fillingGoods ?? [];
      packagingData.fillingGoodCategories = data.fillingGoodCategories ?? [];
      packagingData.fillingGoodTypes = data.fillingGoodTypes ?? [];
      packagingData.assemblyCountries = data.assemblyCountries ?? [];
      this.createUpdateHandler.setPackagingHandlerPackagingData(packagingData);

      const componentData = new ComponentRoutingData();
      componentData.mainBodySubtypes = data.mainBodySubtypes ?? [];
      componentData.closureSubtypes = data.closureSubtypes ?? [];
      componentData.decorationSubtypes = data.decorationSubtypes ?? [];
      componentData.inlaySubtypes = data.inlaySubtypes ?? [];
      componentData.packagingAidSubtypes = data.packagingAidSubtypes ?? [];
      componentData.removabilityConditions = data.removabilityConditions ?? [];

      this.createUpdateHandler.setComponentHandlerComponentData(componentData);
    });
  }

  ngOnDestroy(): void {
    this.routeDataSubscription?.unsubscribe();
  }
}
