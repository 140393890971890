import { RatingsHandler } from './../../../../util/analyses-util/ratings-handler';
import { AggregateEvaluationRecyclabilityOutputDto } from './../../../../data-transfer/entities/aggregate-evaluation-recyclability-output-dto';
import { TranslateService } from '@ngx-translate/core';
import { RecyclabilityAggregatedEvaluationResult } from '../../../../util/analyses-util/recyclability/recyclability-aggregated-evaluation-result';
import { Component, Input, OnChanges, Output, SimpleChanges, ChangeDetectionStrategy, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-view-recyclability-aggregate-evaluation',
  templateUrl: './view-recyclability-aggregate-evaluation.component.html',
  styleUrls: ['./view-recyclability-aggregate-evaluation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ViewRecyclabilityAggregateEvaluationComponent implements OnChanges {

  @Input() recEvaluation: AggregateEvaluationRecyclabilityOutputDto | null = null;
  @Input() isDarkTheme = false;
  @Input() isScrollingEnabled = false;

  recChartDataSource!: RecyclabilityAggregatedEvaluationResult;
  @Output() scrollToTableEvent = new EventEmitter();

  displayedColumnsMassAnalysis = ['material', 'weight', 'rating'];
  showTable = false;

  constructor(
    private translateService: TranslateService,
    private ratingsHandler: RatingsHandler
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.recEvaluation && changes.recEvaluation.currentValue) {
      this.recChartDataSource = new RecyclabilityAggregatedEvaluationResult(changes.recEvaluation.currentValue, this.translateService);
    }
    this.showTable = true;
  }

  getFontColor(element: any) {
    return this.ratingsHandler.getRatingFontColorRecyclability(element.label);
  }

  getBackgorundColor(element: any) {
    return this.ratingsHandler.getRatingCellColorRecyclability(element.label);
  }

  scrollToTable() {
    this.scrollToTableEvent.emit();
  }
}
