<mat-menu #filterMenu="matMenu">
  <ng-template matMenuContent let-caller="caller">

    <!-- <div mat-menu-item mat-filter-item [disableRipple]="true" (click)="$event.stopPropagation()">
      <mat-form-field>
        <mat-select placeholder="{{ 'home.filter.criterion' | translate }}" [(value)]="searchCriterion[caller]">
          <mat-option *ngFor="let criterion of criteriaList" [value]="criterion.value">
            {{ 'home.filter.criteriaList.' + criterion.value | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div> -->

    <div mat-menu-item mat-filter-item [disableRipple]="true" (click)="$event.stopPropagation()">
      <mat-form-field>
        <input matInput placeholder="{{ 'home.filter.value' | translate }}" [(ngModel)]="searchValue[caller]"
          autocomplete="off">
      </mat-form-field>
    </div>

    <div mat-menu-item mat-filter-item [disableRipple]="true">
      <button mat-raised-button (click)="clearColumn(caller)"
        type="button">{{ 'home.filter.removeFilter' | translate }}</button>
      <button mat-raised-button color="primary" (click)="applyColumnFilter(caller)"
        type="button">{{ 'home.filter.startFilter' | translate }}</button>
    </div>

  </ng-template>
</mat-menu>




<mat-menu #dateFilterMenu="matMenu">
  <ng-template matMenuContent let-caller="caller">

    <div mat-menu-item mat-filter-item [disableRipple]="true" (click)="$event.stopPropagation()">
      <mat-form-field class="filter-input">
        <mat-select placeholder="{{ 'home.filter.criterion' | translate }}" [(value)]="searchCriterion[caller]">
          <mat-option *ngFor="let criterion of dateCriteriaList" [value]="criterion.value">
            {{ 'home.filter.criteriaList.' + criterion.value | translate }}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>

    <div mat-menu-item mat-filter-item [disableRipple]="true" (click)="$event.stopPropagation()">
      <mat-form-field class="filter-input">
        <mat-label>YYYY-MM-DD</mat-label>
        <input matInput [matDatepicker]="picker" [(ngModel)]="searchValue[caller]" autocomplete="off">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>
      </mat-form-field>
    </div>

    <div mat-menu-item mat-filter-item [disableRipple]="true">
      <button mat-raised-button (click)="clearColumn(caller)"
        type="button">{{ 'home.filter.removeFilter' | translate }}</button>
      <button mat-raised-button color="primary" (click)="applyDateColumnFilter()"
        type="button">{{ 'home.filter.startFilter' | translate }}</button>
    </div>

  </ng-template>
</mat-menu>




<mat-menu #checkboxFilterMenu="matMenu">
  <ng-template matMenuContent let-caller="caller" let-categories="categories">

    <div *ngFor="let category of categories" mat-menu-item mat-filter-item [disableRipple]="true"
      (click)="$event.stopPropagation()">
      <mat-checkbox [checked]="isSelected(category, selectedCategories[caller])"
        (change)="setSelected($event.checked, category, selectedCategories, caller)">
        {{ category }}
      </mat-checkbox>
    </div>

    <div mat-menu-item mat-filter-item [disableRipple]="true">
      <button mat-raised-button (click)="clearColumn(caller)"
        type="button">{{ 'home.filter.removeFilter' | translate }}</button>
      <button mat-raised-button color="primary" (click)="applyCheckboxColumnFilter(caller, selectedCategories[caller])"
        type="button">{{ 'home.filter.startFilter' | translate }}</button>
    </div>

  </ng-template>
</mat-menu>




<mat-menu #tagFilterMenu="matMenu">
  <ng-template matMenuContent let-caller="caller" let-possibleTags="possibleTags">

    <div class="tag-filter-list">
      <div *ngFor="let tag of possibleTags" mat-menu-item mat-filter-item [disableRipple]="true"
        (click)="$event.stopPropagation()">
        <mat-checkbox [checked]="isSelected(tag, selectedTags[caller])"
          (change)="setSelected($event.checked, tag, selectedTags, caller)">
          {{ tag }}
        </mat-checkbox>
      </div>
    </div>
    <div mat-menu-item mat-filter-item [disableRipple]="true">
      <button mat-raised-button (click)="clearColumn(caller)" type="button">{{ 'home.filter.removeFilter' | translate
        }}</button>
      <button mat-raised-button color="primary" (click)="applyTagColumnFilter(caller, selectedTags[caller])"
        type="button">{{ 'home.filter.startFilter' | translate }}</button>
    </div>

  </ng-template>
</mat-menu>
