<div>
  <div *ngFor="let document of documents">
    <a [href]="document.path" mat-menu-item target="_blank">
      <mat-icon>text_snippet</mat-icon>{{ document.label }}
    </a>
  </div>
  <button *ngIf="isAdmin" mat-menu-item (click)="downloadUsageReport()">
    <mat-icon>text_snippet</mat-icon> {{'downloads.admin.file.userReport' | translate}}
  </button>
</div>

<!-- Exclude Api documentation
<button mat-menu-item (click)="clickExternalHtml()" type="button">
  <mat-icon>text_snippet</mat-icon>
  <span>API Documentation</span>
</button>
-->
