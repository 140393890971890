import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { RecyclingStreamTranslationsDto } from 'src/app/data-transfer/entities/evaluation-entities/recycling-stream-dto';
import { RecyclabilityResultPackagingUnit } from 'src/app/model/evaluations/recyclability-result-packaging-unit';
import { RatingsHandler } from 'src/app/util/analyses-util/ratings-handler';
import { ComponentTableData } from 'src/app/util/analyses-util/recyclability-table-definitions';
import { RowSpanComputer, Span } from 'src/app/util/analyses-util/row-span-computer';

export class ComponentsTableColumn {
  columnDef = '';
  header = '';
  cell = (_: ComponentTableData) => '';
  color = (_: ComponentTableData) => '';
  fontColor = (_: ComponentTableData) => '';
  tooltip = (_: ComponentTableData) => '';
  recyclingStreamTooltip = (_: ComponentTableData) => '';
}

@Component({
  selector: 'app-packaging-unit-components-table',
  templateUrl: './packaging-unit-components-table.component.html',
  styleUrls: ['./packaging-unit-components-table.component.scss']
})
export class PackagingUnitComponentsTableComponent implements OnChanges {

  @Input() dataSource!: RecyclabilityResultPackagingUnit;
  @Input() public recyclingStreams: RecyclingStreamTranslationsDto[] = [];

  dataColumnsComponents: ComponentsTableColumn[] = [];
  displayedColumnsComponents: string[] = [];
  rowSpansComponentsTable: Array<Span[]> = [];
  componentsTableDataSource: ComponentTableData[] = [];

  constructor(
    private translateService: TranslateService,
    private ratingsHandler: RatingsHandler
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource && changes.dataSource.currentValue != null) {
      this.getDataSourceForVersion();
    }
  }

  private getDataSourceForVersion() {
    this.componentsTableDataSource = this.getComponentsTableData(this.dataSource);
    this.dataColumnsComponents = this.getComponentsTableColumns();
    this.displayedColumnsComponents = this.dataColumnsComponents.map(x => x.columnDef);
    this.rowSpansComponentsTable = RowSpanComputer.compute(this.componentsTableDataSource, this.displayedColumnsComponents);
  }

  private getComponentsTableData(dataSource: RecyclabilityResultPackagingUnit): ComponentTableData[] {
    const result: ComponentTableData[] = [];
    for (const component of dataSource.componentResults) {
      for (const material of component.materialResults) {
        const currentLang = this.translateService.store.currentLang;
        const recyclingStreamTranslations = this.recyclingStreams.find(x => x.id === material.recyclingStreamId);
        const recyclingStream = recyclingStreamTranslations?.translations.find(x =>
          x.languageCode === currentLang &&
          x.countryCode === dataSource.evaluatedCountryCode)?.translatedName ??
          recyclingStreamTranslations?.fallbackName ?? '';
        result.push({
          index: component.analyzedPackagingComponent.index ?? 0,
          component: component.analyzedPackagingComponent.packagingComponentCategoryName +
            ' (' + component.analyzedPackagingComponent.packagingComponentSubtypeName + ')',
          material: material.analyzedMaterial.materialManifestationName ?? material.analyzedMaterial.materialName ?? '',
          color: material.analyzedMaterial.colorName ?? '',
          mass: material.totalWeight,
          rating: this.ratingsHandler.getRatingNameFromValue(material.rating),
          recyclingStream
        });
      }
    }
    return result;
  }

  private getComponentsTableColumns(): ComponentsTableColumn[] {
    const dataColumns: ComponentsTableColumn[] = [];
    const resultTableData = new ComponentTableData();
    const columnNames = Object.keys(resultTableData);
    columnNames.shift();
    for (const columnName of columnNames) {
      dataColumns.push({
        columnDef: columnName,
        header: this.translateService.instant('analysis.recyclabilityAnalysis.tableColumnsUnits.' + columnName),
        cell: (element: ComponentTableData) => `${element[columnName as keyof ComponentTableData]}`,
        color: (element: ComponentTableData) => `${this.ratingsHandler.getRatingCellColorRecyclability(element.rating)}`,
        fontColor: (element: ComponentTableData) => `${this.ratingsHandler.getRatingFontColorRecyclability(element.rating)}`,
        tooltip: (element: ComponentTableData) => `${this.ratingsHandler.getRatingTooltip(this.translateService, element.rating)}`,
        recyclingStreamTooltip: () => `${this.translateService.instant('analysis.recyclabilityAnalysis.missingRecyclingStream')}`
      });
    }
    return dataColumns;
  }
}
