<h1 mat-dialog-title>
  {{ (component == null ? 'dataManagement.tags.addDialog.title' : 'dataManagement.tags.editDialog.title') | translate }}
</h1>

<div mat-dialog-content>
  <div class="content">
    <mat-form-field class="chip-list" appearance="outline">
      <mat-label>Tags</mat-label>
      <mat-chip-grid #chipList>
        <mat-chip-row *ngFor="let tag of selectedTags" (removed)="removeTag(tag)" [ngStyle]="{ 'background-color': tag.colorCode }">
          <span class="tag-text">{{ tag.name }}</span>
          <button class="remove-button" matChipRemove>
            <mat-icon>cancel</mat-icon>
          </button>
        </mat-chip-row>
      </mat-chip-grid>
      <div class="flex-row">
        <input
          class="flex"
          placeholder="{{ 'dataManagement.tags.editDialog.addTag' | translate }}"
          #tagInput
          [formControl]="tagCtrl"
          [matAutocomplete]="auto"
          [matChipInputFor]="chipList"
          [matChipInputSeparatorKeyCodes]="separatorKeysCodes" />
        <div class="flex-row add-row">
          <div class="flex"></div>
          <mat-chip-row (click)="createTag($event)">
            <button class="add-chip" matChipTrailingIcon>
              <mat-icon>add</mat-icon>
            </button>
            <span>{{ 'dataManagement.tags.tagsManagement.add' | translate }}</span>
          </mat-chip-row>
        </div>
      </div>
      <mat-autocomplete #auto="matAutocomplete" (optionSelected)="addTag($event.option.value)">
        <mat-option *ngFor="let tag of filteredTags | async" [value]="tag">
          <app-tag-directive [tags]="[tag]"></app-tag-directive>
        </mat-option>
      </mat-autocomplete>
      <span *ngIf="tagCtrl.invalid" class="hint margin">
        {{ 'dataManagement.tags.tagsManagement.noTagAvailable' | translate }}
      </span>
    </mat-form-field>
  </div>
</div>
<div mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="onSubmit()">
    {{ 'common.buttons.save' | translate }}
  </button>
  <button mat-dialog-close mat-raised-button>{{ 'common.buttons.close' | translate }}</button>
</div>
