import { CustomValidators } from './../../../services/custom-validators';
import { Subscription } from 'rxjs';
import { UserDialogHandler } from 'src/app/util/user-dialog-handler';
import {
  NG_VALUE_ACCESSOR, FormGroup, ControlValueAccessor, FormBuilder,
  NG_VALIDATORS, Validators, AbstractControlOptions, Validator, ValidationErrors, AbstractControl
} from '@angular/forms';
import { Component, forwardRef, ChangeDetectionStrategy, Input, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-password-form',
  templateUrl: './password-form.component.html',
  styleUrls: ['./password-form.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PasswordFormComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => PasswordFormComponent),
      multi: true,
    }
  ],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PasswordFormComponent implements ControlValueAccessor, Validator, OnChanges, OnDestroy {

  @Input() submitted = false;
  @Input() isAdmin = false;
  minPasswordLength = 6;

  form: FormGroup;
  public showNewPassword = false;
  public showConfirmPassword = false;

  private subscription?: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private userDialogHandler: UserDialogHandler
  ) {
    this.form = this.formBuilder.group({
      password: ['', [
        Validators.required,
        Validators.minLength(this.minPasswordLength),
        CustomValidators.patternValidator(/\d/, { hasNumber: true }),
        CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
        CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
        CustomValidators.patternValidator(/\W|_/, { hasSpecialCharacters: true })
      ]],
      confirmPassword: ['', Validators.required]
    }, {
      validator: this.userDialogHandler.mustMatch('password', 'confirmPassword')
    } as AbstractControlOptions);

    this.subscription =
      this.form.valueChanges.subscribe(value => {
        this.onChange(value);
        this.onTouched();
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.submitted && changes.submitted.currentValue === true) {
      Object.keys(this.form.controls).forEach(field => {
        const control = this.form.get(field);
        control?.markAsTouched({ onlySelf: true });
      });
    }
  }

  get value() {
    return this.form.value;
  }

  set value(value) {
    this.form.get('password')?.patchValue(value);
    this.form.get('confirmPassword')?.patchValue(value);
    this.onChange(value);
    this.onTouched();
  }

  get passwordControl() {
    return this.form.controls.password;
  }

  get confirmPasswordControl() {
    return this.form.controls.confirmPassword;
  }

  onChange: any = () => { };
  onTouched: any = () => { };

  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouched = fn;
  }

  writeValue(value: any) {
    if (value) {
      this.value = value;
    } else {
      this.form.reset();
    }
  }

  validate(control: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : { profile: { valid: false } as ValidationErrors };
  }

  public changeNewPasswordVisibility() {
    this.showNewPassword = !this.showNewPassword;
  }

  public changeConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }

  get formControls() { return this.form.controls; }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
