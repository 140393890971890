import { ImportHtmlComponent } from './../import-html/import-html.component';
import { SimpleAlertDialogComponent, SimpleDialogData } from './../../../../dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { SelectLocationDialogComponent } from './../../../dialogs/select-location-dialog/select-location-dialog.component';
import { DirectoryDto } from './../../../../../data-transfer/entities/directory-dto';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ChangeCandidateDto } from 'src/app/data-transfer/entities/change-candidate-dto';
import { ImportCandidateDto } from 'src/app/data-transfer/entities/import-candidate-dto';
import { Subscription, forkJoin, Observable } from 'rxjs';
import { Component, OnDestroy } from '@angular/core';
import { DeleteDialogData, DeleteItemDialogComponent } from 'src/app/components/dialogs/delete-item-dialog/delete-item-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { DialogActions } from 'src/app/model/dictionary';

export const IMPORT_COST = 0; // 0.0125;

export enum ImportType {
  WithTracking,
  WithoutTracking
}

export enum ChangeType {
  WithTrackingOverwrite,
  WithTrackingNew,
  WithoutTracking
}

@Component({
  selector: 'app-import-parent',
  templateUrl: './import-parent.component.html',
  styleUrls: ['./import-parent.component.scss']
})
export class ImportParentComponent implements OnDestroy {

  importTypeEnum = ImportType;
  changeTypeEnum = ChangeType;

  selectedImportType: number;
  selectedChangeType: number;

  targetDirectory!: DirectoryDto;

  dataSourceImports: MatTableDataSource<ImportCandidateDto>;
  dataSourceChanges: MatTableDataSource<ChangeCandidateDto>;

  rootFolder!: DirectoryDto;

  allCandidatesSelected = false;
  allChangesSelected = false;

  protected routeDataSubscription: Subscription;
  protected removeImportBackendSubscription?: Subscription;
  protected removeChangeBackendSubscription?: Subscription;
  protected dialogSubscription?: Subscription;
  protected loadDataSourceSubscription?: Subscription;

  constructor(
    protected dialog: MatDialog,
    protected route: ActivatedRoute,
    protected translateService: TranslateService
  ) {
    this.dataSourceImports = new MatTableDataSource<ImportCandidateDto>();
    this.dataSourceChanges = new MatTableDataSource<ChangeCandidateDto>();

    this.selectedImportType = ImportType.WithTracking;
    this.selectedChangeType = ChangeType.WithTrackingOverwrite;

    this.routeDataSubscription = this.route.data.subscribe(data => {
      this.rootFolder = data.rootDtoDirectory;
    });
  }

  importWithoutTracking() {
    this.selectedImportType = ImportType.WithoutTracking;
  }

  importWithTracking() {
    this.selectedImportType = ImportType.WithTracking;
  }

  acceptChangesWithoutTracking() {
    this.selectedChangeType = ChangeType.WithoutTracking;
  }

  acceptChangesWithTrackingOverwrite() {
    this.selectedChangeType = ChangeType.WithTrackingOverwrite;
  }

  acceptChangesWithTrackingNew() {
    this.selectedChangeType = ChangeType.WithTrackingNew;
  }

  protected async selectDirectory() {
    const dialogConfig = getDialogConfig({ rootFolder: this.rootFolder, unreachableFolders: [] }, '500px');
    const dialogRef = this.dialog.open(SelectLocationDialogComponent, dialogConfig);
    const dialogResult = await dialogRef.afterClosed().toPromise();
    if (dialogResult.event === DialogActions.REJECT) { return; }
    this.targetDirectory = dialogResult.data;
  }

  protected removeCandidates(
    selectedCandidates: ImportCandidateDto[],
    deleteFunction: ((candidateId: number) => Observable<any>),
    importHtmlComponent: ImportHtmlComponent
  ) {
    const dialogRef = this.dialog.open(DeleteItemDialogComponent, getDialogConfig(this.getConfirmRemovalDialogData(false), '500px'));
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.DELETE) {
        const observables = selectedCandidates.map(candidate => deleteFunction(candidate.id));
        this.removeImportBackendSubscription = forkJoin(observables).subscribe(_ => {
          this.dataSourceImports.data = this.filterDeletedValues(this.dataSourceImports.data, selectedCandidates, 'id');
          importHtmlComponent.setDataSource(this.dataSourceImports);
          selectedCandidates = [];
        });
      }
    });
  }

  protected removeChanges(
    selectedChanges: ChangeCandidateDto[], deleteFunction: ((changeId: number) => Observable<any>),
    idsToDelete: number[], changeHtmlComponent: ImportHtmlComponent
  ) {
    const dialogRef = this.dialog.open(DeleteItemDialogComponent, getDialogConfig(this.getConfirmRemovalDialogData(true), '500px'));
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.DELETE) {
        const observables = idsToDelete.map(id => deleteFunction(id));
        this.removeChangeBackendSubscription = forkJoin(observables).subscribe(_ => {
          this.dataSourceChanges.data = this.filterDeletedValues(this.dataSourceChanges.data, selectedChanges, 'importCandidateId');
          changeHtmlComponent.setDataSource(this.dataSourceChanges);
          selectedChanges = [];
        });
      }
    });
  }

  protected async importItems(
    selectedCandidates: ImportCandidateDto[],
    importFunction: ((candidateId: number, targetDirId: number, isWithTracking: boolean) => Observable<any>)
  ) {
    const isWithTracking = this.selectedImportType === this.importTypeEnum.WithTracking;
    const observables: Observable<any>[] = selectedCandidates.map(candidate =>
      importFunction(candidate.id, this.targetDirectory.id, isWithTracking));
    await this.evaluateImportResult(observables);
  }

  protected async applyChangesNewItems(
    idsToChange: number[], isWithTracking: boolean,
    changeFunction: ((candidateId: number, targetDirectoryId: number, isWithTracking: boolean) => Observable<any>)
  ) {
    const observables = idsToChange.map(id => changeFunction(id, this.targetDirectory.id, isWithTracking));
    await this.evaluateImportResult(observables);
  }

  protected async applyChangesExistingItems(
    idsToChange: number[], changeFunction: ((changeId: number) => Observable<any>)
  ) {
    const observables = idsToChange.map(id => changeFunction(id));
    await this.evaluateImportResult(observables);
  }

  private async evaluateImportResult(importResult: Observable<any>[]) {
    await forkJoin(importResult).toPromise()
      .then(_ => this.showImportResultDialog(this.getImportSuccessDialogData()))
      .catch(_ => this.showImportResultDialog(this.getImportErrorDialogData()));
  }

  private showImportResultDialog(data: SimpleDialogData) {
    this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(data, '500px'));
  }

  private getImportSuccessDialogData(): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.success'),
      messages: [this.translateService.instant('dataManagement.import.import.importSuccess')], icon: 'info'
    };
  }

  private getImportErrorDialogData(): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.error'),
      messages: [this.translateService.instant('dataManagement.import.import.importError')], icon: 'error'
    };
  }

  private getConfirmRemovalDialogData(isChange: boolean): DeleteDialogData {
    const typeString = isChange ? 'changes' : 'import';
    return {
      dialogHeader: this.translateService.instant(`dataManagement.import.${typeString}.dialog.deleteHeader`),
      dialogText: this.translateService.instant(`dataManagement.import.${typeString}.dialog.deleteText`)
    };
  }

  private filterDeletedValues(allValues: any[], deletedValues: any[], idFieldName: string) {
    const remainingValues = [];
    const deletedValueIds = deletedValues.map(x => x[idFieldName]);
    for (const value of allValues) {
      if (!deletedValueIds.includes(value[idFieldName])) {
        remainingValues.push(value);
      }
    }
    return remainingValues;
  }

  ngOnDestroy(): void {
    this.routeDataSubscription?.unsubscribe();
    this.removeImportBackendSubscription?.unsubscribe();
    this.removeChangeBackendSubscription?.unsubscribe();
    this.dialogSubscription?.unsubscribe();
    this.loadDataSourceSubscription?.unsubscribe();
  }
}
