import { BaseDataApiService } from 'src/app/data-transfer/services/base-data-api-service';
import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { ColorDto } from '../entities/color-dto';
import { FillingGoodCategoryDto } from '../entities/filling-good-category-dto';
import { FillingGoodDto } from '../entities/filling-good-dto';
import { FillingGoodTypeDto } from '../entities/filling-good-type-dto';
import { MaterialManifestationDto } from '../entities/material-manifestation-dto';
import { RemovabilityConditionDto } from '../entities/removability-condition-dto';
import { CountryDto } from '../entities/country-dto';
import { PackagingCategoryMaterialFunctionDto } from '../entities/material-function-dto';

export const colorsResolver: ResolveFn<ColorDto[]> = () => {
    return inject(BaseDataApiService).getColors();
};

export const fillingGoodsResolver: ResolveFn<FillingGoodDto[]> = () => {
    return inject(BaseDataApiService).getFillingGoods();
};

export const fillingGoodCategoriesResolver: ResolveFn<FillingGoodCategoryDto[]> = () => {
    return inject(BaseDataApiService).getFillingGoodCategories();
};

export const fillingGoodTypesResolver: ResolveFn<FillingGoodTypeDto[]> = () => {
    return inject(BaseDataApiService).getFillingGoodTypes();
};

export const manifestationsResolver: ResolveFn<MaterialManifestationDto[]> = () => {
    return inject(BaseDataApiService).getAllManifestations();
};

export const materialFunctionsResolver: ResolveFn<PackagingCategoryMaterialFunctionDto[]> = () => {
    return inject(BaseDataApiService).getValidMaterialFunctions();
};

export const removabilityConditionsResolver: ResolveFn<RemovabilityConditionDto[]> = () => {
    return inject(BaseDataApiService).getRemovabilityConditions();
};

export const allCountriesResolver: ResolveFn<CountryDto[]> = () => {
    return inject(BaseDataApiService).getCountries();
};

export const allCountriesEngResolver: ResolveFn<CountryDto[]> = () => {
    return inject(BaseDataApiService).getCountriesEng();
};

export const assemblyCountriesResolver: ResolveFn<CountryDto[]> = () => {
    return inject(BaseDataApiService).getManufacturingCountries();
};

export const distributionCountriesResolver: ResolveFn<CountryDto[]> = () => {
    return inject(BaseDataApiService).getDistributionCountries();
};

export const manufacturingCountriesResolver: ResolveFn<CountryDto[]> = () => {
    return inject(BaseDataApiService).getManufacturingCountries();
};