<h1 mat-dialog-title>{{ 'dataManagement.manageOrganizations.bulkUpload.bulkUserUpload' | translate }}</h1>

<mat-dialog-content>
  <mat-form-field id="parent-organization">
    <mat-label>{{ 'dataManagement.manageOrganizations.bulkUpload.parentOrganization' | translate }}</mat-label>
    <mat-select [formControl]="parentOrganizationDropdown" >
      <mat-option>{{ 'common.text.noSelection' | translate }}</mat-option>
      <mat-option *ngFor="let organization of allOrganizations" [value]="organization.id">
        {{organization.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field id="contract-packages">
    <mat-label>{{ 'dataManagement.manageOrganizations.bulkUpload.contractPackage' | translate }}</mat-label>
    <mat-select multiple [formControl]="contractPackagesDropdown">
      <mat-option *ngFor="let contractPackage of allContractPackages" [value]="contractPackage.contractPackageId">
        {{contractPackage.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field id="allowed-clients">
    <mat-label>{{ 'dataManagement.manageOrganizations.bulkUpload.allowedClients' | translate }}</mat-label>
    <mat-select multiple [formControl]="allowedClientsDropdown" >
      <mat-option *ngFor="let client of allClients" [value]="client.id">
        {{client.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field id="visible-organizations">
    <mat-label>{{ 'dataManagement.manageOrganizations.bulkUpload.visibleOrganizations' | translate }}</mat-label>
    <mat-select multiple [formControl]="visibleOrganizationsDropdown" >
      <mat-option *ngFor="let organization of allOrganizations" [value]="organization.id">
        {{organization.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field id="packaging-unit-report-template">
    <mat-label>{{ 'dataManagement.manageOrganizations.bulkUpload.packagingUnitReportTemplate' | translate }}</mat-label>
    <mat-select [formControl]="packagingUnitReportTemplatesDropdown" (selectionChange)="refreshButtonDisabled()">
      <mat-option *ngFor="let template of allPackagingUnitReportTemplates" [value]="template.id">
        {{template.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field id="packaging-component-report-template">
    <mat-label>{{ 'dataManagement.manageOrganizations.bulkUpload.packagingComponentReportTemplate' | translate }}</mat-label>
    <mat-select [formControl]="packagingComponentReportTemplatesDropdown" (selectionChange)="refreshButtonDisabled()">
      <mat-option *ngFor="let template of allPackagingComponentReportTemplates" [value]="template.id">
        {{template.name}}
      </mat-option>
    </mat-select>
  </mat-form-field>

  <mat-form-field id="credits-field-recyclability">
    <mat-label>{{ 'dataManagement.manageOrganizations.bulkUpload.creditsRecyclability' | translate }}</mat-label>
    <input matInput autocomplete="off" required [(ngModel)]="creditsCount.recyclabilityCredits" type="number">
  </mat-form-field>

  <mat-form-field id="credits-field-lca">
    <mat-label>{{ 'dataManagement.manageOrganizations.bulkUpload.creditsLca' | translate }}</mat-label>
    <input matInput autocomplete="off" required [(ngModel)]="creditsCount.lcaCredits" type="number">
  </mat-form-field>

  <input type="file" accept=".xlsx" (change)="openExcelFile($event)" #hiddenFileInput
  onclick="value=null" style="display: none" />

  <button mat-raised-button (click)="hiddenFileInput.click()" color="primary" type="button">
    <mat-icon class="material-icons left">upload</mat-icon>
    {{ 'dataManagement.manageOrganizations.bulkUpload.loadExcelFileButton' | translate }}
  </button>
  <span *ngIf="excelFile">
    {{ excelFile.name }}
  </span>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button type="submit" color="primary" (click)="uploadCreate()" [disabled]="createButtonDisabled">
    {{ 'dataManagement.manageOrganizations.bulkUpload.createButton' | translate }}
    <div class="spinner-container" *ngIf="spinnerActive">
      <mat-spinner diameter="24" color="accent"></mat-spinner>
    </div>
  </button>

  <button mat-raised-button type="submit" color="primary" (click)="uploadChangePasswords()" [disabled]="changePasswordsButtonDisabled">
    {{ 'dataManagement.manageOrganizations.bulkUpload.changePasswordsButton' | translate }}
    <div class="spinner-container" *ngIf="spinnerActive">
      <mat-spinner diameter="24" color="accent"></mat-spinner>
    </div>
  </button>

  <button (click)="closeDialog()" mat-raised-button type="button">
    {{ 'dataManagement.manageOrganizations.bulkUpload.closeButton' | translate }} </button>

</mat-dialog-actions>

