<h1 mat-dialog-title>{{ 'dataManagement.directory.dialog.addEditFolder.moveTitle' | translate }}</h1>

<mat-dialog-content>
  <mat-card class="treeview-container">
    <app-directory-tree [rootFolder]="rootFolder" [includeRecyclingBin]="false" [preselectFolder]="true"
      (folderSelected)="setSelectedFolder($event)">
    </app-directory-tree>
  </mat-card>
</mat-dialog-content>

<mat-dialog-actions>
  <button [disabled]="isUnreacheable() || !selectedFolder" mat-raised-button color="primary" (click)="acceptChanges()"
    type="submit">{{ 'common.buttons.select' | translate }}</button>
  <button mat-raised-button (click)="discardChanges()" type="button">
    {{ 'common.buttons.cancel' | translate }}</button>
</mat-dialog-actions>