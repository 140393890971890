import { EvaluationsMenuComponent } from './../components/shared-components/menus/evaluations-menu/evaluations-menu.component';
import { DataMenuComponent } from './../components/shared-components/menus/data-menu/data-menu.component';
import { VersionComponent } from '../components/various-components/version/version.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { MatToolbarModule } from '@angular/material/toolbar';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MatIconModule } from '@angular/material/icon';
import { MainNavComponent } from './components/main-nav/main-nav.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatSidenavModule } from '@angular/material/sidenav';
import { AppNavComponent } from './components/app-nav/app-nav.component';
import { AnalysesMenuParentComponent } from '../components/shared-components/analyses-menu/analyses-menu-parent/analyses-menu-parent.component';
import { DownloadsMenuComponent } from '../components/shared-components/menus/downloads-menu/downloads-menu.component';
import { ReportsMenuComponent } from '../components/shared-components/menus/reports-menu/reports-menu.component';
import { ReportsMenuForPackagingPartComponent } from '../components/reports/reports-menu-for-packaging-part/reports-menu-for-packaging-part.component';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AnalysesMenuPackagingUnitsComponent } from '../components/shared-components/analyses-menu/analyses-menu-packaging-units/analyses-menu-packaging-units.component';
import { AnalysesMenuPackagingSystemsComponent } from '../components/shared-components/analyses-menu/analyses-menu-packaging-systems/analyses-menu-packaging-systems.component';
import { PackagingNavigationComponent } from './components/sidebar-navigation/packaging-navigation/packaging-navigation.component';

@NgModule({
  declarations: [
    AppNavComponent,
    MainNavComponent,
    PackagingNavigationComponent,
    VersionComponent,
    AnalysesMenuPackagingUnitsComponent,
    AnalysesMenuPackagingSystemsComponent,
    AnalysesMenuParentComponent,
    DownloadsMenuComponent,
    DataMenuComponent,
    EvaluationsMenuComponent,
    ReportsMenuForPackagingPartComponent,
    ReportsMenuComponent
  ],
  exports: [
    AppNavComponent,
    MainNavComponent,
    PackagingNavigationComponent,
    VersionComponent,
    AnalysesMenuPackagingUnitsComponent,
    AnalysesMenuPackagingSystemsComponent,
    DownloadsMenuComponent,
    DataMenuComponent,
    EvaluationsMenuComponent,
    ReportsMenuForPackagingPartComponent,
    ReportsMenuComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    MatIconModule,
    MatToolbarModule,
    MatSidenavModule,
    TranslateModule,
    MatMenuModule,
    BrowserModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatListModule,
    MatInputModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    NgxSpinnerModule
  ]
})
export class NavigationModule { }
