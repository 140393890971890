import { Observable } from 'rxjs';
import { AnalysisNotPossibleDialogComponent } from '../../../components/dialogs/analysis-not-possible-dialog/analysis-not-possible-dialog.component';
import { SimpleDialogData } from '../../../components/dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { PathResolveService } from '../../../services/path-resolve.service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CreditsService } from 'src/app/services/credits-service';
import { SimpleConfirmDialogComponent } from 'src/app/components/dialogs/simple-confirm-dialog/simple-confirm-dialog.component';
import { AnalysisType, ParentNavigationService } from './parent-navigation-service';
import { MatDialog } from '@angular/material/dialog';
import { NgxSpinnerService } from 'ngx-spinner';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { EvaluationGenerationDto } from 'src/app/data-transfer/entities/evaluation-entities/evaluation-generation-dto';
import { AnalysisApiService } from 'src/app/data-transfer/services/analysis-api-service';
import { DialogActions } from 'src/app/model/dictionary';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { RecyclabilityResultDto } from 'src/app/data-transfer/entities/evaluation-entities/recyclability-result-dto';
import { LifeCycleResultDto } from 'src/app/data-transfer/entities/evaluation-entities/life-cycle-result-dto';
import { ExpenseResultDto } from 'src/app/data-transfer/entities/evaluation-entities/expense-result-dto';

@Injectable({ providedIn: 'root' })
export class PackagingUnitNavigationService extends ParentNavigationService {

  constructor(
    protected dialog: MatDialog,
    protected translateService: TranslateService,
    protected creditsService: CreditsService,
    private router: Router,
    private analysisApiService: AnalysisApiService,
    private spinner: NgxSpinnerService
  ) {
    super(dialog, translateService, creditsService);
  }

  navigateToPackagingUnit(packagingUnitId: number, packagingUnitVersion?: number) {
    const path = packagingUnitVersion == null ?
      PathResolveService.getUpdatePackagingUnitPath(true, packagingUnitId) :
      PathResolveService.getPackagingUnitHistoryPath(true, packagingUnitId, packagingUnitVersion);
    this.router.navigate([path]);
  }

  navigateToAnalysisWithCostConfirmation(
    analysisType: AnalysisType, packagingUnitId: number, versionId: number,
    isAnalysisAllowed: boolean, minimalCreditsRequirement: number
  ) {
    if (!isAnalysisAllowed) {
      this.stopWhenAnalysisNotPossible();
    } else {
      if (minimalCreditsRequirement === 0 || !this.areSpecificCreditsRunningLow(analysisType)) {
        this.navigateToAnalysis(analysisType, packagingUnitId, versionId, minimalCreditsRequirement);
      } else {
        const data: SimpleDialogData = {
          title: this.translateService.instant('common.text.information'),
          messages: [this.translateService.instant('dataEvaluation.performEvaluations.messages.evalCost',
          { count: minimalCreditsRequirement })], icon: 'info'};
        const dialogRef = this.dialog.open(SimpleConfirmDialogComponent, getDialogConfig(data));
        this.dialogSubscription = dialogRef.afterClosed().subscribe(creditsConfirmation => {
          if (creditsConfirmation.event === DialogActions.CONFIRM) {
            this.navigateToAnalysis(analysisType, packagingUnitId, versionId, minimalCreditsRequirement);
          } else {
            this.spinner.hide();
          }
        });
      }
    }
  }

  private navigateToAnalysis(
    analysisType: AnalysisType, packagingUnitId: number, versionId: number, minimalCreditsRequirement: number
  ) {
    let observableAnalysis: Observable<LifeCycleResultDto[] | RecyclabilityResultDto[] | ExpenseResultDto[]> | null = null;
    const dto: EvaluationGenerationDto = { id: packagingUnitId, version: versionId };
    if (analysisType === AnalysisType.Recyclability) {
      observableAnalysis = this.analysisApiService.getRecyclabilityEvaluationResult(dto, PackagingPart.Unit);
    } else if (analysisType === AnalysisType.Lifecycle) {
      observableAnalysis = this.analysisApiService.getLifeCycleEvaluationResult(dto, PackagingPart.Unit);
    } else if (analysisType === AnalysisType.PlasticTax) {
      this.router.navigate([PathResolveService.getTempPlasticTaxUnitPath(true, packagingUnitId, versionId)]);
      // observableAnalysis = this.analysisApiService.getPlasticTaxCalculationResult(dto, PackagingPart.Unit);
    } else if (analysisType === AnalysisType.LicenseFee) {
      this.router.navigate([PathResolveService.getTempLicenseFeeUnitPath(true, packagingUnitId, versionId)]);
      // observableAnalysis = this.analysisApiService.getLicenseFeeCalculationResult(dto, PackagingPart.Unit);
    }
    if (!observableAnalysis) { return; }

    if (this.areInsufficientCreditsAvailable(analysisType,minimalCreditsRequirement)) {
      this.spinner.hide();
      this.stopWhenCreditsInsufficient();
    } else {
      this.evaluationSubscription = observableAnalysis.subscribe(analyses => {
        this.navigateToHistoryAnalysis(analysisType, packagingUnitId, versionId, analyses[0].id ?? '');
      });
    }
  }

  private stopWhenAnalysisNotPossible(): void {
    this.spinner.hide();
    const alertDialogConfig = getDialogConfig({ packagingPart: PackagingPart.Unit }, '600px');
    this.dialog.open(AnalysisNotPossibleDialogComponent, alertDialogConfig);
  }

  navigateToHistoryAnalysis(
    analysisType: AnalysisType, packagingId: number, packagingVersionId: number, analysisVersionId: string
  ) {
    switch (analysisType) {
      case AnalysisType.Recyclability: {
        this.router.navigate([PathResolveService.getRecyclabilityAnalysisHistoryPathUnit(
          true, packagingId, packagingVersionId, analysisVersionId)]);
        break;
      }
      case AnalysisType.Lifecycle: {
        this.router.navigate([PathResolveService.getLifecycleAnalysisHistoryPathUnit(
          true, packagingId, packagingVersionId, analysisVersionId)]);
        break;
      }
    }
  }

  navigateToComparison(listOfIdsToCompare: number[], creditsRequired: number) {
    //No individual Credits for Compare available
    if (this.creditsService.creditsCount.recyclabilityCredits < creditsRequired) {
      this.stopWhenCreditsInsufficient();
    } else {
      this.router.navigate([PathResolveService.getCompareRecyclabilityAnalysisPathUnit(true, listOfIdsToCompare)]);
    }
  }

  navigateToPackagingUnitPreview(candidateId: number, isChange = false, seePackagingUnitBeforeChange = false) {
    this.router.navigate([PathResolveService.getPackagingUnitPreviewPath(true, candidateId, isChange, seePackagingUnitBeforeChange)]);
  }
}
