import { ComponentReportDto } from 'src/app/data-transfer/entities/component-entities/component-report-dto';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ParentApiService } from './parent-api-service';
import { environment } from 'src/environments/environment';
import { PackagingUnitReportDto } from '../entities/packaging-unit-entities/packaging-unit-report-dto';
import { ReportGenerationDto } from '../entities/report-generation-dto';

@Injectable({ providedIn: 'root' })
export class ReportApiService extends ParentApiService {

  constructor(private http: HttpClient) { super(); }

  // Templates

  public getPackagingUnitReportTemplateById(templateId: number): Observable<PackagingUnitReportDto> {
    const url = `${environment.webApiAddress}/api/packaging-unit-report-profiles/by-id?id=${templateId}`;
    return this.http.get<PackagingUnitReportDto>(url);
  }

  public savePackagingUnitReportTemplate(dto: PackagingUnitReportDto, stayOnPageIfError = true): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-unit-report-profiles`;
    return this.http.put<any>(url, dto, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

  public deletePackagingUnitReportTemplate(templateId: number): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-unit-report-profiles?id=${templateId}`;
    return this.http.delete(url);
  }

  public getAllPackagingUnitReportTemplates(): Observable<PackagingUnitReportDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-unit-report-profiles`;
    return this.http.get<PackagingUnitReportDto[]>(url);
  }

  public getComponentReportTemplateById(templateId: number): Observable<ComponentReportDto> {
    const url = `${environment.webApiAddress}/api/packaging-component-report-profiles/by-id?id=${templateId}`;
    return this.http.get<ComponentReportDto>(url);
  }

  public saveComponentReportTemplate(dto: ComponentReportDto, stayOnPageIfError = true): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-component-report-profiles`;
    return this.http.put<any>(url, dto, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

  public deleteComponentReportTemplate(templateId: number): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-component-report-profiles?id=${templateId}`;
    return this.http.delete(url);
  }

  public getAllComponentReportTemplates(): Observable<ComponentReportDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-component-report-profiles`;
    return this.http.get<ComponentReportDto[]>(url);
  }

  // Reports

  public generatePackagingUnitReport(dto: ReportGenerationDto): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-unit-reports`;
    return this.http.post<any>(url, dto, { responseType: 'Blob' as 'json' } );
  }

  public getAllPackagingUnitReports(): Observable<PackagingUnitReportDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-unit-reports`;
    return this.http.get<PackagingUnitReportDto[]>(url);
  }

  public getAllPackagingUnitReportsById(packagingUnitId?: number): Observable<PackagingUnitReportDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-unit-reports/by-packaging-unit?packagingUnitId=${packagingUnitId}`;
    return this.http.get<PackagingUnitReportDto[]>(url);
  }

  public downloadPackagingUnitReport(reportId: number): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-unit-reports/download?id=${reportId}`;
    return this.http.get<any>(url, { responseType: 'blob' as 'json' });
  }

  public deletePackagingUnitReport(reportId: number): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-unit-reports?id=${reportId}`;
    return this.http.delete(url);
  }

  public generateComponentReport(dto: ReportGenerationDto): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-component-reports`;
    return this.http.post<any>(url, dto, { responseType: 'Blob' as 'json' } );
  }

  public getAllComponentReports(): Observable<ComponentReportDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-component-reports`;
    return this.http.get<ComponentReportDto[]>(url);
  }

  public getAllComponentReportsById(componentId?: number): Observable<ComponentReportDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-component-reports/by-packaging-component?packagingComponentId=${componentId}`;
    return this.http.get<ComponentReportDto[]>(url);
  }

  public downloadComponentReport(reportId: number): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-component-reports/download?id=${reportId}`;
    return this.http.get<any>(url, { responseType: 'blob' as 'json' });
  }

  public deleteComponentReport(reportId: number): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-component-reports?id=${reportId}`;
    return this.http.delete(url);
  }

  public generateUsageReport():  Observable<Blob> {
    const url = `${environment.webApiAddress}/api/usage/generate-report`;
    return this.http.post(url,null, { responseType: 'blob'  });
  }
}
