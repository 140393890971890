import { getDialogConfig } from 'src/app/util/dialog-util';
import { TagDto } from './../../data-transfer/entities/tag-dto';
import { PackagingRoutingData } from './../../components/packaging-unit/create-update/common/packaging-routing-wrapper/packaging-routing-wrapper.component';
import { HttpErrorResponse } from '@angular/common/http';
import { ComponentHandler } from 'src/app/services/component-services/component-handler';
import { DirectoryDto } from './../../data-transfer/entities/directory-dto';
import { FillingGoodTypeDto } from '../../data-transfer/entities/filling-good-type-dto';
import { SimpleAlertDialogComponent, SimpleDialogData } from '../../components/dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { MaterialManifestationDto } from '../../data-transfer/entities/material-manifestation-dto';
import { TranslateService } from '@ngx-translate/core';
import { CountryDto } from '../../data-transfer/entities/country-dto';
import { FillingGoodCategoryDto } from '../../data-transfer/entities/filling-good-category-dto';
import { FillingGoodDto } from '../../data-transfer/entities/filling-good-dto';
import { Injectable } from '@angular/core';
import { MaterialDataHandler } from '../material-services/material-data-handler';
import { ColorDto } from '../../data-transfer/entities/color-dto';
import { MaterialRoutingData } from 'src/app/components/packaging-component-material/create-update/common/material-routing-wrapper/material-routing-wrapper.component';
import { ComponentRoutingData } from 'src/app/components/packaging-component/create-update/common/component-routing-wrapper/component-routing-wrapper.component';
import { MatDialog } from '@angular/material/dialog';
import { PackagingCategoryMaterialFunctionDto } from 'src/app/data-transfer/entities/material-function-dto';
import { CountriesService } from 'src/app/navigation/services/countries-service';
import { ComponentTypeService } from 'src/app/navigation/services/component-type-service';
import { PackagingPart } from 'src/app/model/packaging-part-enum';

@Injectable()
export class CreateUpdateHandler {

  allColors: ColorDto[] = [];
  materialFunctions: PackagingCategoryMaterialFunctionDto[] = [];
  manufacturingCountries: CountryDto[] = [];
  assemblyCountries: CountryDto[] = [];
  distributionCountries: CountryDto[] = [];
  fillingGoods: FillingGoodDto[] = [];
  fillingGoodCategories: FillingGoodCategoryDto[] = [];
  fillingGoodTypes: FillingGoodTypeDto[] = [];
  allManifestations: MaterialManifestationDto[] = [];
  rootDirectory!: DirectoryDto;
  allTags!: TagDto[];

  constructor(
    private materialDataHandler: MaterialDataHandler,
    private countriesService: CountriesService,
    public componentHandler: ComponentHandler,
    public translateService: TranslateService,
    private componentTypeService: ComponentTypeService
  ) { }

  setPackagingHandlerPackagingData(data: PackagingRoutingData) {
    this.fillingGoods = data.fillingGoods ?? [];
    this.fillingGoodCategories = data.fillingGoodCategories ?? [];
    this.fillingGoodTypes = data.fillingGoodTypes ?? [];
    this.assemblyCountries = data.assemblyCountries ?? [];
  }

  setPackagingHandlerMaterialData(data: MaterialRoutingData) {
    this.materialFunctions = data.materialFunctions ?? [];
    this.manufacturingCountries = data.manufacturingCountries ?? [];
    this.distributionCountries = data.distributionCountries ?? [];
    this.allManifestations = data.allManifestations ?? [];
    this.allColors = data.allColors ?? [];
    this.rootDirectory = data.rootDirectory;
    this.allTags = data.allTags;
  }

  setComponentHandlerComponentData(data: ComponentRoutingData) {
    this.componentHandler.setComponentsData(data, this.countriesService, this.componentTypeService);
  }

  setComponentHandlerMaterialData(data: MaterialRoutingData) {
    this.componentHandler.setMaterialData(data);
  }

  translation = (text: string) => this.translateService.instant(text);

  getFillingGoodSubcategory(fillingGoodId: number, allFillingGoods: FillingGoodDto[]): number {
    const categoryId = allFillingGoods.find(x => x.id === fillingGoodId)?.categoryId ?? -1;
    return categoryId;
  }

  getFillingGoodCategory(
    fillingGoodId: number, allFillingGoods: FillingGoodDto[], allFillingGoodCategories: FillingGoodCategoryDto[]
  ): number {
    const categoryId = allFillingGoods.find(x => x.id === fillingGoodId)?.categoryId;
    const typeId = allFillingGoodCategories.find(x => x.id === categoryId)?.typeId ?? -1;
    return typeId;
  }

  getMaterialFunctions(packagingTypeId: number, componentTypeId: number) {
    return this.materialDataHandler.getMaterialFunctions(this.materialFunctions, packagingTypeId, componentTypeId);
  }

  getPackagingUnitSucessDialogData() {
    return {
      title: this.translation('common.text.saved'),
      messages: [this.translation('packagingUnit.messages.packagingUnitSaved')], icon: 'save'
    };
  }

  getComponentSucessDialogData() {
    return this.componentHandler.getComponentSucessDialogData();
  }

  getFailureDialogData(error: HttpErrorResponse): SimpleDialogData {
    return {
      title: this.translation(error.status >= 500 ? 'errors.serverCommunicationError' : 'common.text.error'),
      messages: [this.translation('packagingUnit.errors.packagingUnitNotSaved'), error?.error?.error], icon: 'error'
    };
  }

  getInvalidDialogData(): SimpleDialogData {
    return {
      title: this.translation('common.text.error'),
      messages: [this.translation('errors.notAllFieldsFilled')], icon: 'error'
    };
  }

  getCreateNewVersionDialogData(): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.information'),
      messages: [this.translateService.instant('messages.createNewVersion')],
      icon: 'info'
    };
  }

  getOverwriteConfirmationDialogData(packagingPart: number): SimpleDialogData {
    let parentOverwriteMessage = '';
    switch (packagingPart) {
      case PackagingPart.System: parentOverwriteMessage = this.translateService.instant('messages.changesOnOverwriteSystem');
        break;
      case PackagingPart.Unit: parentOverwriteMessage = this.translateService.instant('messages.changesOnOverwriteUnit');
        break;
      case PackagingPart.Material: parentOverwriteMessage = this.translateService.instant('messages.changesOnOverwriteMaterial');
        break;
    }
    return {
      title: this.translateService.instant('common.text.information'),
      messages: [
        parentOverwriteMessage,        
        this.translateService.instant('messages.deleteAnalysesOnOverwrite'),
      ],
      icon: 'info'
    };
  }

  showLockedPackagingUnitDialog(dialog: MatDialog) {
    this.showLockedDialog(dialog, 'packagingUnit.messages.packagingUnitLocked');
  }

  showLockedMaterialDialog(dialog: MatDialog) {
    this.showLockedDialog(dialog, 'packagingUnit.messages.compositeMaterialLocked');
  }

  private showLockedDialog(dialog: MatDialog, messageSource: string) {
    const data: SimpleDialogData = {
      title: this.translation('common.text.locked'),
      messages: [this.translation(messageSource)],
      icon: 'desktop_access_disabled'
    };
    const alertDialogConfig = getDialogConfig(data, '350px');
    dialog.open(SimpleAlertDialogComponent, alertDialogConfig);
  }
}
