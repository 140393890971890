<div class="outer-container">
  <h1 class="mat-headline-3">{{ 'home.componentHome.title' | translate }}</h1>

  <div id="add-container">
    <div id="add-button-container">
      <button id="add-button" mat-raised-button *ngIf="!isUserValidator" (click)="createNewComponent()" color="primary"
        type="button">
        <mat-icon>add</mat-icon>
        {{ 'home.componentHome.addComponentButton' | translate }}
      </button>
    </div>
    <div id="components-image-container">
      <img id="components-image" [src]="componentsImageSource">
    </div>
  </div>

  <div id="type-container">
    <mat-button-toggle-group #componentGroup="matButtonToggleGroup" [value]="selectedComponentTypeId"
      (change)="onComponentTypeChange(componentGroup.value)">
      <mat-button-toggle class="component-selection-button" *ngFor="let componentCategory of componentCategories"
        [value]="componentCategory.key">
        <img class="type-image" [src]="getImageForComponentType(componentCategory.key)">
        <div class="type-label">
          {{ 'component.' + componentCategory.label | translate }}
        </div>
      </mat-button-toggle>
    </mat-button-toggle-group>
    <mat-button-toggle-group *ngIf="componentGroup.value === 1" #mainBodygroup="matButtonToggleGroup"
      [value]="selectedPackagingUnitTypeId" (change)="onPackagingUnitTypeChange(mainBodygroup.value)"
      id="selection-button-group">
      <mat-button-toggle class="packaging-selection-button" *ngFor="let packagingUnitType of orderedPackagingUnitTypes"
        [value]="packagingUnitType.id">
        <img class="type-image" [src]="getImageForPackagingUnitType(packagingUnitType.id)">
        <div class="icon-text-container centered">
          <span class="packaging-type-text">{{packagingUnitType.name}}</span>
          <mat-icon *ngIf="packagingUnitType.id === genericTypeId" class="gray-icon-16"
            matTooltip="{{'home.tooltips.other' | translate}}">info
          </mat-icon>
        </div>
      </mat-button-toggle>
    </mat-button-toggle-group>
  </div>

  <div class="inner-container">
    <div class="table-container">
      <app-packaging-components-overview [dataSource]="dataSource" [displayedColumns]="displayedColumns"
        [isUserValidator]="isUserValidator">
      </app-packaging-components-overview>
      <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
    </div>
  </div>
</div>