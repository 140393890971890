import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ComponentReportDto } from 'src/app/data-transfer/entities/component-entities/component-report-dto';
import { PackagingUnitReportDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-report-dto';

@Component({
  selector: 'app-report-templates-home',
  templateUrl: './report-templates-home.component.html',
  styleUrls: ['./report-templates-home.component.scss']
})
export class ReportTemplatesHomeComponent implements OnInit, OnDestroy {

  packagingUnitReportTemplates!: PackagingUnitReportDto[];
  componentReportTemplates!: ComponentReportDto[];
  callerPart = PackagingPart;

  displayedColumns: string[] = ['id', 'profileName', 'creationDateString', 'actions'];

  private routeDataSubscription?: Subscription;

  constructor(private route: ActivatedRoute) { }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.data.subscribe(data => {
      this.packagingUnitReportTemplates = data.packagingUnitReportTemplates;
      this.componentReportTemplates = data.componentReportTemplates;
    });
  }

  ngOnDestroy(): void {
    this.routeDataSubscription?.unsubscribe();
  }
}
