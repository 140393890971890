import { FormGroup, FormBuilder, FormArray, FormControl } from '@angular/forms';
import { PackagingSystemApiService } from '../../../../data-transfer/services/packaging-system-api-service';
import { PackagingSystemDto } from './../../../../data-transfer/entities/packaging-system-entities/packaging-system-dto';
import { Component, EventEmitter, Inject, Input, LOCALE_ID, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { OverviewTableComponent } from 'src/app/components/shared-components/parent-components/overview-table/overview-table.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { DirectoryApiService } from 'src/app/data-transfer/services/directory-api-service';
import { ImportExportApiService } from 'src/app/data-transfer/services/import-export-api-service';
import { CreditsService } from 'src/app/services/credits-service';
import { PackagingSystemNavigationService } from 'src/app/navigation/services/navigation-services/packaging-system-navigation.service';
import { forkJoin } from 'rxjs';
import { PermissionTypeDto } from 'src/app/data-transfer/entities/permission-type-dto';
import { SimpleAlertDialogComponent, SimpleDialogData } from 'src/app/components/dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { DeleteDialogData, DeleteItemDialogComponent } from 'src/app/components/dialogs/delete-item-dialog/delete-item-dialog.component';
import { DeletedEntitiesDto } from 'src/app/data-transfer/entities/deleted-entities-dto';
import { DirectoryDto } from 'src/app/data-transfer/entities/directory-dto';
import { SelectLocationDialogComponent } from '../../dialogs/select-location-dialog/select-location-dialog.component';
import { PackagingSystemInfoDto } from 'src/app/data-transfer/entities/packaging-system-entities/packaging-system-info-dto';
import { DialogActions } from 'src/app/model/dictionary';
import { HistoryWrapperLifeCycle } from 'src/app/data-transfer/entities/evaluation-entities/history-wrapper-life-cycle';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { AnalysisApiService } from 'src/app/data-transfer/services/analysis-api-service';
import { SimpleConfirmDialogComponent } from 'src/app/components/dialogs/simple-confirm-dialog/simple-confirm-dialog.component';
import { HistoryWrapperRecyclability } from 'src/app/data-transfer/entities/evaluation-entities/history-wrapper-recyclability';
import { ComparisonDataService } from 'src/app/navigation/services/comparison-data-service';
import { UserApiService } from 'src/app/data-transfer/services/user-api-service';
import { EditQuantitiesDialogComponent } from '../quantities/edit-quantities-dialog/edit-quantities-dialog.component';
import { EditQuantityDialogComponent } from '../quantities/edit-quantity-dialog/edit-quantity-dialog.component';

@Component({
  selector: 'app-packaging-systems-overview',
  templateUrl: './packaging-systems-overview.component.html',
  styleUrls: ['./packaging-systems-overview.component.scss']
})
export class PackagingSystemsOverviewComponent extends OverviewTableComponent implements OnInit, OnChanges {

  @Input() dataSource: MatTableDataSource<PackagingSystemInfoDto> | MatTableDataSource<PackagingSystemDto>;
  @Input() displayedColumns: string[] = [];
  @Input() public analysisWrappersRec?: HistoryWrapperRecyclability[];
  @Input() public analysisWrappersLca?: HistoryWrapperLifeCycle[];
  @Input() isUserValidator = false;
  @Input() isRecyclingBinSelected = false;
  @Input() sortByColumnName?: string;
  @Input() form: FormGroup = this.formBuilder.group({ quantityForms: this.formBuilder.array([]) });

  @Output() dataChanged = new EventEmitter();
  @Output() distributionCountryAdded = new EventEmitter();

  selectedPackagingSystems: PackagingSystemInfoDto[] = [];

  constructor(
    private packagingSystemApiService: PackagingSystemApiService,
    private formBuilder: FormBuilder,
    private analysisApiService: AnalysisApiService,
    private comparisonDataService: ComparisonDataService,
    private userApiService: UserApiService,
    protected navigationService: PackagingSystemNavigationService,
    protected directoryApiService: DirectoryApiService,
    protected importExportApiService: ImportExportApiService,
    protected dialog: MatDialog,
    protected translateService: TranslateService,
    protected spinner: NgxSpinnerService,
    protected creditsService: CreditsService,
    @Inject(LOCALE_ID) public locale: string
  ) {
    super(dialog, directoryApiService, importExportApiService, navigationService, translateService, spinner, creditsService, locale);
    this.dataSource = new MatTableDataSource<PackagingSystemInfoDto>();
  }

  get quantityForms(): FormArray {
    return this.form.controls.quantityForms as FormArray;
  }

  ngOnInit(): void {
    if (this.isUserValidator) {
      this.displayedColumns.push('validation');
      this.displayedColumns.push('organizationId');
      this.displayedColumns.push('creationTimestamp');
      this.organizationsSubscription = this.userApiService.getOrganizations()
        .subscribe(organizations => this.organizations = organizations);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource != null) {
      super.setDataSourceFilter(this.dataSource);
      this.dataSourceSubscription = this.dataSource.connect().subscribe(_ => {
        this.updateDisplayTrackedColumn();
      });
    }
  }

  updateDisplayTrackedColumn() {
    super.updateDisplayTrackedColumn(this.dataSource);
  }

  navigateToPackagingSystem(packagingSystem: PackagingSystemDto) {
    if (packagingSystem.id == null) { return; }
    this.navigationService.navigateToPackagingSystem(packagingSystem.id);
  }

  private openDeleteDialog(packagingSystems: PackagingSystemInfoDto[]) {
    const dialogData: DeleteDialogData = {
      dialogHeader: this.translateService.instant('packagingSystem.deletePackagingSystemHeader'),
      dialogText: this.translateService.instant('packagingSystem.deletePackagingSystemText')
    };
    const dialogRef = this.dialog.open(DeleteItemDialogComponent, getDialogConfig(dialogData, '500px'));
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.DELETE) {
        this.deletePackagingSystems(packagingSystems);
      }
    });
  }

  emptyRecyclingBin() {
    if (!this.isRecyclingBinSelected) {
      console.log('PackagingSystemsOverviewComponent: Recycling bin must be selected prior to emptying.');
      return;
    }
    this.deletePackagingSystems(this.dataSource.data);
  }

  private deletePackagingSystems(packagingSystems: PackagingSystemInfoDto[]) {
    this.spinner.show();
    const deletePermamently = this.isRecyclingBinSelected;
    const toDeleteDto: DeletedEntitiesDto = {
      deleted: packagingSystems.map(x => ({ id: x.id ?? -1, version: x.version ?? -1 }))
    };
    this.deleteBackendSubscription = this.packagingSystemApiService.deletePackagingSystems(toDeleteDto, deletePermamently, true)
      .subscribe(_ => {
        this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(this.getDeleteSuccessData(), '500px'));
        this.dataSource.data = this.dataSource.data.filter(value => {
          if (value.id != null) { return !toDeleteDto.deleted.map(x => x.id).includes(value.id); }
        });
        this.spinner.hide();
      }, error => {
        let errorData;
        if (error.status === 403) {
          errorData = this.getDeletionImpossibleData();
        } else {
          errorData = this.getDeleteErrorData();
        }
        this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(errorData, '500px'));
        this.spinner.hide();
      });
  }

  selectAllPackagingSystems() {
    this.allItemsSelected = !this.allItemsSelected;
    this.selectedPackagingSystems = [];
    if (this.allItemsSelected) {
      for (const element of this.dataSource.data) {
        this.selectedPackagingSystems.push(element);
      }
    }
    this.packagingPartsSelected.emit(this.selectedPackagingSystems);
  }

  isPackagingSystemSelected(item: PackagingSystemInfoDto): boolean {
    if (this.selectedPackagingSystems && this.selectedPackagingSystems.length > 0) {
      return this.selectedPackagingSystems.includes(item);
    }
    return false;
  }

  setPackagingSystemSelected(checked: boolean, item: PackagingSystemInfoDto) {
    if (checked) {
      this.selectedPackagingSystems.push(item);
    } else {
      const index = this.selectedPackagingSystems.indexOf(item);
      this.selectedPackagingSystems.splice(index, 1);
    }
    this.packagingPartsSelected.emit(this.selectedPackagingSystems);
  }

  movePackagingPart(rootFolder: DirectoryDto, unreachableFolders: DirectoryDto[]) {
    const data = { rootFolder, unreachableFolders };
    const dialogConfig = getDialogConfig(data, '500px');
    const dialogRef = this.dialog.open(SelectLocationDialogComponent, dialogConfig);
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.REJECT) { return; }
      const observables = this.selectedPackagingSystems.map(packagingSystem => {
        if (packagingSystem.id != null && packagingSystem.version != null) {
          return this.directoryApiService.movePackagingSystem(result.data.id, packagingSystem.id, packagingSystem.version);
        }
      });
      this.moveBackendSubscription = forkJoin(observables).subscribe(_ => this.dataChanged.emit());
    });
  }

  copyPackagingPart(rootFolder: DirectoryDto, unreachableFolders: DirectoryDto[]) {
    const data = { rootFolder, unreachableFolders };
    const dialogConfigFolderSelection = getDialogConfig(data, '500px');
    const dialogRefFolderSelection = this.dialog.open(SelectLocationDialogComponent, dialogConfigFolderSelection);
    this.dialogSubscription = dialogRefFolderSelection.afterClosed().subscribe(foldersResult => {
      if (foldersResult.event === DialogActions.REJECT) { this.spinner.hide(); return; }
      const observables = this.selectedPackagingSystems.map(packagingSystem => {
        if (packagingSystem.id != null && packagingSystem.version != null) {
          return this.directoryApiService.copyPackagingSystem(foldersResult.data.id, packagingSystem.id, packagingSystem.version);
        }
      });
      this.copyBackendSubscription = forkJoin(observables).subscribe(_ => this.dataChanged.emit(), _ => this.spinner.hide());
    });
  }

  deletePackagingPart(itemsToDelete = this.selectedPackagingSystems) {
    const observables = itemsToDelete.map(packaging => {
      if (packaging.id != null) { return this.packagingSystemApiService.getPackagingSystemPermissions(packaging.id); }
    });
    this.permissionsSubscription = forkJoin(observables).subscribe((permissionsList: PermissionTypeDto[]) => {
      if (permissionsList.filter(x => x.delete === false).length > 0) {
        this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(this.getDeleteNotAllowedData(this.translateService), '500px'));
      } else {
        this.openDeleteDialog(itemsToDelete);
      }
    });
  }

  exportPackagingPart() {
    this.spinner.show();
    const observables = this.selectedPackagingSystems.map(packagingSystem => {
      return this.analysisApiService.isRecyclabilityAnalysisPossible(packagingSystem.id, true, PackagingPart.System);
    });
    this.recSubscription = forkJoin(observables).subscribe(returnList => {
      const proceedWithExport = !returnList.includes(false);

      const dialogConfig = getDialogConfig({ packagingSystems: this.selectedPackagingSystems }, '50%');

      if (proceedWithExport) { this.doExport(dialogConfig); }
      else {
        const data: SimpleDialogData = {
          title: this.translateService.instant('common.text.warning'),
          messages: [this.translateService.instant('warnings.exportIncompletePackagingSystem')], icon: 'warning'
        };
        const confirmDialogConfig = getDialogConfig(data, '350px');
        const dialogRef = this.dialog.open(SimpleConfirmDialogComponent, confirmDialogConfig);
        this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
          if (result.event === DialogActions.REJECT) {
            this.spinner.hide();
            return;
          } else {
            this.doExport(dialogConfig);
          }
        });
      }
    });
  }

  private doExport(dialogConfig: MatDialogConfig) {
    const exportProfilesFunction = () => this.importExportApiService.getPackagingSystemExportProfiles();
    const exportFunction = (packagingId: number, principalId: number, profileId?: number, subprofileId?: number) =>
      this.importExportApiService.exportPackagingSystem(packagingId, principalId, profileId, subprofileId, true);
    super.exportItems(dialogConfig, exportProfilesFunction, exportFunction, true);
    this.resetSelection();
  }

  restorePackagingPart() {
    this.restorePackagingSystems(this.selectedPackagingSystems);
  }

  restoreAllPackagingSystems() {
    this.restorePackagingSystems(this.dataSource.data);
  }

  getFormControlForQuantity(element: PackagingSystemDto) {
    return (this.quantityForms.controls.find(control => control.value.id === element.id) as FormGroup).controls.quantity as FormControl;
  }

  addCountryToPackaging(packagingUnit: PackagingSystemDto) {
    this.distributionCountryAdded.emit({ id: packagingUnit.id, version: packagingUnit.version });
  }

  private restorePackagingSystems(packagingSystems: PackagingSystemInfoDto[]) {
    if (!this.isRecyclingBinSelected) {
      console.log('PackagingSystemsOverviewComponent: Recycling bin must be selected prior to restoring.');
      return;
    }
    for (const packagingSystem of packagingSystems) {
      this.dataSource.data = this.dataSource.data.filter(value => {
        return value.id !== packagingSystem.id;
      });
      if (packagingSystem.id != null && packagingSystem.version != null) {
        this.restoreSubscription = this.directoryApiService.restorePackagingSystemFromRecyclingBin(
          packagingSystem.id, packagingSystem.version).subscribe(_ => {
            window.location.reload();
          });
      }
    }
  }

  getAnalysisWrappersRecForPackaging(packagingSystemId: number) {
    return this.analysisWrappersRec?.find(x => x.id === packagingSystemId) ?? undefined;
  }

  getAnalysisWrappersLcaForPackaging(packagingSystemId: number) {
    return this.analysisWrappersLca?.find(x => x.id === packagingSystemId) ?? undefined;
  }

  getAnalysisIdForPackaging(packagingSystemId: number) {
    if (this.analysisWrappersRec) {
      return this.analysisWrappersRec?.find(x => x.id === packagingSystemId)?.analysisId ?? undefined;
    } else {
      return this.analysisWrappersLca?.find(x => x.id === packagingSystemId)?.analysisId ?? undefined;
    }
  }

  comparePackagingPart() {
    const observablesPermissions = this.selectedPackagingSystems.map(packagingSystem => {
      if (packagingSystem.id != null) {
        return this.packagingSystemApiService.getPackagingSystemPermissions(packagingSystem.id);
      }
    });
    this.permissionsSubscription = forkJoin(observablesPermissions).subscribe((permissionsList: PermissionTypeDto[]) => {
      const isComparisonPermitted = permissionsList.map(x => x.analyze).find(x => x === false) === undefined;
      if (!isComparisonPermitted) {
        this.navigationService.stopWhenAnalysisNotPermitted();
        return;
      }
      const listOfIds: number[] = this.selectedPackagingSystems.filter(x => x.id != null).map(x => x.id ?? -1);
      this.comparisonDataService.setPackagingVersionMapping(this.selectedPackagingSystems);

      this.navigationService.navigateToComparison(listOfIds, 0);
    });
  }

  editPackagingPartQuantity() {
    const dialogConfig = getDialogConfig({ packagingSystems: this.selectedPackagingSystems }, '900px');
    const dialogType: any = this.selectedPackagingSystems.length > 1 ? EditQuantitiesDialogComponent : EditQuantityDialogComponent;
    this.dialog.open(dialogType, dialogConfig);
  }

  getTagsSettingFunction(isEditing: boolean) {
    return (id: number, tagIds: number[]) => isEditing ?
      this.packagingSystemApiService.setPackagingSystemTags(id, tagIds) :
      this.packagingSystemApiService.addPackagingSystemTags(id, tagIds);
  }

  resetSelection() {
    this.selectedPackagingSystems = [];
    this.packagingPartsSelected.emit(this.selectedPackagingSystems);
  }

  private getDeleteSuccessData(): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.success'),
      messages: [this.translateService.instant('dataManagement.directory.dialog.removal.packagingSystemsDeletedSuccess')], icon: 'info'
    };
  }

  private getDeletionImpossibleData(): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.error'),
      messages: [this.translateService.instant('packagingSystem.messages.packagingSystemInUse')],
      icon: 'error'
    };
  }

  private getDeleteErrorData(): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.error'),
      messages: [this.translateService.instant('dataManagement.directory.dialog.removal.packagingSystemsDeletedError')], icon: 'error'
    };
  }
}
