import { Injectable } from '@angular/core';
import { Moment } from 'moment';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateHelperService {

  constructor() { }

  public getDateInDays(date: Date, days: number): Date {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate() + days);
  }

  public getDateInDaysUTC(date: Date, days: number): Date {
    return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate() + days));
  }

  public getMondayOfCurrentWeek(): Date {
    const date = new Date();
    const monday = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    monday.setDate(monday.getDate() - (monday.getDay() + 6) % 7);
    return monday;
  }

  public getDateInDaysMoment(date: Moment, days: number): Moment {
    let newDate = moment(date);
    newDate = newDate.startOf('day').add(days, 'days');
    return newDate;
  }

  public getDateInDaysUTCMoment(date: Moment, days: number): Moment {
    let newDate = moment(date);
    newDate = this.getDateInDaysMoment(newDate, days);
    return moment.utc({year: newDate.year(), month: newDate.month(), date: newDate.date()} as moment.MomentInput);
  }

  public getMondayOfCurrentWeekMoment(): Moment {
    const date = moment().startOf('isoWeek');
    return date;
  }
}
