const MAX_COLOR = 'ff';
const MIN_COLOR = '00';

export class ColorGradientCalculator {

    static getGreenToRedGradient(numberOfEntries: number): string[] {
        const returnArray = [];
        const numberOfColors = numberOfEntries > 1 ? numberOfEntries - 1 : 1;
        const coefficient = Math.floor(0xff / numberOfColors);
        let green;
        let red;
        const middleIndex = (numberOfEntries - 1) / 2;
        for (let index = 0; index < numberOfEntries; index++) {
            let blue = MIN_COLOR;
            if (index < middleIndex) {
                green = MAX_COLOR;
                const toAdd = coefficient * (index * 2);
                red = (0x00 + toAdd).toString(16);
            } else if (index === middleIndex && numberOfEntries > 1) {
                red = MAX_COLOR;
                green = MAX_COLOR;
            } else if (index === middleIndex && numberOfEntries === 1) {
                green = MAX_COLOR;
                red = MIN_COLOR;
            } else {
                red = MAX_COLOR;
                const toSubstract = 255 - coefficient * ((numberOfEntries - (index + 1)) * 2) ;
                green = (0xff - toSubstract).toString(16);
            }

            if (red.length === 1) { red = '0' + red; blue = '55'; }
            if (green.length === 1) { green = '0' + green; blue = '55'; }

            returnArray.push('#' + red + green + blue);
        }
        return returnArray;
    }
}
