import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { PROJECT_IMAGES_PATH } from 'src/app/model/paths';
import { ColorThemeService, COLOR_THEME_DARK } from 'src/app/navigation/services/color-theme-service';

@Component({
  selector: 'app-surface-ratio-info',
  templateUrl: './surface-ratio-info.component.html',
  styleUrls: ['./surface-ratio-info.component.scss']
})
export class SurfaceRatioInfoComponent implements OnDestroy {
  
  imageSource = '';
  isDarkTheme = false;

  private themeSubscription?: Subscription;
  
  constructor(
    private colorThemeService: ColorThemeService
  ) {
    this.themeSubscription = this.colorThemeService.colorThemeSubject.subscribe((nextValue) => {
      this.isDarkTheme = nextValue === COLOR_THEME_DARK;
      this.imageSource = `${PROJECT_IMAGES_PATH}surfaceRatios/surfaceRatioInfo${this.isDarkTheme ? '_dark' : ''}.svg`;
    });
  }

  ngOnDestroy(): void {
    this.themeSubscription?.unsubscribe();
  }
}
