import { AuthService } from 'src/app/services/auth-service';
import { PathResolveService } from './path-resolve.service';
import { inject } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, CanActivateFn } from '@angular/router';
import { LocationStrategy } from '@angular/common';

export const authGuard: CanActivateFn = async (_: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  const loginPath = PathResolveService.getLoginPath();
  const isLogin = state.url.slice(1).startsWith(loginPath);
  const isLandingPage = state.url.slice(1).startsWith('cockpit/landing-page');
  const router = inject(Router);
  const authService = inject(AuthService);
  const locationStrategy = inject(LocationStrategy);
  const isLoggedIn = await authService.isSignedIn();
  let isContractSigned = false;
  let isUserActivated = false;
  let isUserAdmin = false;
  let hasUserChanged = false;

  if (isLoggedIn) {
    if (!authService.isInitialized) {
      await authService.initSerivce();
    }
    hasUserChanged = await authService.hasUserChanged();
    isContractSigned = authService.isContractSigned();
    isUserAdmin = authService.isUserAdmin();
    isUserActivated = !authService.isUserInactive();
  }

  if (hasUserChanged) {
    const baseHref = locationStrategy.getBaseHref();
    window.location.href = baseHref;
    return false;
  } else if (!isLoggedIn && !isLogin) {
    router.navigate([loginPath]);
    return false;
  } else if (isLoggedIn && isLogin) {
    router.navigate([PathResolveService.getCockpitPath()]);
    return false;
  } else if (isLoggedIn && ((isUserActivated && isContractSigned) || isUserAdmin) && isLandingPage) {
    router.navigate([PathResolveService.getCockpitPath()]);
    return false;
  } else if (isLoggedIn && (!isUserActivated || !isContractSigned) && !isUserAdmin && !isLandingPage) {
    router.navigate(['/', PathResolveService.getCockpitPath(), 'landing-page']);
    return false;
  } else {
    return true;
  }
};
