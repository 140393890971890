import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CreditsDto } from 'src/app/data-transfer/entities/credits-dto';
import { DialogActions } from 'src/app/model/dictionary';

@Component({
  selector: 'app-assign-credits-dialog',
  templateUrl: './assign-credits-dialog.component.html',
  styleUrls: ['./assign-credits-dialog.component.scss']
})
export class AssignCreditsDialogComponent {

  currentCreditsCount: CreditsDto;
  additionalCredits : CreditsDto = new CreditsDto();

  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    private dialogRef: MatDialogRef<AssignCreditsDialogComponent>
  ) {
    this.currentCreditsCount = this.data.creditsCount;
  }

  getTotal(capAtZero : boolean = true) : CreditsDto {
    const total : CreditsDto = new CreditsDto();
    total.lcaCredits = this.getTotalLca(capAtZero);
    total.recyclabilityCredits = this.getTotalRecyclability(capAtZero);
    return total;
  }
  getTotalLca(capAtZero : boolean = true) : number{
    const total = (this.currentCreditsCount.lcaCredits ?? 0) + (this.additionalCredits.lcaCredits ?? 0);
    return capAtZero ? (total > 0 ? total : 0) : total;
  }

  getTotalRecyclability(capAtZero : boolean = true) : number{
    const total = (this.currentCreditsCount.recyclabilityCredits ?? 0) + (this.additionalCredits.recyclabilityCredits ?? 0);
    return capAtZero ? (total > 0 ? total : 0) : total;
  }

  acceptChanges() {
    const total = this.getTotal(false);
    const resultCredits : CreditsDto = new CreditsDto();
    resultCredits.lcaCredits = total.lcaCredits > 0 ? this.additionalCredits.lcaCredits : (this.currentCreditsCount.lcaCredits * -1);
    resultCredits.recyclabilityCredits = total.recyclabilityCredits > 0 ? this.additionalCredits.recyclabilityCredits : (this.currentCreditsCount.recyclabilityCredits * -1);
    this.dialogRef.close({ event: DialogActions.CONFIRM, data: resultCredits });
  }

  discardChanges() {
    this.dialogRef.close({ event: DialogActions.REJECT });
  }
}
