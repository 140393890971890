<h1 mat-dialog-title>{{ 'material.editMaterial' | translate }}</h1>

<mat-dialog-content>
  <div [formGroup]="materialForm">
    <app-composite-material-html-template formControlName="multiMaterialControl" [canEditForm]="canEditForm"
      [isPartOfMaterialImport]="true" (manufacturingCountryChanged)="onManufacturingCountryChanged($event)">
    </app-composite-material-html-template>

    <mat-card>
      <mat-card-content class="table-container-wide">
        <div id="table-container">
          <app-multi-material-table [parentCompositeMaterial]="compositeMaterial"
            [allMaterialFunctions]="allMaterialFunctions" [allColors]="createUpdateHandler.allColors"
            [allManifestations]="createUpdateHandler.allManifestations" [callerId]="callerId"
            [manufacturingCountry]="selectedManufacturingCountry" [packagingUnitTypeId]="packagingUnitTypeId"
            [canEditForm]="canEditForm" [isIndependentMaterial]="false" [isPartOfMaterialImport]="true"
            [isMassRequired]="false" (layersSelected)="onMaterialLayersSelected($event)">
          </app-multi-material-table>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</mat-dialog-content>

<mat-dialog-actions>
  <button [disabled]="materialForm.invalid" (click)="doAction()" mat-raised-button type="submit" color="primary">
    {{ 'common.buttons.save' | translate }}</button>
  <button (click)="closeDialog()" mat-raised-button type="button">
    {{ 'common.buttons.cancel' | translate }}</button>
</mat-dialog-actions>