import { GENERIC_PACKAGING_TYPE_ID, MAIN_BODY_DIFFERS_FROM_PACKAGING_TYPE, PackagingUnitTypeService } from '../../../navigation/services/packaging-unit-type-service';
import { ComponentHandler } from 'src/app/services/component-services/component-handler';
import { PackagingComponentTypesEnum } from 'src/app/model/packaging-component-types-enum';
import { DirectoryDto } from './../../../data-transfer/entities/directory-dto';
import { TranslateService } from '@ngx-translate/core';
import { DirectoryApiService } from '../../../data-transfer/services/directory-api-service';
import { SimpleAlertDialogComponent, SimpleDialogData } from './../../dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { SelectLocationDialogComponent } from './../../directory-tree/dialogs/select-location-dialog/select-location-dialog.component';
import { ComponentApiService } from '../../../data-transfer/services/component-api-service';
import { ComponentTypeService } from './../../../navigation/services/component-type-service';
import { ADD_WITH_TRACKING, SelectionDialogComponentsComponent } from '../../dialogs/selection-dialog-components/selection-dialog-components.component';
import { Subscription, forkJoin } from 'rxjs';
import { Component, Input, OnDestroy, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, FormArray } from '@angular/forms';
import { ComponentTableInfo, PackagingComponentPrototype } from 'src/app/model/components/packaging-component-prototype';
import { PackagingComponentDto } from 'src/app/data-transfer/entities/component-entities/packaging-component-dto';
import { DeleteDialogData, DeleteItemDialogComponent } from '../../dialogs/delete-item-dialog/delete-item-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { DialogActions } from 'src/app/model/dictionary';
import { PackagingComponentEntryDto } from 'src/app/data-transfer/entities/component-entities/packaging-component-entry-dto';
import { ComponentDialogData } from '../../dialogs/additional-packaging-components-parent-dialog/additional-packaging-components-parent-dialog.component';

@Component({
  selector: 'app-additional-packaging-component',
  templateUrl: './additional-packaging.component.html',
  styleUrls: ['./additional-packaging.component.scss'],
})

export class AdditionalPackagingComponent implements OnChanges, OnDestroy {

  @Input() public componentEntries!: PackagingComponentEntryDto[];
  @Input() public handler!: ComponentHandler;
  @Input() public packagingTypeId?: number;
  @Input() public componentType!: number;
  @Input() public isTracked = true;
  @Input() public isUserValidator = false;
  @Input() public isPreview = false;
  @Input() public isDarkTheme = false;
  @Input() isSingleComponent = false;
  @Input() canEditForm = true;
  @Input() displayComponentTypeImage = true;

  @Output() packagingTypeChanged = new EventEmitter();
  @Output() volumeChanged = new EventEmitter();
  @Output() componentCountChanged = new EventEmitter();

  componentsForm!: FormGroup;
  dialogActions = DialogActions;
  tableInfo!: ComponentTableInfo;

  dataSource: MatTableDataSource<FormGroup>;
  displayedColumns: { id: string, text: string }[] = [];
  displayedColumnNames: string[] = [];
  componentTypeImageSource = '';

  private displayTrackedColumn = false;
  private componentPrototype!: PackagingComponentPrototype;
  private translation = (text: string, params?: any) => this.translateService.instant(text, params);

  private dialogSubscription?: Subscription;
  private componentByTypeSubscription?: Subscription;
  private saveComponentSubscription?: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private componentTypeService: ComponentTypeService,
    protected packagingUnitTypeService: PackagingUnitTypeService,
    private componentApiService: ComponentApiService,
    private directoryApiService: DirectoryApiService,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService
  ) {
    this.dataSource = new MatTableDataSource<FormGroup>();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.packagingTypeId) {
      if (changes.packagingTypeId.currentValue == null) {
        this.packagingTypeId = GENERIC_PACKAGING_TYPE_ID;
      }
      if (changes.packagingTypeId.currentValue !== changes.packagingTypeId.previousValue &&
        this.componentType === PackagingComponentTypesEnum.MainBody) {
        this.setComponentImage();
      }
      if (changes.componentEntries) {
        this.componentPrototype = this.handler.components[this.componentType];
        this.tableInfo = this.componentPrototype.getComponentTableInfo(this.translation);
        this.componentsForm = this.componentPrototype.getFormFromComponentEntries(
          this.componentEntries, this.handler, this.formBuilder, this.isTracked);
        this.componentsForm.valueChanges.subscribe(_ => {
          this.communicateComponentChanges();
        })
        this.setTableDataSource();
        this.setTableColumns();
        this.setComponentImage();
      }
    }
  }

  get componentsFormArray(): FormArray { return this.componentsForm?.controls.packagingComponents as FormArray; }

  private setTableDataSource() {
    this.dataSource.data = this.componentsFormArray.controls as FormGroup[];
  }

  private setTableColumns() {
    this.setDisplayTrackedColumn();
    const actionColumn = { id: 'action', text: this.translation('dataManagement.actions') };
    this.displayedColumns = this.tableInfo.columns.concat(actionColumn);
    if (this.displayTrackedColumn) {
      const trackingColumn = { id: 'tracked', text: this.translation('dataManagement.tracking') };
      this.displayedColumns.unshift(trackingColumn);
    }
    this.displayedColumnNames = this.displayedColumns.map(x => x.text);
  }

  private setComponentImage() {
    if (this.componentType === PackagingComponentTypesEnum.MainBody) {
      this.componentTypeImageSource = MAIN_BODY_DIFFERS_FROM_PACKAGING_TYPE.includes(this.packagingTypeId ?? -1) ?
        this.packagingUnitTypeService.getMainBodyTypeImage(this.packagingTypeId) :
        this.packagingUnitTypeService.getPackagingUnitTypeImage(this.packagingTypeId);
    } else {
      this.componentTypeImageSource = this.componentTypeService.getComponentTypeImage(this.componentType, this.isDarkTheme);
    }
  }

  openDeleteDialog(index: number) {
    const dialogData: DeleteDialogData = {
      dialogHeader: this.translation('component.deleteComponentsHeader'),
      dialogText: this.translation('component.deleteComponentsText')
    };
    const dialogConfig = getDialogConfig(dialogData, '500px');
    const dialogRef = this.dialog.open(DeleteItemDialogComponent, dialogConfig);
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event !== DialogActions.DELETE) { return; }
      this.componentsFormArray.removeAt(index);
      this.setTableDataSource();
      this.setTableColumns();
    });
  }

  openAddEditDialog(action: number, index: number) {
    const dialogData = this.getDialogData(action, index);
    const dialogConfig = getDialogConfig(dialogData, '1300px');
    const dialogType = this.componentPrototype.getDialogType();

    const dialogRef = this.dialog.open(dialogType, dialogConfig);

    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.REJECT) { return; }
      const newComponent: FormGroup = result.data;
      if (result.event === DialogActions.ADD) {
        this.componentsFormArray.push(newComponent);
      } else if (result.event === DialogActions.EDIT) {
        this.componentsFormArray.controls[index] = newComponent;
      }
      this.componentsForm.updateValueAndValidity();
      this.componentPrototype.setComponentSpecificTableData(newComponent);
      this.setTableDataSource();
    });
  }

  private getDialogData(action: number, index: number): ComponentDialogData {
    const isEdit = action === DialogActions.EDIT;
    const element = isEdit ?
      (this.componentsFormArray.controls[index] as FormGroup) :
      this.componentPrototype.getFormFromSingleComponentEntry(
        this.componentPrototype.getEmptyComponentEntry(), this.handler, this.formBuilder, this.isTracked);
    const componentSubtypes = this.componentPrototype.getComponentSubtypes();

    const dialogPrototypeData: ComponentDialogData = {
      packagingUnitTypeId: this.packagingTypeId ?? -1,
      action,
      canEditForm: this.canEditForm,
      isTracked: this.isTracked || element?.controls.hasInternalTracking?.value,
      allColors: this.handler.allColors,
      allMaterialFunctions: this.handler.getMaterialFunctions(this.packagingTypeId, this.tableInfo.id),
      allManifestations: this.handler.allManifestations,
      manufacturingCountries: this.handler.manufacturingCountries,
      isPreview: this.isPreview,
      isUserValidator: this.isUserValidator,
      baseForm: element,
      componentSubtypes
    };
    this.componentPrototype.setComponentSpecificDialogData(dialogPrototypeData);
    return dialogPrototypeData;
  }

  communicateComponentChanges() {
    if (this.componentType === PackagingComponentTypesEnum.MainBody) {
      const componentForm = this.componentsFormArray.controls[0] as FormGroup;
      if (!componentForm) { return; }
      if (componentForm.controls.packagingComponentSubtypeId.value !== this.packagingTypeId) {
        this.packagingTypeChanged.emit(componentForm.controls.packagingComponentSubtypeId.value);
      }
      this.volumeChanged.emit(componentForm.controls.volume.value)
    } else {
      this.componentCountChanged.emit({ key: this.componentType, count: this.componentsFormArray.length });
    }
  }

  openAddExistingDialog() {
    this.spinner.show();
    let componentsToDisplay: string[];
    if (this.tableInfo.id === PackagingComponentTypesEnum.Decoration) {
      componentsToDisplay = this.componentTypeService.getDecorationTypeNames();
    } else {
      componentsToDisplay = [this.tableInfo.label];
    }

    const observables = componentsToDisplay.map(component => this.componentApiService.getPackagingComponentsByType(component));
    this.componentByTypeSubscription = forkJoin(observables).subscribe(components => {

      const dataSource = new MatTableDataSource<PackagingComponentDto>();
      dataSource.data = components.reduce((accumulator, value) => accumulator.concat(value), []);

      let displayedColumns = ['id', 'packagingComponentSubtypeName', 'articleName', 'articleNumber', 'manufacturer'];
      displayedColumns = displayedColumns.concat(this.isSingleComponent ? 'single-select' : 'select');

      const dialogTitle = this.tableInfo.text;

      const dialogConfig = getDialogConfig({ dataSource, displayedColumns, dialogTitle }, '1100px');
      this.spinner.hide();
      const dialogRef = this.dialog.open(SelectionDialogComponentsComponent, dialogConfig);
      this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
        if (result.event === DialogActions.REJECT) { return; }
        const componentDtos = result.data as PackagingComponentDto[];
        const componentEntries: PackagingComponentEntryDto[] =
          componentDtos.map(x => this.componentPrototype.wrapComponentInComponentEntry(x));
        const isWithTracking = result.event === ADD_WITH_TRACKING;
        if (isWithTracking) {
          componentEntries.forEach(x => x.hasInternalTracking = true);
        }
        const addedComponentsForm: FormGroup = this.componentPrototype.getFormFromComponentEntries(
          componentEntries, this.handler, this.formBuilder, isWithTracking || this.isTracked);
        this.doImport((addedComponentsForm.controls.packagingComponents as FormArray).controls as FormGroup[]);
        this.setTableColumns();
      });
    });
  }

  private setDisplayTrackedColumn() {
    this.displayTrackedColumn = this.componentsFormArray.value.filter((x: any) => x.hasInternalTracking).length > 0;
  }

  private doImport(componentColtrols: FormGroup[]) {
    for (const addedComponentControl of componentColtrols) {
      this.componentsFormArray.controls.push(addedComponentControl);
    }
    this.componentsFormArray.updateValueAndValidity();
    this.componentsForm.updateValueAndValidity();
    this.setTableDataSource();
    if (this.tableInfo.id === PackagingComponentTypesEnum.Closure ||
      this.tableInfo.id === PackagingComponentTypesEnum.Decoration ||
      this.tableInfo.id === PackagingComponentTypesEnum.Inlay ||
      this.tableInfo.id === PackagingComponentTypesEnum.PackagingAid) {
      this.showAutofillDataDialog();
    }
  }

  async saveComponent(componentIndex: number) {
    const rootDirDto = await this.directoryApiService.getDirectories().toPromise();
    const targetDirectoryId = await this.showSelectDirectoryDialog(rootDirDto);
    if (targetDirectoryId == null) { return; }

    const componentFormValue = this.componentsForm.getRawValue().packagingComponents[componentIndex];
    const componentToSave = this.componentPrototype.getSingleComponentEntryFromForm(componentFormValue, false);
    componentToSave.underlyingComponent.id = undefined; // saving as new component, so ID must be unset
    componentToSave.underlyingComponent.directoryId = targetDirectoryId;
    componentToSave.underlyingComponent.distributionCountries = [];

    let componentTypeName;
    if (this.tableInfo.id === PackagingComponentTypesEnum.Decoration) {
      const decorationSubtypeId = componentToSave.underlyingComponent.packagingComponentSubtypeId;
      componentTypeName = this.componentTypeService.getDecorationSubtypeNameBySubtypeId(decorationSubtypeId);
    } else {
      componentTypeName = this.tableInfo.label;
    }
    this.saveComponentSubscription =
      this.componentApiService.putPackagingComponent(componentToSave.underlyingComponent, componentTypeName)
        .subscribe(_ => {
          this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(this.handler.getComponentSucessDialogData(), '350px'));
        }, error => {
          console.log('Error has occured when saving component. ', error);
        });
  }

  getComponentEntries() {
    this.componentsFormArray.updateValueAndValidity();
    return this.componentPrototype.getComponentEntriesFromForm(this.componentsFormArray.getRawValue());
  }

  private showSelectDirectoryDialog(rootFolder: DirectoryDto): Promise<number> {
    const dialogConfig = getDialogConfig({ rootFolder, unreachableFolders: [] }, '500px');
    const dialogRef = this.dialog.open(SelectLocationDialogComponent, dialogConfig);

    return dialogRef.afterClosed().toPromise().then(result => {
      if (result.event !== DialogActions.REJECT) {
        return Promise.resolve(result.data.id);
      }
    });
  }

  private showAutofillDataDialog(): void {
    const data: SimpleDialogData = {
      title: this.translation('common.text.information'),
      messages: [this.translation('warnings.incompleteComponentImported')], icon: 'info'
    };
    const dialogConfig = getDialogConfig(data, '500px');
    this.dialog.open(SimpleAlertDialogComponent, dialogConfig);
  }

  public isPristine() {
    return this.componentsFormArray.controls.find(x => !x.pristine) == undefined;
  }

  ngOnDestroy(): void {
    this.dialogSubscription?.unsubscribe();
    this.componentByTypeSubscription?.unsubscribe();
    this.saveComponentSubscription?.unsubscribe();
  }
}
