import { TagDto } from './../../../../../data-transfer/entities/tag-dto';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { ColorDto } from 'src/app/data-transfer/entities/color-dto';
import { CountryDto } from 'src/app/data-transfer/entities/country-dto';
import { DirectoryDto } from 'src/app/data-transfer/entities/directory-dto';
import { PackagingCategoryMaterialFunctionDto } from 'src/app/data-transfer/entities/material-function-dto';
import { MaterialManifestationDto } from 'src/app/data-transfer/entities/material-manifestation-dto';
import { CreateUpdateHandler } from 'src/app/services/packaging-services/create-update-handler';

export class MaterialRoutingData {
  materialFunctions: PackagingCategoryMaterialFunctionDto[] = [];
  manufacturingCountries: CountryDto[] = [];
  distributionCountries: CountryDto[] = [];
  allManifestations: MaterialManifestationDto[] = [];
  allColors: ColorDto[] = [];
  rootDirectory!: DirectoryDto;
  allTags!: TagDto[];
}

@Component({
  selector: 'app-material-routing-wrapper',
  templateUrl: './material-routing-wrapper.component.html',
  styleUrls: ['./material-routing-wrapper.component.scss']
})
export class MaterialRoutingWrapperComponent implements OnInit, OnDestroy {

  private routeDataSubscription?: Subscription;

  constructor(
    public createUpdateHandler: CreateUpdateHandler,
    private route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.data.subscribe(data => {
      const materialData = new MaterialRoutingData();
      materialData.materialFunctions = data.materialFunctions ?? [];
      materialData.manufacturingCountries = data.manufacturingCountries ?? [];
      materialData.distributionCountries = data.distributionCountries ?? [];
      materialData.allManifestations = data.allManifestations ?? [];
      materialData.allColors = data.colors ?? [];
      materialData.rootDirectory = data.rootDirectory;
      materialData.allTags = data.allTags;

      this.createUpdateHandler.setPackagingHandlerMaterialData(materialData);
      this.createUpdateHandler.setComponentHandlerMaterialData(materialData);
    });
  }

  ngOnDestroy(): void {
    this.routeDataSubscription?.unsubscribe();
  }
}
