<table mat-table [dataSource]="componentsTableDataSource" class="mat-elevation-z8 table-wide">
  <ng-container *ngFor="let column of dataColumnsComponents; let iCol = index" [matColumnDef]="column.columnDef">
    <th mat-header-cell *matHeaderCellDef [class]="column.columnDef" class="header">
      {{ column.header }}
    </th>
    <td mat-cell *matCellDef="let row; let iRow = index" class="cell">
      <div [ngSwitch]="column.columnDef">
        <ng-container>
          {{ column.cell(row) }}
        </ng-container>
      </div>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumnsComponents"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumnsComponents"></tr>
</table>