import { EnvironmentalEffectsEvaluationResultDto } from '../../../data-transfer/entities/evaluation-entities/environmental-effects-evaluation-result-dto';
import { ChartSeries } from './life-cycle-service';
import { AggregateEvaluationLifecycleOutputDto } from './../../../data-transfer/entities/aggregate-evaluation-lifecycle-output-dto';
import { LcaStageInfo, LcaEffectInfo, LifeCycleAnalysisInformation } from 'src/app/model/life-cycle-data-information';

export class LifeCycleAggregatedEvaluationResult {

  public dataSource: ChartSeries[][];
  public effectsInfo: LcaEffectInfo[];
  private stagesInfo: LcaStageInfo[];

  constructor(
    private lcaOutput: AggregateEvaluationLifecycleOutputDto,
    private lcaInfo: LifeCycleAnalysisInformation
  ) {
    this.stagesInfo = this.lcaInfo.lifeCycleStageInformation;
    this.effectsInfo = this.lcaInfo.environmentalEffectsInformation;
    this.dataSource = this.getDataSource();
  }

  private getDataSource(): ChartSeries[][] {
    const stagesChartDataSource = new Array<Array<ChartSeries>>(this.effectsInfo.length);
    for (let effectIdx = 0; effectIdx < this.effectsInfo.length; effectIdx++) {
      const effectKey = this.effectsInfo[effectIdx].key;
      stagesChartDataSource[effectIdx] = [];
      this.stagesInfo.forEach(lifeCycleStage => {
        const stageName = lifeCycleStage.name;
        const stageKey = lifeCycleStage.key;
        // let stageKeyValue: EnvironmentalEffectsEvaluationResultDto;
        let stageTotal: number;
        if (stageKey === 'environmentalEffectsMaterials') {
          stageTotal =
            this.lcaOutput.environmentalEffectsPrimaryMaterials[effectKey as keyof EnvironmentalEffectsEvaluationResultDto] +
            this.lcaOutput.environmentalEffectsSecondaryMaterials[effectKey as keyof EnvironmentalEffectsEvaluationResultDto] +
            this.lcaOutput.environmentalEffectsManufacturing[effectKey as keyof EnvironmentalEffectsEvaluationResultDto];
        } else {
          const stageKeyValue =
            this.lcaOutput[stageKey as keyof AggregateEvaluationLifecycleOutputDto] as EnvironmentalEffectsEvaluationResultDto;
          stageTotal = stageKeyValue[effectKey as keyof EnvironmentalEffectsEvaluationResultDto];
        }
        // const stageTotal: number = stageKeyValue[effectKey as keyof EnvironmentalEffectsEvaluationResultDto];
        stagesChartDataSource[effectIdx].push(this.getComponentObject(stageName, stageTotal));
      });
    }
    return stagesChartDataSource;
  }

  private getComponentObject(name: string, value: number): ChartSeries {
    return { name, value, label: value.toExponential(2), series: [] };
  }

  public getEffectName(idx: number): string {
    return idx != null ? this.effectsInfo[idx].name : '';
  }

  public getEffectUnit(idx: number): string {
    return idx != null ? this.effectsInfo[idx].unit : '';
  }

  public getEffectDescription(idx: number): string {
    return idx != null ? this.effectsInfo[idx].description : '';
  }

  public getEffectUnitDescription(idx: number): string {
    return idx != null ? this.effectsInfo[idx].unitDescription : '';
  }
}
