import { Component, Input } from '@angular/core';
import { PackagingUnitLifeCycleResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-unit-life-cycle-result-dto';
import { LifeCycleService } from 'src/app/util/analyses-util/live-cycle/life-cycle-service';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { AnalysisApiService } from 'src/app/data-transfer/services/analysis-api-service';
import { TreeLevelParent } from '../../shared-components/parent-components/packaging-part-tree/packaging-part-tree.component';
import { PackagingUnitDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-dto';
import { AnalysisLifeCyclePackagingPartComponent } from '../analysis-life-cycle-packaging-part/analysis-life-cycle-packaging-part.component';
import {LicenseService} from 'src/app/services/licensing-service'
import {DomSanitizer} from '@angular/platform-browser';
import {BuyType} from 'src/app/components/shared-components/shop/buying-from-shop/buying-from-shop.component'

@Component({
  selector: 'app-analysis-life-cycle-packaging-unit',
  templateUrl: './analysis-life-cycle-packaging-unit.component.html',
  styleUrls: ['../analysis-life-cycle-packaging-system/analysis-life-cycle-packaging-system.component.scss']
})
export class AnalysisLifeCyclePackagingUnitComponent extends AnalysisLifeCyclePackagingPartComponent {

  @Input() selectedAnalysisVersion!: PackagingUnitLifeCycleResultDto;
  @Input() packagingUnit!: PackagingUnitDto;

  buyType = BuyType;

  constructor(
    private lcaService: LifeCycleService,
    protected translateService: TranslateService,
    protected analysisApiService: AnalysisApiService,
    protected dialog: MatDialog,
    protected licenseService : LicenseService,
    protected sanitizer : DomSanitizer
  ) {
    super(translateService, analysisApiService, dialog, licenseService, sanitizer);
    this.selectedNodeType = PackagingPart.Unit;
  }

  onNodeSelected(node: TreeLevelParent | null) {
    if (!node) { return; }
    this.selectedNodeType = node.type;
    if (node.type === PackagingPart.Unit) {
      this.onUnitNodeChanged(node.id);
    } else if (node.type === PackagingPart.Component) {
      this.onComponentNodeChanged(node.id);
    }
    super.onNodeChanged();
  }

  private onUnitNodeChanged(unitId: number | null) {
    if (unitId == null) { return; }
    this.dataSourceForCountry = this.lcaService.getPackagingUnitLcaResultFromDto(this.selectedAnalysisVersion);
    this.dataSourceForCountry.effectRelevances = this.filterRelevantEffects(this.dataSourceForCountry.effectRelevances);
    super.setOriginalLcaData();
    this.volume = this.selectedAnalysisVersion.analyzedPackagingUnit.packagingVolume ?? null;
    this.weight = this.selectedAnalysisVersion.analyzedPackagingUnit.packagingQuantity ?? null;
  }

  private onComponentNodeChanged(componentId: number | null) {
    if (componentId == null) { return; }
    const selectedResult = this.selectedAnalysisVersion.componentResults.find(x => x.analyzedPackagingComponent.id === componentId);
    if (!selectedResult) { return; }
    this.dataSourceForCountry = this.lcaService.getComponentLcaResultFromDto(selectedResult);
    this.dataSourceForCountry.effectRelevances = this.filterRelevantEffects(this.dataSourceForCountry.effectRelevances);
    super.setOriginalLcaData();
    this.volume = null;
    this.weight = null;
  }
}
