import { Component, Input, ViewChild } from '@angular/core';
import { MomentDateAdapter } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatMenu } from '@angular/material/menu';
import { MatTableDataSource } from '@angular/material/table';

export const CRITERIA_LIST = [
  { value: 'isEmpty', label: 'Is empty' },
  { value: 'isNotEmpty', label: 'Is not empty' },
  { value: 'isEqual', label: 'Is equal' },
  { value: 'isNotEqual', label: 'Is not equal' },
  { value: 'containsValue', label: 'Contains value' }
];

export const DATE_CRITERIA_LIST = [
  { value: 'dateFrom', label: 'Date from' },
  { value: 'dateTo', label: 'Date to' }
];

export const CHECKBOX_CRITERIA_LIST = [
  { value: 'containedInList', label: 'Contained in list' }
];

export const TAG_CRITERIUM = { value: 'containedTagInList', label: 'Contained tag in list' };

export const CRITERIA_FUNCTIONS = {
  isEmpty(value: any) {
    return value === null || value === '';
  },
  isNotEmpty(value: any) {
    return value !== null && value !== '';
  },
  isEqual(value: any, filteredValue: any) {
    if (!value || !filteredValue) { return; }
    return value.toString().toLowerCase() === filteredValue.toString().toLowerCase();
  },
  isNotEqual(value: any, filteredValue: any) {
    return value.toString().toLowerCase() !== filteredValue.toString().toLowerCase();
  },
  containsValue(value: any, filteredValue: any) {
    if (value !== null && filteredValue !== null) {
      return value.toString().toLowerCase().includes(filteredValue.toString().toLowerCase());
    } else { return false; }
  },
  dateFrom(value: any, selectedValue: any) {
    return Date.parse(selectedValue) <= Date.parse(value);
  },
  dateTo(value: any, selectedValue: any) {
    return Date.parse(selectedValue) >= Date.parse(value);
  },
  containedInList(value: any, filteredValueList: any) {
    if (!filteredValueList) { return; }
    let contained = false;
    for (const listItem of filteredValueList) {
      if (listItem === value) { contained = true; }
    }
    return contained;
  },
  containedTagInList(value: any, filteredValueList: any) {
    if (!filteredValueList) { return; }
    let contained = false;
    for (const listItem of filteredValueList) {
      if (value.includes(listItem)) { contained = true; }
    }
    return contained;
  }
};

export const MY_FORMATS = {
  parse: {
    dateInput: 'YYYY-MM-DD',
  },
  display: {
    dateInput: 'YYYY-MM-DD',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'YYYY',
  },
};

@Component({
  selector: 'app-filter-menu',
  templateUrl: './filter-menu.component.html',
  styleUrls: ['./filter-menu.component.scss'],
  exportAs: 'columnFilterComponent',
  providers: [
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS }
  ]
})
export class FilterMenuComponent {

  @ViewChild('filterMenu', { static: true }) filterMenu!: MatMenu;
  @ViewChild('dateFilterMenu', { static: true }) dateFilterMenu!: MatMenu;
  @ViewChild('checkboxFilterMenu', { static: true }) checkboxFilterMenu!: MatMenu;
  @ViewChild('tagFilterMenu', { static: true }) tagFilterMenu!: MatMenu;

  @Input() dataSource!: MatTableDataSource<any>;

  public criteriaList = CRITERIA_LIST;
  public dateCriteriaList = DATE_CRITERIA_LIST;
  public checkboxCriteriaList = CHECKBOX_CRITERIA_LIST;
  public tagCriterium = TAG_CRITERIUM;
  private filterMethods = CRITERIA_FUNCTIONS;
  public searchValue: any = {};
  public searchCriterion: any = {};
  selectedCategories: any = {};
  selectedTags: any = {};

  constructor() { }

  applyColumnFilter(caller?: string) {
    if (caller) {
      this.searchCriterion[caller] = this.criteriaList[4].value;
    }
    const searchFilter: any = {
      value: this.searchValue,
      criterion: this.searchCriterion,
      allCriteria: this.filterMethods
    };
    this.dataSource.filter = searchFilter;
  }

  applyDateColumnFilter() {
    const searchFilter: any = {
      value: this.searchValue,
      criterion: this.searchCriterion,
      allCriteria: this.filterMethods
    };
    this.dataSource.filter = searchFilter;
  }

  applyTagColumnFilter(caller: string, targetList: string[]) {
    this.searchCriterion[caller] = this.tagCriterium.value;
    this.searchValue[caller] = targetList;
    const searchFilter: any = {
      value: this.searchValue,
      criterion: this.searchCriterion,
      allCriteria: this.filterMethods
    };
    this.dataSource.filter = searchFilter;
  }

  applyCheckboxColumnFilter(caller: string, targetList: string[]) {
    this.searchCriterion[caller] = this.checkboxCriteriaList[0].value;
    this.searchValue[caller] = targetList;
    const searchFilter: any = {
      value: this.searchValue,
      criterion: this.searchCriterion,
      allCriteria: this.filterMethods
    };
    this.dataSource.filter = searchFilter;
  }

  clearColumn(columnKey: string): void {
    delete this.searchValue[columnKey];
    delete this.searchCriterion[columnKey];
    this.selectedCategories[columnKey] = undefined;
    this.selectedTags[columnKey] = undefined;
    this.applyColumnFilter();
  }

  setSelected(checked: boolean, category: string, targetList: any, caller: string) {
    if (checked) {
      if (!targetList[caller]) { targetList[caller] = []; }
      targetList[caller].push(category);
    } else {
      const index = targetList[caller].indexOf(category);
      targetList[caller].splice(index, 1);
      if (targetList[caller].length === 0) { targetList[caller] = undefined; }
    }
    this.searchValue[caller] = targetList;
  }

  isSelected(category: string, targetList: string[]) {
    if (targetList && targetList.length > 0) {
      return targetList.includes(category);
    }
  }
}
