<div [formGroup]="form">
  <div class="field-icon-inline">
    <mat-form-field class="input-field">
      <mat-label class="label-large">{{ 'dataManagement.userData.columns.password' | translate }}</mat-label>
      <input matInput formControlName="password" [type]="showNewPassword ? 'text' : 'password'"
        [ngClass]="{ 'is-invalid': submitted && formControls.password.errors }" (copy)="this.isAdmin"
        onDrag="return false" autocomplete="new-password">
    </mat-form-field>
    <button mat-icon-button matSuffix (click)="changeNewPasswordVisibility()" tabindex="-1" type="button">
      <mat-icon>{{ showNewPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
    </button>
  </div>
  <div class="error" *ngIf="formControls.password.errors">
    <div>
      <mat-error *ngIf="formControls.password.hasError('required') && submitted">
        {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
      </mat-error>
    </div>
    <div>
      <mat-error *ngIf="formControls.password.hasError('minlength')">
        {{ 'dataManagement.userData.userDialog.validation.minLength' | translate: {minLength: minPasswordLength} }}
      </mat-error>
    </div>
    <div>
      <mat-error *ngIf="formControls.password.hasError('hasNumber')">
        {{ 'dataManagement.userData.userDialog.validation.hasNumber' | translate }}
      </mat-error>
    </div>
    <div>
      <mat-error *ngIf="formControls.password.hasError('hasCapitalCase')">
        {{ 'dataManagement.userData.userDialog.validation.hasCapitalCase' | translate }}
      </mat-error>
    </div>
    <div>
      <mat-error *ngIf="formControls.password.hasError('hasSmallCase')">
        {{ 'dataManagement.userData.userDialog.validation.hasSmallCase' | translate }}
      </mat-error>
    </div>
    <div>
      <mat-error *ngIf="formControls.password.hasError('hasSpecialCharacters')">
        {{ 'dataManagement.userData.userDialog.validation.hasSpecialCharacters' | translate }}
      </mat-error>
    </div>
  </div>

  <div class="field-icon-inline">
    <mat-form-field class="input-field">
      <mat-label class="label-large">
        {{ 'dataManagement.userData.columns.confirmedPassword' | translate }}
      </mat-label>
      <input matInput formControlName="confirmPassword" [type]="showConfirmPassword ? 'text' : 'password'"
        [ngClass]="{ 'is-invalid': submitted && formControls.confirmPassword.errors }" onDrop="return false"
        (paste)="this.isAdmin" autocomplete="off">
    </mat-form-field>
    <button mat-icon-button matSuffix (click)="changeConfirmPasswordVisibility()" tabindex="-1" type="button">
      <mat-icon>{{ showConfirmPassword ? 'visibility' : 'visibility_off'}}</mat-icon>
    </button>
  </div>
  <div class="error" *ngIf="formControls.confirmPassword.errors">
    <div>
      <mat-error *ngIf="formControls.confirmPassword.hasError('required') && submitted">
        {{ 'dataManagement.userData.userDialog.validation.required' | translate }}
      </mat-error>
    </div>
    <div>
      <mat-error *ngIf="formControls.confirmPassword.hasError('mustMatch')">
        {{ 'dataManagement.userData.userDialog.validation.notSame' | translate }}
      </mat-error>
    </div>
  </div>
</div>
