import { CountriesService } from 'src/app/navigation/services/countries-service';
import { PackagingQuantityDto } from '../../../data-transfer/entities/packaging-unit-entities/packaging-quantity-dto';
import { Component, Inject, ViewChild, AfterViewInit } from '@angular/core';
import { MomentLocaleUtil } from 'src/app/util/moment-locale-util';
import { MatSort } from '@angular/material/sort';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

class MappedPackagingQuantityDto extends PackagingQuantityDto {
  countryName = '';
  date = '';
}

@Component({
  selector: 'app-aggregated-quantities-dialog',
  templateUrl: './aggregated-quantities-dialog.component.html',
  styleUrls: ['./aggregated-quantities-dialog.component.scss']
})
export class AggregatedQuantitiesDialogComponent implements AfterViewInit {

  @ViewChild(MatSort) sort!: MatSort;

  quantitiesDataSource: MatTableDataSource<MappedPackagingQuantityDto>;
  displayedColumns = ['packagingUnitId', 'countryName', 'date', 'quantity'];
  sortingDataAccessor = (item: any, property: any) => {
    switch (property) {
      case 'date': return new Date(item.year, item.month, 1);
      default: return item[property];
    }
  }

  constructor(
    @Inject(MAT_DIALOG_DATA) dialogData: PackagingQuantityDto[],
    private dialogRef: MatDialogRef<AggregatedQuantitiesDialogComponent>,
    private countriesService: CountriesService
  ) {
    this.quantitiesDataSource = new MatTableDataSource<MappedPackagingQuantityDto>();
    const mappedDataSource: MappedPackagingQuantityDto[] = [];
    dialogData.forEach(x => mappedDataSource.push({
      packagingUnitId: x.packagingUnitId,
      country: x.country,
      countryName: this.countriesService.getCountryNameByCode(x.country),
      month: x.month,
      year: x.year,
      date: MomentLocaleUtil.getTranslatedMonthYear(x.year, x.month - 1),
      quantity: x.quantity
    }));
    this.quantitiesDataSource.data = mappedDataSource;
    this.quantitiesDataSource.sortingDataAccessor = this.sortingDataAccessor;
  }

  ngAfterViewInit(): void {
    this.quantitiesDataSource.sort = this.sort;
  }

  closeDialog() {
    this.dialogRef.close();
  }
}
