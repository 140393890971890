<div *ngIf="showLabels; else minimal" class="chart-container-full">
  <highcharts-chart [Highcharts]="highcharts" [options]="chartOptions" [(update)]="updateFlag">
  </highcharts-chart>
</div>

<ng-template #minimal>
  <div class="chart-container-minimal" #minimalDiv>
    <div *ngIf="this.chartRawData.analysisId !== ''; else newAnalysis">
      <highcharts-chart [Highcharts]="highcharts" [options]="chartOptions" [(update)]="updateFlag">
      </highcharts-chart>
    </div>
    <ng-template #newAnalysis>
      <div class="no-analysis-text">
        <div *ngIf="chartWidth > 350; else shortLabel">
          {{ 'analysis.recyclabilityAnalysis.noAnalysis' | translate }}
        </div>
      </div>
      <ng-template #shortLabel>
        {{ 'analysis.recyclabilityAnalysis.noAnalysisShort' | translate }}
      </ng-template>
      <div [matTooltipDisabled]="isRecPossible" matTooltip="{{ 'warnings.analysisNotPossible' | translate }}">
        <button mat-raised-button color="primary" (click)="createNewAnalysis()" [disabled]="!isRecPossible"
          *ngIf="chartWidth > 350; else shortButton">
          {{ 'analysis.recyclabilityAnalysis.newAnalysisButton' | translate }}
          <mat-icon *ngIf="!isRecPossible" [matTooltipDisabled]="isRecPossible">
            info
          </mat-icon>
        </button>
      </div>
      <ng-template #shortButton>
        <div [matTooltipDisabled]="isRecPossible" matTooltip="{{ 'warnings.analysisNotPossible' | translate }}">
          <button mat-raised-button color="primary" (click)="createNewAnalysis()" [disabled]="!isRecPossible">
            {{ 'analysis.recyclabilityAnalysis.newAnalysisButtonShort' | translate }}
            <mat-icon *ngIf="!isRecPossible">
              info
            </mat-icon>
          </button>
        </div>
      </ng-template>
    </ng-template>
  </div>
</ng-template>