import { DocumentDto } from './document-dto';
import { ImageDto } from './image-dto';

export class PackagingPartBaseData {
    creationTimestamp!: Date;
    lastModificationTimestamp!: Date;
    associatedTagIdentifiers: number[] = [];
    directoryId!: number;
    directoryName?: string;
    images: ImageDto[] = [];
    documents: DocumentDto[] = [];
    hasExternalTracking?: boolean;
    isCurrentVersion = false;
    version!: number;
    versionName?: string;
}
