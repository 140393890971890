import { CreateUserDto } from './../../../data-transfer/entities/create-user-dto';
import { UserDialogComponent } from './../add-user-dialog/add-user-dialog.component';
import { UserDto } from 'src/app/data-transfer/entities/user-dto';
import { Component, Inject, Optional, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserDetailsDialogData } from 'src/app/util/user-dialog-handler';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { DialogActions } from 'src/app/model/dictionary';
import { CreditsDto, CreditType } from 'src/app/data-transfer/entities/credits-dto';

@Component({
  selector: 'app-add-organization-dialog',
  templateUrl: './add-organization-dialog.component.html',
  styleUrls: ['./add-organization-dialog.component.scss']
})
export class AddOrganizationDialogComponent implements OnDestroy {

  usersInOrgDataSource: MatTableDataSource<CreateUserDto>;
  usersColumns = ['name', 'email', 'actions'];
  allUsers: UserDto[];
  organizationName = '';
  creditsCount : CreditsDto = new CreditsDto();
  existingOrganizationsNames: Set<string>;
  creditType = CreditType;

  private dialogSubscription?: Subscription;

  constructor(
    private dialogRef: MatDialogRef<AddOrganizationDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: any,
    private dialog: MatDialog
  ) {
    this.usersInOrgDataSource = new MatTableDataSource<CreateUserDto>();
    this.allUsers = data.allUsers;
    this.existingOrganizationsNames = new Set<string>(data.existingOrganizationsNames);
  }

  addOrganization() {
    if (!this.organizationName || this.organizationNameExists() || this.allCreditsInvalid()) {
      return;
    } else {
      this.dialogRef.close({ organizationName: this.organizationName, users: this.usersInOrgDataSource.data, credits: this.creditsCount });
    }
  }

  organizationNameExists() {
    if (this.existingOrganizationsNames.size < 1 || !this.organizationName) { return false; }
    return this.existingOrganizationsNames.has(this.organizationName);
  }

  // returns true if at least one credit type is negative
  allCreditsInvalid() {
    let negative : boolean = false;
    Object.keys(CreditType).forEach((value, index : number) => {
        if (this.creditsInvalid(index as CreditType)) {
          negative = true;
          return;
        }
    })
    return negative;
  }
  creditsInvalid(type : CreditType) {
    return this.creditsCount === undefined || this.creditsCount.hasNegativValue(type);
  }

  closeDialog() {
    this.dialogRef.close({ event: DialogActions.REJECT });
  }

  addUser() {
    const dialogConfig = this.getAddEditDialogConfig();
    const dialogRef = this.dialog.open(UserDialogComponent, dialogConfig);

    this.dialogSubscription = dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult.event === DialogActions.REJECT) { return; }
      const newUser: CreateUserDto = dialogResult.data;
      this.usersInOrgDataSource.data.push(newUser);
      this.usersInOrgDataSource._updateChangeSubscription();
    });
  }

  editNewUser(newUser: CreateUserDto) {
    const dialogConfig = this.getAddEditDialogConfig(newUser);
    const dialogRef = this.dialog.open(UserDialogComponent, dialogConfig);

    this.dialogSubscription = dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult.event === DialogActions.REJECT) { return; }
      const editedUser: CreateUserDto = dialogResult.data;
      const index = this.usersInOrgDataSource.data.indexOf(newUser);
      if (index !== -1) {
        this.usersInOrgDataSource.data[index] = editedUser;
      }
      this.usersInOrgDataSource._updateChangeSubscription();
    });
  }

  private getAddEditDialogConfig(user?: CreateUserDto): MatDialogConfig {
    const namesUsed = new Set<string>(this.allUsers.map(userItem => userItem.name.toLowerCase()));
    const namesOfNewUsers = new Set<string>(this.usersInOrgDataSource.data.map(x => x.userName));
    namesOfNewUsers.forEach(name => namesUsed.add(name));
    const action = user ? DialogActions.EDIT : DialogActions.ADD;
    const dialogData = new UserDetailsDialogData(namesUsed, action, user ?? undefined);
    const dialogConfig = getDialogConfig(dialogData);
    return dialogConfig;
  }

  deleteNewUser(newUser: CreateUserDto) {
    const index = this.usersInOrgDataSource.data.indexOf(newUser);
    this.usersInOrgDataSource.data.splice(index, 1);
  }

  ngOnDestroy(): void {
    this.dialogSubscription?.unsubscribe();
  }
}
