import { PackagingUnitInfoDto } from '../entities/packaging-unit-entities/packaging-unit-info-dto';
import { MultiMaterialCompositeDto } from '../entities/material-entities/multi-material-composite-dto';
import { SecurityPrincipalDto } from '../entities/security-principal-dto';
import { Injectable } from '@angular/core';
import { PermissionTypeDto } from '../entities/permission-type-dto';
import { PermissionDto } from '../entities/permission-dto';
import { AddDirectoryDto } from '../entities/add-directory-dto';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { DirectoryDto } from '../entities/directory-dto';
import { ParentApiService } from './parent-api-service';
import { PackagingComponentDto } from '../entities/component-entities/packaging-component-dto';
import { environment } from 'src/environments/environment';
import { PackagingSystemInfoDto } from '../entities/packaging-system-entities/packaging-system-info-dto';

@Injectable({ providedIn: 'root' })
export class DirectoryApiService extends ParentApiService {

  constructor(private http: HttpClient) {
    super();
  }

  public getDirectories(): Observable<DirectoryDto> {
    const url = `${environment.webApiAddress}/api/directories`;
    return this.http.get<DirectoryDto>(url);
  }

  public createDirectory(directory: AddDirectoryDto, stayOnPageIfError: boolean = true): Observable<number> {
    const url = `${environment.webApiAddress}/api/directories`;
    return this.http.post<number>(url, directory, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

  public deleteDirectory(directoryIdToDelete: number, stayOnPageIfError: boolean = true): Observable<any> {
    const url = `${environment.webApiAddress}/api/directories?directoryId=${directoryIdToDelete}`;
    return this.http.request('delete', url, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

  public renameDirectory(directoryId: number, name: string, stayOnPageIfError: boolean = true) {
    const url = `${environment.webApiAddress}/api/directories/rename?directoryId=${directoryId}&newName=${name}`;
    return this.http.put(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

  public moveDirectory(directoryId: number, parentId: number, stayOnPageIfError: boolean = true) {
    let params: HttpParams = new HttpParams();
    params = params.set('directoryId', directoryId);
    params = params.set('newParentDirectoryId', parentId);
    const url = `${environment.webApiAddress}/api/directories/move-directory`;
    return this.http.put(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public movePackagingSystem(directoryId: number, packagingSystemId: number, versionId: number, stayOnPageIfError: boolean = true) {
    let params: HttpParams = new HttpParams();
    params = params.set('packagingSystemId', packagingSystemId);
    params = params.set('directoryId', directoryId);
    params = params.set('version', versionId);
    const url = `${environment.webApiAddress}/api/directories/move-packaging-system`;
    return this.http.put(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public movePackagingUnit(directoryId: number, packagingUnitId: number, versionId: number, stayOnPageIfError: boolean = true) {
    let params: HttpParams = new HttpParams();
    params = params.set('packagingUnitId', packagingUnitId);
    params = params.set('directoryId', directoryId);
    params = params.set('version', versionId);
    const url = `${environment.webApiAddress}/api/directories/move-packaging-unit`;
    return this.http.put(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public moveComponent(directoryId: number, componentId: number, versionId: number, stayOnPageIfError: boolean = true) {
    let params: HttpParams = new HttpParams();
    params = params.set('packagingComponentId', componentId);
    params = params.set('directoryId', directoryId);
    params = params.set('version', versionId);
    const url = `${environment.webApiAddress}/api/directories/move-packaging-component`;
    return this.http.put(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public moveMaterial(directoryId: number, materialId: number, versionId: number, stayOnPageIfError: boolean = true) {
    let params: HttpParams = new HttpParams();
    params = params.set('multiMaterialId', materialId);
    params = params.set('directoryId', directoryId);
    params = params.set('version', versionId);
    const url = `${environment.webApiAddress}/api/directories/move-multi-material`;
    return this.http.put(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public copyPackagingSystem(directoryId: number, packagingSystemId: number, versionId: number, stayOnPageIfError: boolean = true) {
    let params: HttpParams = new HttpParams();
    params = params.set('packagingSystemId', packagingSystemId);
    params = params.set('directoryId', directoryId);
    params = params.set('version', versionId);
    const url = `${environment.webApiAddress}/api/directories/copy-packaging-system`;
    return this.http.put(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public copyPackagingUnit(directoryId: number, packagingUnitId: number, versionId: number, stayOnPageIfError: boolean = true) {
    let params: HttpParams = new HttpParams();
    params = params.set('packagingUnitId', packagingUnitId);
    params = params.set('directoryId', directoryId);
    params = params.set('version', versionId);
    const url = `${environment.webApiAddress}/api/directories/copy-packaging-unit`;
    return this.http.put(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public copyComponent(directoryId: number, componentId: number, versionId: number, stayOnPageIfError: boolean = true) {
    let params: HttpParams = new HttpParams();
    params = params.set('packagingComponentId', componentId);
    params = params.set('directoryId', directoryId);
    params = params.set('version', versionId);
    const url = `${environment.webApiAddress}/api/directories/copy-packaging-component`;
    return this.http.put(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public copyMaterial(directoryId: number, materialId: number, versionId: number, stayOnPageIfError: boolean = true) {
    let params: HttpParams = new HttpParams();
    params = params.set('multiMaterialId', materialId);
    params = params.set('directoryId', directoryId);
    params = params.set('version', versionId);
    const url = `${environment.webApiAddress}/api/directories/copy-multi-material`;
    return this.http.put(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public getPackagingSystemsInDirectory(directoryId: number): Observable<PackagingSystemInfoDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-systems/directory?directoryId=${directoryId}`;
    return this.http.get<PackagingUnitInfoDto[]>(url);
  }

  public getPackagingSystemsInRecyclingBin(): Observable<PackagingSystemInfoDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-systems/recycler`;
    return this.http.get<PackagingUnitInfoDto[]>(url);
  }

  public getPackagingUnitsInDirectory(directoryId: number): Observable<PackagingUnitInfoDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-units/directory?directoryId=${directoryId}`;
    return this.http.get<PackagingUnitInfoDto[]>(url);
  }

  public getPackagingUnitsInRecyclingBin(): Observable<PackagingUnitInfoDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-units/recycler`;
    return this.http.get<PackagingUnitInfoDto[]>(url);
  }

  public getComponentsInDirectory(directoryId: number): Observable<PackagingComponentDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-components/directory?directoryId=${directoryId}`;
    return this.http.get<PackagingComponentDto[]>(url);
  }

  public getComponentsInRecyclingBin(): Observable<PackagingComponentDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-components/recycler`;
    return this.http.get<PackagingComponentDto[]>(url);
  }

  public getMaterialsInDirectory(directoryId: number): Observable<MultiMaterialCompositeDto[]> {
    const url = `${environment.webApiAddress}/api/multi-materials/directory?directoryId=${directoryId}`;
    return this.http.get<MultiMaterialCompositeDto[]>(url);
  }

  public getMaterialsInRecyclingBin(): Observable<MultiMaterialCompositeDto[]> {
    const url = `${environment.webApiAddress}/api/multi-materials/recycler`;
    return this.http.get<MultiMaterialCompositeDto[]>(url);
  }

  public restorePackagingSystemFromRecyclingBin(packagingSytemId: number, versionId: number, stayOnPageIfError: boolean = true) {
    const url = `${environment.webApiAddress}/api/packaging-systems/recycler/restore?id=${packagingSytemId}&version=${versionId}`;
    return this.http.put(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

  public restorePackagingUnitFromRecyclingBin(packagingSytemId: number, versionId: number, stayOnPageIfError: boolean = true) {
    const url = `${environment.webApiAddress}/api/packaging-units/recycler/restore?id=${packagingSytemId}&version=${versionId}`;
    return this.http.put(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

  public restorePackagingComponentFromRecyclingBin(componentId: number, versionId: number, stayOnPageIfError: boolean = true) {
    const url = `${environment.webApiAddress}/api/packaging-components/recycler/restore?id=${componentId}&version=${versionId}`;
    return this.http.put(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

  public restoreMaterialFromRecyclingBin(
    materialId: number | null | undefined, versionId: number | null | undefined, stayOnPageIfError: boolean = true
  ) {
    if (materialId == null || versionId == null) { return; }
    const url = `${environment.webApiAddress}/api/multi-materials/recycler/restore?id=${materialId}&version=${versionId}`;
    return this.http.put(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

  public setPermission(permission: PermissionDto, stayOnPageIfError: boolean = true): Observable<number> {
    const url = `${environment.webApiAddress}/api/directories/set-permission`;
    return this.http.put<number>(url, permission, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

  public getPermissionForDirectory(directoryId: number, principalId: number, deny = false): Observable<PermissionTypeDto> {
    let params: HttpParams = new HttpParams();
    params = params.set('directoryId', directoryId);
    params = params.set('principalId', principalId);
    params = params.set('deny', deny);
    const url = `${environment.webApiAddress}/api/directories/permission`;
    return this.http.get<PermissionTypeDto>(url, { params });
  }

  public determineCurrentUserPermissions(directoryId: number): Observable<PermissionTypeDto> {
    const url = `${environment.webApiAddress}/api/directories/determine-user-permission?directoryId=${directoryId}`;
    return this.http.get<PermissionTypeDto>(url);
  }

  public getRootDirectoryId() {
    const url = `${environment.webApiAddress}/api/directories/root-directory-id`;
    return this.http.get<number>(url);
  }

  public getSecurityPrincipals(): Observable<SecurityPrincipalDto[]> {
    const url = `${environment.webApiAddress}/api/SecurityPrincipals`;
    return this.http.get<SecurityPrincipalDto[]>(url);
  }

  public getExternalSecurityPrincipals(): Observable<SecurityPrincipalDto[]> {
    const url = `${environment.webApiAddress}/api/SecurityPrincipals/external`;
    return this.http.get<SecurityPrincipalDto[]>(url);
  }
}
