import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentalEffectTexts, LifeCycleStageTexts, TextsLcaData } from 'src/app/model/tooltips-lca-data';

export const ECOINVENT_VERSION = 3.8;

export interface LcaEffectInfo {
  key: string;
  name: string;
  description: string;
  unit: string;
  unitDescription: string;
}

export interface LcaStageInfo {
  key: string;
  name: string;
  description: string;
}

@Injectable({ providedIn: 'root' })
export class LifeCycleAnalysisInformation {

  environmentalEffectsInformation: LcaEffectInfo[] = [];
  lifeCycleStageInformation: LcaStageInfo[] = [];

  constructor(translateService: TranslateService) {
    const textsData = new TextsLcaData(translateService);
    this.addEnvironmentalEffects(textsData.environmentalEffectsTexts, translateService);
    this.addLifeCycleStages(textsData.lifeCycleStagesTexts, translateService);
  }

  private addEnvironmentalEffects(texts: EnvironmentalEffectTexts, translateService: TranslateService) {

    this.environmentalEffectsInformation.push({
      key: 'climateChange',
      name: translateService.instant('analysis.lifecycleAnalysis.climateChange'),
      unit: translateService.instant('analysis.lifecycleAnalysis.climateChangeUnit'),
      description: texts.climateChange.description,
      unitDescription: texts.climateChange.unit
    });
    this.environmentalEffectsInformation.push({
      key: 'freshwaterAndTerrestrialAcidification',
      name: translateService.instant('analysis.lifecycleAnalysis.acidification'),
      unit: translateService.instant('analysis.lifecycleAnalysis.acidificationUnit'),
      description: texts.acidification.description,
      unitDescription: texts.acidification.unit
    });
    this.environmentalEffectsInformation.push({
      key: 'freshwaterEcotoxicity',
      name: translateService.instant('analysis.lifecycleAnalysis.freshwaterEcotoxicity'),
      unit: translateService.instant('analysis.lifecycleAnalysis.freshwaterEcotoxicityUnit'),
      description: texts.ecotoxicityFreshwater.description,
      unitDescription: texts.ecotoxicityFreshwater.unit
    });
    this.environmentalEffectsInformation.push({
      key: 'freshwaterEutrophication',
      name: translateService.instant('analysis.lifecycleAnalysis.freshwaterEutrophication'),
      unit: translateService.instant('analysis.lifecycleAnalysis.freshwaterEutrophicationUnit'),
      description: texts.eutrophicationFreshwater.description,
      unitDescription: texts.eutrophicationFreshwater.unit
    });
    this.environmentalEffectsInformation.push({
      key: 'marineEutrophication',
      name: translateService.instant('analysis.lifecycleAnalysis.marineEutrophication'),
      unit: translateService.instant('analysis.lifecycleAnalysis.marineEutrophicationUnit'),
      description: texts.eutrophicationMarine.description,
      unitDescription: texts.eutrophicationMarine.unit
    });
    this.environmentalEffectsInformation.push({
      key: 'terrestrialEutrophication',
      name: translateService.instant('analysis.lifecycleAnalysis.terrestrialEutrophication'),
      unit: translateService.instant('analysis.lifecycleAnalysis.terrestrialEutrophicationUnit'),
      description: texts.eutrophicationTerrestrial.description,
      unitDescription: texts.eutrophicationTerrestrial.unit
    });
    this.environmentalEffectsInformation.push({
      key: 'carcinogenicEffects',
      name: translateService.instant('analysis.lifecycleAnalysis.carcinogenicEffects'),
      unit: translateService.instant('analysis.lifecycleAnalysis.carcinogenicEffectsUnit'),
      description: texts.cancerogenicEffects.description,
      unitDescription: texts.cancerogenicEffects.unit
    });
    this.environmentalEffectsInformation.push({
      key: 'ionizingRadiation',
      name: translateService.instant('analysis.lifecycleAnalysis.ionizingRadiation'),
      unit: translateService.instant('analysis.lifecycleAnalysis.ionizingRadiationUnit'),
      description: texts.ionizingRadiation.description,
      unitDescription: texts.ionizingRadiation.unit
    });
    this.environmentalEffectsInformation.push({
      key: 'nonCarcinogenicEffects',
      name: translateService.instant('analysis.lifecycleAnalysis.nonCarcinogenicEffects'),
      unit: translateService.instant('analysis.lifecycleAnalysis.nonCarcinogenicEffectsUnit'),
      description: texts.noncancerogenicEffects.description,
      unitDescription: texts.noncancerogenicEffects.unit
    });
    this.environmentalEffectsInformation.push({
      key: 'ozoneLayerDepletion',
      name: translateService.instant('analysis.lifecycleAnalysis.ozoneLayerDepletion'),
      unit: translateService.instant('analysis.lifecycleAnalysis.ozoneLayerDepletionUnit'),
      description: texts.ozoneLayerDepletion.description,
      unitDescription: texts.ozoneLayerDepletion.unit
    });
    this.environmentalEffectsInformation.push({
      key: 'photochemicalOzoneCreationPotential',
      name: translateService.instant('analysis.lifecycleAnalysis.photochemicalOzoneCreationPotential'),
      unit: translateService.instant('analysis.lifecycleAnalysis.photochemicalOzoneCreationPotentialUnit'),
      description: texts.photochemicalOzoneCreation.description,
      unitDescription: texts.photochemicalOzoneCreation.unit
    });
    this.environmentalEffectsInformation.push({
      key: 'respiratoryEffects',
      name: translateService.instant('analysis.lifecycleAnalysis.respiratoryEffects'),
      unit: translateService.instant('analysis.lifecycleAnalysis.respiratoryEffectsUnit'),
      description: texts.respiratoryEffects.description,
      unitDescription: texts.respiratoryEffects.unit
    });
    this.environmentalEffectsInformation.push({
      key: 'waterScarcity',
      name: translateService.instant('analysis.lifecycleAnalysis.waterScarcity'),
      unit: translateService.instant('analysis.lifecycleAnalysis.waterScarcityUnit'),
      description: texts.waterScarcity.description,
      unitDescription: texts.waterScarcity.unit
    });
    this.environmentalEffectsInformation.push({
      key: 'fossils',
      name: translateService.instant('analysis.lifecycleAnalysis.fossils'),
      unit: translateService.instant('analysis.lifecycleAnalysis.fossilsUnit'),
      description: texts.fossils.description,
      unitDescription: texts.fossils.unit
    });
    this.environmentalEffectsInformation.push({
      key: 'landUse',
      name: translateService.instant('analysis.lifecycleAnalysis.landUse'),
      unit: translateService.instant('analysis.lifecycleAnalysis.landUseUnit'),
      description: texts.landUse.description,
      unitDescription: texts.landUse.unit
    });
    this.environmentalEffectsInformation.push({
      key: 'mineralsAndMetals',
      name: translateService.instant('analysis.lifecycleAnalysis.mineralsAndMetals'),
      unit: translateService.instant('analysis.lifecycleAnalysis.mineralsAndMetalsUnit'),
      description: texts.mineralsAndMetals.description,
      unitDescription: texts.mineralsAndMetals.unit
    });
  }

  private addLifeCycleStages(texts: LifeCycleStageTexts, translateService: TranslateService) {

    this.lifeCycleStageInformation.push({
      key: 'environmentalEffectsMaterials',
      name: translateService.instant('analysis.lifecycleAnalysis.materials'),
      description: '',
    });
    /* this.lifeCycleStageInformation.push({
      key: 'environmentalEffectsPrimaryMaterials',
      name: translateService.instant('analysis.lifecycleAnalysis.virginMaterials'),
      description: texts.primaryMaterials,
    });
    this.lifeCycleStageInformation.push({
      key: 'environmentalEffectsSecondaryMaterials',
      name: translateService.instant('analysis.lifecycleAnalysis.recycledMaterials'),
      description: texts.secondaryMaterials,
    });
    this.lifeCycleStageInformation.push({
      key: 'environmentalEffectsManufacturing',
      name: translateService.instant('analysis.lifecycleAnalysis.manufacturing'),
      description: '',
    }); */
    this.lifeCycleStageInformation.push({
      key: 'environmentalEffectsTransport',
      name: translateService.instant('analysis.lifecycleAnalysis.transport'),
      description: '',
    });
    this.lifeCycleStageInformation.push({
      key: 'environmentalEffectsDistribution',
      name: translateService.instant('analysis.lifecycleAnalysis.distribution'),
      description: '',
    });
    this.lifeCycleStageInformation.push({
      key: 'environmentalEffectsEndOfLife',
      name: translateService.instant('analysis.lifecycleAnalysis.endOfLife'),
      description: '',
    });
    this.lifeCycleStageInformation.push({
      key: 'environmentalEffectsTotal',
      name: translateService.instant('analysis.lifecycleAnalysis.total'),
      description: '',
    });
  }
}
