import { TranslateService } from '@ngx-translate/core';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { DeleteItemDialogComponent } from './../../../dialogs/delete-item-dialog/delete-item-dialog.component';
import { AuthService } from './../../../../services/auth-service';
import { SimpleAlertDialogComponent, SimpleDialogData } from './../../../dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { TagApiService } from '../../../../data-transfer/services/tag-api-service';
import { TagsDialogComponent } from './../tags-dialog/tags-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TagDto } from '../../../../data-transfer/entities/tag-dto';
import { Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DialogActions } from 'src/app/model/dictionary';

@Component({
  selector: 'app-tags-management',
  templateUrl: './tags-management.component.html',
  styleUrls: ['./tags-management.component.scss'],
})
export class TagsManagementComponent implements OnInit, OnDestroy {
  defaultTags: TagDto[] = [];
  customTags: TagDto[] = [];
  allTagNames: string[] = [];

  isUserAdmin: boolean;

  private routeDataSubscription?: Subscription;
  private saveSubscription?: Subscription;
  private deleteSubscription?: Subscription;

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private tagApiService: TagApiService,
    private authService: AuthService,
    private translateService: TranslateService
  ) {
    this.isUserAdmin = this.authService.isUserAdmin();
  }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.data.subscribe(data => {
      this.customTags = data?.allTags.filter((tag: TagDto) => !tag.isDefault) ?? [];
      this.defaultTags = data?.allTags.filter((tag: TagDto) => tag.isDefault) ?? [];
      this.allTagNames = data?.allTags.map((tag: TagDto) => tag.name);
    });
  }

  async createTag(isDefault = false) {
    const dialogConfig = getDialogConfig({ allTagNames: this.allTagNames });
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(TagsDialogComponent, dialogConfig);
    const dialogResult = await dialogRef.afterClosed().toPromise();
    if (dialogResult.event === DialogActions.REJECT) {
      return;
    }
    const tagDto: TagDto = dialogResult.data;
    tagDto.isDefault = isDefault;
    this.saveSubscription = this.tagApiService.saveTag(tagDto).subscribe(newId => {
      tagDto.id = newId;
      if (isDefault) {
        this.defaultTags.push(tagDto);
      } else {
        this.customTags.push(tagDto);
      }
      this.allTagNames = this.defaultTags.concat(this.customTags).map((tag: TagDto) => tag.name);
    });
  }

  async editTag(tag: TagDto) {
    const dialogConfig = getDialogConfig({ tag, allTagNames: this.allTagNames });
    dialogConfig.disableClose = true;

    const dialogRef = this.dialog.open(TagsDialogComponent, dialogConfig);
    const dialogResult = await dialogRef.afterClosed().toPromise();
    if (dialogResult.event === DialogActions.REJECT) {
      return;
    }
    const tagDto: TagDto = dialogResult.data;
    tagDto.isDefault = tag.isDefault;
    this.saveSubscription = this.tagApiService.saveTag(tagDto).subscribe(_ => {
      tag.description = tagDto.description;
      tag.colorCode = tagDto.colorCode;
      tag.name = tagDto.name;
      this.allTagNames = this.defaultTags.concat(this.customTags).map((tag: TagDto) => tag.name);
    });
  }

  async deleteTag(tag: TagDto) {
    if (tag.id == null) {
      return;
    }

    const deleteDialogData = {
      dialogHeader: this.translateService.instant('dataManagement.tags.tagsManagement.messages.deleteTagHeader'),
      dialogText: this.translateService.instant('dataManagement.tags.tagsManagement.messages.deleteTagText'),
    };
    const dialogConfig = getDialogConfig(deleteDialogData, '500px');
    const deleteDialogRef = this.dialog.open(DeleteItemDialogComponent, dialogConfig);
    const result = await deleteDialogRef.afterClosed().toPromise();
    if (result.event === DialogActions.REJECT) {
      return;
    }

    this.deleteSubscription = this.tagApiService.deleteTag(tag.id).subscribe(
      _ => {
        this.customTags = this.customTags.filter(x => x.id !== tag.id);
        this.defaultTags = this.defaultTags.filter(x => x.id !== tag.id);
      },
      _ => {
        const dialogData: SimpleDialogData = {
          title: '',
          messages: [''],
          icon: 'error',
        };
        this.dialog.open(SimpleAlertDialogComponent, { disableClose: true, data: dialogData });
      }
    );
  }

  ngOnDestroy(): void {
    this.routeDataSubscription?.unsubscribe();
    this.saveSubscription?.unsubscribe();
    this.deleteSubscription?.unsubscribe();
  }
}
