import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { OnInit, Component, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

const displayButtonForErrors = [400, 403, 500, 502];
const displayLinkForErrors = [404];

@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.scss']
})
export class ErrorPageComponent implements OnInit, OnDestroy {

  private statusCode = -1;
  public returnUrl = '';

  public errorTitle = '';
  public errorMessage = '';

  public displayReturnButton = false;
  public displayRouterLink = false;

  private routeDataSubscription?: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.data.subscribe(data => {
      this.statusCode = data.errorCode;
      this.returnUrl = data.path;
    });

    this.errorTitle = `${this.statusCode} - ${this.translateService.instant('errors.codeSpecific.' + this.statusCode + '_title')}`;
    this.errorMessage = this.translateService.instant('errors.codeSpecific.' + this.statusCode + '_message');

    this.displayReturnButton = displayButtonForErrors.includes(this.statusCode);
    this.displayRouterLink = displayLinkForErrors.includes(this.statusCode);
  }

  onReturn() {
    this.router.navigateByUrl(this.returnUrl);
  }

  ngOnDestroy(): void {
    this.routeDataSubscription?.unsubscribe();
  }
}
