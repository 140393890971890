import { Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { PackagingComponentEntryDto } from 'src/app/data-transfer/entities/component-entities/packaging-component-entry-dto';
import { MultiMaterialCompositeDto } from 'src/app/data-transfer/entities/material-entities/multi-material-composite-dto';
import { PackagingSystemDto } from 'src/app/data-transfer/entities/packaging-system-entities/packaging-system-dto';
import { PackagingUnitDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-dto';
import { CountriesExpenseAvailabilityDict } from 'src/app/model/expense-countries';

@Component({
  selector: 'app-expense-calculation-parent',
  templateUrl: './expense-calculation-parent.component.html',
  styleUrls: ['./expense-calculation-parent.component.scss']
})
export class ExpenseCalculationParentComponent implements OnDestroy {

  protected packagingPartWeight!: number; // TODO delete when calculated in backend
  protected countriesAvailability!: CountriesExpenseAvailabilityDict[];
  protected packagingPartType!: number;

  protected routeDataSubscription?: Subscription;

  protected getPackagingSystemWeight(packagingSystem: PackagingSystemDto): number {
    return this.getSystemWeightRecursively(packagingSystem);
  }

  private getSystemWeightRecursively(packagingSystem: PackagingSystemDto, quantity = 1, totalWeight: number = 0): number {
    packagingSystem.packagingUnits.forEach(unit => {
      totalWeight += this.getPackagingUnitWeight(unit.underlyingPackagingUnit) * unit.quantity;
    });
    for (const subSystem of packagingSystem.packagingSystems) {
      this.getSystemWeightRecursively(subSystem.underlyingPackagingSystem, subSystem.quantity, totalWeight);
    }
    return totalWeight * quantity;
  }

  protected getPackagingUnitWeight(packagingUnit: PackagingUnitDto): number {
    const weightCalculator = (components: PackagingComponentEntryDto[]) => {
      let totalWeight = 0;
      components.forEach(x => totalWeight += this.getMaterialWeightRecursively(x.underlyingComponent.multiMaterial))
      return totalWeight;
    };
    const mainBodyWeight = packagingUnit.mainBody ? weightCalculator([packagingUnit.mainBody]) : 0;
    const closuresWeight = weightCalculator(packagingUnit.closures);
    const labelsWeight = weightCalculator(packagingUnit.labels);
    const sleevesWeight = weightCalculator(packagingUnit.sleeves);
    const inMoldLabelsWeight = weightCalculator(packagingUnit.inMoldLabels);
    const inlaysWeight = weightCalculator(packagingUnit.inlays);
    const packAidsWeight = weightCalculator(packagingUnit.packagingAids);
    return mainBodyWeight + closuresWeight + labelsWeight + sleevesWeight + inMoldLabelsWeight + inlaysWeight + packAidsWeight;
  }

  private getMaterialWeightRecursively(multiMaterial: MultiMaterialCompositeDto, totalWeight: number = 0): number {
    totalWeight += multiMaterial.totalWeight ?? 0;
    for (const subMultiMaterial of multiMaterial.subMultiMaterials) {
      this.getMaterialWeightRecursively(subMultiMaterial, totalWeight);
    }
    return totalWeight;
  }

  ngOnDestroy(): void {
    this.routeDataSubscription?.unsubscribe();
  }
}
