import { mergeMap } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GetCookieDTO } from '../data-transfer/entities/get-cookie-dto';
import { environment } from './../../environments/environment';
import { SnackbarService } from './snackbar.service';
import { TranslateService } from '@ngx-translate/core';
import { LicenseService } from './licensing-service';

export interface UserClaims {
  userId: number;
  userName: string;
  lastLoginTimestamp: string;
  isTestUser: boolean;
  isUserManager: boolean;
  isAdmin: boolean;
  isValidator: boolean;
  isPremiumUser: boolean;
  isContractSigned: boolean;
  isInactive: boolean
}

export enum LoginResponse {
  OK = 0,
  UserUnknown = 1,
  PasswordInvalid = 2,
  OtherError = 3,
}

@Injectable()
export class AuthService {

  private userClaims?: UserClaims;
  private stayOnPageHeader = new HttpHeaders({ stayOnPageIfError: 'true' });

  isInitialized = false;

  constructor(
    private http: HttpClient,
    private snackbarService: SnackbarService,
    private translateService: TranslateService,
    private licenseService: LicenseService
  ) { }

  public signInAzureAd(provider: string) {
    const returnUrl = encodeURIComponent('/login');
    window.location.href = `${environment.webApiAddress}/api/account/external-login?provider=${provider}&returnUrl=${returnUrl}`;
  }

  public getExternalLogins() {
    const url = `${environment.webApiAddress}/api/account/external-login-list`;
    return this.http.get<string[]>(url);
  }

  public async signIn(login: string, password: string): Promise<LoginResponse> {
    await this.csrfRefresh();
    const cookieRequestDto = new GetCookieDTO(login, password);
    const url = `${environment.webApiAddress}/api/account/login`;
    let loginResponse: LoginResponse = LoginResponse.OK;
    await this.http.post(url, cookieRequestDto, { headers: this.stayOnPageHeader }).toPromise().catch(
      err => {
        console.log(err);
        switch (err.status) {
          case 404:
            loginResponse = LoginResponse.UserUnknown;
            break;
          case 401:
            loginResponse = LoginResponse.PasswordInvalid;
            break;
          default:
            loginResponse = LoginResponse.OtherError;
            break;
        }
      });
    if (loginResponse === LoginResponse.OK) {
      await this.setUserClaims();
      await this.licenseService.refreshingLicenses();
    }
    await this.csrfRefresh();
    return loginResponse;
  }

  public async initSerivce(): Promise<void> {
    const isSignedIn = await this.isSignedIn();
    if (isSignedIn) {
      await this.setUserClaims();
      await this.csrfRefresh();
    }
    this.isInitialized = true;
  }

  public signOut(): Observable<void> {
    return this.http.post(`${environment.webApiAddress}/api/account/logout`, null).pipe(
      mergeMap(_ => this.isSignedIn().then(isSignedIn => {
        if (!isSignedIn) {
          this.userClaims = undefined;
        }
      })
    ));
  }

  public csrfRefresh() {
    const url = `${environment.webApiAddress}/api/antiforgery-token`;
    return this.http.get(url, { headers: this.stayOnPageHeader }).toPromise();
  }

  public async hasUserChanged() {
    const userId = this.userClaims?.userId;
    if (userId != null) {
      await this.setUserClaims();
      if (userId !== this.userClaims?.userId) {
        return true;
      }
    }
    return false;
  }

  private async setUserClaims(): Promise<void> {
    const url = `${environment.webApiAddress}/api/account/user-claims`;
    const result = await this.http.get<UserClaims>(url).toPromise();
    if (result) {
      this.userClaims = result;
    }
  }

  public async isSignedIn(): Promise<boolean | void> {
    const url = `${environment.webApiAddress}/api/account/is-logged-in`;
    return await this.http.get<boolean>(url, { headers: this.stayOnPageHeader }).toPromise().catch(error => {
      if (error.status === 403) {
        this.snackbarService.showInfoWithCloseButton(this.translateService.instant('messages.loginSnackbar'));
      }
    });
  }

  isUserValidator(): boolean {
    if (!this.userClaims) {
      this.throwError('isValidator');
    }
    return this.userClaims?.isValidator;
  }

  isUserAdmin(): boolean {
    if (!this.userClaims) { this.throwError('isAdmin'); }
    return this.userClaims?.isAdmin;
  }

  IsPremiumUser(): boolean {
    if (!this.userClaims) { this.throwError('isPremiumUser'); }
    return this.userClaims?.isPremiumUser;
  }

  isContractSigned(): boolean {
    if (!this.userClaims) { this.throwError('isContractSigned'); }
    return this.userClaims?.isContractSigned;
  }

  isUserInactive(): boolean {
    if (!this.userClaims) { this.throwError('isInactive'); }
    return this.userClaims?.isInactive;
  }

  getSignedInUsername(): string {
    if (!this.userClaims) { this.throwError('userName'); }
    return this.userClaims?.userName;
  }

  getUserId(): number {
    if (!this.userClaims) { this.throwError('userId'); }
    return this.userClaims?.userId;
  }

  getLastLogin(): Date {
    if (!this.userClaims) { this.throwError('lastLoginTimestamp'); }
    return new Date(this.userClaims?.lastLoginTimestamp);
  }

  heartbeat() {
    const url = `${environment.webApiAddress}/api/heartbeat`;
    return this.http.post<null>(url, null, { headers: this.stayOnPageHeader });
  }

  private throwError(fieldName: string): never {
    throw new Error(`AuthService: User not signed in. Field "${fieldName}" cannot be retrieved.`);
  }
}

