<div class="outer-container">
  <mat-card>
    <mat-card-title class="grid-item-title">{{ 'dataManagement.uiChangelog.title' | translate }}</mat-card-title>
    <mat-card-content>
      <div class="accordion">
        <mat-accordion togglePosition="before" class="mat-typography">
          <mat-expansion-panel *ngFor="let version of versions; first as isFirst" [expanded]="isFirst">
            <mat-expansion-panel-header>
              <mat-panel-title>
                {{ version.id }}
              </mat-panel-title>
              <mat-panel-description>
                {{ version.releaseDate | date }}
              </mat-panel-description>
            </mat-expansion-panel-header>
            <markdown emoji [src]="getFileName(version.id)" (error)="onError($event)">
            </markdown>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </mat-card-content>
  </mat-card>
</div>
