import { Injectable } from '@angular/core';
import { PackagingPartTypeDto } from 'src/app/data-transfer/entities/packaging-part-type-dto';
import { PROJECT_IMAGES_PATH } from 'src/app/model/paths';

export const GENERIC_PACKAGING_TYPE_ID = 12;
export const GENERIC_FLEXIBLE_PACKAGING_TYPE_ID = 14;
export const GENERIC_RIGID_PACKAGING_TYPE_ID = 15;
export const GENERIC_ANALYZABLE_PACKAGING_TYPE_IDS = [14, 15];
export const TUBE_PACKAGING_TYPE_ID = 10;
export const MAIN_BODY_DIFFERS_FROM_PACKAGING_TYPE = [3, 5, 10, 11, 13]

@Injectable({ providedIn: 'root' })
export class PackagingUnitTypeService {

  private packagingUnitTypes: PackagingPartTypeDto[] = [];
  private packagingUnitTypesEnglish: PackagingPartTypeDto[] = [];

  setPackagingUnitTypes(packagingUnitTypes: PackagingPartTypeDto[]) {
    this.packagingUnitTypes = packagingUnitTypes;
  }

  getPackagingUnitTypes(): PackagingPartTypeDto[] {
    return this.packagingUnitTypes;
  }

  getOrderedPackagingUnitTypes() {
    const unorderedTypes: PackagingPartTypeDto[] = this.packagingUnitTypes;
    const nonAnalyzableType = unorderedTypes.find(x => x.id === GENERIC_PACKAGING_TYPE_ID);
    const genricAnalyzableTypes = unorderedTypes.filter(x => GENERIC_ANALYZABLE_PACKAGING_TYPE_IDS.includes(x.id));
    if (!nonAnalyzableType || !genricAnalyzableTypes) { return unorderedTypes; }

    let orderedTypes: PackagingPartTypeDto[] = unorderedTypes.filter(x =>
      !GENERIC_ANALYZABLE_PACKAGING_TYPE_IDS.includes(x.id) && x.id !== GENERIC_PACKAGING_TYPE_ID);

    orderedTypes = orderedTypes.concat(genricAnalyzableTypes).concat(nonAnalyzableType);
    return orderedTypes;
  }

  getLastSelectedTypeId(): number {
    const lastSelectedTypeId = localStorage.getItem('lastSelectedTypeId');
    return lastSelectedTypeId ? +lastSelectedTypeId : 1;
  }

  setLastSelectedTypeId(value: number) {
    localStorage.setItem('lastSelectedTypeId', value.toString());
  }

  setPackagingUnitTypesEnglish(packagingUnitTypesEnglish: PackagingPartTypeDto[]) {
    this.packagingUnitTypesEnglish = packagingUnitTypesEnglish;
  }

  getPackagingUnitTypeName(packagingUnitTypeId: number): string {
    const packagingUnitType = this.packagingUnitTypesEnglish.find(x => x.id === packagingUnitTypeId);
    if (!packagingUnitType) { throw new Error('PackagingUnitTypeService: packaging type not found'); }
    return packagingUnitType.name.toLowerCase();
  }

  getPackagingUnitTypeImage(packagingUnitTypeId?: number | null, isGray = false): string {
    if (packagingUnitTypeId === 0 || packagingUnitTypeId == null) {
      packagingUnitTypeId = 12;
    }
    const packagingUnitType = this.packagingUnitTypesEnglish.find(x => x.id === packagingUnitTypeId);
    if (!packagingUnitType) { throw new Error('PackagingUnitTypeService: packaging type not found'); }
    const baseImagePath = `${PROJECT_IMAGES_PATH}elements/packaging_types/${packagingUnitType.name.toLowerCase()}`;
    const grayAddition = isGray ? '_gray' : '';
    return baseImagePath + grayAddition + '.svg';
  }

  getMainBodyTypeImage(packagingUnitTypeId?: number | null, isGray = false): string {
    const packagingUnitType = this.packagingUnitTypesEnglish.find(x => x.id === packagingUnitTypeId);
    if (!packagingUnitType) { throw new Error('PackagingUnitTypeService: packaging type not found'); }
    const baseImagePath = `${PROJECT_IMAGES_PATH}elements/component_types/main_body_types/${packagingUnitType.name.toLowerCase()}`;
    const grayAddition = isGray ? '_gray' : '';
    return baseImagePath + grayAddition + '.svg';
  }

  getPackagingUnitImage(isDarkTheme: boolean) {
    const baseImagePath = `${PROJECT_IMAGES_PATH}elements/packaging_unit`;
    const darkAddition = isDarkTheme ? '_dark' : '';
    return baseImagePath + darkAddition + '.svg';
  }

  getPackagingUnitTypeNames(): string[] {
    return this.packagingUnitTypes.map(x => x.name);
  }

  getPackagingUnitTypeId(packagingUnitTypeName: string) {
    return this.getPackagingUnitTypeDtoFromName(packagingUnitTypeName).id;
  }

  getPackagingUnitTypeDtoFromName(packagingName: string): PackagingPartTypeDto {
    const packagingUnitTypeEng = this.packagingUnitTypesEnglish.find(x => x.name.toLowerCase() === packagingName);
    if (!packagingUnitTypeEng) { throw new Error('PackagingUnitTypeService: packaging type not found'); }
    const id = packagingUnitTypeEng.id;
    const packagingUnitType = this.packagingUnitTypes.find(x => x.id === id);
    if (!packagingUnitType) { throw new Error('PackagingUnitTypeService: packaging type not found'); }
    return packagingUnitType;
  }

  getPackagingUnitTypeDtoFromId(packagingUnitTypeId: number): PackagingPartTypeDto {
    const packagingUnitType = this.packagingUnitTypes.find(x => x.id === packagingUnitTypeId);
    if (!packagingUnitType) { throw new Error('PackagingUnitTypeService: packaging type not found'); }
    return packagingUnitType;
  }

  isPackagingUnitTypeGeneric(packagingUnitTypeId: number): boolean {
    if (packagingUnitTypeId == null) { return false; }
    return packagingUnitTypeId === GENERIC_PACKAGING_TYPE_ID;
  }
}
