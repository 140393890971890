import { getDialogConfig } from 'src/app/util/dialog-util';
import { TranslateService } from '@ngx-translate/core';
import { SimpleAlertDialogComponent, SimpleDialogData } from './../simple-alert-dialog/simple-alert-dialog.component';
import { Component, OnInit, Optional, Inject } from '@angular/core';
import { Validators, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogActions } from 'src/app/model/dictionary';

export class PasswordDialogData {
  userId: number;
  login: string;
  isAdmin: boolean;

  constructor(userId: number, login: string, isAdmin: boolean) {
    this.userId = userId;
    this.login = login;
    this.isAdmin = isAdmin;
  }
}

@Component({
  selector: 'app-change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.scss']
})
export class ChangePasswordDialogComponent implements OnInit {

  changePasswordForm!: FormGroup;

  login: string;
  userId: number;
  isAdmin = false;

  submitted = false;
  public showOldPassword = false;

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: PasswordDialogData,
    private dialogRef: MatDialogRef<ChangePasswordDialogComponent>,
    private formBuilder: FormBuilder,
    protected dialog: MatDialog,
    protected translateService: TranslateService
  ) {
    this.userId = dialogData.userId;
    this.login = dialogData.login;
    this.isAdmin = dialogData.isAdmin;
  }

  ngOnInit(): void {
    this.changePasswordForm = this.formBuilder.group({
      oldPassword: [''],
      newPassword: []
    });
    if (!(this.isAdmin && this.userId)) {
      this.changePasswordForm.controls.oldPassword.setValidators(Validators.required);
    }
  }

  public changeOldPasswordVisibility() {
    this.showOldPassword = !this.showOldPassword;
  }

  doAction() {
    this.submitted = true;
    Object.keys(this.changePasswordForm.controls).forEach(field => {
      const control = this.changePasswordForm.get(field);
      control?.markAsTouched({ onlySelf: true });
    });
    if (this.changePasswordForm.invalid) { return; }
    if (this.isAdmin && this.userId != null) {
      this.dialogRef.close({
        event: DialogActions.CONFIRM,
        data: {
          userId: this.dialogData.userId,
          password: this.changePasswordForm.get('newPassword')?.value.password
        }
      });
    } else {
      this.dialogRef.close({
        event: DialogActions.CONFIRM,
        data: {
          login: this.dialogData.login,
          oldPassword: this.changePasswordForm.get('oldPassword')?.value,
          newPassword: this.changePasswordForm.get('newPassword')?.value.password
        }
      });
    }
    const data: SimpleDialogData = {
      title: this.translateService.instant('common.text.success'),
      messages: [this.translateService.instant('dataManagement.userData.texts.passwordChanged')], icon: 'info'
    };
    this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(data, '500px'));
  }

  closeDialog() {
    this.dialogRef.close({ event: DialogActions.REJECT });
  }

  onReset() {
    this.submitted = false;
    this.changePasswordForm.reset();
  }

  get formControls() { return this.changePasswordForm.controls; }
}
