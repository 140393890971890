import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PackagingComponentDto } from 'src/app/data-transfer/entities/component-entities/packaging-component-dto';
import { PackagingUnitDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-dto';
import { PackagingComponentTypesEnum } from 'src/app/model/packaging-component-types-enum';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { PROJECT_IMAGES_PATH } from 'src/app/model/paths';
import { ComponentTypeService } from 'src/app/navigation/services/component-type-service';
import { MAIN_BODY_DIFFERS_FROM_PACKAGING_TYPE, PackagingUnitTypeService } from 'src/app/navigation/services/packaging-unit-type-service';

export interface TreeLevelParent {
  id: number;
  name: string;
  quantity: number;
  type: number;
  index: number;
}

export interface PackagingSystemTreeLevel extends TreeLevelParent {
  children?: PackagingSystemTreeLevel[] | PackagingUnitTreeLevel[];
}

export interface PackagingUnitTreeLevel extends TreeLevelParent {
  isTransportPackaging?: boolean;
  packagingUnitTypeId?: number;
  children?: ComponentTreeLevel[];
}

export interface ComponentTreeLevel extends TreeLevelParent {
  componentTypeId?: number;
  packagingTypeId?: number;
}

@Component({
  selector: 'app-packaging-part-tree',
  templateUrl: './packaging-part-tree.component.html',
  styleUrls: ['./packaging-part-tree.component.scss']
})
export class PackagingPartTreeComponent {

  @Input() public isDarkTheme = false;
  @Output() protected nodeSelected = new EventEmitter();

  dataSource: any;

  constructor(
    protected packagingUnitTypeService: PackagingUnitTypeService,
    protected componentTypeService: ComponentTypeService
  ) { }

  hasChild = (node: PackagingUnitTreeLevel) => node.children && node.children.length > 0;

  protected getPackagingPartImage(node: PackagingSystemTreeLevel | PackagingUnitTreeLevel | ComponentTreeLevel) {
    let typeId;
    switch (node.type) {
      case PackagingPart.System:
        return `${PROJECT_IMAGES_PATH}elements/packaging_system${this.isDarkTheme ? '_dark' : ''}_gray.svg`;
      case PackagingPart.Unit:
        typeId = (node as PackagingUnitTreeLevel).packagingUnitTypeId;
        if (typeId == null) {
          return `${PROJECT_IMAGES_PATH}icons/unit${this.isDarkTheme ? '_dark' : ''}.svg`;
        }
        return this.packagingUnitTypeService.getPackagingUnitTypeImage(typeId, true);
      case PackagingPart.Component:
        typeId = (node as ComponentTreeLevel).componentTypeId;
        if (typeId == null) {
          return `${PROJECT_IMAGES_PATH}elements/component${this.isDarkTheme ? '_dark' : ''}.svg`;
        }
        if (typeId === PackagingComponentTypesEnum.MainBody) {
          const packagingTypeId = (node as ComponentTreeLevel).packagingTypeId;
          if (packagingTypeId != null) {
            return MAIN_BODY_DIFFERS_FROM_PACKAGING_TYPE.includes(packagingTypeId)
              ? this.packagingUnitTypeService.getMainBodyTypeImage(packagingTypeId, true)
              : this.packagingUnitTypeService.getPackagingUnitTypeImage(packagingTypeId, true);
          }
        }
        return this.componentTypeService.getComponentTypeImage(typeId, this.isDarkTheme, true);
      default: break;
    }
  }

  protected getImageTooltip(node: PackagingUnitTreeLevel): string {
    let typeId;
    switch (node.type) {
      case PackagingPart.Unit:
        typeId = (node as PackagingUnitTreeLevel).packagingUnitTypeId;
        if (typeId == null) { return ''; }
        return this.packagingUnitTypeService.getPackagingUnitTypeDtoFromId(typeId).name;
      default: break;
    }
    return '';
  }

  protected getComponentTreeLevelsForUnit(unit: PackagingUnitDto): ComponentTreeLevel[] {
    const allComponentTreeLevels: ComponentTreeLevel[] = [];
    const mainBody = unit.mainBody?.underlyingComponent;
    if (mainBody) { allComponentTreeLevels.push(this.getComponentTreeLevel(mainBody)); }
    unit.closures.forEach(x =>
      allComponentTreeLevels.push(this.getComponentTreeLevel(x.underlyingComponent)));
    unit.labels.forEach(x =>
      allComponentTreeLevels.push(this.getComponentTreeLevel(x.underlyingComponent)));
    unit.sleeves.forEach(x =>
      allComponentTreeLevels.push(this.getComponentTreeLevel(x.underlyingComponent)));
    unit.inMoldLabels.forEach(x =>
      allComponentTreeLevels.push(this.getComponentTreeLevel(x.underlyingComponent)));
    unit.inlays.forEach(x =>
      allComponentTreeLevels.push(this.getComponentTreeLevel(x.underlyingComponent)));
    unit.packagingAids.forEach(x =>
      allComponentTreeLevels.push(this.getComponentTreeLevel(x.underlyingComponent)));
    return allComponentTreeLevels;
  }

  private getComponentTreeLevel(componentDto: PackagingComponentDto): ComponentTreeLevel {
    const name = componentDto.packagingComponentSubtypeName.concat(componentDto.articleName ? ` (${componentDto.articleName})` : '');
    return {
      id: componentDto.id ?? -1,
      index: 0,
      name,
      quantity: 1,
      type: PackagingPart.Component,
      componentTypeId: componentDto.packagingComponentCategoryId ?? -1,
      packagingTypeId: componentDto.packagingComponentSubtypeId ?? -1,
    };
  }
}
