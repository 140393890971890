import { Component, OnInit } from '@angular/core';
import { HistoryWrapperLifeCycle } from 'src/app/data-transfer/entities/evaluation-entities/history-wrapper-life-cycle';
import { PackagingSystemLifeCycleResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-system-life-cycle-result-dto';
import { PackagingSystemDto } from 'src/app/data-transfer/entities/packaging-system-entities/packaging-system-dto';
import { LifeCycleResultPackagingSystem } from 'src/app/model/evaluations/life-cycle-result-packaging-system';
import { ComparePackagingPartComponent } from '../../shared-components/parent-components/compare-packaging-part/compare-packaging-part.component';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { AnalysisApiService } from 'src/app/data-transfer/services/analysis-api-service';
import { CountriesService } from 'src/app/navigation/services/countries-service';
import { CreditsService } from 'src/app/services/credits-service';
import { LifeCycleService } from 'src/app/util/analyses-util/live-cycle/life-cycle-service';
import { RecyclabilityResultPackagingSystem } from 'src/app/model/evaluations/recyclability-result-packaging-system';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { ComparisonLcaRowDefinition, ComparisonRowDefinition } from 'src/app/util/analyses-util/recyclability-table-definitions';
import { RecyclabilityService } from 'src/app/util/analyses-util/recyclability/recyclability-service';
import { HistoryWrapperRecyclability } from 'src/app/data-transfer/entities/evaluation-entities/history-wrapper-recyclability';
import { PackagingSystemRecyclabilityResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-system-recyclability-result-dto';

@Component({
  selector: 'app-compare-packaging-systems',
  templateUrl: './compare-packaging-systems.component.html',
  styleUrls: ['./compare-packaging-systems.component.scss']
})
export class ComparePackagingSystemsComponent extends ComparePackagingPartComponent implements OnInit {

  recDataSource: RecyclabilityResultPackagingSystem[][] = [];
  lcaDataSource: LifeCycleResultPackagingSystem[][] = [];

  constructor(
    protected analysisApiService: AnalysisApiService,
    protected translateService: TranslateService,
    protected dialog: MatDialog,
    protected creditsService: CreditsService,
    protected spinner: NgxSpinnerService,
    private route: ActivatedRoute,
    private recyclabilityService: RecyclabilityService,
    private lifeCycleService: LifeCycleService,
    protected countriesService: CountriesService
  ) {
    super(analysisApiService, translateService, dialog, creditsService, spinner);
  }

  ngOnInit() {
    this.routeDataSubscription = this.route.data.subscribe(async data => {
      const packagingSystems = data.packagingSystems;
      const countryCodes = super.getAllEvaluatedCountryCodes(packagingSystems);
      const lcaForComparison: HistoryWrapperLifeCycle[] = data.lcaForComparison;
      const recForComparison: HistoryWrapperRecyclability[] = data.recyclabilityForComparison;
      if (lcaForComparison) {
        this.lcaDataSource = this.getLcaResultsPackagingSystem(lcaForComparison, packagingSystems, countryCodes);
        this.volumeAvailable = this.allPackagingSystemsHaveVolume(packagingSystems);
        this.weightAvailable = this.allPackagingSystemsHaveWeight(packagingSystems);
        lcaForComparison.forEach(lcaWrapper => { this.newLcaAllowed.push(lcaWrapper.lifeCycleDtos.length === 0) });
      }
      if (recForComparison) {
        this.recDataSource = this.getRecResultsPackagingSystem(recForComparison, packagingSystems, countryCodes);
      }
      await this.setAnalysisPossibleData(this.recDataSource, this.lcaDataSource, PackagingPart.System);
      this.analysesDataLoaded = Promise.resolve(true);
      this.creditsService.setCreditsCount();
    });
    this.recTableData = this.getRecTableData(this.recDataSource);
    this.lcaTableData = this.getLcaTableData(this.lcaDataSource);
  }

  // --- RECYCLABILITY ANALYSIS ---

  private getRecResultsPackagingSystem(
    recyclabilityWrappersList: HistoryWrapperRecyclability[], packagingSystems: PackagingSystemDto[], countryCodes: string[]
  ): RecyclabilityResultPackagingSystem[][] {
    const allRecResults: RecyclabilityResultPackagingSystem[][] = recyclabilityWrappersList.map(wrapper => {
      const recResults: RecyclabilityResultPackagingSystem[] = [];
      const packagingSystem = packagingSystems?.find(y => y.id === wrapper.id);
      if (!packagingSystem) { return recResults; }
      const brandName = packagingSystem.brandName;
      const productName = packagingSystem.productName;
      const recDtos = wrapper.recyclabilityDtos[0] as PackagingSystemRecyclabilityResultDto[];
      countryCodes.forEach(countryCode => {
        const recDto = (recDtos && recDtos.find(x => x.evaluatedCountry === countryCode)) ??
          this.getDummyPackagingSystemRecyclability(wrapper.analysisId, wrapper.id, wrapper.version, brandName, productName, countryCode);
        const recResult = this.recyclabilityService.getPackagingSystemRecyclabilityResultFromDto(recDto);
        recResults.push(recResult);
      });
      return recResults;
    });
    return this.getRecDataSourcePackagingSystems(allRecResults, countryCodes);
  }

  private getRecDataSourcePackagingSystems(
    allEvaluations: RecyclabilityResultPackagingSystem[][], countryCodes: string[]
  ): RecyclabilityResultPackagingSystem[][] {
    const evaluationByCountry: RecyclabilityResultPackagingSystem[][] = [];
    countryCodes.forEach(_ => evaluationByCountry.push([]));
    for (const evaluation of allEvaluations) {
      for (let index = 0; index < countryCodes.length; index++) {
        const countryCode = countryCodes[index];
        const countryEval = evaluation ? evaluation.find(x => x.evaluatedCountryCode === countryCode) : null;
        if (!countryEval) { throw new Error("ComparePackagingSystemsComponent: evaluation for country not found"); }
        evaluationByCountry[index].push(countryEval);
      }
    }
    return evaluationByCountry;
  }

  private getDummyPackagingSystemRecyclability(
    analysisId: string, packagingSystemId: number, version: number, brandName: string, productName: string, countryCode: string
  ): PackagingSystemRecyclabilityResultDto {
    const dummyPackagingSystem = new PackagingSystemDto();
    dummyPackagingSystem.id = packagingSystemId;
    dummyPackagingSystem.version = version;
    dummyPackagingSystem.brandName = brandName;
    dummyPackagingSystem.productName = productName;
    const returnDto = new PackagingSystemRecyclabilityResultDto();
    returnDto.id = analysisId;
    returnDto.analyzedPackagingSystem = dummyPackagingSystem;
    returnDto.evaluatedCountry = countryCode;
    returnDto.packagingSystemResults = [];
    returnDto.packagingUnitResults = [];
    super.setDefaultRecyclabilityData(returnDto);
    return returnDto;
  }

  private getRecTableData(recDataSource: RecyclabilityResultPackagingSystem[][]): ComparisonRowDefinition[][] {
    const recTableData: ComparisonRowDefinition[][] = [];
    if (!recDataSource) { return recTableData; }
    const allLabels = recDataSource[0][0].dataSource.map(x => x.label);
    for (const recCountryData of recDataSource) {
      const recCountryTableData: ComparisonRowDefinition[] = [];
      for (const packaging of recCountryData) {
        for (const label of allLabels) {
          super.getRecTableCellData(packaging.dataSource, label, recCountryTableData);
        }
        super.getRecTableCellData(packaging.dataSource, 'total', recCountryTableData, true);
      }
      recTableData.push(recCountryTableData);
    }
    return recTableData;
  }

  createNewRecyclabilityAnalysis(e: { id: number, version: number }) {
    super.createRecyclabilityAnalysis(e.id, e.version, PackagingPart.System);
  }

  // --- LIFE CYCLE ANALYSIS ---

  getLcaResultsPackagingSystem(
    lcaWrappersList: HistoryWrapperLifeCycle[], packagingSystems: PackagingSystemDto[], countryCodes: string[]
  ): LifeCycleResultPackagingSystem[][] {
    const allLcaResults: LifeCycleResultPackagingSystem[][] = lcaWrappersList.map(wrapper => {
      const lcaResults: LifeCycleResultPackagingSystem[] = [];
      const packagingSystem = packagingSystems?.find(y => y.id === wrapper.id);
      if (!packagingSystem) { return lcaResults; }
      const brandName = packagingSystem.brandName;
      const productName = packagingSystem.productName;
      const lcaDtos = wrapper.lifeCycleDtos[0] as PackagingSystemLifeCycleResultDto[];
      countryCodes.forEach(countryCode => {
        const lcaDto = (lcaDtos && lcaDtos.find(x => x.evaluatedCountry === countryCode)) ??
          this.getDummyPackagingSystemLca(wrapper.id, wrapper.version, brandName, productName, countryCode);
        const lcaResult = this.lifeCycleService.getPackagingSystemLcaResultFromDto(lcaDto);
        lcaResults.push(lcaResult)
      });
      return lcaResults;
    });
    return this.getLcaDataSourcePackagingSystems(allLcaResults, countryCodes);
  }

  private getLcaDataSourcePackagingSystems(
    allEvaluations: LifeCycleResultPackagingSystem[][], countryCodes: string[]
  ): LifeCycleResultPackagingSystem[][] {
    const evaluationByCountry: LifeCycleResultPackagingSystem[][] = [];
    countryCodes.forEach(_ => evaluationByCountry.push([]));
    for (const evaluation of allEvaluations) {
      for (let index = 0; index < countryCodes.length; index++) {
        const countryCode = countryCodes[index];
        const countryEval = evaluation ? evaluation.find(x => x.evaluatedCountryCode === countryCode) : null;
        if (!countryEval) { throw new Error("ComparePackagingSystemsComponent: evaluation for country not found"); }
        evaluationByCountry[index].push(countryEval);
      }
    }
    return evaluationByCountry;
  }

  private getDummyPackagingSystemLca(
    packagingSystemId: number, version: number, brandName: string, productName: string, countryCode: string
  ): PackagingSystemLifeCycleResultDto {
    const dummyPackagingSystem = new PackagingSystemDto();
    dummyPackagingSystem.id = packagingSystemId;
    dummyPackagingSystem.version = version;
    dummyPackagingSystem.brandName = brandName;
    dummyPackagingSystem.productName = productName;
    const emptyDto = new PackagingSystemLifeCycleResultDto();
    emptyDto.analyzedPackagingSystem = dummyPackagingSystem;
    emptyDto.evaluatedCountry = countryCode;
    emptyDto.packagingSystemResults = [];
    emptyDto.packagingUnitResults = [];
    return emptyDto;
  }

  private getLcaTableData(lcaDataSource: LifeCycleResultPackagingSystem[][]): ComparisonLcaRowDefinition[][] {
    const lcaTableData: ComparisonLcaRowDefinition[][] = [];
    if (!lcaDataSource) { return lcaTableData; }
    for (const lcaCountryData of lcaDataSource) {
      const lcaCountryTableData: ComparisonLcaRowDefinition[] = [];
      const allRelevantCategories: string[] = super.getRelevantEffectKeys(lcaCountryData);
      const allCategories: string[] = lcaCountryData[0].effectRelevances.map(x => x.key);
      for (const packaging of lcaCountryData) {
        for (const category of allCategories) {
          const effectsTotal = packaging?.lifeCycleDataSource?.environmentalEffectsTotal;
          const effectRelevances = packaging.effectRelevances
          const volume = packaging.packagingVolume ?? -1;
          const weight = packaging.packagingWeight ?? -1;
          super.getLcaTableCellData(effectsTotal, category, effectRelevances, lcaCountryTableData, volume, weight);
        }
      }
      lcaCountryTableData.forEach(x => x.isRelevant = allRelevantCategories.includes(x.key));
      const relevantTableData = lcaCountryTableData.filter(x => x.isRelevant === true);
      const remainingTableData = lcaCountryTableData.filter(x => x.isRelevant === false);
      lcaTableData.push(relevantTableData.concat(remainingTableData));
    }
    return lcaTableData;
  }

  createNewLifecycleAnalysis(e: { id: number, version: number }) {
    super.createLifecycleAnalysis(e.id, e.version, PackagingPart.System);
  }

  private allPackagingSystemsHaveVolume(packagingSystems: PackagingSystemDto[]): boolean {
    return false; // TODO calculate
  }

  private allPackagingSystemsHaveWeight(packagingSystems: PackagingSystemDto[]): boolean {
    return false; // TODO calculate
  }
}
