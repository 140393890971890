import { Component, ViewChild } from '@angular/core';
import { MatMenu } from '@angular/material/menu';
import { Router } from '@angular/router';
import { PathResolveService } from 'src/app/services/path-resolve.service';

@Component({
  selector: 'app-reports-menu',
  templateUrl: './reports-menu.component.html',
  styleUrls: ['./reports-menu.component.scss']
})
export class ReportsMenuComponent {

  @ViewChild(MatMenu, { static: true }) menu!: MatMenu;

  constructor(private router: Router) { }

  createPackagingReportTemplate() {
    const path = PathResolveService.getCreatePackagingUnitReportTemplatePath(true);
    this.router.navigate([path]);
  }

  createComponentReportTemplate() {
    const path = PathResolveService.getCreateComponentReportTemplatePath(true);
    this.router.navigate([path]);
  }

  createMaterialReportTemplate() {
    const path = PathResolveService.getCreateMaterialReportTemplatePath(true);
    this.router.navigate([path]);
  }

  viewReportTemplates() {
    const path = PathResolveService.getReportTemplatesHomePath(true);
    this.router.navigate([path]);
  }

  viewReports() {
    const path = PathResolveService.getReportsHomePath(true);
    this.router.navigate([path]);
  }
}
