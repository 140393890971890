export class VersionDto {
    id?: number;
    versionNumber?: number;
    versionName?: string;
    isCurrentVersion = false;
    creationTimestamp?: Date;
    hasRecyclabilityResult?: boolean;
    hasLifeCycleResult?: boolean;
    distributionCountries?: string[];
    productName?: string;
}
