import { PackagingSystemEntryRecyclabilityResultDto, PackagingSystemRecyclabilityResultDto, PackagingUnitEntryRecyclabilityResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-system-recyclability-result-dto';
import { RecyclabilityLabelData } from 'src/app/util/analyses-util/recyclability/recyclability-label-data';
import { RecyclabilityResult } from './recyclability-result';

export class RecyclabilityResultPackagingSystem extends RecyclabilityResult {

  childUnitResults: PackagingUnitEntryRecyclabilityResultDto[];
  childSystemResults: PackagingSystemEntryRecyclabilityResultDto[];

  constructor(
    private recResultDto: PackagingSystemRecyclabilityResultDto,
    protected labelData: RecyclabilityLabelData,
    public countryName: string
  ) {
    super(recResultDto);
    const system = this.recResultDto.analyzedPackagingSystem;
    this.packagingPartId = system.id;
    this.packagingPartVersion = system.version ?? 0;
    this.packagingPartName = `${system.brandName}/${system.productName}`;
    this.packagingPartDistributionCountries = system.distributionCountries;
    this.evaluatedCountry = this.countryName;
    this.childUnitResults = this.recResultDto.packagingUnitResults;
    this.childSystemResults = this.recResultDto.packagingSystemResults;
    this.labelData = labelData;

    const systemsAndUnits: (PackagingUnitEntryRecyclabilityResultDto | PackagingSystemEntryRecyclabilityResultDto)[] = [];
    this.childUnitResults.forEach((child: PackagingUnitEntryRecyclabilityResultDto) => systemsAndUnits.push(child));
    this.childSystemResults.forEach((child: PackagingSystemEntryRecyclabilityResultDto) => systemsAndUnits.push(child));
    this.setDataSourcePackagingUnitsAndSystems(systemsAndUnits);
  }

  private setDataSourcePackagingUnitsAndSystems(componentResults:
    (PackagingUnitEntryRecyclabilityResultDto | PackagingSystemEntryRecyclabilityResultDto)[]
  ) {
    if (!componentResults) { return; }
    this.dataSource = super.createHighchartDataSource(componentResults);
    this.dataSource.forEach(x => x.components.forEach(component => {
      if (Object.prototype.hasOwnProperty.call(component.analyzedComponent, 'analyzedPackagingUnitEntry')) {
        const puEntry = (component.analyzedComponent as PackagingUnitEntryRecyclabilityResultDto).analyzedPackagingUnitEntry;
        const packagingUnit = puEntry.underlyingPackagingUnit;
        component.name = `${packagingUnit.brandName} (${packagingUnit.productName})`;
      } else if (Object.prototype.hasOwnProperty.call(component.analyzedComponent, 'analyzedPackagingSystemEntry')) {
        const psEntry = (component.analyzedComponent as PackagingSystemEntryRecyclabilityResultDto).analyzedPackagingSystemEntry;
        const packagingSystem = psEntry.underlyingPackagingSystem;
        component.name = `${packagingSystem.brandName} (${packagingSystem.productName})`;
      } else {
        throw new Error("RecyclabilityResult: unrecognized packaging part type");
      }
    }));
  }
}
