import { ChangeMaterialCandidateDto } from '../entities/material-entities/change-material-candidate-dto';
import { ImportMaterialCandidateDto } from '../entities/material-entities/import-material-candidate-dto';
import { PackagingComponentExportProfileDto } from '../entities/component-entities/packaging-component-export-profile-dto';
import { PackagingUnitExportProfileDto } from '../entities/packaging-unit-entities/packaging-unit-export-profile-dto';
import { ParentApiService } from './parent-api-service';
import { ChangeComponentCandidateDto } from '../entities/component-entities/change-component-candidate-dto';
import { ImportComponentCandidateDto } from '../entities/component-entities/import-component-candidate-dto';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ImportPackagingUnitCandidateDto } from '../entities/packaging-unit-entities/import-packaging-unit-candidate-dto';
import { ChangePackagingUnitCandidateDto } from '../entities/packaging-unit-entities/change-packaging-unit-candidate-dto';
import { environment } from 'src/environments/environment';
import { CompositeMaterialExportProfileDto } from '../entities/material-entities/composite-material-export-profile-dto';
import { PackagingSystemExportProfileDto } from '../entities/packaging-system-entities/packaging-system-export-profile-dto';
import { ChangePackagingSystemCandidateDto } from '../entities/packaging-system-entities/change-packaging-system-candidate-dto';
import { ImportPackagingSystemCandidateDto } from '../entities/packaging-system-entities/import-packaging-system-candidate-dto';

@Injectable({ providedIn: 'root' })
export class ImportExportApiService extends ParentApiService {

  constructor(private http: HttpClient) {
    super();
  }

  // Packaging Systems

  public getPackagingSystemExportProfiles(): Observable<PackagingSystemExportProfileDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-system-export-profiles`;
    return this.http.get<PackagingSystemExportProfileDto[]>(url);
  }

  public setPackagingSystemExportProfile(dto: PackagingSystemExportProfileDto, stayOnPageIfError?: boolean): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-system-export-profiles`;
    return this.http.put<any>(url, dto, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

  public deletePackagingSystemExportProfile(profileId: number): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-system-export-profiles?id=${profileId}`;
    return this.http.delete(url);
  }

  public exportPackagingSystem(
    packagingSystemId: number, principalId: number, psProfileId?: number, puProfileId?: number, stayOnPageIfError?: boolean
  ): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.set('packagingSystemId', packagingSystemId);
    params = params.set('principalId', principalId);
    if (psProfileId != null) { params = params.set('packagingSystemExportProfileId', psProfileId); }
    if (puProfileId != null) { params = params.set('packagingUnitExportProfileId', puProfileId); }
    const url = `${environment.webApiAddress}/api/packaging-systems/export`;
    return this.http.put(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public importPackagingSystem(
    importCandidateId: number, directoryId: number, tracking: boolean, stayOnPageIfError = true
  ): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.set('importCandidateId', importCandidateId);
    params = params.set('directoryId', directoryId);
    params = params.set('tracking', tracking);
    const url = `${environment.webApiAddress}/api/packaging-systems/import`;
    return this.http.put(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public getPackagingSystemImportCandidates(): Observable<ImportPackagingSystemCandidateDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-systems/import-candidates`;
    return this.http.get<ImportPackagingSystemCandidateDto[]>(url);
  }

  public deletePackagingSystemImportCandidate(importCandidateId: number): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-systems/import-candidates?importCandidateId=${importCandidateId}`;
    return this.http.delete(url);
  }

  public getPackagingSystemTrackedChanges(): Observable<ChangePackagingSystemCandidateDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-systems/changes`;
    return this.http.get<ChangePackagingSystemCandidateDto[]>(url);
  }

  public ignorePackagingSystemChanges(packagingSystemId: number): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-systems/ignore-changes?packagingSystemId=${packagingSystemId}`;
    return this.http.put(url, null);
  }

  public applyPackagingSystemChanges(packagingSystemId: number, stayOnPageIfError = true): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-systems/adopt-changes?packagingSystemId=${packagingSystemId}`;
    return this.http.put(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

  // Packaging Units

  public getPackagingUnitExportProfiles(): Observable<PackagingUnitExportProfileDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-unit-export-profiles`;
    return this.http.get<PackagingUnitExportProfileDto[]>(url);
  }

  public setPackagingUnitExportProfile(dto: PackagingUnitExportProfileDto, stayOnPageIfError?: boolean): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-unit-export-profiles`;
    return this.http.put<any>(url, dto, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

  public deletePackagingUnitExportProfile(profileId: number): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-unit-export-profiles?id=${profileId}`;
    return this.http.delete(url);
  }

  public exportPackagingUnit(
    packagingUnitId: number, principalId: number, exportProfileId?: number, stayOnPageIfError?: boolean
  ): Observable<any> {
    let url =
      `${environment.webApiAddress}/api/packaging-units/export?packagingUnitId=${packagingUnitId}&principalId=${principalId}`;
    if (exportProfileId != null) {
      url = url.concat(`&exportProfileId=${exportProfileId}`);
    }
    return this.http.put(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

  public importPackagingUnit(importCandidateId: number, directoryId: number, tracking: boolean, stayOnPageIfError = true): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.set('importCandidateId', importCandidateId);
    params = params.set('directoryId', directoryId);
    params = params.set('tracking', tracking);
    const url = `${environment.webApiAddress}/api/packaging-units/import`;
    return this.http.put(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public getPackagingUnitImportCandidates(): Observable<ImportPackagingUnitCandidateDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-units/import-candidates`;
    return this.http.get<ImportPackagingUnitCandidateDto[]>(url);
  }

  public deletePackagingUnitImportCandidate(importCandidateId: number): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-units/import-candidates?importCandidateId=${importCandidateId}`;
    return this.http.delete(url);
  }

  public getPackagingUnitTrackedChanges(): Observable<ChangePackagingUnitCandidateDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-units/changes`;
    return this.http.get<ChangePackagingUnitCandidateDto[]>(url);
  }

  public ignorePackagingUnitChanges(packagingUnitId: number): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-units/ignore-changes?packagingUnitId=${packagingUnitId}`;
    return this.http.put(url, null);
  }

  public applyPackagingUnitChanges(packagingUnitId: number, stayOnPageIfError = true): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-units/adopt-changes?packagingUnitId=${packagingUnitId}`;
    return this.http.put(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

  // Components

  public getPackagingComponentExportProfiles(): Observable<PackagingComponentExportProfileDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-component-export-profiles`;
    return this.http.get<PackagingComponentExportProfileDto[]>(url);
  }

  public setPackagingComponentExportProfile(dto: PackagingComponentExportProfileDto, stayOnPageIfError?: boolean): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-component-export-profiles`;
    return this.http.put<any>(url, dto, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

  public deletePackagingComponentExportProfile(profileId: number): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-component-export-profiles?id=${profileId}`;
    return this.http.delete(url);
  }

  public exportComponents(
    componentId: number, principalId: number, exportProfileId?: number, stayOnPageIfError?: boolean
  ): Observable<any> {
    let url = `${environment.webApiAddress}/api/packaging-components/export?packagingComponentId=${componentId}&principalId=${principalId}`;
    if (exportProfileId != null) {
      url = url.concat(`&exportProfileId=${exportProfileId}`);
    }
    return this.http.put(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

  public importComponents(importCandidateId: number, directoryId: number, tracking: boolean, stayOnPageIfError = true): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.set('importCandidateId', importCandidateId);
    params = params.set('directoryId', directoryId);
    params = params.set('tracking', tracking);
    const url = `${environment.webApiAddress}/api/packaging-components/import`;
    return this.http.put(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public getComponentImportCandidates(): Observable<ImportComponentCandidateDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-components/import-candidates`;
    return this.http.get<ImportComponentCandidateDto[]>(url);
  }

  public deleteComponentImportCandidate(importCandidateId: number): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-components/import-candidates?importCandidateId=${importCandidateId}`;
    return this.http.delete(url);
  }

  public getComponentTrackedChanges(): Observable<ChangeComponentCandidateDto[]> {
    const url = `${environment.webApiAddress}/api/packaging-components/changes`;
    return this.http.get<ChangeComponentCandidateDto[]>(url);
  }

  public ignoreComponentChanges(componentId: number): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-components/ignore-changes?packagingComponentId=${componentId}`;
    return this.http.put(url, null);
  }

  public applyComponentChanges(componentId: number, stayOnPageIfError = true): Observable<any> {
    const url = `${environment.webApiAddress}/api/packaging-components/adopt-changes?packagingComponentId=${componentId}`;
    return this.http.put(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

  // Materials

  public getCompositeMaterialExportProfiles(): Observable<CompositeMaterialExportProfileDto[]> {
    const url = `${environment.webApiAddress}/api/multi-material-export-profiles`;
    return this.http.get<CompositeMaterialExportProfileDto[]>(url);
  }

  public setCompositeMaterialExportProfile(dto: CompositeMaterialExportProfileDto, stayOnPageIfError?: boolean): Observable<any> {
    const url = `${environment.webApiAddress}/api/multi-material-export-profiles`;
    return this.http.put<any>(url, dto, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

  public deleteCompositeMaterialExportProfile(profileId: number): Observable<any> {
    const url = `${environment.webApiAddress}/api/multi-material-export-profiles?id=${profileId}`;
    return this.http.delete(url);
  }

  public exportMaterial(materialId: number, principalId: number, exportProfileId?: number, stayOnPageIfError?: boolean): Observable<any> {
    let url = `${environment.webApiAddress}/api/multi-materials/export?multiMaterialId=${materialId}&principalId=${principalId}`;
    if (exportProfileId != null) {
      url = url.concat(`&exportProfileId=${exportProfileId}`);
    }
    return this.http.put(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

  public importMaterials(importCandidateId: number, directoryId: number, tracking: boolean, stayOnPageIfError = true): Observable<any> {
    let params: HttpParams = new HttpParams();
    params = params.set('importCandidateId', importCandidateId);
    params = params.set('directoryId', directoryId);
    params = params.set('tracking', tracking);
    const url = `${environment.webApiAddress}/api/multi-materials/import`;
    return this.http.put(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public getMaterialImportCandidates(): Observable<ImportMaterialCandidateDto[]> {
    const url = `${environment.webApiAddress}/api/multi-materials/import-candidates`;
    return this.http.get<ImportMaterialCandidateDto[]>(url);
  }

  public deleteMaterialImportCandidate(importCandidateId: number): Observable<any> {
    const url = `${environment.webApiAddress}/api/multi-materials/import-candidates?importCandidateId=${importCandidateId}`;
    return this.http.delete(url);
  }

  public getMaterialTrackedChanges(): Observable<ChangeMaterialCandidateDto[]> {
    const url = `${environment.webApiAddress}/api/multi-materials/changes`;
    return this.http.get<ChangeMaterialCandidateDto[]>(url);
  }

  public applyMaterialChanges(materialId: number, stayOnPageIfError = true): Observable<any> {
    const url = `${environment.webApiAddress}/api/multi-materials/adopt-changes?multiMaterialId=${materialId}`;
    return this.http.put(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

  public ignoreMaterialChanges(materialId: number): Observable<any> {
    const url = `${environment.webApiAddress}/api/multi-materials/ignore-changes?multiMaterialId=${materialId}`;
    return this.http.put(url, null);
  }
}
