<div class="flex-container">
  <mat-button-toggle-group name="environmentalEffect" #group="matButtonToggleGroup" vertical [value]="selectedEffectIdx"
    (change)="selectedEffectIdx = $event.value" class="buttons-div">
    <mat-button-toggle *ngFor="let effect of lcaChartDataSource.effectsInfo; let i = index" [value]="i"
    [matTooltip]="this.getTooltipName(i)"
    matTooltipPosition = "right">
    <mat-icon *ngIf="!this.isEffectAuthorized(i)">lock</mat-icon>
      {{effect.name}}
    </mat-button-toggle>
  </mat-button-toggle-group>
  <div class="chart-div">
    <mat-card class="description-container">
      <mat-card-content>
        <div class="total">
          {{ lcaChartDataSource.getEffectName(selectedEffectIdx) }}
          ({{ lcaChartDataSource.getEffectUnit(selectedEffectIdx) }})
        </div>
        <div>{{ lcaChartDataSource.getEffectDescription(selectedEffectIdx) }}</div>
        <div>
          <strong>{{ 'analysis.lifecycleAnalysis.measurementUnit' | translate }}</strong>
          {{ lcaChartDataSource.getEffectUnitDescription(selectedEffectIdx) }}
        </div>
      </mat-card-content>
    </mat-card>
    <mat-card class="chart-container">
      <app-buying-from-shop  *ngIf="!this.isEffectAuthorized(selectedEffectIdx)" [buyType]="buyType.EnvironmentallEffect"
        [contentValue]="lcaChartDataSource.getEffectName(selectedEffectIdx)">
      </app-buying-from-shop>
      <app-life-cycle-chart [chartRawData]="lcaChartDataSource.dataSource[selectedEffectIdx]" isLcaStagesChart="true"
        [unit]="lcaChartDataSource.getEffectUnit(selectedEffectIdx)" chartTitle="" [isDarkTheme]="isDarkTheme"
        [hasDrilldown]="false" [chartHeight]="300" [class]="this.isEffectAuthorized(this.selectedEffectIdx)? '' : 'hide-chart'">
      </app-life-cycle-chart>
    </mat-card>
    <alert type="info" class="lca-chart-alert">
      {{ 'analysis.lifecycleAnalysis.streamlinedLca' | translate: { version: ecoinventVersion } }}
    </alert>
  </div>
</div>
