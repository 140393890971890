<h1 mat-dialog-title>{{ dialogTitle }}</h1>

<mat-dialog-content>
  <app-packaging-components-overview [dataSource]="dataSource" [displayedColumns]="displayedColumns"
    [isUserValidator]="false" (packagingPartsSelected)="componentsSelected($event)">
  </app-packaging-components-overview>
</mat-dialog-content>

<mat-dialog-actions>
  <button (click)="importComponents(true)" mat-raised-button type="submit" color="primary"
    [disabled]="selectedComponents.length === 0">
    {{ 'component.addWithTracking' | translate }}</button>
  <button (click)="importComponents(false)" mat-raised-button type="submit" color="primary"
    [disabled]="selectedComponents.length === 0">
    {{ 'component.addWithoutTracking' | translate }}</button>
  <button (click)="closeDialog()" mat-raised-button type="button">
    {{ 'common.buttons.cancel' | translate }}</button>
</mat-dialog-actions>