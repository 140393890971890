<h1 mat-dialog-title *ngIf="action===dialogActions.ADD">{{ 'component.addDecoration' | translate }}
</h1>
<h1 mat-dialog-title *ngIf="action===dialogActions.EDIT">{{ 'component.editDecoration' | translate }}
</h1>

<mat-dialog-content [formGroup]="addEditDecorationForm">

  <app-component-sub-html-template [form]="addEditDecorationForm" [componentSubtypes]="componentSubtypes"
    [manufacturingCountries]="manufacturingCountries" [canEditForm]="canEditForm" [isDecoration]="true"
    [isTracked]="isTracked" [componentTypeSelectionLabel]="'component.fields.ofDecoration' | translate"
    [removabilityConditions]="removabilityConditions" (adhesivesOptionChanged)="onAdhesivesOptionChange($event)">
  </app-component-sub-html-template>

  <mat-card>
    <mat-card-content class="table-container-wide">
      <app-multi-material-table [parentCompositeMaterial]="compositeMaterial"
        [allMaterialFunctions]="allMaterialFunctions" [allColors]="allColors" [allManifestations]="allManifestations"
        [callerId]="callerId" [manufacturingCountry]="selectedManufacturingCountry"
        [packagingUnitTypeId]="packagingUnitTypeId" [canEditForm]="canEditForm" [isFirstOpen]="!isEditing"
        (totalWeightChanged)="totalWeightChanged($event, addEditDecorationForm)">
      </app-multi-material-table>
    </mat-card-content>
  </mat-card>
</mat-dialog-content>

<mat-dialog-actions>
  <button *ngIf="action===dialogActions.ADD" (click)="doAction()" mat-raised-button type="submit" color="primary">
    {{ 'common.buttons.add' | translate }}</button>
  <button *ngIf="action===dialogActions.EDIT" (click)="doAction()" mat-raised-button type="submit" color="primary"
    [disabled]="!canEditForm">{{ 'common.buttons.edit' | translate }}</button>
  <button (click)="closeDialog()" mat-raised-button type="button">
    {{ 'common.buttons.cancel' | translate }}</button>
</mat-dialog-actions>
