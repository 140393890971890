import { TranslateService } from '@ngx-translate/core';

export class RecyclabilityLabelData {

  labels: { key: string, numericKey: number, value: string }[];

  constructor(
    private translateService: TranslateService
  ) {
    this.labels = this.createLabelsDataSource();
  }

  private createLabelsDataSource() {
    const labels = [];

    labels.push({ key: 'A', numericKey: 1, value: this.getLabelName('A') });
    labels.push({ key: 'B', numericKey: 2, value: this.getLabelName('B') });
    labels.push({ key: 'C', numericKey: 3, value: this.getLabelName('C') });
    labels.push({ key: 'D', numericKey: 4, value: this.getLabelName('D') });
    labels.push({ key: 'X', numericKey: 5, value: this.getLabelName('X') });
    labels.push({ key: '?', numericKey: 0, value: this.getLabelName('Unknown') });

    return labels;
  }

  private getLabelName(type: string) {
    return this.translateService.instant('analysis.recyclabilityAnalysis.percentageRating' + type);
  }

  getLabel(key: string) {
    return this.labels.find(x => x.key === key)?.value;
  }

  getLabelFromNumericKey(key: number) {
    return this.labels.find(x => x.numericKey === key)?.value;
  }

  getKeyFromNumericKey(key: number) {
    return this.labels.find(x => x.numericKey === key)?.key;
  }
}
