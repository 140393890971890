import { ComponentReportDto } from 'src/app/data-transfer/entities/component-entities/component-report-dto';
import { Injectable } from '@angular/core';
import { PackagingUnitReportDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-report-dto';
import { InclusionMap } from '../templates/report-template-wizard/report-template-wizard.component';
import { InclusionMapContentType } from './dto-to-map-service';

@Injectable({ providedIn: 'root' })
export class MapToDtoService {

  parsePackagingUnitInclusionMapToTemplate(inclusionMap: InclusionMap): PackagingUnitReportDto | null {
    const resultTemplate = new PackagingUnitReportDto();
    const inclusionMapSubitems = inclusionMap.subitems;
    if (!inclusionMapSubitems) { return null; }

    for (const packagingPart of inclusionMapSubitems) {
      let target: any;
      let fieldName = packagingPart.name;
      if (packagingPart.name === 'primary') {
        target = resultTemplate;
        fieldName = 'masterData';
      } else {
        target = resultTemplate[packagingPart.name as keyof typeof resultTemplate];
      }
      if (!packagingPart.included) { this.nullifyListValue(fieldName, resultTemplate); }
      const packagingPartSubitems = packagingPart.subitems ?? [];
      this.setTemplateValues(target, packagingPartSubitems);
    }
    return resultTemplate;
  }

  parseComponentInclusionMapToTemplate(inclusionMap: InclusionMap): ComponentReportDto | null {
    const resultTemplate = new ComponentReportDto();
    const inclusionMapSubitems = inclusionMap.subitems;
    if (!inclusionMapSubitems) { return null; }

    for (const packagingPart of inclusionMapSubitems) {
      const packagingPartSubitems = packagingPart.subitems ?? [];
      this.setTemplateValues(resultTemplate, packagingPartSubitems);
    }
    return resultTemplate;
  }

  private setTemplateValues(template: PackagingUnitReportDto | ComponentReportDto, subitems: InclusionMap[]) {
    for (const subitem of subitems) {
      if (subitem.contentType === InclusionMapContentType.List || subitem.contentType === InclusionMapContentType.TableList) {
        if (subitem.included) {
          this.setListFieldValues(subitem, template[subitem.name as keyof typeof template]);
        } else {
          this.nullifyListValue(subitem.name, template);
        }
      } else {
        this.setBooleanValue(subitem, template);
      }
    }
  }

  private setListFieldValues(mapItem: InclusionMap, target: any) {
    const inclusionMapMasterData = mapItem.associatedObject;
    if (!inclusionMapMasterData) { return null; }

    const fieldNames = Object.keys(target);
    fieldNames.forEach(fieldName =>
      target[fieldName as keyof typeof target] =
      inclusionMapMasterData.find((x: { name: string, label: string, included: boolean }) => x.name === fieldName)?.included);
  }

  setBooleanValue(mapItem: InclusionMap, target: any) {
    target[mapItem.name] = mapItem.included;
  }

  nullifyListValue(fieldName: string, target: any) {
    target[fieldName] = null;
  }
}
