import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { PermissionTypeDto } from 'src/app/data-transfer/entities/permission-type-dto';
import { CreditsService } from 'src/app/services/credits-service';
import { ImportExportApiService } from 'src/app/data-transfer/services/import-export-api-service';
import { DirectoryApiService } from '../../../../data-transfer/services/directory-api-service';
import { TranslateService } from '@ngx-translate/core';
import { MaterialApiService } from '../../../../data-transfer/services/material-api-service';
import { MaterialNavigationService } from '../../../../navigation/services/navigation-services/material-navigation.service';
import { OverviewTableComponent } from './../../../shared-components/parent-components/overview-table/overview-table.component';
import { MultiMaterialCompositeDto } from 'src/app/data-transfer/entities/material-entities/multi-material-composite-dto';
import { Component, EventEmitter, Input, Output, SimpleChanges, OnChanges, AfterViewInit, Inject, LOCALE_ID } from '@angular/core';
import { DeleteDialogData, DeleteItemDialogComponent } from 'src/app/components/dialogs/delete-item-dialog/delete-item-dialog.component';
import { SimpleAlertDialogComponent, SimpleDialogData } from 'src/app/components/dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { SelectLocationDialogComponent } from '../../dialogs/select-location-dialog/select-location-dialog.component';
import { forkJoin } from 'rxjs';
import { MultiMaterialCompositeImportWrapper } from 'src/app/components/dialogs/selection-dialog-materials/selection-dialog-materials.component';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { DirectoryDto } from 'src/app/data-transfer/entities/directory-dto';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { DeletedEntitiesDto, DeletedEntityDto } from 'src/app/data-transfer/entities/deleted-entities-dto';
import { DialogActions } from 'src/app/model/dictionary';

@Component({
  selector: 'app-composite-materials-overview',
  templateUrl: './composite-materials-overview.component.html',
  styleUrls: ['./composite-materials-overview.component.scss']
})
export class CompositeMaterialsOverviewComponent extends OverviewTableComponent implements OnChanges, AfterViewInit {

  @Input() dataSource: MatTableDataSource<MultiMaterialCompositeImportWrapper>;
  @Input() displayedColumns: string[] = [];
  @Input() isUserValidator = false;
  @Input() isRecyclingBinSelected = false;

  @Output() dataChanged = new EventEmitter();

  selectedMaterials: MultiMaterialCompositeDto[] = [];
  callerLevelId = PackagingPart.Material;

  constructor(
    private materialApiService: MaterialApiService,
    protected navigationService: MaterialNavigationService,
    protected directoryApiService: DirectoryApiService,
    protected importExportApiService: ImportExportApiService,
    protected dialog: MatDialog,
    protected translateService: TranslateService,
    protected spinner: NgxSpinnerService,
    protected creditsService: CreditsService,
    @Inject(LOCALE_ID) public locale: string
  ) {
    super(dialog, directoryApiService, importExportApiService, navigationService, translateService, spinner, creditsService, locale);
    this.dataSource = new MatTableDataSource<MultiMaterialCompositeImportWrapper>();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource != null) {
      super.setDataSourceFilter(this.dataSource);
      this.dataSourceSubscription = this.dataSource.connect().subscribe(_ => {
        this.updateDisplayTrackedColumn();
      });
    }
  }

  updateDisplayTrackedColumn() {
    super.updateDisplayTrackedColumn(this.dataSource);
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  navigateToMaterial(material: MultiMaterialCompositeDto) {
    if (material.id == null) { return; }
    this.navigationService.navigateToMaterial(material.id);
  }

  movePackagingPart(rootFolder: DirectoryDto, unreachableFolders: DirectoryDto[]) {
    const data = { rootFolder, unreachableFolders };
    const dialogConfig = getDialogConfig(data, '500px');
    const dialogRef = this.dialog.open(SelectLocationDialogComponent, dialogConfig);
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.REJECT) { return; }
      const observables = this.selectedMaterials.map(material => {
        if (material.id == null || material.version == null) { return; }
        return this.directoryApiService.moveMaterial(result.data.id, material.id, material.version);
      });
      this.moveBackendSubscription = forkJoin(observables).subscribe(_ => this.dataChanged.emit());
    });
  }

  copyPackagingPart(rootFolder: DirectoryDto, unreachableFolders: DirectoryDto[]) {
    const data = { rootFolder, unreachableFolders };
    const dialogConfig = getDialogConfig(data, '500px');
    const dialogRef = this.dialog.open(SelectLocationDialogComponent, dialogConfig);
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.REJECT) { return; }
      const observables = this.selectedMaterials.map(material => {
        if (material.id == null || material.version == null) { return; }
        return this.directoryApiService.copyMaterial(result.data.id, material.id, material.version)
      });
      this.copyBackendSubscription = forkJoin(observables).subscribe(_ => this.dataChanged.emit(), _ => this.spinner.hide());
    });
  }

  deletePackagingPart(itemsToDelete = this.selectedMaterials) {
    const observables = itemsToDelete.map(material => {
      if (material.id != null) { return this.materialApiService.getMaterialPermissions(material.id); }
    });
    this.permissionsSubscription = forkJoin(observables).subscribe((permissionsList: PermissionTypeDto[]) => {
      if (permissionsList.filter(x => x?.delete === false).length > 0) {
        this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(this.getDeleteNotAllowedData(this.translateService), '500px'));
      } else {
        this.openDeleteDialog(itemsToDelete);
      }
    });
  }

  async exportPackagingPart() {
    const dialogConfig = getDialogConfig({ materials: this.selectedMaterials }, '50%');
    const exportProfilesFunction = () => this.importExportApiService.getCompositeMaterialExportProfiles();
    const exportFunction = (materialId: number, principalId: number, profileId?: number) =>
      this.importExportApiService.exportMaterial(materialId, principalId, profileId);
    super.exportItems(dialogConfig, exportProfilesFunction, exportFunction);
    this.resetSelection();
  }

  private openDeleteDialog(materials: MultiMaterialCompositeDto[]) {
    const dialogData: DeleteDialogData = {
      dialogHeader: this.translateService.instant('material.deleteMaterialHeader'),
      dialogText: this.translateService.instant('material.deleteMaterialsText')
    };
    const dialogRef = this.dialog.open(DeleteItemDialogComponent, getDialogConfig(dialogData, '500px'));
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.DELETE) {
        this.deleteMaterialsBulk(materials);
      }
    });
  }

  emptyRecyclingBin() {
    if (!this.isRecyclingBinSelected) {
      console.log('PackagingUnitsOverviewComponent: Recycling bin must be selected prior to emptying.');
      return;
    }
    this.deleteMaterialsBulk(this.dataSource.data);
  }

  private deleteMaterialsBulk(materials: MultiMaterialCompositeDto[]) {
    this.spinner.show();
    const deletePermamently = this.isRecyclingBinSelected;
    const materialsToDelete: DeletedEntityDto[] = materials.map(x => ({ id: x.id ?? -1, version: x.version ?? -1 }));
    const toDeleteDto: DeletedEntitiesDto = {
      deleted: materialsToDelete
    };
    this.deleteBackendSubscription = this.materialApiService.deleteCompositeMaterials(toDeleteDto, deletePermamently, true)
      .subscribe(_ => {
        this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(this.getDeleteMaterialsSuccessData(), '500px'));
        this.dataSource.data = this.dataSource.data.filter(value => {
          if (value.id != null) { return !toDeleteDto.deleted.map(x => x.id).includes(value.id); }
        });
        this.spinner.hide();
      }, error => {
        let errorData;
        if (error.status === 403) {
          errorData = this.getDeletionImpossibleData();
        } else {
          errorData = this.getDeleteMaterialsErrorData();
        }
        this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(errorData, '500px'));
        this.spinner.hide();
      });
  }

  restorePackagingPart() {
    this.restoreMaterials(this.selectedMaterials);
  }

  restoreAllMaterials() {
    this.restoreMaterials(this.dataSource.data);
  }

  private restoreMaterials(materials: MultiMaterialCompositeDto[]) {
    if (!this.isRecyclingBinSelected) {
      console.log('PackagingComponentsOverviewComponent: Recycling bin must be selected prior to restoring.');
      return;
    }
    for (const material of materials) {
      this.dataSource.data = this.dataSource.data.filter(value => {
        return value.id !== material.id;
      });
      this.restoreSubscription = this.directoryApiService.restoreMaterialFromRecyclingBin(
        material.id, material.version)?.subscribe(_ => {
          window.location.reload();
        });
    }
  }

  getTagsSettingFunction(isEditing: boolean) {
    return (id: number, tagIds: number[]) => isEditing ?
      this.materialApiService.setCompositeMaterialTags(id, tagIds) :
      this.materialApiService.addCompositeMaterialTags(id, tagIds);
  }

  comparePackagingPart() {}

  editPackagingPartQuantity() {}

  resetSelection() {
    this.selectedMaterials = [];
  }

  selectAllMaterials() {
    this.allItemsSelected = !this.allItemsSelected;
    this.selectedMaterials = [];
    if (this.allItemsSelected) {
      for (const element of this.dataSource.data) {
        if (!Object.prototype.hasOwnProperty.call(element, 'isValidForComponent') ||
          element.isValidForComponent) {
          this.selectedMaterials.push(element);
        }
      }
    }
    this.packagingPartsSelected.emit(this.selectedMaterials);
  }

  isMaterialSelected(item: MultiMaterialCompositeDto): boolean {
    if (this.selectedMaterials && this.selectedMaterials.length > 0) {
      return this.selectedMaterials.includes(item);
    }
    return false;
  }

  setMaterialSelected(checked: boolean, item: MultiMaterialCompositeDto) {
    if (checked) {
      this.selectedMaterials.push(item);
    } else {
      const index = this.selectedMaterials.indexOf(item);
      this.selectedMaterials.splice(index, 1);
    }
    this.packagingPartsSelected.emit(this.selectedMaterials);
  }

  private getDeleteMaterialsSuccessData(): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.success'),
      messages: [this.translateService.instant('dataManagement.directory.dialog.removal.materialsDeletedSuccess')], icon: 'info'
    };
  }

  private getDeletionImpossibleData(): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.error'),
      messages: [this.translateService.instant('material.messages.materialInUse')],
      icon: 'error'
    };
  }

  private getDeleteMaterialsErrorData(): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.error'),
      messages: [this.translateService.instant('dataManagement.directory.dialog.removal.materialsDeletedError')], icon: 'error'
    };
  }
}
