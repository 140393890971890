<mat-table [dataSource]="dataSource" class="mat-elevation-z8" id="profile-table" *ngIf="dataSource.data.length > 0">
  <ng-container matColumnDef="profileName">
    <mat-header-cell *matHeaderCellDef> {{ 'dataManagement.exportProfiles.tableColumns.name' | translate }}
    </mat-header-cell>
    <mat-cell [ngClass]="{'selectable-row': canSelectRows}" *matCellDef="let element" (click)="onNameClicked(element)">
      {{ element.profileName }}
      <div *ngIf="element.isDefaultProfile" class="bold-default">
        ({{ 'dataManagement.exportProfiles.defaultProfile' | translate }})
      </div>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="owningOrganizationName">
    <mat-header-cell *matHeaderCellDef> {{ 'dataManagement.exportProfiles.tableColumns.org' | translate }}
    </mat-header-cell>
    <mat-cell *matCellDef="let element"> {{element.owningOrganizationName}} </mat-cell>
  </ng-container>

  <ng-container matColumnDef="actions">
    <mat-header-cell *matHeaderCellDef> {{ 'common.buttons.action' | translate }} </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <button mat-icon-button color="primary" (click)="editClicked(element)" type="button">
        <mat-icon *ngIf="element.isDefaultProfile && !isAdmin; else editIcon"
          matTooltip="{{ 'dataManagement.exportProfiles.buttons.tooltips.view' | translate }}">preview</mat-icon>
        <ng-template #editIcon>
          <mat-icon matTooltip="{{ 'dataManagement.exportProfiles.buttons.tooltips.edit' | translate }}">edit</mat-icon>
        </ng-template>
      </button>
      <button *ngIf="!element.isDefaultProfile || isAdmin" mat-icon-button color="primary"
        (click)="deleteClicked(element)"
        matTooltip="{{ 'dataManagement.exportProfiles.buttons.tooltips.delete' | translate }}" type="button">
        <mat-icon>delete_forever</mat-icon>
      </button>
    </mat-cell>
  </ng-container>

  <ng-container matColumnDef="viewProfile">
    <mat-header-cell *matHeaderCellDef> {{ 'common.buttons.action' | translate }} </mat-header-cell>
    <mat-cell *matCellDef="let element">
      <button mat-icon-button color="primary" (click)="viewClicked(element)"
        matTooltip="{{ 'dataManagement.exportProfiles.buttons.tooltips.view' | translate }}" type="button">
        <mat-icon>preview</mat-icon>
      </button>
    </mat-cell>
  </ng-container>

  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row mat-row *matRowDef="let row; columns: displayedColumns;" [class.selected-row]="selectedProfile === row"
    [ngClass]="isDarkTheme ? 'background-highlight-dark' : 'background-highlight'"></mat-row>
</mat-table>
