import { TranslateService } from '@ngx-translate/core';
import { ColorThemeService } from './../../../navigation/services/color-theme-service';
import { LifeCycleAnalysisInformation, LcaStageInfo, LcaEffectInfo } from './../../../model/life-cycle-data-information';
import { Component, Inject } from '@angular/core';
import { EffectInfosDictionary, TOTAL_VALUE_INDEX } from 'src/app/util/analyses-util/live-cycle/life-cycle-service';
import { EnvironmentalEffectsEvaluationResultDto } from 'src/app/data-transfer/entities/evaluation-entities/environmental-effects-evaluation-result-dto';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogActions } from 'src/app/model/dictionary';
import { LifeCycleResultDto } from 'src/app/data-transfer/entities/evaluation-entities/life-cycle-result-dto';

const IMPACT_KEY = 'impact';

@Component({
  selector: 'app-life-cycle-table-dialog',
  templateUrl: './life-cycle-table-dialog.component.html',
  styleUrls: ['./life-cycle-table-dialog.component.scss']
})
export class LifeCycleTableDialogComponent {

  formatValue = ((x: number) => x?.toExponential(4) ?? '???');
  formatPercentageValue = ((x: number) => (x * 100).toFixed(2) + '%' ?? '???');

  isDarkTheme = false;
  lifeCycleEvaluationResult!: LifeCycleResultDto;
  private effectsWithImpact: EffectInfosDictionary[];
  private lifeCycleStagesTableData: LcaStageInfo[];

  constructor(
    private dialogRef: MatDialogRef<LifeCycleTableDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data: any,
    private colorThemeService: ColorThemeService,
    private lifeCycleAnalysisInformation: LifeCycleAnalysisInformation,
    private translateService: TranslateService
  ) {
    this.lifeCycleEvaluationResult = this.data.lifeCycleEvaluationResult;
    this.effectsWithImpact = this.data.effectsList;
    const impactInfo = { key: IMPACT_KEY, name: this.translateService.instant('analysis.lifecycleAnalysis.impactHeader'), description: '' };
    this.lifeCycleStagesTableData = this.lifeCycleAnalysisInformation.lifeCycleStageInformation.concat(impactInfo);
    this.isDarkTheme = this.colorThemeService.isDarkTheme();
  }

  getTableRows(): LcaEffectInfo[] {
    return this.effectsWithImpact;
  }

  getTableColumns(): LcaStageInfo[] {
    return this.lifeCycleStagesTableData;
  }

  getTableDisplayedColumns() {
    const displayedColumns = ['lifeCycleStep'];
    this.getTableColumns().forEach(x => displayedColumns.push(x.key));
    return displayedColumns;
  }

  getTableElement(result: LifeCycleResultDto, column: LcaStageInfo, row: LcaEffectInfo) {
    return this.getEnvironmentalEffectValue(result, column.key, row.key);
  }

  private getEnvironmentalEffectValue(
    lifeCycleResult: LifeCycleResultDto, lifeCycleStage: string, effect: string
  ): string {
    if (lifeCycleStage === IMPACT_KEY) {
      const effectWithImpact = this.effectsWithImpact.find(x => x.key === effect);
      const impact = effectWithImpact ? effectWithImpact.impact : 0;
      return this.formatPercentageValue(impact);
    } else {
      let effectInfoDto;
      if (lifeCycleStage === 'environmentalEffectsMaterials') {
        effectInfoDto =
          lifeCycleResult.environmentalEffectsPrimaryMaterials[effect as keyof EnvironmentalEffectsEvaluationResultDto] +
          lifeCycleResult.environmentalEffectsSecondaryMaterials[effect as keyof EnvironmentalEffectsEvaluationResultDto] +
          lifeCycleResult.environmentalEffectsManufacturing[effect as keyof EnvironmentalEffectsEvaluationResultDto];
      } else {
        const evalResultDto: EnvironmentalEffectsEvaluationResultDto | null =
          lifeCycleResult[lifeCycleStage as keyof LifeCycleResultDto] as EnvironmentalEffectsEvaluationResultDto;
        effectInfoDto = evalResultDto[effect as keyof EnvironmentalEffectsEvaluationResultDto];
      }
      return this.formatValue(effectInfoDto);
    }
  }

  getTableElementType(columnIndex: number) {
    return columnIndex === TOTAL_VALUE_INDEX ?
      (this.isDarkTheme ? 'dark-highlighted-cell' : 'highlighted-cell') : 'default-cell';
  }

  closeDialog() {
    this.dialogRef.close({ event: DialogActions.REJECT });
  }
}
