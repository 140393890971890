import { LifeCycleResultPackagingUnit } from './../../../../model/evaluations/life-cycle-result-packaging-unit';
import { TranslateService } from '@ngx-translate/core';
import { Component, Input, OnInit, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import * as  Highcharts from 'highcharts';
import {
  DARK_THEME_BACKGROUND, DARK_THEME_BORDER, DARK_THEME_FONT, LIGHT_THEME_BACKGROUND,
  LIGHT_THEME_BORDER, LIGHT_THEME_FONT
} from 'src/app/model/charts-color-collection';
import Accessibility from 'highcharts/modules/accessibility';
import { LifeCycleResult } from 'src/app/model/evaluations/life-cycle-result';
import { LifeCycleResultPackagingSystem } from 'src/app/model/evaluations/life-cycle-result-packaging-system';
import { LicenseService} from 'src/app/services/licensing-service';
Accessibility(Highcharts);

interface ComparisonSeriesData extends Highcharts.PointOptionsObject {
  unit: string;
}

@Component({
  selector: 'app-life-cycle-comparison-chart',
  templateUrl: './life-cycle-comparison-chart.component.html',
  styleUrls: ['./life-cycle-comparison-chart.component.scss']
})
export class LifeCycleComparisonChartComponent implements OnInit, OnChanges {

  @Input() chartRawData: (LifeCycleResultPackagingUnit | LifeCycleResultPackagingSystem)[] = [];
  @Input() selectedEffectKey = '';
  @Input() selectedEffectUnit = '';
  @Input() isDarkTheme = false;
  @Input() newAllowed: boolean[] = [];
  @Input() chartSubtitle = '';

  @Output() newAnalysisRequested = new EventEmitter();

  private colorsDarkMode = ['#06AA81', '#6D06AA', '#AA5806', '#0696AA', '#AB0730', '#81AA06', '#0658AA', '#AA9606', '#AA06AA', '#06AA06'];
  private colorsLightMode = ['#2AACFC', '#E0FC2A', '#FC2C2A', '#43FC2A', '#FC7B2A', '#462AFC', '#91FC2A', '#FC2AC6', '#2AFCAF', '#FCC92A'];

  emptyEvaluations: LifeCycleResult[] = [];
  selectedPackagingUnitEval!: LifeCycleResult;

  updateFlag = false;
  highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    chart: {
      type: 'column',
      style: {
        fontFamily: 'Helsinki, sans-serif',
        fontWeight: 'normal'
      }
    },
    title: {
      style: {
        fontSize: '20px',
        'font-family': '\'Helsinki\', sans-serif',
      }
    },
    subtitle: {
      style: {
        fontSize: '14px',
        'font-family': '\'Helsinki\', sans-serif',
      }
    },
    credits: {
      enabled: false
    },
    navigation: {
      buttonOptions: {
        width: 28,
        height: 28,
        symbolX: 14,
        symbolY: 14,
        theme: {}
      },
      menuStyle: {},
      menuItemStyle: {},
      menuItemHoverStyle: {}
    },
    exporting: {
      sourceWidth: 1500,
      sourceHeight: 1000,
      buttons: {
        contextButton: {
          menuItems: ['viewFullscreen', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF']
        }
      }
    },
    plotOptions: {
      series: {
        dataLabels: {
          enabled: true,
          formatter() {
            return this.point.y === 0 ? '0' : this.point.y?.toExponential(2);
          },
          allowOverlap: true,
          style: {
            fontFamily: 'Helsinki, sans-serif',
            fontWeight: '400'
          }
        }
      }
    },
    tooltip: {
      headerFormat: '',
      /* formatter() {
        const customPoint = this.point.options as ComparisonSeriesData;
        return `<span style="color:${this.point.color}">${this.point.name}</span>: <b>${this.y === 0 ? '0' :
        this.y.toExponential(2)}</b> ${customPoint.unit}`;
      } */
    },
    lang: {
      viewFullscreen: this.translateService.instant('analysis.recyclabilityAnalysis.fullscreen'),
      exitFullscreen: this.translateService.instant('analysis.recyclabilityAnalysis.exitFullscreen'),
      downloadJPEG: this.translateService.instant('analysis.recyclabilityAnalysis.downloadJpeg'),
      downloadPNG: this.translateService.instant('analysis.recyclabilityAnalysis.downloadPng'),
      downloadPDF: this.translateService.instant('analysis.recyclabilityAnalysis.downloadPdf'),
    },
    legend: {
      layout: 'horizontal',
      itemStyle: {
        fontSize: '18px'
      },
    }
  };

  constructor(private translateService: TranslateService, public licenseService: LicenseService) { }

  ngOnInit(): void {
    this.setOriginDependentChartOptions();
    this.setChartData();
    this.fillEmptyEvalList();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.selectedEffectKey && changes.selectedEffectKey.previousValue
      && changes.selectedEffectKey.currentValue !== changes.selectedEffectKey.previousValue) ||
      (changes.chartRawData && changes.chartRawData.previousValue
        && changes.chartRawData.currentValue !== changes.chartRawData.previousValue) ||
      (changes.isDarkTheme && changes.isDarkTheme.previousValue !== undefined &&
        changes.isDarkTheme.currentValue !== changes.isDarkTheme.previousValue)
    ) {
      this.setOriginDependentChartOptions();
      this.setChartData();
      this.updateFlag = true;
    }
  }

  onDataUpdate() {
    this.setChartData();
    this.updateFlag = true;
  }

  private setOriginDependentChartOptions() {
    if (this.chartOptions.chart) {
      this.chartOptions.chart.backgroundColor = this.isDarkTheme ? DARK_THEME_BACKGROUND : LIGHT_THEME_BACKGROUND;
    }
    if (this.chartOptions.navigation) {
      if (this.chartOptions.navigation.buttonOptions && this.chartOptions.navigation.buttonOptions.theme) {
        this.chartOptions.navigation.buttonOptions.theme.fill = this.isDarkTheme ? DARK_THEME_BACKGROUND : LIGHT_THEME_BACKGROUND;
        this.chartOptions.navigation.buttonOptions.theme.stroke = this.isDarkTheme ? DARK_THEME_BORDER : LIGHT_THEME_BORDER;
      }
      if (this.chartOptions.navigation.menuStyle) {
        this.chartOptions.navigation.menuStyle.backgroundColor = this.isDarkTheme ? DARK_THEME_BACKGROUND : LIGHT_THEME_BACKGROUND;
      }
      if (this.chartOptions.navigation.menuItemStyle) {
        this.chartOptions.navigation.menuItemStyle.color = this.isDarkTheme ? DARK_THEME_FONT : LIGHT_THEME_FONT;
      }
      if (this.chartOptions.navigation.menuItemHoverStyle) {
        this.chartOptions.navigation.menuItemHoverStyle.backgroundColor = this.isDarkTheme ? DARK_THEME_BORDER : LIGHT_THEME_BORDER;
      }
    }
    if (this.chartOptions.legend && this.chartOptions.legend.itemStyle) {
      this.chartOptions.legend.itemStyle.color = this.isDarkTheme ? DARK_THEME_FONT : LIGHT_THEME_FONT;
    }
    if (this.chartOptions.title && this.chartOptions.title.style) {
      this.chartOptions.title.style.color = this.isDarkTheme ? DARK_THEME_FONT : LIGHT_THEME_FONT;
      this.chartOptions.title.text = this.translateService.instant('analysis.lifecycleAnalysis.contributorsByStage');
    }
    if (this.chartOptions.subtitle) {
      this.chartOptions.subtitle.text = this.chartSubtitle;
      if (this.chartOptions.subtitle.style) {
        this.chartOptions.subtitle.style.color = this.isDarkTheme ? DARK_THEME_FONT : LIGHT_THEME_FONT;
      }
    }
    this.chartOptions.xAxis = {
      type: 'category',
      labels: {
        style: {
          fontSize: '15px',
          'font-family': '\'Helsinki\', sans-serif',
          color: this.isDarkTheme ? DARK_THEME_FONT : LIGHT_THEME_FONT
        }
      }
    };
    this.chartOptions.yAxis = {
      labels: {
        formatter() {
          return (this.value as number) === 0 ? '0' : (this.value as number).toExponential(2);
        },
        style: {
          fontSize: '15px',
          'font-family': '\'Helsinki\', sans-serif',
          color: this.isDarkTheme ? DARK_THEME_FONT : LIGHT_THEME_FONT
        }
      },
      title: {
        text: ''
      }
    };
  }

  private setChartData() {
    this.chartOptions.series = [];
    for (let index = 0; index < this.chartRawData.length; index++) {
      const psEvaluation = this.chartRawData[index];
      const selectedEffectIndex = psEvaluation.effectRelevances.findIndex(x => x.key === this.selectedEffectKey);
      const lifeStagesData = psEvaluation.stagesChartDataSource[selectedEffectIndex];
      const seriesData: ComparisonSeriesData[] = [];
      if (lifeStagesData) {
        for (const effectEvaluation of lifeStagesData) {
          seriesData.push({
            name: effectEvaluation.name,
            y: effectEvaluation.value,
            unit: this.selectedEffectUnit
          });
        }
        this.chartOptions.series.push({
          type: 'column',
          name: psEvaluation.packagingPartName,
          data: seriesData,
          color: this.isDarkTheme ? this.colorsDarkMode[index] : this.colorsLightMode[index],
          borderColor: this.isDarkTheme ? this.colorsDarkMode[index] : this.colorsLightMode[index]
        });
      }
    }
  }

  private fillEmptyEvalList() {
    if (this.chartRawData.length !== this.newAllowed.length) { return; }
    this.emptyEvaluations = [];
    for (let index = 0; index < this.chartRawData.length; index++) {
      if (this.newAllowed[index]) {
        this.emptyEvaluations.push(this.chartRawData[index]);
      }
    }
    this.selectedPackagingUnitEval = this.emptyEvaluations[0];
  }

  createNewAnalysis() {
    this.newAnalysisRequested.emit({
      id: this.selectedPackagingUnitEval.packagingPartId,
      version: this.selectedPackagingUnitEval.packagingPartVersion
    });
  }
}
