import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { AuthService } from './services/auth-service';
import { ColorThemeService, COLOR_THEME_DARK } from './navigation/services/color-theme-service';
import { OverlayContainer } from '@angular/cdk/overlay';
import { PROJECT_IMAGES_PATH } from './model/paths';
import { DomSanitizer } from '@angular/platform-browser';
import { MatIconRegistry } from '@angular/material/icon';

const { title: appTitle } = require('package.json');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, OnDestroy {

  public title = '';
  isDarkTheme = false;
  imageSource = '';

  private themeSubscription?: Subscription;

  constructor(
    private authService: AuthService,
    private colorThemeService: ColorThemeService,
    private overlayContainer: OverlayContainer,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer
  ) {
    iconRegistry.addSvgIcon('analysis-icon', sanitizer.bypassSecurityTrustResourceUrl(`${PROJECT_IMAGES_PATH}/icons/analysis-icon.svg`));
  }

  async ngOnInit() {
    this.title = appTitle;

    await this.authService.initSerivce();

    this.themeSubscription = this.colorThemeService.colorThemeSubject.subscribe(nextValue => {
      this.isDarkTheme = nextValue === COLOR_THEME_DARK;
      this.changeTheme(this.isDarkTheme ? 'dark-theme' : 'app-theme');
      this.imageSource = `${PROJECT_IMAGES_PATH}logo/logo_paco_white.svg`;
    });
  }

  /**
   * Method to change theme in dialogs, dropdown-menus etc.
   * @param theme one of the two existing themes
   */
  private changeTheme(theme: 'app-theme' | 'dark-theme'): void {
    const overlayContainerClasses = this.overlayContainer.getContainerElement().classList;
    const themeClassesToRemove = Array.from(overlayContainerClasses)
      .filter((item: string) => item.includes('-theme'));
    if (themeClassesToRemove.length) {
      overlayContainerClasses.remove(...themeClassesToRemove);
    }
    overlayContainerClasses.add(theme);
  }

  ngOnDestroy(): void {
    this.themeSubscription?.unsubscribe();
  }
}
