import { ComponentRecyclabilityResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/component-recyclability-result-dto';
import { RecyclabilityResult } from './recyclability-result';
import { RecyclabilityLabelData } from 'src/app/util/analyses-util/recyclability/recyclability-label-data';
import { MaterialRecyclabilityResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/material-recyclability-result-dto';

export class RecyclabilityResultComponent extends RecyclabilityResult {
  materialResults: MaterialRecyclabilityResultDto[];

  constructor(
    private recResultDto: ComponentRecyclabilityResultDto,
    protected labelData: RecyclabilityLabelData,
    private countryName: string
  ) {
    super(recResultDto);
    const component = this.recResultDto.analyzedPackagingComponent;
    this.packagingPartId = component.id;
    this.packagingPartVersion = component.version ?? 0;
    this.packagingPartName = `${component.packagingComponentCategoryName}/${component.packagingComponentSubtypeName}`;
    this.packagingPartDistributionCountries = component.distributionCountries;
    this.evaluatedCountry = this.countryName;
    this.materialResults = this.recResultDto.materialResults;
    this.labelData = labelData;
    this.setDataSourceMaterials(this.materialResults);
  }

  private setDataSourceMaterials(materialResults: MaterialRecyclabilityResultDto[]) {
    if (!materialResults) { return; }
    this.dataSource = super.createHighchartDataSource(materialResults);
    this.dataSource.forEach(x => x.components.forEach(component => {
      const analyzedPackagingPart = (component.analyzedComponent as MaterialRecyclabilityResultDto).analyzedMaterial;
      component.name = `${analyzedPackagingPart.functionName}
      (${analyzedPackagingPart.materialManifestationName})`;
    }));
  }
}