import { AggregateEvaluationLifecycleOutputDto } from './../../../../data-transfer/entities/aggregate-evaluation-lifecycle-output-dto';
import { LifeCycleAggregatedEvaluationResult } from './../../../../util/analyses-util/live-cycle/life-cycle-aggregated-evaluation-result';
import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { EnvironmentalEffectDto } from 'src/app/data-transfer/entities/environmental-effect-dto';
import { ECOINVENT_VERSION, LifeCycleAnalysisInformation } from 'src/app/model/life-cycle-data-information';
import {LicenseService} from 'src/app/services/licensing-service'
import {DomSanitizer} from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import {CountryDto} from './../../../../data-transfer/entities/country-dto'
import {BuyType} from 'src/app/components/shared-components/shop/buying-from-shop/buying-from-shop.component'
@Component({
  selector: 'app-view-lifecycle-aggregate-evaluation',
  templateUrl: './view-lifecycle-aggregate-evaluation.component.html',
  styleUrls: ['./view-lifecycle-aggregate-evaluation.component.scss']
})
export class ViewLifecycleAggregateEvaluationComponent implements OnChanges {

  @Input() lcaEvaluation: AggregateEvaluationLifecycleOutputDto | null = null;
  @Input() isDarkTheme = false;

  lcaChartDataSource!: LifeCycleAggregatedEvaluationResult;
  selectedEffectIdx = 0;
  ecoinventVersion = ECOINVENT_VERSION;
  allowedEffects : EnvironmentalEffectDto[] = [];
  allowedCountries: CountryDto[] = [];
  showBuyText: boolean = false;
  emailString!: string;
  buyType = BuyType;

  constructor(private lcaInfo: LifeCycleAnalysisInformation,
              private licenseService: LicenseService,
              private sanitizer : DomSanitizer,
              private translateService: TranslateService) {
    this.allowedEffects = licenseService.allowedEffects;
    this.allowedCountries = licenseService.allowedCountries;
    this.emailString = "mailto:"+this.translateService.instant('shop.emailWorkaround.email')
      +'?Subject='+this.translateService.instant('shop.emailWorkaround.subjectContent')+'&body='
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.lcaEvaluation && changes.lcaEvaluation.currentValue) {
      this.lcaChartDataSource = new LifeCycleAggregatedEvaluationResult(changes.lcaEvaluation.currentValue, this.lcaInfo);
    }
  }

  protected getTooltipName(efftectIndex: number) {
    const effect = this.lcaChartDataSource.effectsInfo[efftectIndex];
    if (this.isEffectAuthorizedIntern(effect.key)) {
      return '';
    } else {
      return this.translateService.instant('analysis.lifecycleAnalysis.warnings.impactNotAuthorized',{name: ''});
    }
  }

  protected isEffectAuthorized(efftectIndex: number) : boolean {
    const effect = this.lcaChartDataSource.effectsInfo[efftectIndex];
    return this.isEffectAuthorizedIntern(effect.key);
  }

  private isEffectAuthorizedIntern(efftectName: string) : boolean {
    return this.allowedEffects.some((effect: EnvironmentalEffectDto) => effect.name == efftectName);
  }

  protected invertBuyText () {
    this.showBuyText = !this.showBuyText;
  }
  protected getEmailString (names:string[]) {
    let namestring = names.reduce((sum, current) => sum + current + '; ', '');
    namestring = namestring.slice(0, -2);
    return this.sanitize(this.emailString+this.translateService.instant('shop.emailWorkaround.content',{effect:namestring}));
  }

  protected getEmailContentStringForBrowser (names:string[]) {
    let namestring = names.reduce((sum, current) => sum + current + '; ', '');
    namestring = namestring.slice(0, -2);
    return this.translateService.instant('shop.emailWorkaround.environmentalEffects.content',{effect:namestring}).replace(/%0D%0A/g,'\n');
  }

  protected sanitize(url:string){
    return this.sanitizer.bypassSecurityTrustUrl(url);
  }

}
