import { ComponentReportDto } from '../../../../data-transfer/entities/component-entities/component-report-dto';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SimpleAlertDialogComponent, SimpleDialogData } from '../../../dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { PackagingUnitReportDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-report-dto';
import { ReportApiService } from 'src/app/data-transfer/services/report-api-service';
import { MatListOption } from '@angular/material/list';
import { DtoToMapService, InclusionMapContentType } from '../../services/dto-to-map-service';
import { MapToDtoService } from '../../services/map-to-dto-service';
import { PackagingUnitText } from 'src/app/model/path-building-blocks';
import { PathResolveService } from 'src/app/services/path-resolve.service';
import { PROJECT_IMAGES_PATH } from 'src/app/model/paths';

export interface InclusionMap {
  name: string;
  label?: string;
  included: boolean;
  subitems?: InclusionMap[];
  deactivatedSubitems?: InclusionMap[];
  associatedObject?: any;
  contentType?: number;
}

@Component({
  selector: 'app-report-template-wizard',
  templateUrl: './report-template-wizard.component.html',
  styleUrls: ['./report-template-wizard.component.scss']
})
export class ReportTemplateWizardComponent implements OnInit, OnDestroy {

  form: FormGroup;

  includeSpec!: InclusionMap;
  reportTemplateId?: number;
  contentTypes = InclusionMapContentType;
  displayAnnex = false;
  isPackagingUnitReport = false;

  columnsComponentsTable = ['component', 'type', 'articleName'];
  columnsRatingTable = ['component', 'type', 'function', 'material', 'color', 'rating', 'recyclingStream', 'mass'];
  columnsPercentageTable = ['name', 'percentageTotal', 'component', 'percentagePerComponent'];

  componentsImageSource = `${PROJECT_IMAGES_PATH}elements/packaging_types/bottle_gray.svg`;
  imagePlaceholderSource = `${PROJECT_IMAGES_PATH}image_placeholder.jpg`;
  documentPlaceholderSource = `${PROJECT_IMAGES_PATH}document_placeholder.jpg`;
  pieChartSource = `${PROJECT_IMAGES_PATH}elements/diagrams/pie-chart-generic.jpg`;

  private routeDataSubscription?: Subscription;
  private saveSubscription?: Subscription;

  translate = (key: string) => this.translateService.instant(key);

  constructor(
    private reportApiService: ReportApiService,
    private dialog: MatDialog,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
    private dtoToMapService: DtoToMapService,
    private mapToDtoService: MapToDtoService
  ) {
    this.form = this.fb.group({ reportName: [null, Validators.required] });
  }

  ngOnInit(): void {
    const path = this.location.path().substring(1);
    this.isPackagingUnitReport = path.includes(PackagingUnitText);
    this.routeDataSubscription = this.route.data.subscribe(data => {
      let template;
      if (this.isPackagingUnitReport) {
        template = data.reportTemplate ?? new PackagingUnitReportDto(true);
        this.includeSpec = this.dtoToMapService.getPackagingUnitSpecMapFromDto(template);
      } else {
        template = data.reportTemplate ?? new ComponentReportDto(true);
        this.includeSpec = this.dtoToMapService.getComponentSpecMapFromDto(template);
      }
      this.includeSpec.subitems?.forEach(subitem => this.includeSubitems(subitem, subitem.included));
      this.reportTemplateId = template.id;
      this.form.controls.reportName.setValue(template.profileName ?? null);
    });
  }

  includeSubitems(item: InclusionMap, isIncluded: boolean) {
    if (!isIncluded) {
      item.deactivatedSubitems = item.subitems;
      item.subitems = [];
    } else {
      if (item.deactivatedSubitems) {
        item.subitems = item.deactivatedSubitems;
        item.deactivatedSubitems = [];
      }
    }
    this.checkParent();
    this.setDisplayAnnex();
  }

  private checkParent() {
    if (!this.includeSpec.subitems) { return; }
    const nonAnalysisSubitems = this.includeSpec.subitems.filter(x => x.contentType !== InclusionMapContentType.Analysis);
    for (const reportPart of nonAnalysisSubitems) {
      let atLeastOneSubitemSelected = false;
      if (!reportPart.subitems) { continue; }
      for (const subitem of reportPart.subitems) {
        if (subitem.included) { atLeastOneSubitemSelected = true; }
      }
      reportPart.included = atLeastOneSubitemSelected;
    }
  }

  private setDisplayAnnex() {
    this.displayAnnex = false;
    this.includeSpec.subitems?.forEach(part => {
      part.subitems?.forEach(item => {
        if (item.contentType === InclusionMapContentType.Annex && item.included) {
          this.displayAnnex = true;
          return;
        }
      });
    });
  }

  saveReport() {
    this.form.markAllAsTouched();
    const reslultTemplate = this.isPackagingUnitReport ?
      this.mapToDtoService.parsePackagingUnitInclusionMapToTemplate(this.includeSpec) :
      this.mapToDtoService.parseComponentInclusionMapToTemplate(this.includeSpec);
    if (this.form.invalid || !reslultTemplate) { return; }
    const resultEmpty = Object.values(reslultTemplate).filter(x => x).length === 0;
    if (resultEmpty) {
      const doalogData = this.getErrorDialogData(this.translate('report.dialogs.emptyTemplateError'));
      this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(doalogData));
      return;
    }
    reslultTemplate.profileName = this.form.controls.reportName.value;
    reslultTemplate.id = this.reportTemplateId;

    let observable;
    if (this.isPackagingUnitReport) {
      observable = this.reportApiService.savePackagingUnitReportTemplate(reslultTemplate as PackagingUnitReportDto, true);
    } else {
      observable = this.reportApiService.saveComponentReportTemplate(reslultTemplate as ComponentReportDto, true);
    }
    this.saveSubscription = observable.subscribe(reportId => {
      this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(this.getTemplateSavedDialogData()));
      const path = this.isPackagingUnitReport ?
        PathResolveService.getUpdatePackagingUnitReportTemplatePath(true, reportId) :
        PathResolveService.getUpdateComponentReportTemplatePath(true, reportId);
      this.router.navigate([path]);
    }, error => {
      let errorMessage: string;
      if (error.status === 403) {
        errorMessage = this.translate('report.dialogs.nameExistsError');
      } else {
        errorMessage = this.translate('report.dialogs.textSavedError');
      }
      const dialogData = this.getErrorDialogData(errorMessage);
      this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(dialogData));
    });
  }

  private getTemplateSavedDialogData(): SimpleDialogData {
    return {
      title: this.translate('report.dialogs.titleSavedOk'),
      messages: [this.translate('report.dialogs.textSavedOk')], icon: 'info'
    };
  }

  private getErrorDialogData(error: string): SimpleDialogData {
    return {
      title: this.translate('report.dialogs.titleSavedError'),
      messages: [error], icon: 'error'
    };
  }

  fieldsSelectionChanged(inclusionMap: InclusionMap, options: MatListOption[]) {
    const fieldsObject = inclusionMap.associatedObject;
    if (!fieldsObject) { return; }
    const selectedFieldNames = options.map(o => o.value);
    inclusionMap.associatedObject.forEach((field: { name: string, label: string, included: boolean }) =>
      field.included = selectedFieldNames.includes(field.name));
  }

  ngOnDestroy(): void {
    this.routeDataSubscription?.unsubscribe();
    this.saveSubscription?.unsubscribe();
  }
}
