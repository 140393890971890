<h1 mat-dialog-title>
  <mat-icon id="info-icon">info</mat-icon>{{'analysisNotPossibleInfo.title' | translate}}
</h1>
<mat-dialog-content>
  <div class="dialog-content">
    <div class="section-text">{{ generalExplanation }}</div>

    <div class="scrolling-wrapper">
      <div class="section" *ngFor="let section of sectionsWithBulletpoints">
        <div class="section-title">{{ section.title }}</div>
        <ul>
          <li *ngFor="let item of section.items">{{ item }}</li>
        </ul>
      </div>
      <div class="section" *ngFor="let section of sections">
        <div class="section-title">{{ section.title }}</div>
        <div> {{ section.text }} </div>
      </div>
      <div class="hint-section" *ngFor="let hint of hints">
        <div> {{ hint.text }} </div>
      </div>
    </div>

  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-dialog-close mat-raised-button color="primary" type="submit">OK</button>
</mat-dialog-actions>
