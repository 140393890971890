<mat-card>
  <div class="outer-container">

    <div *ngIf="isFormLocked">
      <alert type="info">
        <div class="icon-text-container">
          <mat-icon class="gray-icon-18">info</mat-icon>
          {{ 'packagingUnit.messages.componentLocked' | translate }}
        </div>
      </alert>
    </div>

    <div *ngIf="isTracked && !isPreview">
      <alert type="info">
        <div class="icon-text-container">
          <mat-icon class="gray-icon-18">info</mat-icon>
          {{ 'component.messages.componentTracked' | translate }}
        </div>
      </alert>
    </div>

    <div *ngIf="!isEditPermitted">
      <alert type="info">
        <div class="icon-text-container">
          <mat-icon class="gray-icon-18">info</mat-icon>
          {{ 'warnings.editNotPermitted' | translate }}
        </div>
      </alert>
    </div>

    <form [formGroup]="form">

      <div id="component-header">
        <div class="flex">
          <div id="type-id-date-container">
            <ng-content select="[header]"></ng-content>
            <span *ngIf="this.packagingComponent?.id != null; else elseHeader">
              <span class="grey">({{ 'commonPackagingFields.id' | translate }}: {{ packagingComponent.id }},
                {{ 'commonPackagingFields.creationDate' | translate }}: {{ getComponentCreationDate() }})
              </span>
            </span>
            <ng-template #elseHeader>
              <div class="grey">{{ 'component.newComponent' | translate }}</div>
            </ng-template>
          </div>
          <div class="flex-row flex-space-between version-tags-container">
            <div id="versions-container"
              *ngIf="this.packagingComponent?.id != null && !isPreview && !isUserValidator; else elseBlock">
              <div (click)="onHistoryVersionClicked()">
                <mat-form-field class="form-field-versions">
                  <mat-label class="label-large">{{ 'home.packagingUnitHome.historyVersion' | translate }}</mat-label>
                  <mat-select [(value)]="packagingComponent.version"
                    (selectionChange)="onHistoryVersionChange($event.value)">
                    <mat-select-trigger>
                      Version {{ packagingComponent.version }} <span *ngIf="packagingComponent.versionName">
                        ({{ packagingComponent.versionName }})</span>
                      {{ getDateTimeString(packagingComponent.lastModificationTimestamp) }}
                      <strong *ngIf="packagingComponent.isCurrentVersion">({{ 'common.text.current' |
                        translate }})</strong>
                    </mat-select-trigger>
                    <mat-option *ngFor="let historyVersion of historyVersions" [value]="historyVersion.versionNumber">
                      Version {{ historyVersion.versionNumber }} <span *ngIf="historyVersion.versionName">
                        ({{ historyVersion.versionName }})</span>
                      {{ getDateTimeString(historyVersion.creationTimestamp) }}
                      <strong *ngIf="historyVersion.isCurrentVersion">({{ 'common.text.current' | translate
                        }})</strong>
                      <span class="spinner-container">
                        <mat-spinner diameter="24" color="accent" *ngIf="spinnerActive"></mat-spinner>
                      </span>
                      <button mat-icon-button color="primary"
                        (click)="$event.stopPropagation(); editVersionName(historyVersion)">
                        <mat-icon class="version-edit-icon">edit</mat-icon>
                      </button>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
            <ng-template #elseBlock>
              <div id="versions-container"></div>
            </ng-template>
            <div class="packaging-tag-container">
              <app-select-tags [allTags]="allTags" [selectedTags]="selectedTags" *ngIf="!isPreview && !isUserValidator"
                (tagsChanged)="setTags()">
              </app-select-tags>
            </div>
          </div>
        </div>
        <div class="image-container"><img [src]="imageSource"></div>
      </div>

      <alert type="warning" *ngIf="changedMaterialsInfo.length > 0 && packagingComponent.isCurrentVersion">
        <p>{{ 'component.messages.materialsChanged' | translate }}</p>
        <div *ngFor="let material of changedMaterialsInfo" class="changed-component">
          <span>ID {{ material.id }}</span>
          <span *ngIf="material.name"> ({{ material.name }})</span>
          <button mat-raised-button (click)="handleMaterialChange(true, material.id)" class="custom-button-side">
            {{ 'common.buttons.apply' | translate }}
          </button>
          <button mat-raised-button (click)="handleMaterialChange(false, material.id)" class="custom-button-side">
            {{ 'common.buttons.discard' | translate }}
          </button>
        </div>
      </alert>

      <mat-form-field class="full-width comment-form-field" appearance="outline">
        <mat-label>{{ 'packagingUnit.fields.comment' | translate }}</mat-label>
        <textarea class="no-resize-textarea" matInput formControlName="comment" [maxlength]="maxCommentLength"
          placeholder="{{ 'packagingUnit.fields.commentMaxLength' | translate: {length: maxCommentLength} }}">
        </textarea>
      </mat-form-field>

      <app-component-sub-html-template [form]="form" [componentSubtypes]="componentSubtypes"
        [manufacturingCountries]="manufacturingCountries" [distributionCountries]="distributionCountries"
        [canEditForm]="canEditForm" [isDecoration]="isDecoration" [isMainBody]="isMainBody"
        [componentTypeSelectionLabel]="componentTypeSelectionLabel" [isIndependentComponent]="true"
        (componentSubtypeChanged)="onDecorationSubtypeChange($event)">
      </app-component-sub-html-template>

      <ng-content select="[image]"></ng-content>

      <ng-content select="[pdf]"></ng-content>

      <mat-card>
        <mat-card-content class="table-container-wide">
          <app-multi-material-table [parentCompositeMaterial]="packagingComponent.multiMaterial"
            [allMaterialFunctions]="allMaterialFunctions" [allColors]="allColors"
            [allManifestations]="allManifestations" [callerId]="callerId"
            [manufacturingCountry]="selectedManufacturingCountry" [packagingUnitTypeId]="packagingUnitTypeId"
            [canEditForm]="canEditForm" (totalWeightChanged)="totalWeightChanged($event)">
          </app-multi-material-table>
        </mat-card-content>
      </mat-card>

      <div *ngIf="canEditForm">
        <div id="version-name-container">
          <div id="version-name-label" class="label-large">
            {{ 'packagingUnit.messages.nameVersionLabel' | translate }}
          </div>
          <div id="version-name-input">
            <mat-form-field class="form-field-medium">
              <mat-label class="label-large">{{ 'commonPackagingFields.versionName' | translate }}</mat-label>
              <input formControlName="newVersionName" matInput autocomplete="off">
            </mat-form-field>
          </div>
        </div>

        <button (click)="onSubmit()" mat-raised-button color="primary" class="custom-button" type="button">
          <mat-icon>save_alt</mat-icon>
          {{ 'common.buttons.save' | translate }}
        </button>

        <button mat-raised-button class="custom-button" [disabled]="packagingComponent.id == null"
          [matMenuTriggerFor]="reports.reportsMenu" (click)="reports.loadReports()" type="button" color="primary">
          <mat-icon>topic</mat-icon>
          {{'report.buttons.buttonReports' | translate}}
        </button>
        <app-reports-menu-for-packaging-part #reports [callerItemId]="packagingComponent.id" [callerPage]="callerPageId"
          [callerLevel]="callerLevelId">
        </app-reports-menu-for-packaging-part>
      </div>
    </form>
  </div>
</mat-card>
