import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PackagingSystemExpenseResultDto } from 'src/app/data-transfer/entities/evaluation-entities/expense-result-dto';
import { PackagingSystemDto } from 'src/app/data-transfer/entities/packaging-system-entities/packaging-system-dto';
import { LICENSE_FEE_COUNTRIES } from 'src/app/model/expense-countries';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { ExpenseCalculationParentComponent } from '../expense-calculation-parent/expense-calculation-parent.component';

@Component({
  selector: 'app-license-fee-packaging-system',
  templateUrl: './license-fee-packaging-system.component.html',
  styleUrls: ['./license-fee-packaging-system.component.scss']
})
export class LicenseFeePackagingSystemComponent extends ExpenseCalculationParentComponent implements OnInit {

  licenseFeeResult!: PackagingSystemExpenseResultDto[];
  packagingSystem!: PackagingSystemDto;

  constructor(private route: ActivatedRoute) { super(); }

  ngOnInit(): void {
    this.routeDataSubscription = this.route.data.subscribe(data => {
      this.licenseFeeResult = data.licenseFeeResult;
      this.packagingSystem = this.licenseFeeResult[0].analyzedPackagingSystem;
      this.packagingPartWeight = super.getPackagingSystemWeight(this.packagingSystem);
      this.countriesAvailability = LICENSE_FEE_COUNTRIES;
      this.packagingPartType = PackagingPart.System;
    });
  }
}
