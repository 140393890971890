import { SimpleAlertDialogComponent } from '../simple-alert-dialog/simple-alert-dialog.component';
import { Component, Inject, Optional } from '@angular/core';
import { AggregateEvaluationWrapper } from 'src/app/data-transfer/entities/aggregate-evaluation-recyclability-output-dto';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export interface ExceptionReason {
  packagingElementId: number;
  referringRecyclabilityAggregations: AggregateEvaluationWrapper[];
  referringLifeCycleAggregations: AggregateEvaluationWrapper[];
}

@Component({
  selector: 'app-packaging-unit-usage-in-eval-dialog',
  templateUrl: './packaging-unit-usage-in-eval-dialog.component.html',
  styleUrls: ['./packaging-unit-usage-in-eval-dialog.component.scss']
})
export class PackagingUnitUsageInEvalDialogComponent extends SimpleAlertDialogComponent {

  exceptionReasons: ExceptionReason[] = [];

  constructor(
    public dialogRef: MatDialogRef<PackagingUnitUsageInEvalDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    super(dialogRef, data);
    this.exceptionReasons = data.exceptionReasons;
  }

  parseDate(dateString: string): string {
    const date = new Date(dateString);
    if (!date) { return 'N/A'; }
    return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
  }
}
