import { IdParam, PathsDirectory, PackagingText } from './../../model/path-building-blocks';
import { ActivatedRouteSnapshot, RouterStateSnapshot, ResolveFn } from '@angular/router';

export const errorPathResolver: ResolveFn<string> = (route: ActivatedRouteSnapshot, state: RouterStateSnapshot) => {
  if (route.queryParams?.returnUrl) {
    return route.queryParams.returnUrl;
  }

  const typoPath = state.url.slice(1).split('/'); // skip slash
  const dictionary = Object.values(PathsDirectory)
    .filter(path => path.length <= typoPath.length); // at most as many sections as typoPath

  if (!dictionary.length) { return ''; }

  const bestFitPathComponents = findBestFitPath(typoPath, dictionary);
  const bestFitPath = bestFitPathComponents ? bestFitPathComponents.join('/') : '';
  return `/${bestFitPath}`;
};

const findBestFitPath = (typoPath: string[], dictionary: string[][], correctId: boolean = true): string[] | null => {
  let bestDist = Number.MAX_VALUE;
  let bestPath = null;

  dictionary.forEach(path => {
    // first we sum up the length of the tail
    let dist = typoPath.slice(path.length).join('/').length;
    path.forEach((section, sectionIndex) => {
      if (section.indexOf(IdParam) < 0 && section.indexOf(PackagingText) < 0) {
        // no id-section, so we add up the distance
        dist += calcLevenstheinDistance(section, typoPath[sectionIndex]);
      } else if (correctId) {
        // id-section which we want to replace
        path[sectionIndex] = typoPath[sectionIndex];
      }
    });
    if (dist < bestDist) {
      bestDist = dist;
      bestPath = path;
    }
  });
  return bestPath;
}

const calcLevenstheinDistance = (a: string, b: string): number => {
  if (a.length === 0) {
    return b.length;
  }
  if (b.length === 0) {
    return a.length;
  }

  const matrix = [];

  // increment along the first column of each row
  for (let i = 0; i <= b.length; i++) {
    matrix[i] = [i];
  }

  // increment each column in the first row
  for (let j = 0; j <= a.length; j++) {
    matrix[0][j] = j;
  }

  // Fill in the rest of the matrix
  for (let i = 1; i <= b.length; i++) {
    for (let j = 1; j <= a.length; j++) {
      if (b.charAt(i - 1) === a.charAt(j - 1)) {
        matrix[i][j] = matrix[i - 1][j - 1];
      } else {
        matrix[i][j] = Math.min(
          matrix[i - 1][j - 1] + 1, // substitution
          matrix[i][j - 1] + 1, // insertion
          matrix[i - 1][j] + 1, // deletion
        );
      }
    }
  }
  return matrix[b.length][a.length];
}