<h1 mat-dialog-title>{{ 'disclaimer.title' | translate }}</h1>

<div class="inner-container">
  <mat-dialog-content>
    <div class="dialog-content">
      <div class="section-text">{{ 'disclaimer.generalConditions' | translate }}</div>

      <div class="scrolling-wrapper">
        <div class="section" *ngFor="let section of sectionsWithBulletpoints">
          <div class="section-title">{{ section.title }}</div>
          <ul>
            <li *ngFor="let item of section.items">{{ item }}</li>
          </ul>
        </div>
        <div class="section" *ngFor="let section of sections">
          <div class="section-title">{{ section.title }}</div>
          <div> {{ section.text }} </div>
        </div>
      </div>

      <div class="section-text">
        {{ 'disclaimer.furtherInformation' | translate}}
        <a href="https://circularanalytics.com/" target="_blank">Circular Analytics</a>
      </div>

      <div class="section-text">{{ 'disclaimer.internalUseOnly' | translate }}</div>
    </div>
  </mat-dialog-content>
</div>

<mat-dialog-actions>
  <button (click)="doConfirm()" mat-raised-button type="button"
    color="primary">{{ 'common.buttons.confirm' | translate }}</button>
  <button (click)="doReject()" mat-raised-button type="button">
    {{ 'common.buttons.reject' | translate }}</button>
</mat-dialog-actions>
