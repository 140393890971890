import { Observable, Subscription } from 'rxjs';
import { ReportApiService } from 'src/app/data-transfer/services/report-api-service';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute } from '@angular/router';
import { Component, OnDestroy } from '@angular/core';
import { ComponentReportInfoDto, PackagingUnitReportInfoDto } from 'src/app/data-transfer/entities/report-info-dto';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { downloadDocument } from 'src/app/services/document-download';

class MappedPackagingUnitReportInfoDto extends PackagingUnitReportInfoDto {
  creationDateString?: string;
}

class MappedComponentReportInfoDto extends ComponentReportInfoDto {
  creationDateString?: string;
}

@Component({
  selector: 'app-reports-home',
  templateUrl: './reports-home.component.html',
  styleUrls: ['./reports-home.component.scss']
})
export class ReportsHomeComponent implements OnDestroy {

  packagingUnitReportsDataSource!: MatTableDataSource<MappedPackagingUnitReportInfoDto>;
  componentReportsDataSource!: MatTableDataSource<MappedComponentReportInfoDto>;

  packagingUnitDisplayedColumns = ['id', 'name', 'packagingUnitId',
    'packagingUnitVersion', 'creationDateString', 'reportProfileId', 'actions'];
  componentDisplayedColumns = ['id', 'name', 'packagingComponentId', 'packagingComponentVersion',
    'creationDateString', 'reportProfileId', 'actions'];

  packagingPartEnum = PackagingPart;

  private routeDataSubscription?: Subscription;
  private reportSubscription?: Subscription;
  private deleteSubscription?: Subscription;

  constructor(
    private route: ActivatedRoute,
    private reportApiService: ReportApiService
  ) {
    this.packagingUnitReportsDataSource = new MatTableDataSource<MappedPackagingUnitReportInfoDto>();
    this.componentReportsDataSource = new MatTableDataSource<MappedComponentReportInfoDto>();
    this.routeDataSubscription = this.route.data.subscribe(data => {
      this.packagingUnitReportsDataSource.data = data.packagingUnitReports;
      this.componentReportsDataSource.data = data.componentReports;
      this.packagingUnitReportsDataSource.data.forEach(x => x.creationDateString =
        x.creationTimestamp ? this.formatDateToString(x.creationTimestamp) : '');
      this.componentReportsDataSource.data.forEach(x => x.creationDateString =
        x.creationTimestamp ? this.formatDateToString(x.creationTimestamp) : '');
    });
  }

  viewReport(reportId: number, callerLevel: number) {
    let observable: Observable<any> | null = null;
    switch (callerLevel) {
      case PackagingPart.Unit:
        observable = this.reportApiService.downloadPackagingUnitReport(reportId);
        break;
      case PackagingPart.Component:
        observable = this.reportApiService.downloadComponentReport(reportId);
        break;
      default: break;
    }
    if (!observable) { return; }
    this.reportSubscription = observable.subscribe(reportPdf => {
      const pdfUrl = window.URL.createObjectURL(reportPdf);
      window.open(pdfUrl, '_blank');
    });
  }

  downloadReport(reportId: number, callerLevel: number) {
    let observable: Observable<any> | null = null;
    switch (callerLevel) {
      case PackagingPart.Unit:
        observable = this.reportApiService.downloadPackagingUnitReport(reportId);
        break;
      case PackagingPart.Component:
        observable = this.reportApiService.downloadComponentReport(reportId);
        break;
      default: break;
    }
    if (!observable) { return; }
    this.reportSubscription = observable.subscribe(reportPdf => {
      downloadDocument(reportPdf);
    });
  }

  deleteReport(reportId: number, callerLevel: number) {
    // TODO dialogs for confirmation, success/error
    switch (callerLevel) {
      case PackagingPart.Unit:
        this.deleteSubscription = this.reportApiService.deletePackagingUnitReport(reportId).subscribe(_ => {
          this.packagingUnitReportsDataSource.data = this.packagingUnitReportsDataSource.data.filter(x => x.id !== reportId);
        });
        break;
      case PackagingPart.Component:
        this.deleteSubscription = this.reportApiService.deleteComponentReport(reportId).subscribe(_ => {
          this.componentReportsDataSource.data = this.componentReportsDataSource.data.filter(x => x.id !== reportId);
        });
        break;
      default: break;
    }
  }

  formatDateToString(timestamp: string) {
    if (timestamp) {
      const date = new Date(timestamp);
      return `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
    } else {
      return '';
    }
  }

  ngOnDestroy(): void {
    this.routeDataSubscription?.unsubscribe();
    this.reportSubscription?.unsubscribe();
    this.deleteSubscription?.unsubscribe();
  }
}
