<mat-tree #nestedTree [dataSource]="treeDataSource" [treeControl]="nestedTreeControl" class="dir-tree">
  <mat-nested-tree-node *matTreeNodeDef="let node">
    <div class="mat-tree-node cursor"
      [ngClass]="selectedFolder === node ? isDarkTheme ? 'background-highlight-dark' : 'background-highlight' : ''"
      (click)="setSelectedFolder(node)" (contextmenu)="openContextMenu($event, node); $event.preventDefault();">
      <button mat-icon-button [attr.aria-label]="'Toggle ' + node.name" matTreeNodeToggle
        [ngClass]="{'invisible': !hasChild(node)}">
        <mat-icon>
          {{nestedTreeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
        </mat-icon>
      </button>
      <mat-icon class="tree-icon" *ngIf="node.id !== recyclingBinDtoFolder.id; else recyclingBinIcon">folder</mat-icon>
      <ng-template #recyclingBinIcon>
        <mat-icon class="tree-icon">delete</mat-icon>
      </ng-template>
      <div>{{node.name}}</div>
    </div>
    <div [class.dir-tree-invisible]="!nestedTreeControl.isExpanded(node)" role="group">
      <ng-container matTreeNodeOutlet></ng-container>
    </div>
  </mat-nested-tree-node>
</mat-tree>