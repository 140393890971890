import { TranslateService } from '@ngx-translate/core';
import { PackagingSystemRecyclabilityResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-system-recyclability-result-dto';
import { PackagingUnitRecyclabilityResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-unit-recyclability-result-dto';
import { RecyclabilityResultPackagingSystem } from 'src/app/model/evaluations/recyclability-result-packaging-system';
import { RecyclabilityResultPackagingUnit } from 'src/app/model/evaluations/recyclability-result-packaging-unit';
import { CountriesService } from 'src/app/navigation/services/countries-service';
import { RecyclabilityLabelData } from './recyclability-label-data';
import { Injectable } from '@angular/core';
import { ComponentRecyclabilityResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/component-recyclability-result-dto';
import { RecyclabilityResultComponent } from 'src/app/model/evaluations/recyclability-result-component';
import { AnalysesParentService } from '../analyses-parent-service';

@Injectable({ providedIn: 'root' })
export class RecyclabilityService extends AnalysesParentService {

  private labelData: RecyclabilityLabelData;

  constructor(
    protected translateService: TranslateService,
    protected countriesService: CountriesService
  ) {
    super();
    this.labelData = new RecyclabilityLabelData(this.translateService);
  }

  getPackagingSystemRecyclabilityResultFromDto(recResult: PackagingSystemRecyclabilityResultDto): RecyclabilityResultPackagingSystem {
    const countryName = this.countriesService.getCountryNameByCode(recResult.evaluatedCountry);
    return new RecyclabilityResultPackagingSystem(recResult, this.labelData, countryName);
  }

  getPackagingUnitRecyclabilityResultFromDto(recResult: PackagingUnitRecyclabilityResultDto): RecyclabilityResultPackagingUnit {
    const countryName = this.countriesService.getCountryNameByCode(recResult.evaluatedCountry);
    return new RecyclabilityResultPackagingUnit(recResult, this.labelData, countryName);
  }

  getComponentRecyclabilityResultFromDto(recResult: ComponentRecyclabilityResultDto): RecyclabilityResultComponent {
    const countryName = this.countriesService.getCountryNameByCode(recResult.evaluatedCountry);
    return new RecyclabilityResultComponent(recResult, this.labelData, countryName);
  }
}