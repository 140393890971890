<mat-table id="materials-table">
  <div *ngFor="let column of displayedColumns">
    <ng-container [matColumnDef]="column">
      <mat-header-cell *matHeaderCellDef>
        {{ column }}
      </mat-header-cell>
    </ng-container>
  </div>
  <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
  <mat-row mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
</mat-table>
