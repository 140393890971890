import { Component, EventEmitter, Output, HostListener } from '@angular/core';

const enum Status {
  OFF = 0,
  RESIZE = 1
}

@Component({
  selector: 'app-splitter',
  templateUrl: './splitter.component.html',
  styleUrls: ['./splitter.component.scss']
})
export class SplitterComponent {
  status: Status = Status.OFF;
  @Output() splitterMoved = new EventEmitter();

  @HostListener('window:mousemove', ['$event'])
  onMouseMove(event: MouseEvent){
    if (this.status === Status.RESIZE) {
      this.splitterMoved.emit(event);
    }
  }

  setStatus(event: MouseEvent, status: number){
    if (status === 1) {
      event.stopPropagation();
    }
    this.status = status;
  }
}
