import { CompositeMaterialExportProfileDto } from '../material-entities/composite-material-export-profile-dto';

export function setValues(
  classObj: any, defaultBooleanValue?: boolean, defaultNumericValue = -1, defaultStringValue = ''
) {
  Object.keys(classObj).forEach(key => {
    if (typeof classObj[key] === 'boolean') {
      classObj[key] = defaultBooleanValue;
    } else if (typeof classObj[key] === 'number') {
      classObj[key] = defaultNumericValue;
    } else if (typeof classObj[key] === 'string') {
      classObj[key] = defaultStringValue;
    }
  });
}

export class PackagingUnitExportProfileDto {
  id = -1;
  profileName = '';
  owningOrganizationId = -1;
  owningOrganizationName = '';
  isDefaultProfile = false;
  brandName = false;
  productName = false;
  articleNumber = false;
  comment = false;
  fillingGood = false;
  fillingGoodCategory = false;
  fillingGoodType = false;
  packagingQuantity = false;
  packagingVolume = false;
  printingCoverage = false;
  assemblyCountry = false;
  distributionCountries = false;
  length = false;
  width = false;
  height = false;
  gtin = false;
  mainBody!: ExportProfileMainBodyDto;
  closures!: ExportProfileClosureDto;
  labels!: ExportProfileLabelDto;
  inMoldLabels!: ExportProfileInMoldLabelDto;
  packagingAids!: ExportProfilePackagingAidDto;
  sleeves!: ExportProfileSleeveDto;
  inlays!: ExportProfileInlayDto;
  images!: ExportProfileImages;
  documents!: ExportProfileDocuments;

  constructor(defaultBooleanValue?: boolean) {
    if (defaultBooleanValue == null) { return; }
    this.mainBody = new ExportProfileMainBodyDto(defaultBooleanValue);
    this.closures = new ExportProfileClosureDto(defaultBooleanValue);
    this.labels = new ExportProfileLabelDto(defaultBooleanValue);
    this.sleeves = new ExportProfileSleeveDto(defaultBooleanValue);
    this.inMoldLabels = new ExportProfileInMoldLabelDto(defaultBooleanValue);
    this.packagingAids = new ExportProfilePackagingAidDto(defaultBooleanValue);
    this.inlays = new ExportProfileInlayDto(defaultBooleanValue);
    this.images = new ExportProfileImages(defaultBooleanValue);
    this.documents = new ExportProfileDocuments(defaultBooleanValue);
    setValues(this, defaultBooleanValue);
    this.isDefaultProfile = false;
  }
}

export class ExportProfileComponentParent {
  manufacturer = false;
  articleName = false;
  articleNumber = false;
  manufacturingCountry = false;
  isRigidComponent = false;
  containsNearInfraredBarrier = false;
  printingCoverage = false;
  length = false;
  width = false;
  height = false;
  gtin = false;
  multiMaterial!: CompositeMaterialExportProfileDto;

  constructor(defaultBooleanValue?: boolean) {
    if (defaultBooleanValue == null) { return; }
    this.multiMaterial = new CompositeMaterialExportProfileDto(defaultBooleanValue);
    setValues(this, defaultBooleanValue);
  }
}

export class ExportProfileMainBodyDto extends ExportProfileComponentParent {
  isRigidComponent = false;
  packagingVolume = false;

  constructor(defaultBooleanValue?: boolean) {
    super(defaultBooleanValue);
    if (defaultBooleanValue == null) { return; }
    setValues(this, defaultBooleanValue);
  }
}

export class ExportProfileClosureDto extends ExportProfileComponentParent {
  removedByConsumer = false;
  removedBeforeUsage = false;

  constructor(defaultBooleanValue?: boolean) {
    super(defaultBooleanValue);
    if (defaultBooleanValue == null) { return; }
    setValues(this, defaultBooleanValue);
  }
}

export class ExportProfileLabelDto extends ExportProfileComponentParent {
  surfaceRatio = false;
  isAdhesiveUsed = false;
  removabilityCondition = false;
  removedByConsumer = false;
  removedBeforeUsage = false;

  constructor(defaultBooleanValue?: boolean) {
    super(defaultBooleanValue);
    if (defaultBooleanValue == null) { return; }
    setValues(this, defaultBooleanValue);
  }
}

export class ExportProfileSleeveDto extends ExportProfileComponentParent {
  surfaceRatio = false;
  removedByConsumer = false;
  removedBeforeUsage = false;

  constructor(defaultBooleanValue?: boolean) {
    super(defaultBooleanValue);
    if (defaultBooleanValue == null) { return; }
    setValues(this, defaultBooleanValue);
  }
}

export class ExportProfileInMoldLabelDto extends ExportProfileComponentParent {
  surfaceRatio = false;
  removedByConsumer = false;
  removedBeforeUsage = false;

  constructor(defaultBooleanValue?: boolean) {
    super(defaultBooleanValue);
    if (defaultBooleanValue == null) { return; }
    setValues(this, defaultBooleanValue);
  }
}

export class ExportProfileInlayDto extends ExportProfileComponentParent {
  isAdhesiveUsed = false;
  removabilityCondition = false;
  isPhysicallyAttached = false;

  constructor(defaultBooleanValue?: boolean) {
    super(defaultBooleanValue);
    if (defaultBooleanValue == null) { return; }
    setValues(this, defaultBooleanValue);
  }
}

export class ExportProfilePackagingAidDto extends ExportProfileComponentParent {
  removedByConsumer = false;
  removedBeforeUsage = false;

  constructor(defaultBooleanValue?: boolean) {
    super(defaultBooleanValue);
    if (defaultBooleanValue == null) { return; }
    setValues(this, defaultBooleanValue);
  }
}

export class ExportProfileMultiMaterialLayerDto {
  function = false;
  material = false;
  materialManifestation = false;
  mass = false;
  massPercentage = false;
  thickness = false;
  grammage = false;
  density = false;
  recyclingMaterialPercentage = false;
  color = false;
  manufacturingType = false;

  constructor(defaultBooleanValue?: boolean) {
    if (defaultBooleanValue == null) { return; }
    setValues(this, defaultBooleanValue);
  }
}

export class ExportProfileImages {
  imageId = false;
  name = false;
  index = false;
  isMainImage = false;

  constructor(defaultBooleanValue?: boolean) {
    if (defaultBooleanValue == null) { return; }
    setValues(this, defaultBooleanValue);
  }
}

export class ExportProfileDocuments {
  documentId = false;
  name = false;
  index = false;

  constructor(defaultBooleanValue?: boolean) {
    if (defaultBooleanValue == null) { return; }
    setValues(this, defaultBooleanValue);
  }
}
