import { getDialogConfig } from 'src/app/util/dialog-util';
import { SimpleAlertDialogComponent, SimpleDialogData } from './../../../dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { Subscription } from 'rxjs';
import { Component, Input, EventEmitter, Output, ViewChildren, QueryList, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

export const MAX_FILES_COUNT = 5;

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent implements OnDestroy {

  @Input() canEditForm = true;

  @Output() filesEdited = new EventEmitter();

  @ViewChildren('invisibleNames') invisibleNamesQueryList!: QueryList<any>;

  maxCountReached = false;
  nameFieldWidth: number[] = [];
  minFieldWidth = -1;
  maxFieldWidth = -1;

  currentNameBeforeChange = '';

  protected dialogSubscription?: Subscription;

  constructor(
    protected dialog: MatDialog
  ) { }

  protected showDialog(dialogData: SimpleDialogData) {
    const alertDialogConfig = getDialogConfig(dialogData, '350px');
    this.dialog.open(SimpleAlertDialogComponent, alertDialogConfig);
  }

  resizeInput(index: number): void {
    setTimeout(() => {
      const actualNameLength: number = this.invisibleNamesQueryList?.toArray()[index]?.nativeElement.offsetWidth;
      if (actualNameLength > this.minFieldWidth) {
        if (actualNameLength < this.maxFieldWidth) {
          this.nameFieldWidth[index] = actualNameLength;
        } else {
          this.nameFieldWidth[index] = this.maxFieldWidth;
        }
      } else {
        this.nameFieldWidth[index] = this.minFieldWidth;
      }
    }, 0);
  }

  setCurrentNameBeforeChange(filesList: any[], index: number) {
    this.currentNameBeforeChange = filesList[index].name;
  }

  onNameEdited(filesList: any[], index: number, dialogData: SimpleDialogData) {
    const nameCount = filesList.map(x => x.name).filter(x => x === filesList[index].name).length;
    if (nameCount > 1) {
      filesList[index].name = this.currentNameBeforeChange ?? '';
      this.showDialog(dialogData);
      this.resizeInput(index);
    }
  }

  setInitialNamesLength() {
    for (let index = 0; index < MAX_FILES_COUNT; index++) {
      this.nameFieldWidth[index] = this.minFieldWidth;
    }
  }

  setExistingNamesLength(filesList: any[]) {
    if (!filesList || filesList.length > MAX_FILES_COUNT) { return; }

    for (let index = 0; index < filesList.length; index++) {
      this.resizeInput(index);
    }
  }

  getNameNoExtension(fullName: string) {
    return fullName?.slice(0, fullName?.lastIndexOf('.')) ?? '';
  }

  getExtension(fullName: string) {
    return fullName?.split('.').pop() ?? '';
  }

  public getNewFilesFormData(filesList: File[], fileTag: string) {
    const formData = new FormData();
    for (const file of filesList) {
      formData.append(fileTag, file);
    }
    return formData;
  }

  appendTextToDuplicateNameRecursively(listOfNames: string[], originalName: string, currentIndex: number): string {
    const nameNoExtension = this.getNameNoExtension(originalName);
    const extension = this.getExtension(originalName);
    let appendedName = currentIndex === 0 ? originalName : nameNoExtension.concat(`(${currentIndex}).`).concat(extension);
    if (listOfNames.map(x => x).includes(appendedName)) {
      appendedName = this.appendTextToDuplicateNameRecursively(listOfNames, originalName, currentIndex + 1);
    } else {
      return appendedName;
    }
    return appendedName;
  }

  ngOnDestroy(): void {
    this.dialogSubscription?.unsubscribe();
  }
}
