<div class="parent-div">
  <mat-card id="organizations-card">
    <mat-card-header>
      <mat-card-title class="org-header">
        <span>{{ 'dataManagement.manageOrganizations.title.organizationTitle' | translate }}</span>
        <button mat-raised-button type="button" color="primary" (click)="addOrganization()" class="org-button">
          <mat-icon>add</mat-icon>
          {{ 'dataManagement.manageOrganizations.button.addOrganization' | translate }}
        </button>

        <button mat-raised-button type="button" color="primary" (click)="bulkUserUpload()" class="org-button">
          <mat-icon>upload</mat-icon>
          {{ 'dataManagement.manageOrganizations.bulkUpload.bulkUserUpload' | translate }}
        </button>
      </mat-card-title>
    </mat-card-header>
    <mat-card-content class="scrollable">
      <table mat-table [dataSource]="organizationsDataSource" class="organizations-table" #orgsTableSort="matSort"
        matSort [matSortActive]="sortOrganizationsColumn" matSortDirection="asc">
        <ng-container matColumnDef="id">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'dataManagement.manageOrganizations.orgTableColumn.id' | translate }}
          </th>
          <td mat-cell *matCellDef="let element"> {{element.id}} </td>
        </ng-container>
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'dataManagement.manageOrganizations.orgTableColumn.name' | translate }}
          </th>
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="organizationsColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: organizationsColumns;" (click)="onOrganizationClicked(row)"
          [class.selected-row]="clickedOrganizationRow === row"></tr>
      </table>
      <table mat-table *ngIf="deletedOrganizationsDataSource.data.length > 0"
        [dataSource]="deletedOrganizationsDataSource" class="organizations-table deleted-table"
        #delOrgsTableSort="matSort" matSort [matSortActive]="sortOrganizationsColumn" matSortDirection="asc">
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ 'dataManagement.manageOrganizations.orgTableColumn.deletedName' | translate }}
          </th>
          <td mat-cell *matCellDef="let element" class="deleted-entry"> {{element.name}} </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="organizationsColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: organizationsColumns;" (click)="onOrganizationClicked(row)"
          [class.selected-row]="clickedOrganizationRow === row"></tr>
      </table>
    </mat-card-content>
  </mat-card>

  <mat-card id="details-card" *ngIf="clickedOrganizationRow; else placeholderCard">
    <mat-card-header>
      <mat-card-title class="org-header">
        <span>{{ clickedOrganizationRow.name }} (ID {{ clickedOrganizationRow.id }})</span>
        <button *ngIf="!clickedOrganizationRow.isDeleted" mat-raised-button type="button" color="warn"
          (click)="deleteOrganization(clickedOrganizationRow)" class="org-button">
          <mat-icon>delete</mat-icon>
          {{ 'dataManagement.manageOrganizations.button.delete' | translate }}
        </button>
        <button *ngIf="clickedOrganizationRow.isDeleted" mat-raised-button type="button" color="primary"
          (click)="restoreOrganization(clickedOrganizationRow)" class="org-button">
          <mat-icon>delete</mat-icon>
          {{ 'dataManagement.manageOrganizations.button.restore' | translate }}
        </button>
      </mat-card-title>
    </mat-card-header>
    <mat-tab-group color="accent" [backgroundColor]="" class="mat-elevation-z2" id="tab-group">
      <mat-tab label="{{ 'dataManagement.manageOrganizations.title.generalSettingsTitle' | translate }}">
        <div>
          <div style="display: flex; align-items: baseline; margin-top: 16px;">
            <mat-slide-toggle hideIcon color="primary" [formControl]="isContractSignedControl" style="margin: 16px;">{{ 'dataManagement.manageOrganizations.generalSettingsColumn.contractSigned' | translate }}</mat-slide-toggle>
            <div style="display: flex; gap: 16px; align-items: baseline; margin: 16px;">
              <mat-slide-toggle hideIcon color="primary" [formControl]="isMaxUserCountActivatedControl">{{ 'dataManagement.manageOrganizations.generalSettingsColumn.maxUserCount' | translate }}</mat-slide-toggle>
              <mat-form-field>
                <input matInput [formControl]="maxUserCountControl" type="number">
              </mat-form-field>
            </div>
          </div>

          <div style="display: flex; align-items: baseline; margin: 16px; margin-top: 0px">
            <mat-form-field style="width: 40%">
              <mat-label>{{ 'dataManagement.manageOrganizations.generalSettingsColumn.allowedClients' | translate }}</mat-label>
              <mat-select multiple [formControl]="allowedClientsDropdown" >
                <mat-option *ngFor="let client of allClients" [value]="client.id">
                  {{client.name}}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <button style="margin: 16px;" mat-raised-button color="primary" (click)="updateOrganizationSettings()">{{ 'dataManagement.manageOrganizations.button.saveSettings' | translate }}</button>
        </div>
      </mat-tab>

      <mat-tab label="{{ 'dataManagement.manageOrganizations.title.creditsTitle' | translate }}">
        <div class="flex-tab-content">
          <div class="scrollable-subtable">
            <table mat-table [dataSource]="accountsDataSource" id="accounts-table" #accountsTableSort="matSort" matSort>
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'dataManagement.manageOrganizations.accountsTableColumn.id' | translate }}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'dataManagement.manageOrganizations.accountsTableColumn.name' | translate }}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
              </ng-container>
              <ng-container matColumnDef="recyclabilityCredits">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'dataManagement.manageOrganizations.accountsTableColumn.creditsCountRecyclability' | translate }}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.recyclabilityCredits}} </td>
              </ng-container>
              <ng-container matColumnDef="lcaCredits">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'dataManagement.manageOrganizations.accountsTableColumn.creditsCountLca' | translate }}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.lcaCredits}} </td>
              </ng-container>
              <ng-container matColumnDef="edit">
                <th mat-header-cell *matHeaderCellDef>
                  {{ 'dataManagement.manageOrganizations.accountsTableColumn.edit' | translate }}
                </th>
                <td mat-cell *matCellDef="let element">
                  <span>
                    <button mat-icon-button color="primary" (click)="editCredits(element)">
                      <mat-icon>edit</mat-icon>
                    </button>
                    <button mat-icon-button color="primary" (click)="editAuthorizations(element)">
                      <mat-icon>gpp_good</mat-icon>
                    </button>
                  </span>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="accountsColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: accountsColumns;"></tr>
            </table>
          </div>
          <div class="button-container">
            <div class="hint margin">
              {{ 'dataManagement.manageOrganizations.messages.accountsCount' | translate: {count:
              accountsDataSource.data.length} }}
            </div>
          </div>
        </div>
      </mat-tab>

      <mat-tab label="{{ 'dataManagement.manageOrganizations.title.userTitle' | translate }}">
        <div class="flex-tab-content">
          <div class="scrollable-subtable">
            <app-users-table [usersDataSource]="usersDataSource" [deletedUsersDataSource]="deletedUsersDataSource"
              [usersColumns]="usersColumns">
            </app-users-table>

            <button style="margin: 16px;" mat-raised-button color="primary" (click)="updatePremiumUserFlags()">{{ 'dataManagement.manageOrganizations.button.saveSettings' | translate }}</button>

          </div>
          <div class="button-container">
            <div class="hint margin">
              {{ 'dataManagement.manageOrganizations.messages.usersCount' | translate: {count:
              usersDataSource.data.length} }}
            </div>
            <div *ngIf="!clickedOrganizationRow.isDeleted">
              <div class="hint margin">
                {{ 'dataManagement.manageOrganizations.messages.addUserHint' | translate }}
              </div>
              <button mat-raised-button type="button" color="primary" (click)="addUser()">
                <mat-icon>add</mat-icon>
                {{ 'dataManagement.manageOrganizations.button.addUser' | translate }}
              </button>
            </div>
          </div>
        </div>
      </mat-tab>



      <mat-tab label="{{ 'dataManagement.manageOrganizations.title.visibilityTitle' | translate }}">
        <div class="flex-tab-content">
          <div class="scrollable-subtable">
            <table mat-table [dataSource]="visibilityDataSource" id="visiblity-table" #visibilityTableSort="matSort"
              matSort matSortDirection="asc" [matSortActive]="sortOrganizationsColumn">
              <ng-container matColumnDef="id">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'dataManagement.manageOrganizations.userTableColumn.id' | translate }}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.id}} </td>
              </ng-container>
              <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>
                  {{ 'dataManagement.manageOrganizations.userTableColumn.name' | translate }}
                </th>
                <td mat-cell *matCellDef="let element"> {{element.name}} </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="visibilityColumns; sticky: true"></tr>
              <tr mat-row *matRowDef="let row; columns: visibilityColumns;"></tr>
            </table>
          </div>
          <div class="button-container">
            <div class="hint margin">
              {{ 'dataManagement.manageOrganizations.messages.visibleOrgCount' | translate: {count:
              visibilityDataSource.data.length} }}
            </div>
            <div *ngIf="!clickedOrganizationRow.isDeleted">
              <div class="hint margin">
                {{ 'dataManagement.manageOrganizations.messages.visibilityHint' | translate }}
              </div>
              <button mat-raised-button type="button" color="primary" (click)="manageVisibility()">
                <mat-icon>edit</mat-icon>
                {{ 'dataManagement.manageOrganizations.button.manageVisibility' | translate }}
              </button>
            </div>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </mat-card>

  <ng-template #placeholderCard>
    <mat-card id="placeholder-card">
      <span class="hint margin">Select an organization to see the magic happen</span>
    </mat-card>
  </ng-template>
</div>
