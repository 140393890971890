import { TranslateService } from '@ngx-translate/core';
import { inject, Injectable } from '@angular/core';
import { CanDeactivateFn } from '@angular/router';

export interface ComponentCanDeactivate {
  canDeactivate: () => boolean;
  releaseComponent: () => void;
}

export const unsavedChangesGuard: CanDeactivateFn<ComponentCanDeactivate> = (component: ComponentCanDeactivate) =>
  inject(UnsavedChangesGuard).canDeactivate(component);

@Injectable({
  providedIn: 'root',
})
export class UnsavedChangesGuard {
  constructor(private translateService: TranslateService) {}

  async canDeactivate(component: ComponentCanDeactivate) {
    const message = this.translateService.instant('warnings.unsavedChanges');

    if (component.canDeactivate()) {
      await component.releaseComponent();
      return true;
    } else {
      return confirm(message);
    }
  }
}
