import { LcaStageInfo, LcaEffectInfo } from '../life-cycle-data-information';
import { LifeCycleResult } from './life-cycle-result';
import { ComponentLifeCycleResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/component-life-cycle-result-dto';

export class LifeCycleResultComponent extends LifeCycleResult {

  lifeCycleDataSource!: ComponentLifeCycleResultDto;

  constructor(
    private template: ComponentLifeCycleResultDto,
    private stagesInfo: LcaStageInfo[],
    private effectsInfo: LcaEffectInfo[],
    private countryName: string
  ) {
    super();

    const component = this.template.analyzedPackagingComponent;
    this.packagingPartId = component.id;
    this.packagingPartVersion = component.version ?? 0;
    this.packagingPartName = `${component.packagingComponentCategoryName}/${component.packagingComponentSubtypeName}`
      .concat(component.articleName ? `/${component.articleName}` : '');

    this.lifeCycleDataSource = this.template;
    this.analysisId = this.template.id;
    this.evaluatedCountryCode = this.template.evaluatedCountry;
    this.validationState = this.template.validationState;

    this.evaluatedCountry = this.countryName;
    this.effectRelevances = super.createEffectRelevances(this.template, this.effectsInfo);
    if (this.lifeCycleDataSource.id !== '') {
      super.initChartData(this.lifeCycleDataSource, this.effectRelevances, this.stagesInfo);
    }
  }
}
