import { DirectoryDto } from './../../../../data-transfer/entities/directory-dto';
import { Component, Optional, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogActions } from 'src/app/model/dictionary';

export class SelectLocationDialogData {
  rootFolder!: DirectoryDto;
  unreachableFolders: DirectoryDto[] = [];
}

@Component({
  selector: 'app-select-location-dialog',
  templateUrl: './select-location-dialog.component.html',
  styleUrls: ['./select-location-dialog.component.scss'],
})
export class SelectLocationDialogComponent {

  rootFolder: DirectoryDto;
  unreachableFolders: DirectoryDto[];
  selectedFolder!: DirectoryDto;

  constructor(
    private dialogRef: MatDialogRef<SelectLocationDialogComponent>,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: SelectLocationDialogData,
  ) {
    this.rootFolder = data.rootFolder;
    this.unreachableFolders = data.unreachableFolders;
    this.setSelectedFolder(data.rootFolder);
  }

  setSelectedFolder(folder: DirectoryDto): void {
    this.selectedFolder = folder;
  }

  acceptChanges() {
    this.dialogRef.close({ data: this.selectedFolder });
  }

  discardChanges() {
    this.dialogRef.close({ event: DialogActions.REJECT });
  }

  isUnreacheable() {
    if (!this.unreachableFolders || this.unreachableFolders.length === 0 || !this.selectedFolder) { return false; }
    return this.unreachableFolders?.map(x => x?.id).includes(this.selectedFolder?.id);
  }
}
