<h1 mat-dialog-title>{{ 'dataManagement.manageOrganizations.creditsDialog.title' | translate }}</h1>

<mat-dialog-content>
  <div>{{ 'dataManagement.manageOrganizations.creditsDialog.currentRecyclability' | translate }}: <strong>{{ currentCreditsCount.recyclabilityCredits }}</strong></div>
  <div>{{ 'dataManagement.manageOrganizations.creditsDialog.futureRecyclability' | translate }}: <strong>{{ getTotalRecyclability() }}</strong></div>
  <span id="increase-label-recyclability">{{ 'dataManagement.manageOrganizations.creditsDialog.increaseBy' | translate }}</span>
  <mat-form-field>
    <input matInput [(ngModel)]="additionalCredits.recyclabilityCredits" type="number" autocomplete="off">
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-content>
  <div>{{ 'dataManagement.manageOrganizations.creditsDialog.currentLca' | translate }}: <strong>{{ currentCreditsCount.lcaCredits }}</strong></div>
  <div>{{ 'dataManagement.manageOrganizations.creditsDialog.futureLca' | translate }}: <strong>{{ getTotalLca() }}</strong></div>
  <span id="increase-label-lca">{{ 'dataManagement.manageOrganizations.creditsDialog.increaseBy' | translate }}</span>
  <mat-form-field>
    <input matInput [(ngModel)]="additionalCredits.lcaCredits" type="number" autocomplete="off">
  </mat-form-field>
</mat-dialog-content>

<mat-dialog-actions>
  <button (click)="acceptChanges()" mat-raised-button type="submit" color="primary">
    {{ 'common.buttons.ok' | translate }}
  </button>
  <button (click)="discardChanges()" mat-raised-button type="button">
    {{ 'common.buttons.cancel' | translate }}
  </button>
</mat-dialog-actions>
