<div *ngIf="packagingUnit != null">
  <app-packaging-navigation [itemId]="packagingUnit.id" [callerLevelId]="packagingPart.Unit"
    [callerTypeId]="packagingUnit.packagingTypeId" [currentDirectoryId]="currentDirectoryId"
    [brandName]="packagingUnit.brandName" [productName]="packagingUnit.productName"
    [articleNumber]="packagingUnit.articleNumber" [versionId]="packagingUnit.version"
    [isCurrentVersion]="packagingUnit.isCurrentVersion" [isPreview]="isPreview" [isGeneric]="isGeneric"
    [hasRecyclabilityResult]="packagingUnit.hasRecyclabilityResult"
    [hasLifeCycleResult]="packagingUnit.hasLifeCycleResult" [creationTimestamp]="packagingUnit.creationTimestamp">

    <div class="outer-container" *ngIf="packagingUnitForm && packagingUnit">

      <div *ngIf="isFormLocked">
        <alert type="info">
          <div class="icon-text-container">
            <mat-icon class="gray-icon-18">info</mat-icon>
            {{ 'packagingUnit.messages.packagingUnitLocked' | translate }}
          </div>
        </alert>
      </div>

      <div *ngIf="isTracked && !isPreview">
        <alert type="info">
          <div class="icon-text-container">
            <mat-icon class="gray-icon-18">info</mat-icon>
            {{ 'packagingUnit.messages.packagingTracked' | translate }}
          </div>
        </alert>
      </div>

      <div *ngIf="!isEditPermitted">
        <alert type="info">
          <div class="icon-text-container">
            <mat-icon class="gray-icon-18">info</mat-icon>
            {{ 'warnings.editNotPermitted' | translate }}
          </div>
        </alert>
      </div>

      <form [formGroup]="packagingUnitForm">

        <div id="packaging-header">
          <div class="flex">
            <div id="type-id-date-container">
              <h1>{{ packagingUnitType?.name ?? 'packagingUnit.packagingUnit' | translate }}</h1>
              <span *ngIf="packagingUnit.id != null; else elseHeader">
                <span class="grey">({{ 'commonPackagingFields.id' | translate }}:
                  {{ packagingUnit.id }},
                  {{ 'commonPackagingFields.creationDate' | translate }}:
                  {{ getLocaleDate(this.packagingUnit.creationTimestamp) }})
                </span>
              </span>
              <ng-template #elseHeader>
                <div class="grey">{{ 'packagingUnit.newPackagingUnit' | translate }}</div>
              </ng-template>
            </div>
            <div class="flex-row flex-space-between version-tags-container">
              <div id="versions-container">
                <div (click)="loadHistoryVersions()" *ngIf="packagingUnit.id != null && !isPreview && !isUserValidator">
                  <mat-form-field class="form-field-versions">
                    <mat-label class="label-large">{{ 'home.packagingUnitHome.historyVersion' | translate }}</mat-label>
                    <mat-select [(value)]="packagingUnit.version"
                      (selectionChange)="onHistoryVersionChange($event.value)">
                      <mat-select-trigger>
                        Version {{ packagingUnit.version }}
                        <span *ngIf="packagingUnit.versionName">({{ packagingUnit.versionName }})</span>
                        {{ getDateTimeString(packagingUnit.lastModificationTimestamp) }}
                        <strong *ngIf="packagingUnit.isCurrentVersion">({{ 'common.text.current' | translate
                          }})</strong>
                      </mat-select-trigger>
                      <mat-option *ngFor="let historyVersion of historyVersions" [value]="historyVersion.versionNumber">
                        Version {{ historyVersion.versionNumber }} <span *ngIf="historyVersion.versionName">({{
                          historyVersion.versionName }})</span>
                        {{ getDateTimeString(historyVersion.creationTimestamp) }}
                        <strong *ngIf="historyVersion.isCurrentVersion">({{ 'common.text.current' | translate
                          }})</strong>
                        <span class="spinner-container">
                          <mat-spinner diameter="24" color="accent" *ngIf="spinnerActive">
                          </mat-spinner>
                        </span>
                        <button mat-icon-button color="primary"
                          (click)="$event.stopPropagation(); editVersionName(historyVersion)">
                          <mat-icon class="version-edit-icon">edit</mat-icon>
                        </button>
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="packaging-tag-container">
                <app-select-tags [allTags]="allTags" [selectedTags]="selectedTags"
                  *ngIf="!isPreview && !isUserValidator" (tagsChanged)="setTags()">
                </app-select-tags>
              </div>
            </div>
          </div>
          <div class="image-container"><img [src]="packagingUnitTypeImageSource"></div>
        </div>

        <alert type="warning" *ngIf="changedPackagingPartsInfo.length > 0 && packagingUnit.isCurrentVersion">
          <p>{{ 'packagingUnit.messages.componentsChanged' | translate }}</p>
          <div *ngFor="let component of changedPackagingPartsInfo" class="changed-component">
            <span>{{ 'common.dictionary.' + component.type | translate }} </span>
            <span>{{ component.subtype }}, ID {{ component.id }}</span>
            <span *ngIf="component.name"> ({{ component.name }})</span>
            <button mat-raised-button (click)="acceptComponentChange(component.id)" class="custom-button-side">
              {{ 'common.buttons.apply' | translate }}
            </button>
            <button mat-raised-button (click)="declineComponentChange(component.id)" class="custom-button-side">
              {{ 'common.buttons.discard' | translate }}
            </button>
          </div>
        </alert>

        <!--######################################################################################-->
        <!-- Primary packaging -->
        <!--######################################################################################-->

        <app-packaging-unit-html-template [formControl]="primaryPackagingControl"
          [fillingVolume]="fillingVolume" [showErrors]="submitted"
          [distributionCountries]="createUpdateHandler.distributionCountries"
          [fillingGoodTypes]="createUpdateHandler.fillingGoodTypes"
          [fillingGoodCategories]="createUpdateHandler.fillingGoodCategories"
          [fillingGoods]="createUpdateHandler.fillingGoods" [assemblyCountries]="createUpdateHandler.assemblyCountries"
          [canEditForm]="canEditForm" [isTracked]="isTracked" [packagingUnitTypeId]="packagingUnitType?.id">
        </app-packaging-unit-html-template>

        <div>
          <app-image-upload [canEditForm]="canEditForm" [images]="packagingUnit.images" (filesEdited)="onFilesEdited()">
          </app-image-upload>
        </div>

        <div>
          <app-pdf-upload [canEditForm]="canEditForm" [pdfs]="packagingUnit.documents" (filesEdited)="onFilesEdited()">
          </app-pdf-upload>
        </div>

        <!--######################################################################################-->
        <!-- Additional packaging -->
        <!--######################################################################################-->

        <mat-expansion-panel [expanded]="true" class="additional-packaging-panel">
          <mat-expansion-panel-header collapsedHeight="100px" expandedHeight="100px">
            <mat-panel-title>
              <h2>{{ 'component.mainBody' | translate }}</h2>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-additional-packaging-component #mainBody
            [componentEntries]="packagingUnitForm.controls.mainBodyControl.value" [showErrors]="submitted"
            [componentType]="componentTypes.MainBody" [handler]="createUpdateHandler.componentHandler"
            [packagingTypeId]="packagingUnitType?.id" [canEditForm]="canEditForm" [isTracked]="isTracked"
            [isSingleComponent]="true" [isPreview]="isPreview" [isUserValidator]="isUserValidator"
            [isDarkTheme]="isDarkTheme" (packagingTypeChanged)="setPackagingType($event)"
            (volumeChanged)="updateMainBodyVolume($event)">
          </app-additional-packaging-component>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="false" class="additional-packaging-panel">
          <mat-expansion-panel-header collapsedHeight="100px" expandedHeight="100px">
            <mat-panel-title>
              <h2>
                {{ 'component.closure' | translate }}
                <span *ngIf="componentsCount[componentTypes.Closure]>0">
                  ({{ componentsCount[componentTypes.Closure] }})
                </span>
              </h2>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-additional-packaging-component #closure
            [componentEntries]="packagingUnitForm.controls.closuresControl.value" [showErrors]="submitted"
            [componentType]="componentTypes.Closure" [handler]="createUpdateHandler.componentHandler"
            [packagingTypeId]="packagingUnitType?.id" [canEditForm]="canEditForm" [isTracked]="isTracked"
            [isDarkTheme]="isDarkTheme" (componentCountChanged)="updateComponentCount($event)">
          </app-additional-packaging-component>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="false" class="additional-packaging-panel">
          <mat-expansion-panel-header collapsedHeight="100px" expandedHeight="100px">
            <mat-panel-title>
              <h2>
                {{ 'component.decoration' | translate }}
                <span *ngIf="componentsCount[componentTypes.Decoration]>0">
                  ({{ componentsCount[componentTypes.Decoration] }})
                </span>
              </h2>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-additional-packaging-component #decoration
            [componentEntries]="packagingUnitForm.controls.decorationsControl.value" [showErrors]="submitted"
            [componentType]="componentTypes.Decoration" [handler]="createUpdateHandler.componentHandler"
            [packagingTypeId]="packagingUnitType?.id" [canEditForm]="canEditForm" [isTracked]="isTracked"
            [isDarkTheme]="isDarkTheme" (componentCountChanged)="updateComponentCount($event)">
          </app-additional-packaging-component>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="false" class="additional-packaging-panel">
          <mat-expansion-panel-header collapsedHeight="100px" expandedHeight="100px">
            <mat-panel-title>
              <h2>
                {{ 'component.inlay' | translate }}
                <span *ngIf="componentsCount[componentTypes.Inlay]>0">
                  ({{ componentsCount[componentTypes.Inlay] }})
                </span>
              </h2>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-additional-packaging-component #inlay [componentEntries]="packagingUnitForm.controls.inlaysControl.value"
            [showErrors]="submitted" [componentType]="componentTypes.Inlay"
            [handler]="createUpdateHandler.componentHandler" [packagingTypeId]="packagingUnitType?.id"
            [canEditForm]="canEditForm" [isTracked]="isTracked" [isDarkTheme]="isDarkTheme"
            (componentCountChanged)="updateComponentCount($event)">
          </app-additional-packaging-component>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="false" class="additional-packaging-panel">
          <mat-expansion-panel-header collapsedHeight="100px" expandedHeight="100px">
            <mat-panel-title>
              <h2>
                {{ 'component.packagingAid' | translate }}
                <span *ngIf="componentsCount[componentTypes.PackagingAid]>0">
                  ({{ componentsCount[componentTypes.PackagingAid] }})
                </span>
              </h2>
            </mat-panel-title>
          </mat-expansion-panel-header>
          <app-additional-packaging-component #packAid
            [componentEntries]="packagingUnitForm.controls.packagingAidsControl.value" [showErrors]="submitted"
            [componentType]="componentTypes.PackagingAid" [handler]="createUpdateHandler.componentHandler"
            [packagingTypeId]="packagingUnitType?.id" [canEditForm]="canEditForm" [isTracked]="isTracked"
            [isDarkTheme]="isDarkTheme" (componentCountChanged)="updateComponentCount($event)">
          </app-additional-packaging-component>
        </mat-expansion-panel>

        <mat-expansion-panel [expanded]="false" class="additional-packaging-panel">
          <mat-expansion-panel-header collapsedHeight="100px" expandedHeight="100px">
            <mat-panel-title>
              <h2>{{ 'packagingUnit.miscellaneous' | translate }}</h2>
            </mat-panel-title>
          </mat-expansion-panel-header>

          <div class="grid-container">
            <div class="grid-item">
              <mat-card id="type-info">
                <mat-card-title class="grid-item-title">
                  {{ 'packagingUnit.fields.transportPackagingHeader' | translate }}
                </mat-card-title>
                <mat-card-content class="inner-container">
                  <app-tri-state-selector formControlName="isTransportPackaging"
                    [options]="isTransportPackagingDictionary"
                    label=""
                    matTooltip="{{ 'packagingUnit.fields.tooltips.transportPackaging' | translate }}">
                  </app-tri-state-selector>
                </mat-card-content>
              </mat-card>
            </div>

            <div class="grid-item">
              <mat-card id="type-info">
                <mat-card-title class="grid-item-title">
                  {{ 'packagingUnit.fields.isPackagingPartOfProductHeader' | translate }}
                </mat-card-title>
                <mat-card-content class="inner-container">
                  <app-tri-state-selector formControlName="isPackagingPartOfProduct"
                    [options]="isPackagingPartOfProductDictionary"
                    label=""
                    matTooltip="{{ 'packagingUnit.fields.tooltips.isPackagingPartOfProduct' | translate }}">
                  </app-tri-state-selector>
                </mat-card-content>
              </mat-card>
            </div>

            <div class="grid-item">
              <mat-card id="type-info">
                <mat-card-title class="grid-item-title">
                  {{ 'packagingUnit.fields.isMultiUsePackagingHeader' | translate }}
                </mat-card-title>
                <mat-card-content class="inner-container">
                  <app-tri-state-selector formControlName="isMultiUsePackaging"
                    [options]="isMultiUseDictionary" label=""
                    matTooltip="{{ 'packagingUnit.fields.tooltips.isMultiUsePackaging' | translate }}">
                  </app-tri-state-selector>
                </mat-card-content>
              </mat-card>
            </div>
          </div>
        </mat-expansion-panel>

        <div *ngIf="!isPreview">
          <div id="version-name-container" *ngIf="canEditForm">
            <div id="version-name-label" class="label-large">
              {{ 'packagingUnit.messages.nameVersionLabel' | translate }}
            </div>
            <div id="version-name-input">
              <mat-form-field class="form-field-medium">
                <mat-label class="label-large">{{ 'commonPackagingFields.versionName' | translate }}</mat-label>
                <input formControlName="newVersionName" matInput autocomplete="off">
              </mat-form-field>
            </div>
          </div>

          <button mat-raised-button color="primary" (click)="onSubmit()" class="custom-button" [disabled]="!canEditForm"
            type="button">
            <mat-icon>save_alt</mat-icon>
            {{ 'common.buttons.save' | translate }}
          </button>

          <button mat-raised-button color="primary" [matMenuTriggerFor]="analyses.analysesMenu"
            (click)="analyses.loadAnalysesForPackagingUnit()" [disabled]="packagingUnit.id == null || isUserValidator"
            *ngIf="!isGeneric" class="custom-button" type="button">
            <mat-icon svgIcon="analysis-icon"></mat-icon>
            {{'home.packagingUnitHome.evaluations' | translate}}
          </button>
          <app-analyses-menu-packaging-units #analyses [packagingUnitId]="packagingUnit.id"
            [packagingUnitType]="packagingUnit.packagingTypeId" [versionId]="packagingUnit.version"
            [isUserValidator]="isUserValidator" [caller]="callerPageId"
            [hasRecyclabilityResult]="packagingUnit.hasRecyclabilityResult"
            [hasLifeCycleResult]="packagingUnit.hasLifeCycleResult"
            [creationTimestamp]="packagingUnit.creationTimestamp">
          </app-analyses-menu-packaging-units>

          <button mat-raised-button class="custom-button" [disabled]="packagingUnit.id == null || isUserValidator"
            [matMenuTriggerFor]="reports.reportsMenu" (click)="reports.loadReports()" type="button" color="primary">
            <mat-icon>topic</mat-icon>
            {{'report.buttons.buttonReports' | translate}}
          </button>
          <app-reports-menu-for-packaging-part #reports [callerItemId]="packagingUnit.id" [callerPage]="callerPageId"
            [callerLevel]="callerLevelId">
          </app-reports-menu-for-packaging-part>
        </div>
      </form>
    </div>
  </app-packaging-navigation>
</div>
