import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { PackagingUnitDto } from 'src/app/data-transfer/entities/packaging-unit-entities/packaging-unit-dto';
import { DialogActions } from 'src/app/model/dictionary';

@Component({
  selector: 'app-selection-dialog-packaging-units',
  templateUrl: './selection-dialog-packaging-units.component.html',
  styleUrls: ['./selection-dialog-packaging-units.component.scss']
})
export class SelectionDialogPackagingUnitsComponent {

  dataSource: MatTableDataSource<PackagingUnitDto>;
  displayedColumns: string[] = [];
  selectedPackagingUnits: PackagingUnitDto[];

  constructor(
    protected dialogRef: MatDialogRef<SelectionDialogPackagingUnitsComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: any
  ) {
    this.dataSource = new MatTableDataSource<PackagingUnitDto>();
    this.selectedPackagingUnits = [];
    if (data) {
      this.dataSource = data.dataSource;
      this.displayedColumns = data.displayedColumns;
    }
  }

  packagingUnitsSelected(e: PackagingUnitDto[]) {
    this.selectedPackagingUnits = e;
  }

  importPackagingUnits() {
    this.dialogRef.close({ event: DialogActions.CONFIRM, selectedPackagingUnits: this.selectedPackagingUnits });
  }

  closeDialog() {
    this.dialogRef.close({ event: DialogActions.REJECT });
  }
}
