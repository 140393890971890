import { PackagingSystemLifeCycleResultDto } from 'src/app/data-transfer/entities/evaluation-entities/packaging-part-results/packaging-system-life-cycle-result-dto';
import { LifeCycleResult } from './life-cycle-result';
import { LcaEffectInfo, LcaStageInfo } from '../life-cycle-data-information';

export class LifeCycleResultPackagingSystem extends LifeCycleResult {

  lifeCycleDataSource!: PackagingSystemLifeCycleResultDto;

  constructor(
    private template: PackagingSystemLifeCycleResultDto,
    private stagesInfo: LcaStageInfo[],
    private effectsInfo: LcaEffectInfo[],
    private countryName: string
  ) {
    super();

    const system = this.template.analyzedPackagingSystem;
    this.packagingPartId = system.id;
    this.packagingPartVersion = system.version ?? 0;
    this.packagingPartName = `${system.brandName}/${system.productName}`;

    this.lifeCycleDataSource = template;
    this.analysisId = this.template.id;
    this.evaluatedCountryCode = this.template.evaluatedCountry;
    this.validationState = this.template.validationState;

    this.evaluatedCountry = this.countryName;
    this.effectRelevances = super.createEffectRelevances(this.template, this.effectsInfo);
    if (this.lifeCycleDataSource.id !== '') {
      super.initChartData(this.lifeCycleDataSource, this.effectRelevances, this.stagesInfo);
    }
  }
}