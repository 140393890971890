import { ComparisonLcaRowDefinition } from './../../../util/analyses-util/recyclability-table-definitions';
import { TranslateService } from '@ngx-translate/core';
import { Component, Output, EventEmitter, Input, OnChanges } from '@angular/core';
import { LifeCycleResultPackagingUnit } from 'src/app/model/evaluations/life-cycle-result-packaging-unit';
import { LifeCycleResultPackagingSystem } from 'src/app/model/evaluations/life-cycle-result-packaging-system';

class NormalizationCriterion {
  key = -1;
  label = '';
  value = '';
  units: string[] = [];
}

export class NormalizationData {
  normalizationFactor = -1;
  normalizeByQuantity = false;
  normalizeByVolume = false;
  normalizeByWeight = false;
  normalizationUnit?: string;
  displayUnit?: string;
}

@Component({
  selector: 'app-normalization',
  templateUrl: './normalization.component.html',
  styleUrls: ['./normalization.component.scss']
})
export class NormalizationComponent implements OnChanges {

  @Input() actualVolume!: number | null;
  @Input() actualWeight!: number | null;
  @Input() volumeAvailable = false;
  @Input() weightAvailable = false;

  normalizationCriteria: NormalizationCriterion[] = [];
  selectedCriterion?: NormalizationCriterion | null = null;
  normalizationFactor?: number;
  criterionUnits: string[] = [];
  selectedUnit = '';

  originalLcaDataSource!: (LifeCycleResultPackagingUnit | LifeCycleResultPackagingSystem)[][];
  originalLcaTableData!: ComparisonLcaRowDefinition[][];

  @Output() normalizeClick = new EventEmitter();

  constructor(
    private translateService: TranslateService
  ) { }

  ngOnChanges(): void {
    this.setNormalizationCriteria();
  }

  normalizeLcaResultData(lcaResult: (LifeCycleResultPackagingUnit | LifeCycleResultPackagingSystem), normData: NormalizationData) {
    const weight = lcaResult.packagingWeight;
    const volume = lcaResult.packagingVolume;

    lcaResult.stagesChartDataSource.forEach(effect => {
      effect.forEach(lifeStage => {
        if (lifeStage.value !== 0) {
          lifeStage.series.forEach(component => {
            if (component.value !== 0) {
              component.value = this.normalize(component.value, normData, weight, volume);
            }
          });
          lifeStage.value = this.normalize(lifeStage.value, normData, weight, volume);
          lifeStage.label = lifeStage.value.toExponential(2);
        }
      });
    });
  }

  normalizeLcaComparisonTableData(tableRow: ComparisonLcaRowDefinition, normData: NormalizationData) {
    tableRow.entries.forEach(entry => {
      const weight = entry.weight;
      const volume = entry.volume;
      const originalDisplayValue = entry.displayValue;
      entry.value = this.normalize(entry.value, normData, weight, volume);
      entry.displayValue = entry.value.toExponential(2);
      const referenceValue = normData.normalizeByQuantity ? '1' :
        normData.normalizeByVolume ? volume.toString() : weight.toString();
      const referenceUnit = normData.normalizeByQuantity ? '' : normData.normalizeByVolume ? 'ml' : 'g';
      entry.tooltip =
        this.getTooltip(originalDisplayValue, entry.displayValue, referenceValue, referenceUnit, normData.normalizationFactor);
    });
  }

  private normalize(value: number, normData: NormalizationData, weight: number | null, volume: number | null) {
    if (normData.normalizeByQuantity) {
      return this.normalizeByQuantity(value, normData.normalizationFactor);
    }
    else if (normData.normalizeByVolume && volume && volume !== 0) {
      return this.normalizeByVolume(value, normData.normalizationFactor, volume);
    }
    else if (normData.normalizeByWeight && weight && weight !== 0) {
      return this.normalizeByWeight(value, normData.normalizationFactor, weight);
    }
    return value;
  }

  private normalizeByQuantity(valueToNormalize: number, normalizationFactor: number) {
    return valueToNormalize * (normalizationFactor ?? 1);
  }

  private normalizeByVolume(valueToNormalize: number, normalizationFactor: number, packagingVolume: number) {
    if (packagingVolume === 0) { return valueToNormalize; }
    return (valueToNormalize / packagingVolume) * (normalizationFactor ?? packagingVolume);
  }

  private normalizeByWeight(valueToNormalize: number, normalizationFactor: number, packagingWeight: number) {
    if (packagingWeight === 0) { return valueToNormalize; }
    return (valueToNormalize / packagingWeight) * (normalizationFactor ?? packagingWeight);
  }

  private getTooltip(
    actualValue: string, normalizedValue: string, referenceValue: string,
    referenceUnit: string, normalizationFactor: number) {
    return referenceUnit === null ? actualValue :
      `${this.translateService.instant('analysis.analysisComparison.lca.normValue')}: ${normalizedValue}
          ${this.translateService.instant('analysis.analysisComparison.lca.perTag')}
          ${normalizationFactor} ${referenceUnit}. ${this.translateService.instant('analysis.analysisComparison.lca.origValue')}:
          ${actualValue} ${this.translateService.instant('analysis.analysisComparison.lca.perTag')}
          ${referenceValue} ${referenceUnit}`;
  }

  private setNormalizationCriteria() {
    this.normalizationCriteria = [{
      key: 0, label: 'none', value: this.translateService.instant('analysis.lifecycleAnalysis.normCriterionNone'), units: []
    },
    {
      key: 1, label: 'quantity', value: this.translateService.instant('analysis.lifecycleAnalysis.normCriterionQuantity'), units: []
    }];
    if (this.volumeAvailable) {
      this.normalizationCriteria.push({
        key: 2, label: 'volume', value: this.translateService.instant('analysis.lifecycleAnalysis.normCriterionVolume'), units: ['ml', 'l']
      });
    }
    if (this.weightAvailable) {
      this.normalizationCriteria.push({
        key: 3, label: 'weight', value: this.translateService.instant('analysis.lifecycleAnalysis.normCriterionWeight'), units: ['g', 'kg']
      });
    }
  }

  setCriterionUnits(criterion: NormalizationCriterion) {
    this.criterionUnits = criterion?.units;
    this.selectedUnit = this.criterionUnits[0] ?? '';
  }

  doNormalize() {
    let adjustedNormFactor = this.normalizationFactor ?? 0;
    let displayUnit;
    if (this.selectedCriterion?.label === 'volume' && this.selectedUnit === 'l') {
      adjustedNormFactor = adjustedNormFactor * 1000;
      displayUnit = 'ml';
    }
    if (this.selectedCriterion?.label === 'weight' && this.selectedUnit === 'kg') {
      adjustedNormFactor = adjustedNormFactor * 1000;
      displayUnit = 'g';
    }
    const normData: NormalizationData = {
      normalizationFactor: adjustedNormFactor,
      normalizeByQuantity: this.selectedCriterion?.label === 'quantity',
      normalizeByVolume: this.selectedCriterion?.label === 'volume',
      normalizeByWeight: this.selectedCriterion?.label === 'weight',
      normalizationUnit: this.selectedUnit,
      displayUnit
    };
    this.normalizeClick.emit(normData);
  }

  resetData() {
    this.selectedCriterion = null;
  }
}
