<h1 mat-dialog-title>{{ title | translate }}</h1>

<mat-dialog-content>
  <form [formGroup]="form">
    <mat-form-field class="form-field-wide">
      <mat-label>{{ nameLabel | translate }}</mat-label>
      <input matInput formControlName="name" autocomplete="off">
    </mat-form-field>
  </form>
</mat-dialog-content>

<mat-dialog-actions>
  <button mat-raised-button color="primary" (click)="acceptChanges()"
    type="submit">{{ 'dataEvaluation.viewEvaluations.rename.button' | translate }}</button>
  <button mat-raised-button (click)="discardChanges()" type="button">
    {{ 'common.buttons.cancel' | translate }}</button>
</mat-dialog-actions>
