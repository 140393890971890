import { PackagingUnitDto } from '../../packaging-unit-entities/packaging-unit-dto';
import { LifeCycleResultDto } from '../life-cycle-result-dto';
import { ComponentLifeCycleResultDto } from './component-life-cycle-result-dto';

export class PackagingUnitLifeCycleResultDto extends LifeCycleResultDto {
  analyzedPackagingUnit!: PackagingUnitDto;
  componentResults!: ComponentLifeCycleResultDto[];
}

export class DummyPackagingUnitLifeCycleResultDto extends PackagingUnitLifeCycleResultDto {
  analysisExists!: boolean;
}