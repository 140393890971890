import { Component, forwardRef, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { FormGroup, ControlValueAccessor, NG_VALUE_ACCESSOR, FormBuilder, Validators, ValidationErrors, NG_VALIDATORS } from '@angular/forms';
import { Subscription } from 'rxjs';
import { CountryDto } from 'src/app/data-transfer/entities/country-dto';
import { CountriesService } from 'src/app/navigation/services/countries-service';
import { GtinCheckService } from 'src/app/services/component-services/gtin-check-service';

@Component({
  selector: 'app-packaging-system-html-template',
  templateUrl: './packaging-system-html-template.component.html',
  styleUrls: ['./packaging-system-html-template.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PackagingSystemHtmlTemplateComponent),
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: PackagingSystemHtmlTemplateComponent,
      multi: true,
    },
  ]
})
export class PackagingSystemHtmlTemplateComponent implements OnChanges, OnDestroy, ControlValueAccessor {

  @Input() assemblyCountries: CountryDto[] = [];
  @Input() distributionCountries: CountryDto[] = [];
  @Input() displayErrors = false;

  packagingSystemForm!: FormGroup;
  minimalValue = 0.001;
  maxCommentLength = 250;

  showDimensionsInfo = false;
  allDistrCountryNames = '';

  private formSubscription?: Subscription;
  private gtinSubscription?: Subscription;

  constructor(
    private fb: FormBuilder,
    private gtinCheckService: GtinCheckService,
    private countriesService: CountriesService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.displayErrors && changes.displayErrors.currentValue) {
      this.packagingSystemForm.markAllAsTouched();
    }
  }

  writeValue(value: any): void {
    this.packagingSystemForm = this.fb.group({
      comment: [value.comment],
      brandName: [value.brandName ?? null, Validators.required],
      productName: [value.productName ?? null, Validators.required],
      articleNumber: [value.articleNumber ?? null],
      gtin: [value.gtin ?? null],
      assemblyCountry: [value?.assemblyCountry ?? null],
      distributionCountries: [value?.distributionCountries ?? []],
      length: [value.length, Validators.min(this.minimalValue)],
      width: [value.width, Validators.min(this.minimalValue)],
      height: [value.height, Validators.min(this.minimalValue)]
    });
    this.formSubscription = this.packagingSystemForm.valueChanges.subscribe(_ => {
      this.onChange(this.packagingSystemForm.value);
      this.onValidationChange();
    });
    this.gtinSubscription = this.packagingSystemForm.controls.gtin.valueChanges.subscribe(async val => {
      this.packagingSystemForm.controls.gtin.setValue(val?.trim(), { emitEvent: false });
      await this.gtinCheckService.checkGtinValidity(this.packagingSystemForm);
      this.packagingSystemForm.controls.gtin.markAsTouched();
    });
    this.allDistrCountryNames = this.getAllDistrCountryNames();
  }

  setDisabledState?(isDisabled: boolean): void {
    isDisabled ? this.packagingSystemForm.disable() : this.packagingSystemForm.enable();
  }

  validate(): ValidationErrors | null {
    if (this.packagingSystemForm?.invalid) {
      return { invalid: true };
    } else {
      return null;
    }
  }

  get packagingForm() { return this.packagingSystemForm.controls; }

  onChange: any = () => { };
  onTouched: any = () => { };
  onValidationChange: any = () => { };
  registerOnChange(fn: any) { this.onChange = fn; }
  registerOnTouched(fn: any) { this.onTouched = fn; }
  registerOnValidatorChange?(fn: () => void): void { this.onValidationChange = fn; }

  private getAllDistrCountryNames() {
    const allDistrCountryCodes: string[] = this.packagingSystemForm.controls.distributionCountries.value;
    const allDistrCountryNames = allDistrCountryCodes.map(x => this.countriesService.getCountryNameByCode(x));
    return allDistrCountryNames.map((item) => item).join(', ');
  }

  ngOnDestroy(): void {
    this.formSubscription?.unsubscribe();
    this.gtinSubscription?.unsubscribe();
  }
}
