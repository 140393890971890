import { Component } from '@angular/core';
import { SimpleConfirmDialogComponent } from '../simple-confirm-dialog/simple-confirm-dialog.component';
import { DialogActions } from 'src/app/model/dictionary';

@Component({
  selector: 'app-optional-version-dialog',
  templateUrl: './optional-version-dialog.component.html',
  styleUrls: ['../simple-confirm-dialog/simple-confirm-dialog.component.scss']
})
export class OptionalVersionDialogComponent extends SimpleConfirmDialogComponent {

  createNewVersion() {
    this.dialogRef.close({ event: DialogActions.CONFIRM, data: { createNewVersion: true } });
  }

  overwriteExistingVersion() {
    this.dialogRef.close({ event: DialogActions.CONFIRM, data: { createNewVersion: false } });
  }
}
