import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { EMPTY, Observable, forkJoin, of } from 'rxjs';
import { PathResolveService } from 'src/app/services/path-resolve.service';
import { PackagingUnitDto } from '../entities/packaging-unit-entities/packaging-unit-dto';
import { PackagingUnitApiService } from '../services/packaging-unit-api-service';
import { inject } from '@angular/core';
import { DateHelperService } from 'src/app/services/date-helper.service';
import { PackagingUnitInfoDto } from '../entities/packaging-unit-entities/packaging-unit-info-dto';
import { PermissionTypeDto } from '../entities/permission-type-dto';
import { PackagingPartTypeDto } from '../entities/packaging-part-type-dto';
import { AuthService } from 'src/app/services/auth-service';
import { ChangeText, OriginalText } from 'src/app/model/path-building-blocks';
import * as moment from 'moment';

export const packagingUnitResolver: ResolveFn<PackagingUnitDto> = (route: ActivatedRouteSnapshot) => {
  const id = PathResolveService.getPackagingUnitIdFromRoute(route);
  return id != null ? inject(PackagingUnitApiService).getPackagingUnit(id) : EMPTY;
};

export const allPackagingUnitsResolver: ResolveFn<PackagingUnitInfoDto[]> = () => {
  const startDate = inject(DateHelperService).getDateInDaysUTCMoment(moment(), -6);
  const endDate = inject(DateHelperService).getDateInDaysUTCMoment(moment(), 1);
  return inject(PackagingUnitApiService).getFilteredPackagingUnits(startDate.toISOString(), endDate.toISOString());
};

export const packagingUnitHistoryResolver: ResolveFn<PackagingUnitDto> = (route: ActivatedRouteSnapshot) => {
  const id = PathResolveService.getPackagingUnitIdFromRoute(route);
  const versionId = PathResolveService.getPackagingVersionIdFromRoute(route);
  return (id != null && versionId != null) ? inject(PackagingUnitApiService).getPackagingUnit(id, versionId) : EMPTY;
};

export const packagingUnitPermissionsResolver: ResolveFn<PermissionTypeDto> = (route: ActivatedRouteSnapshot) => {
  const id = PathResolveService.getPackagingUnitIdFromRoute(route);
  return id != null ? inject(PackagingUnitApiService).getPackagingUnitPermissions(id) : EMPTY;
};

export const packagingUnitTypesResolver: ResolveFn<PackagingPartTypeDto[]> = () => {
  return inject(PackagingUnitApiService).getPackagingUnitTypes();
};

export const packagingUnitTypesEngResolver: ResolveFn<PackagingPartTypeDto[]> = () => {
  return inject(PackagingUnitApiService).getPackagingUnitTypesEnglish();
};

export const multiplePackagingUnitsResolver: ResolveFn<PackagingUnitDto[]> = (route: ActivatedRouteSnapshot) => {
  const ids = PathResolveService.getPackagingUnitIdListFromRoute(route);

  if (ids && ids.length > 0) {
    const observablesList: Observable<PackagingUnitDto>[] = [];
    for (const id of ids) {
      observablesList.push(inject(PackagingUnitApiService).getPackagingUnit(id));
    }
    return forkJoin(observablesList);
  } else {
    return of([] as PackagingUnitDto[]);
  }
};

export const lastViewedPackagingUnitsResolver: ResolveFn<PackagingUnitInfoDto[]> = () => {
  if (!inject(AuthService).isUserValidator()) {
    return inject(PackagingUnitApiService).getLastViewedPackagingUnits(8);
  } else {
    return of([]);
  }
};

export const changedComponentsResolver: ResolveFn<number[]> = (route: ActivatedRouteSnapshot) => {
  const id = PathResolveService.getPackagingUnitIdFromRoute(route);
  return id != null ? inject(PackagingUnitApiService).getChangedComponentIdsInPackagingUnit(id) : EMPTY;
};

export const packagingUnitPreviewResolver: ResolveFn<PackagingUnitDto> = (route: ActivatedRouteSnapshot) => {
  const candidateId = PathResolveService.getCandidateIdFromRoute(route);
  if (candidateId == null) { return EMPTY; }

  const url = route.url.map(segment => segment.toString());
  if (url.findIndex(x => x === ChangeText) > -1) {
    if (url.findIndex(y => y === OriginalText) > -1) {
      return resolveChange(candidateId, true);
    }
    return resolveChange(candidateId, false);
  } else {
    return resolveImport(candidateId);
  }
};

const resolveImport = (candidateId: number) => {
  return inject(PackagingUnitApiService).getPackagingUnitPreview(candidateId);
};

const resolveChange = (candidateId: number, isOriginal = false) => {
  return isOriginal ? inject(PackagingUnitApiService).getPackagingUnit(candidateId) :
    inject(PackagingUnitApiService).getPackagingUnitPreview(candidateId);
}
