import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, OnChanges, Input, SimpleChanges } from '@angular/core';
import * as  Highcharts from 'highcharts';
import Drilldown from 'highcharts/modules/drilldown';
import { DARK_THEME_BACKGROUND, DARK_THEME_BORDER, DARK_THEME_FONT, IMPORTANT_INFO_COLOR, LIGHT_THEME_BACKGROUND, LIGHT_THEME_BORDER, LIGHT_THEME_FONT } from 'src/app/model/charts-color-collection';
import { ChartSeries } from 'src/app/util/analyses-util/live-cycle/life-cycle-service';
Drilldown(Highcharts);
import Accessibility from 'highcharts/modules/accessibility';
Accessibility(Highcharts);

@Component({
  selector: 'app-life-cycle-chart',
  templateUrl: './life-cycle-chart.component.html',
  styleUrls: ['./life-cycle-chart.component.scss']
})
export class LifeCycleParentChartComponent implements OnInit, OnChanges {

  @Input() chartRawData: ChartSeries[] = [];
  @Input() unit = '';
  @Input() chartTitle = '';
  @Input() chartSubtitle = '';
  @Input() chartExportCaption = '';
  @Input() isLcaStagesChart = '';
  @Input() isDarkTheme = false;
  @Input() hasDrilldown = false;
  @Input() chartHeight = 500;

  private colors = ['#003D4A', '#006176', '#0097B7', '#7FB0BA', '#7FCBDB', '#25D9FF', '#92ECFF'];

  updateFlag = false;
  highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    chart: {
      type: 'column',
      height: 500,
      style: {
        fontFamily: 'Helsinki, sans-serif',
        fontWeight: 'normal'
      }
    },
    legend: {
      enabled: true,
      layout: 'vertical',
      align: 'right',
      verticalAlign: 'middle',
      itemStyle: {}
    },
    title: {
      style: {
        fontSize: '30px',
        'font-family': '\'Helsinki\', sans-serif',
      }
    },
    subtitle: {
      style: {
        fontSize: '20px',
        'font-family': '\'Helsinki\', sans-serif',
      }
    },
    exporting: {
      sourceWidth: 1300,
      buttons: {
        contextButton: {
          menuItems: ['viewFullscreen', 'separator', 'downloadPNG', 'downloadJPEG', 'downloadPDF']
        }
      },
      chartOptions: {
        caption: {}
      }
    },
    navigation: {
      buttonOptions: {
        width: 28,
        height: 28,
        symbolX: 14,
        symbolY: 14,
        theme: {}
      },
      menuStyle: {},
      menuItemStyle: {},
      menuItemHoverStyle: {}
    },
    plotOptions: {
      series: {
        borderWidth: 0,
        dataLabels: {
          enabled: true,
          formatter() {
            return this.point.y === 0 ? '0' : this.point.y?.toExponential(2);
          },
          style: {
            fontFamily: 'Helsinki, sans-serif',
            fontWeight: '400'
          }
        }
      },
      column: {
        grouping: false
      }
    },
    tooltip: {
      headerFormat: '',
      formatter() {
        return `<span style="color:${this.point.color}">${this.point.name}</span>: <b>${this.y === 0 ? '0' :
          this.y?.toExponential(2)}</b> ${this.point.options.description}`;
      }
    },
    drilldown: {
      activeAxisLabelStyle: {
        color: 'black'
      }
    },
    lang: {
      viewFullscreen: this.translateService.instant('analysis.recyclabilityAnalysis.fullscreen'),
      exitFullscreen: this.translateService.instant('analysis.recyclabilityAnalysis.exitFullscreen'),
      downloadJPEG: this.translateService.instant('analysis.recyclabilityAnalysis.downloadJpeg'),
      downloadPNG: this.translateService.instant('analysis.recyclabilityAnalysis.downloadPng'),
      downloadPDF: this.translateService.instant('analysis.recyclabilityAnalysis.downloadPdf')
    },
    credits: {
      enabled: false
    }
  };

  constructor(private translateService: TranslateService) { }

  ngOnInit(): void {
    this.setOriginDependentChartOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ((changes.chartRawData && changes.chartRawData.currentValue) ||
      (changes.isDarkTheme && changes.isDarkTheme.previousValue !== undefined &&
        changes.isDarkTheme.currentValue !== changes.isDarkTheme.previousValue)
    ) {
      const chartData = this.isLcaStagesChart ? this.chartRawData : this.chartRawData.filter(x => x.value !== 0);
      this.setOriginDependentChartOptions();
      this.setChartData(chartData);
      if (this.hasDrilldown) {
        this.setChartDrilldown(chartData);
      }
      this.updateFlag = true;
    }
  }

  private setOriginDependentChartOptions() {
    if (this.chartOptions.chart) {
      this.chartOptions.chart.height = this.chartHeight;
      this.chartOptions.chart.backgroundColor = this.isDarkTheme ? DARK_THEME_BACKGROUND : LIGHT_THEME_BACKGROUND;
    }
    if (this.chartOptions.title) {
      this.chartOptions.title.text = this.chartTitle;
      if (this.chartOptions.title.style) {
        this.chartOptions.title.style.color = this.isDarkTheme ? DARK_THEME_FONT : LIGHT_THEME_FONT;
      }
    }
    if (this.chartOptions.subtitle) {
      this.chartOptions.subtitle.text = this.chartSubtitle;
      if (this.chartOptions.subtitle.style) {
        this.chartOptions.subtitle.style.color = IMPORTANT_INFO_COLOR;
      }
    }
    if (this.chartOptions.navigation) {
      if (this.chartOptions.navigation.buttonOptions && this.chartOptions.navigation.buttonOptions.theme) {
        this.chartOptions.navigation.buttonOptions.theme.fill = this.isDarkTheme ? DARK_THEME_BACKGROUND : LIGHT_THEME_BACKGROUND;
        this.chartOptions.navigation.buttonOptions.theme.stroke = this.isDarkTheme ? DARK_THEME_BORDER : LIGHT_THEME_BORDER;
      }
      if (this.chartOptions.navigation.menuStyle) {
        this.chartOptions.navigation.menuStyle.backgroundColor = this.isDarkTheme ? DARK_THEME_BACKGROUND : LIGHT_THEME_BACKGROUND;
      }
      if (this.chartOptions.navigation.menuItemStyle) {
        this.chartOptions.navigation.menuItemStyle.color = this.isDarkTheme ? DARK_THEME_FONT : LIGHT_THEME_FONT;
      }
      if (this.chartOptions.navigation.menuItemHoverStyle) {
        this.chartOptions.navigation.menuItemHoverStyle.backgroundColor = this.isDarkTheme ? DARK_THEME_BORDER : LIGHT_THEME_BORDER;
      }
    }
    if (this.chartOptions.legend && this.chartOptions.legend.itemStyle) {
      this.chartOptions.legend.itemStyle.color = this.isDarkTheme ? DARK_THEME_FONT : LIGHT_THEME_FONT;
    }
    if (this.chartOptions.drilldown) {
      this.chartOptions.drilldown.activeAxisLabelStyle = {
        color: this.isDarkTheme ? DARK_THEME_FONT : LIGHT_THEME_FONT
      };
      this.chartOptions.xAxis = {
        type: 'category',
        labels: {
          style: {
            fontSize: '15px',
            'font-family': '\'Helsinki\', sans-serif',
            color: this.isDarkTheme ? DARK_THEME_FONT : LIGHT_THEME_FONT
          }
        }
      };
      this.chartOptions.yAxis = {
        labels: {
          formatter() {
            return (this.value as number) === 0 ? '0' : (this.value as number).toExponential(2);
          },
          style: {
            fontSize: '15px',
            'font-family': '\'Helsinki\', sans-serif',
            color: this.isDarkTheme ? DARK_THEME_FONT : LIGHT_THEME_FONT
          }
        },
        title: {
          text: ''
        }
      };
      if (this.chartOptions.exporting && this.chartOptions.exporting.chartOptions && this.chartOptions.exporting.chartOptions.caption) {
        this.chartOptions.exporting.chartOptions.caption.text = this.chartExportCaption;
      }
    }
  }

  private setChartData(chartData: ChartSeries[]) {
    this.chartOptions.series = [];
    for (let index = 0; index < chartData.length; index++) {
      this.chartOptions.series.push({
        type: 'column',
        name: chartData[index].name,
        color: this.colors[index],
        data: [{
          name: chartData[index].name,
          description: this.unit,
          x: index,
          y: chartData[index].value,
          drilldown: chartData[index].name
        }]
      });
    }
  }

  private setChartDrilldown(chartData: ChartSeries[]) {
    const drilldownSeries = [];
    for (const chartSection of chartData) {
      const seriesData = [];
      const drillDataWithValues = !this.isLcaStagesChart ? chartSection.series : chartSection.series.filter(x => x.value !== 0);
      for (const componentSection of drillDataWithValues) {
        seriesData.push({
          name: componentSection.name,
          y: componentSection.value,
          description: this.unit
        });
      }
      drilldownSeries.push({
        name: chartSection.name,
        colors: this.colors,
        id: chartSection.name,
        type: 'column',
        data: seriesData
      });
    }
    this.chartOptions.drilldown = {
      /* drillUpButton: {
        theme: {
          fill: this.isDarkTheme ? DARK_THEME_BUTTON : LIGHT_THEME_BUTTON,
          'stroke-width': 1,
          stroke: 'lightgrey',
          r: 5,
          padding: 10,
          style: {
            'font-size': '14px',
            'font-family': '\'Helsinki\', sans-serif',
            color: this.isDarkTheme ? LIGHT_THEME_FONT : DARK_THEME_FONT
          }
        }
      }, */
      series: drilldownSeries as any
    };
  }
}
