import { Component, Inject, OnInit, Optional } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DialogActions } from 'src/app/model/dictionary';

export enum RenameDialogType {
  ViewEvaluation,
  PackagingSystemVersion,
  PackagingUnitVersion,
  PackagingComponentVersion,
  CompositeMaterial
}

@Component({
  selector: 'app-rename-dialog',
  templateUrl: './rename-dialog.component.html',
  styleUrls: ['./rename-dialog.component.scss']
})
export class RenameDialogComponent implements OnInit {

  form!: FormGroup;
  action = '';
  name = '';
  translationKey = '';
  type!: RenameDialogType;

  constructor(
    private dialogRef: MatDialogRef<RenameDialogComponent>,
    private formBuilder: FormBuilder,
    @Optional() @Inject(MAT_DIALOG_DATA) protected data: { name: string, type: RenameDialogType }
  ) {
    if (data) {
      this.name = data.name ?? '';
      this.type = data.type;
    }
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      name: [this.name]
    });
    if (this.type === RenameDialogType.ViewEvaluation) {
      this.translationKey = 'dataEvaluation.viewEvaluations';
    } else if (this.type === RenameDialogType.PackagingUnitVersion) {
      this.translationKey = 'packagingUnit';
    }  else if (this.type === RenameDialogType.PackagingSystemVersion) {
      this.translationKey = 'packagingSystem';
    } else if (this.type === RenameDialogType.PackagingComponentVersion) {
      this.translationKey = 'component';
    } else if (this.type === RenameDialogType.CompositeMaterial) {
      this.translationKey = 'material';
    }
  }

  get title(): string {
    return this.translationKey + '.rename.dialogTitle';
  }

  get nameLabel(): string {
    return this.translationKey + '.rename.nameLabel';
  }

  acceptChanges() {
    this.form.markAllAsTouched();
    if (this.form.invalid) { return; }
    this.dialogRef.close({ event: DialogActions.CONFIRM, data: this.form.value });
  }

  discardChanges() {
    this.dialogRef.close({ event: DialogActions.REJECT });
  }
}
