<app-component-html-template [packagingComponent]="inlay" [form]="form" [componentSubtypes]="componentSubtypes"
  [callerId]="callerId" [isPreview]="isPreview" [isTracked]="isTracked" [isFormLocked]="isFormLocked"
  [historyVersions]="historyVersions" [spinnerActive]="spinnerActive" [canEditForm]="canEditForm"
  [isUserValidator]="isUserValidator" [isEditPermitted]="isEditPermitted" [imageSource]="imageSource"
  [componentTypeSelectionLabel]="'component.fields.ofInlay' | translate" [changedMaterialsInfo]="changedMaterialsInfo"
  (submitted)="onComponentSubmit($event)" (historicVersionClicked)="loadHistoryVersions(inlay.id)"
  (historicVersionChanged)="onHistoryVersionChange($event)" (versionRenamed)="changeInlayVersionName($event)"
  (materialChangeHandled)="handleMaterialChange($event)">

  <h1 header>{{ 'component.inlay' | translate }}</h1>

  <div image>
    <app-image-upload [canEditForm]="canEditForm" [images]="inlay.images" (filesEdited)="onFilesEdited()">
    </app-image-upload>
  </div>

  <div pdf>
    <app-pdf-upload [canEditForm]="canEditForm" [pdfs]="inlay.documents" (filesEdited)="onFilesEdited()">
    </app-pdf-upload>
  </div>

</app-component-html-template>