<h1 mat-dialog-title>
  <span matDialogTitle class="align-center">
    <mat-icon *ngIf="icon">{{icon}}</mat-icon> {{ 'common.text.information' | translate }}
  </span>
</h1>
<mat-dialog-content>
  <div *ngFor="let message of messages">
    <p>{{message}}</p>
  </div>
</mat-dialog-content>
<mat-dialog-actions>
  <button (click)="createNewVersion()" mat-raised-button type="button" color="primary">
    {{ 'common.buttons.createNewVersion' | translate }}</button>
  <button (click)="overwriteExistingVersion()" mat-raised-button type="button" color="primary">
    {{ 'common.buttons.overwriteExistingVersion' | translate }}</button>
  <button (click)="doReject()" mat-raised-button type="button">
    {{ 'common.buttons.cancel' | translate }}</button>
</mat-dialog-actions>