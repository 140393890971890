import { GtinCheckService } from 'src/app/services/component-services/gtin-check-service';
import { Subscription } from 'rxjs';
import { Component, forwardRef, Input, OnDestroy, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators, ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS } from '@angular/forms';
import { MultiMaterialCompositeDto } from 'src/app/data-transfer/entities/material-entities/multi-material-composite-dto';
import { CreateUpdateHandler } from 'src/app/services/packaging-services/create-update-handler';
import { CompositeMaterialSevice } from 'src/app/services/material-services/composite-material-service';

@Component({
  selector: 'app-composite-material-html-template',
  templateUrl: './composite-material-html-template.component.html',
  styleUrls: ['./composite-material-html-template.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CompositeMaterialHtmlTemplateComponent),
      multi: true
    },
    { provide: NG_VALIDATORS, useExisting: CompositeMaterialHtmlTemplateComponent, multi: true }
  ]
})
export class CompositeMaterialHtmlTemplateComponent implements OnDestroy, ControlValueAccessor {

  @Input() isPartOfMaterialImport = false;
  @Input() isTracked = false;
  @Input() canEditForm = true;

  @Output() manufacturingCountryChanged = new EventEmitter();

  form!: FormGroup;
  private compositeMaterial!: MultiMaterialCompositeDto;

  private gtinChangesSubscription?: Subscription;
  private formChangesSubscription?: Subscription;
  private manufCountrySubscription?: Subscription;
  private grammageSubscription?: Subscription;

  constructor(
    private formBuilder: FormBuilder,
    public handler: CreateUpdateHandler,
    private gtinCheckService: GtinCheckService
  ) { }

  writeValue(value: MultiMaterialCompositeDto | null): void {
    this.compositeMaterial = value ?? CompositeMaterialSevice.createEmptyCompositeMaterial();
    this.value = this.compositeMaterial;
  }

  set value(value: MultiMaterialCompositeDto) {
    this.initForm(value);
    this.formChangesSubscription = this.form.valueChanges.subscribe(_ => {
      this.onChange(this.form.value);
    });
    this.gtinChangesSubscription = this.form.controls.gtin.valueChanges.subscribe(async val => {
      this.form.controls.gtin.setValue(val?.trim(), { emitEvent: false });
      await this.gtinCheckService.checkGtinValidity(this.form);
      this.form.controls.gtin.markAsTouched();
    });
    this.manufCountrySubscription = this.form.controls.manufacturingCountry.valueChanges.subscribe(newCountry =>
      this.manufacturingCountryChanged.emit(newCountry));
    this.manufacturingCountryChanged.emit(this.form.controls.manufacturingCountry.value ?? '');
    if (!this.canEditForm) { this.form.disable(); }
  }

  onChange: any = () => { };
  onTouched: any = () => { };
  registerOnChange(fn: any) { this.onChange = fn; }
  registerOnTouched(fn: any) { this.onTouched = fn; }

  private initForm(material: MultiMaterialCompositeDto) {
    if (!material) { return; }
    this.form = this.formBuilder.group({
      manufacturerName: { value: material.manufacturerName, disabled: this.isTracked },
      articleName: material.articleName,
      articleNumber: material.articleNumber,
      manufacturingCountry: [{ value: material.manufacturingCountry, disabled: this.isTracked }, Validators.required],
      gtin: material.gtin,
      totalGrammage: material.totalGrammage,
      totalWeight: { value: material.totalWeight, disabled: true },
    });
    this.grammageSubscription = this.form.controls.totalGrammage.valueChanges
      .subscribe(newTotalGrammage => this.compositeMaterial.totalGrammage = newTotalGrammage);
  }

  validate() {
    if (this.form?.invalid) {
      return { invalid: true };
    } else {
      return null;
    }
  }

  ngOnDestroy(): void {
    this.formChangesSubscription?.unsubscribe();
    this.gtinChangesSubscription?.unsubscribe();
    this.manufCountrySubscription?.unsubscribe();
    this.grammageSubscription?.unsubscribe();
  }
}
