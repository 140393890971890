import { TranslateService } from '@ngx-translate/core';
import { Injectable } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class RatingsHandler {

  getRatingNameFromValue(ratingValue: number) {
    switch (ratingValue) {
      case 1: return 'A';
      case 2: return 'B';
      case 3: return 'C';
      case 4: return 'D';
      case 5: return 'X';
      default: return '?';
    }
  }

  getRatingCellColorRecyclability(ratingName: string) {
    switch (ratingName) {
      case 'A': return '#A5C814';
      case 'B': return '#5DC5EA';
      case 'C': return '#666666';
      case 'D': return '#000000';
      case 'X': return '#E73E2B';
      default: return '#003D4A';
    }
  }

  getRatingColorRecyclabilityMap(ratingName: string) {
    switch (ratingName) {
      case 'A': return '#A5C814';
      case 'B': return '#D3EF61';
      case 'C': return '#D1AD1A';
      case 'NotRecyclable': return '#A42F37';
      default: return '#003D4A';
    }
  }

  getRatingFontColorRecyclability(ratingName: string) {
    switch (ratingName) {
      case 'A': return 'black';
      case 'B': return 'black';
      case 'C': return 'white';
      case 'D': return 'white';
      case 'X': return 'black';
      default: return 'white';
    }
  }

  getRatingTooltip(translateService: TranslateService, ratingLabel: string) {
    const ratingName = ratingLabel === '?' ? 'Unknown' : ratingLabel;
    return translateService.instant('analysis.recyclabilityAnalysis.percentageRating' + ratingName);
  }
}
