<div class="chart-container">
  <highcharts-chart [Highcharts]="highcharts" [options]="chartOptions" [(update)]="updateFlag">
  </highcharts-chart>
  <div *ngIf="emptyEvaluations.length > 0" class="versions-container">
    {{ 'analysis.lifecycleAnalysis.noAnalysis' | translate }}
    <mat-form-field class="form-field-medium" id="packaging-units-selection">
      <mat-select [(value)]='selectedPackagingUnitEval'>
        <mat-option *ngFor="let packagingEval of emptyEvaluations" [value]="packagingEval">
          {{ packagingEval.packagingPartName }} (ID {{packagingEval.packagingPartId}}, Version
          {{packagingEval.packagingPartVersion}})
        </mat-option>
      </mat-select>
    </mat-form-field>
    <span [matTooltipDisabled]="selectedPackagingUnitEval.isLcaPossible && this.licenseService.isLcaAllowed"
      matTooltip="{{ 'warnings.analysisNotPossible' | translate }}">
      <button mat-raised-button color="primary" (click)="createNewAnalysis()"
        [disabled]="!selectedPackagingUnitEval.isLcaPossible || !this.licenseService.isLcaAllowed">
        {{ 'analysis.lifecycleAnalysis.newAnalysisButton' | translate }}
        <mat-icon *ngIf="!selectedPackagingUnitEval.isLcaPossible">
          info
        </mat-icon>
      </button>
    </span>
  </div>
</div>
