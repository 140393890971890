import { CompositeMaterialExportProfileDto } from '../entities/material-entities/composite-material-export-profile-dto';
import { map } from 'rxjs/operators';
import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import { Observable, forkJoin } from 'rxjs';
import { PackagingUnitExportProfileDto } from '../entities/packaging-unit-entities/packaging-unit-export-profile-dto';
import { PackagingComponentExportProfileDto } from '../entities/component-entities/packaging-component-export-profile-dto';
import { ImportExportApiService } from '../services/import-export-api-service';
import { PackagingSystemExportProfileDto } from '../entities/packaging-system-entities/packaging-system-export-profile-dto';

export class ProfilesCollection {
  packagingSystemProfiles: PackagingSystemExportProfileDto[] = [];
  packagingUnitProfiles: PackagingUnitExportProfileDto[] = [];
  componentProfiles: PackagingComponentExportProfileDto[] = [];
  materialProfiles: CompositeMaterialExportProfileDto[] = [];
}

export const exportProfilesResolver: ResolveFn<ProfilesCollection> =
  () => {
    const importExportApiService = inject(ImportExportApiService);
    const profilesObservable: Observable<any>[] = [];
    profilesObservable.push(importExportApiService.getPackagingSystemExportProfiles());
    profilesObservable.push(importExportApiService.getPackagingUnitExportProfiles());
    profilesObservable.push(importExportApiService.getPackagingComponentExportProfiles());
    profilesObservable.push(importExportApiService.getCompositeMaterialExportProfiles());
    return forkJoin(profilesObservable).pipe(
      map(profilesArray => {
        const profilesCollection = new ProfilesCollection();
        profilesCollection.packagingSystemProfiles = profilesArray[0];
        profilesCollection.packagingUnitProfiles = profilesArray[1];
        profilesCollection.componentProfiles = profilesArray[2];
        profilesCollection.materialProfiles = profilesArray[3];
        return profilesCollection;
      })
    );
  };
