<mat-form-field class="form-field-wide" floatLabel="always">
  <mat-label *ngIf="label !== ''" class="label-large">
    {{ label }}
  </mat-label>
  <mat-select [formControl]="control" [placeholder]="placeholder" (selectionChange)="onSelectionChange($event)">
    <mat-option *ngFor="let option of options" [value]="option.key">
      {{ option.value }}
    </mat-option>
  </mat-select>
</mat-form-field>
