import { PathResolveService } from 'src/app/services/path-resolve.service';
import { Injectable } from '@angular/core';
import {
  HttpEvent, HttpRequest, HttpHandler,
  HttpInterceptor, HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';

const getErrorTitle = (error: HttpErrorResponse) =>
  error.status + ' - ' + error.statusText;

const getErrorMessage =
  (error: HttpErrorResponse) =>
    ((error.error instanceof ErrorEvent) ? error.error : error) ?? error.toString();

const getErrorLocation = (error: ErrorEvent) =>
  'at ' + error.error.filename + ' (line: ' + error.error.lineno + ')';

@Injectable()
export class ServerErrorInterceptor implements HttpInterceptor {

  constructor(
    private router: Router) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const returnUrl = this.router.routerState.snapshot.url;
    return next.handle(request).pipe(
      catchError((error) => {
        if (error instanceof HttpErrorResponse && !request.headers.get('stayOnPageIfError')) {
          if (error.error instanceof ErrorEvent) {
            // Client error
            this.router.navigate([PathResolveService.getErrorPath()],
              {
                queryParams: {
                  title: getErrorTitle(error), message: getErrorMessage(error),
                  location: getErrorLocation(error.error), returnUrl
                }
              });
            return new Observable<never>();
          } else {
            // Server error
            this.router.navigate([PathResolveService.getErrorPath()],
              { queryParams: { statusCode: error.status, returnUrl } });
            return new Observable<never>();
          }
        }
        return throwError(error); // use global error handler
      })
    );
  }
}
