import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { PackagingSystemDto } from 'src/app/data-transfer/entities/packaging-system-entities/packaging-system-dto';
import { DialogActions } from 'src/app/model/dictionary';

@Component({
  selector: 'app-selection-dialog-packaging-systems',
  templateUrl: './selection-dialog-packaging-systems.component.html',
  styleUrls: ['./selection-dialog-packaging-systems.component.scss']
})
export class SelectionDialogPackagingSystemsComponent {
  dataSource: MatTableDataSource<PackagingSystemDto>;
  displayedColumns: string[] = [];
  selectedPackagingSystems: PackagingSystemDto[];

  constructor(
    protected dialogRef: MatDialogRef<SelectionDialogPackagingSystemsComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: any
  ) {
    this.dataSource = new MatTableDataSource<PackagingSystemDto>();
    this.selectedPackagingSystems = [];
    if (data) {
      this.dataSource = data.dataSource;
      this.displayedColumns = data.displayedColumns;
    }
  }

  packagingSystemsSelected(e: PackagingSystemDto[]) {
    this.selectedPackagingSystems = e;
  }

  importPackagingSystems() {
    this.dialogRef.close({ event: DialogActions.CONFIRM, selectedPackagingSystems: this.selectedPackagingSystems });
  }

  closeDialog() {
    this.dialogRef.close({ event: DialogActions.REJECT });
  }
}
