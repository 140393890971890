export class ComponentTableData {
  index: number;
  component: string;
  material: string;
  color: string;
  mass: number;
  rating: string;
  recyclingStream: string;

  constructor() {
    this.index = -1;
    this.component = '';
    this.material = '';
    this.color = '';
    this.mass = 0;
    this.rating = '';
    this.recyclingStream = '';
  }
}

export class RecyclabilityTableData {
  ratingName: string;
  ratingPercentage: number;
  component: string;
  componentPercentage: number;

  constructor() {
    this.ratingName = '';
    this.ratingPercentage = 0;
    this.component = '';
    this.componentPercentage = 0;
  }
}

export class PackagingSystemComponentTableData {
  index: number;
  name: string;
  quantity: number;
  totalWeight: number;
  recyclablePercentage: number;

  constructor() {
    this.index = -1;
    this.name = '';
    this.quantity = 0;
    this.totalWeight = 0;
    this.recyclablePercentage = 0;
  }
}

export class ComponentMaterialsTableData {
  index: number;
  material: string;
  color: string;
  mass: number;
  rating: string;
  recyclingStream: string;

  constructor() {
    this.index = -1;
    this.material = '';
    this.color = '';
    this.mass = 0;
    this.rating = '';
    this.recyclingStream = '';
  }
}

export interface ComparisonRowDefinition {
  key: string;
  label: string;
  entries: ComparisonEntryDefinition[];
}

export interface ComparisonEntryDefinition {
  value: number;
  displayValue: string;
  color: string;
  tooltip: string;
}

export interface ComparisonLcaRowDefinition extends ComparisonRowDefinition {
  isRelevant: boolean;
  entries: ComparisonLcaEntryDefinition[];
}

export interface ComparisonLcaEntryDefinition extends ComparisonEntryDefinition {
  weight: number;
  volume: number;
}
