import { Subject, Subscription } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { BaseDataApiService } from 'src/app/data-transfer/services/base-data-api-service';
import { CreditsDto} from '../data-transfer/entities/credits-dto';

@Injectable({ providedIn: 'root' })
export class CreditsService implements OnDestroy {

  creditsCount = new CreditsDto;
  creditsCountChange: Subject<CreditsDto> = new Subject<CreditsDto>();

  private subscription: Subscription;

  constructor(private baseDataApiService: BaseDataApiService) {
    this.subscription = this.creditsCountChange.subscribe((value) => {
      this.creditsCount = value;
    });
  }

  async setCreditsCount() {
    this.creditsCountChange.next(await this.getCredits());
  }

  private async getCredits() {
    return await this.baseDataApiService.getCreditsCount().toPromise();
  }

  // this function returns true if all credit scores are infinite
  areCreditsInfinite() {
    return this.areLcaCreditsInfinite() && this.areRycyclingCreditsInfinite();
  }
  
  areLcaCreditsInfinite() {
    return this.creditsCount.lcaCredits >= 1000000000;
  }

  areRycyclingCreditsInfinite() {
    return this.creditsCount.recyclabilityCredits >= 1000000000;
  }

  // this function returns true if at least one credit score is running low
  areCreditsRunningLow() {
    return this.areLcaCreditsRunningLow() || this.areRecyclabilityCreditsRunningLow();
  }

  areLcaCreditsRunningLow() {
    return this.creditsCount.lcaCredits <= 100;
  }
  areRecyclabilityCreditsRunningLow() {
    return this.creditsCount.recyclabilityCredits <= 100;
  }
  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
