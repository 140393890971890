import { Subscription } from 'rxjs';
import { SecurityPrincipalDto } from './../../../../data-transfer/entities/security-principal-dto';
import { PermissionTypeDto } from '../../../../data-transfer/entities/permission-type-dto';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Component, OnInit, Optional, Inject, Output, EventEmitter, OnDestroy } from '@angular/core';
import { DirectoryApiService } from 'src/app/data-transfer/services/directory-api-service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogActions } from 'src/app/model/dictionary';

export class PermissionsDialogData {
  directoryId?: number;
  principals: SecurityPrincipalDto[] = [];
}

export class PermissionMapping {
  principalId?: number;
  permissions!: PermissionTypeDto;
  deny = false;
}

@Component({
  selector: 'app-permissions-dialog',
  templateUrl: './permissions-dialog.component.html',
  styleUrls: ['./permissions-dialog.component.scss']
})
export class PermissionsDialogComponent implements OnInit, OnDestroy {

  permissionsForm!: FormGroup;
  allPrincipals: SecurityPrincipalDto[] = [];
  selectedPrincipal!: SecurityPrincipalDto;
  currentDirId = -1;

  private permissionChangesEventSubscription?: Subscription;
  private permissionsAllowSubscription?: Subscription;
  private permissionsDenySubscription?: Subscription;

  @Output() permissionsApplied = new EventEmitter();

  constructor(
    @Optional() @Inject(MAT_DIALOG_DATA) dialogData: PermissionsDialogData,
    private dialogRef: MatDialogRef<PermissionsDialogComponent>,
    private formBuilder: FormBuilder,
    private directoryApiService: DirectoryApiService) {
    if (dialogData && dialogData.directoryId != null) {
      this.allPrincipals = dialogData.principals;
      this.currentDirId = dialogData.directoryId;
    }
  }

  ngOnInit(): void {
    this.permissionsForm = this.formBuilder.group({
      principalsControl: [null],
      readAllow: [{ value: false, disabled: true }],
      writeAllow: [{ value: false, disabled: true }],
      analyzeAllow: [{ value: false, disabled: true }],
      deleteAllow: [{ value: false, disabled: true }],
      readDeny: [{ value: false, disabled: true }],
      writeDeny: [{ value: false, disabled: true }],
      analyzeDeny: [{ value: false, disabled: true }],
      deleteDeny: [{ value: false, disabled: true }]
    });
    // Liste besteht immer nur aus 1 Komponente da multiple selection nicht erlaubt ist, daher x[0]
    this.permissionChangesEventSubscription = this.permissionsForm.controls.principalsControl.valueChanges
      .subscribe(x => this.onUserSelected(x[0]));
  }

  setOppositeValueOnClick(clickedControlName: string, oppositeControlName: string) {
    const clickedControlValue = this.permissionsForm.controls[clickedControlName].value;
    // Value of control is still set to one before click
    if (clickedControlValue === false) {
      this.permissionsForm.controls[oppositeControlName].patchValue(false);
    }
  }

  onUserSelected(principal: SecurityPrincipalDto) {
    this.selectedPrincipal = principal;
    this.permissionsAllowSubscription = this.directoryApiService.getPermissionForDirectory(this.currentDirId, principal.id, false)
      .subscribe((permissions: PermissionTypeDto) => {
        this.permissionsForm.controls.readAllow.patchValue(permissions.read);
        this.permissionsForm.controls.writeAllow.patchValue(permissions.write);
        this.permissionsForm.controls.analyzeAllow.patchValue(permissions.analyze);
        this.permissionsForm.controls.deleteAllow.patchValue(permissions.delete);
        this.permissionsForm.controls.readAllow.enable();
        this.permissionsForm.controls.writeAllow.enable();
        this.permissionsForm.controls.analyzeAllow.enable();
        this.permissionsForm.controls.deleteAllow.enable();
      });

    this.permissionsDenySubscription = this.directoryApiService.getPermissionForDirectory(this.currentDirId, principal.id, true)
      .subscribe((permissions: PermissionTypeDto) => {
        this.permissionsForm.controls.readDeny.patchValue(permissions.read);
        this.permissionsForm.controls.writeDeny.patchValue(permissions.write);
        this.permissionsForm.controls.analyzeDeny.patchValue(permissions.analyze);
        this.permissionsForm.controls.deleteDeny.patchValue(permissions.delete);
        this.permissionsForm.controls.readDeny.enable();
        this.permissionsForm.controls.writeDeny.enable();
        this.permissionsForm.controls.analyzeDeny.enable();
        this.permissionsForm.controls.deleteDeny.enable();
      });
  }

  saveSettings() {
    this.permissionsForm.markAllAsTouched();
    if (this.permissionsForm.invalid) {
      return;
    } else {
      this.dialogRef.close({ data: this.getCurrentPermissionMapping() });
    }
  }

  applySettings() {
    this.permissionsApplied.emit(this.getCurrentPermissionMapping());
  }

  closeDialog() {
    this.dialogRef.close({ event: DialogActions.REJECT });
  }

  private getCurrentPermissionMapping(): PermissionMapping[] {
    const principalPermissionAllowDenyList: PermissionMapping[] = [];

    principalPermissionAllowDenyList.push({
      principalId: this.selectedPrincipal.id,
      permissions: this.getCurrentPermissionTypeAllow(),
      deny: false
    });
    principalPermissionAllowDenyList.push({
      principalId: this.selectedPrincipal.id,
      permissions: this.getCurrentPermissionTypeDeny(),
      deny: true
    });

    return principalPermissionAllowDenyList;
  }

  private getCurrentPermissionTypeAllow(): PermissionTypeDto {
    return {
      read: this.permissionsForm.controls.readAllow.value,
      write: this.permissionsForm.controls.writeAllow.value,
      analyze: this.permissionsForm.controls.analyzeAllow.value,
      delete: this.permissionsForm.controls.deleteAllow.value
    };
  }

  private getCurrentPermissionTypeDeny(): PermissionTypeDto {
    return {
      read: this.permissionsForm.controls.readDeny.value,
      write: this.permissionsForm.controls.writeDeny.value,
      analyze: this.permissionsForm.controls.analyzeDeny.value,
      delete: this.permissionsForm.controls.deleteDeny.value
    };
  }

  ngOnDestroy(): void {
    this.permissionChangesEventSubscription?.unsubscribe();
    this.permissionsAllowSubscription?.unsubscribe();
    this.permissionsDenySubscription?.unsubscribe();
  }
}
