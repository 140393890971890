import { MatDialogConfig } from '@angular/material/dialog';

export function getDialogConfig(data?: any, width?: string): MatDialogConfig {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = false;
    if (data) { dialogConfig.data = data; }
    if (width) { dialogConfig.width = width; }
    return dialogConfig;
}
