import { PackagingComponentsOverviewComponent } from './../../directory-tree/components/packaging-components-overview/packaging-components-overview.component';
import { GENERIC_PACKAGING_TYPE_ID, MAIN_BODY_DIFFERS_FROM_PACKAGING_TYPE } from '../../../navigation/services/packaging-unit-type-service';
import { PackagingUnitTypeService } from 'src/app/navigation/services/packaging-unit-type-service';
import { ColorThemeService, COLOR_THEME_DARK } from './../../../navigation/services/color-theme-service';
import { TranslateService } from '@ngx-translate/core';
import { getPaginatorIntl } from 'src/app/util/paginator-translation.provider';
import { PackagingComponentDto } from '../../../data-transfer/entities/component-entities/packaging-component-dto';
import { COMPONENT_CATEGORIES, ComponentTypeService } from 'src/app/navigation/services/component-type-service';
import { ComponentApiService } from '../../../data-transfer/services/component-api-service';
import { Subscription, forkJoin } from 'rxjs';
import { AuthService } from './../../../services/auth-service';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { PathResolveService } from 'src/app/services/path-resolve.service';
import { PackagingComponentTypesEnum } from './../../../model/packaging-component-types-enum';
import { Component, OnInit, OnDestroy, ViewChild, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { PackagingPartTypeDto } from 'src/app/data-transfer/entities/packaging-part-type-dto';
import { MainBodyDto } from 'src/app/data-transfer/entities/component-entities/main-body-dto';

@Component({
  selector: 'app-component-home',
  templateUrl: './component-home.component.html',
  styleUrls: ['./component-home.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ComponentHomeComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(PackagingComponentsOverviewComponent) table!: PackagingComponentsOverviewComponent;

  selectedComponentTypeId = -1;
  selectedPackagingUnitTypeId = -1;

  dataSource: MatTableDataSource<PackagingComponentDto>;
  displayedColumns = ['id', 'tracking', 'packagingComponentSubtypeName', 'articleName', 'articleNumber', 'manufacturer', 'action'];

  componentCategories = COMPONENT_CATEGORIES;
  genericTypeId: number;
  componentsImageSource = '';
  allMainBodies: MainBodyDto[] = [];
  isUserValidator = false;
  orderedPackagingUnitTypes: PackagingPartTypeDto[] = [];

  private routeParamsSubscription?: Subscription;
  private navigationSubscription?: Subscription;
  private byTypeSubscription?: Subscription;
  private dialogSubscription?: Subscription;
  private deleteSubscription?: Subscription;
  private themeSubscription?: Subscription;
  private paginationSubscription?: Subscription;

  constructor(
    private componentTypeService: ComponentTypeService,
    private router: Router,
    private authService: AuthService,
    private componentApiService: ComponentApiService,
    private route: ActivatedRoute,
    private translateService: TranslateService,
    private spinner: NgxSpinnerService,
    private colorThemeService: ColorThemeService,
    private packagingUnitTypeService: PackagingUnitTypeService,
  ) {
    this.dataSource = new MatTableDataSource<PackagingComponentDto>();
    this.isUserValidator = this.authService.isUserValidator();
    this.themeSubscription = this.colorThemeService.colorThemeSubject.subscribe((nextValue) => {
      const isDarkTheme = nextValue === COLOR_THEME_DARK;
      this.componentsImageSource = this.componentTypeService.getComponentsImage(isDarkTheme);
    });
    this.genericTypeId = GENERIC_PACKAGING_TYPE_ID;
  }

  ngOnInit(): void {
    this.spinner.show();

    this.orderedPackagingUnitTypes = this.packagingUnitTypeService.getOrderedPackagingUnitTypes();
    this.routeParamsSubscription = this.route.params.subscribe(params => {
      this.dataSource.data = [];
      this.selectedComponentTypeId = params.type ? this.getComponentId(params.type) : PackagingComponentTypesEnum.MainBody;

      if (this.selectedComponentTypeId === PackagingComponentTypesEnum.MainBody) {
        this.selectedPackagingUnitTypeId = this.packagingUnitTypeService.getPackagingUnitTypes()[0].id;
      }

      let componentsToDisplay: string[];
      if (this.selectedComponentTypeId === PackagingComponentTypesEnum.Decoration) {
        // Label, sleeve, in-mold label
        componentsToDisplay = this.componentTypeService.getDecorationTypeNames();
      } else {
        const componentType = this.componentCategories.find(x => x.key === this.selectedComponentTypeId);
        if (!componentType) { throw new Error('ComponentHomeComponent: component type not found'); }
        componentsToDisplay = [componentType.label];
      }

      const observables = componentsToDisplay.map(component =>
        this.componentApiService.getPackagingComponentsByType(component));
      this.byTypeSubscription = forkJoin(observables).subscribe(components => {
        const componentsList = components.reduce((accumulator, value) => accumulator.concat(value), []);
        this.dataSource.data = componentsList;
        if (this.selectedComponentTypeId === PackagingComponentTypesEnum.MainBody) {
          this.allMainBodies = componentsList as MainBodyDto[];
          this.dataSource.data = this.allMainBodies.filter(x => x.packagingComponentSubtypeId === this.selectedPackagingUnitTypeId);
        }
        this.spinner.hide();
      });
    });

    this.navigationSubscription = this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.onComponentTypeChange(this.selectedComponentTypeId);
        if (this.dataSource.data.length === 0) { this.spinner.show(); }
      }
    });
  }

  ngAfterViewInit() {
    this.paginator._intl = getPaginatorIntl(this.translateService);
    this.dataSource.paginator = this.paginator;
    this.paginationSubscription = this.paginator.page.subscribe(_ => {
      this.table.updateDisplayTrackedColumn();
    });
  }

  getImageForComponentType(componentTypeId: number) {
    const isGray = componentTypeId !== this.selectedComponentTypeId;
    const isDark = this.colorThemeService.isDarkTheme();
    return this.componentTypeService.getComponentTypeImage(componentTypeId, isDark, isGray);
  }

  onComponentTypeChange(newComponentTypeId: number) {
    if (this.selectedComponentTypeId !== newComponentTypeId) {
      const path = PathResolveService.getComponentTypePath(true, this.getComponentLabel(newComponentTypeId));
      this.router.navigate([path]);
    }
  }

  private getComponentLabel(componentId: number): string {
    const componentType = this.componentCategories.find(x => x.key === componentId);
    if (!componentType) { throw new Error('ComponentHomeComponent: component type not found'); }
    return componentType.label;
  }

  private getComponentId(componentLabel: string): number {
    const componentType = this.componentCategories.find(x => x.label === componentLabel);
    if (!componentType) { throw new Error('ComponentHomeComponent: component type not found'); }
    return componentType.key;
  }

  createNewComponent() {
    let path;
    const componentTypeName = this.getComponentLabel(this.selectedComponentTypeId);
    if (this.selectedComponentTypeId === PackagingComponentTypesEnum.MainBody) {
      const packagingUnitTypeName = this.packagingUnitTypeService.getPackagingUnitTypeName(this.selectedPackagingUnitTypeId);
      path = PathResolveService.getCreateMainBodyComponentPath(true, componentTypeName, packagingUnitTypeName);
    } else {
      path = PathResolveService.getCreateComponentPath(true, componentTypeName);
    }
    this.router.navigate([path]);
  }

  getImageForPackagingUnitType(packagingUnitTypeId: number) {
    const isGray = packagingUnitTypeId !== this.selectedPackagingUnitTypeId;
    const imageSource = MAIN_BODY_DIFFERS_FROM_PACKAGING_TYPE.includes(packagingUnitTypeId) ?
      this.packagingUnitTypeService.getMainBodyTypeImage(packagingUnitTypeId, isGray) :
      this.packagingUnitTypeService.getPackagingUnitTypeImage(packagingUnitTypeId, isGray);
    return imageSource;
  }

  onPackagingUnitTypeChange(newPackagingUnitTypeId: number) {
    if (this.selectedPackagingUnitTypeId !== newPackagingUnitTypeId) {
      this.selectedPackagingUnitTypeId = newPackagingUnitTypeId;
      this.dataSource.data = this.allMainBodies.filter(x => x.packagingComponentSubtypeId === this.selectedPackagingUnitTypeId);
    }
  }

  ngOnDestroy(): void {
    this.routeParamsSubscription?.unsubscribe();
    this.navigationSubscription?.unsubscribe();
    this.byTypeSubscription?.unsubscribe();
    this.dialogSubscription?.unsubscribe();
    this.deleteSubscription?.unsubscribe();
    this.themeSubscription?.unsubscribe();
    this.paginationSubscription?.unsubscribe();
  }
}
