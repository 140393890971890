import { TemplateRef, ViewContainerRef, Injectable, OnDestroy } from '@angular/core';
import { TemplatePortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { fromEvent, Subscription } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { DirectoryDto } from 'src/app/data-transfer/entities/directory-dto';

@Injectable({ providedIn: 'root' })
export class ContextMenuService implements OnDestroy {

  overlayRef: OverlayRef | null = null;
  private subscription?: Subscription;

  constructor(private overlay: Overlay) { }

  openContextMenu(contextMenu: TemplateRef<any>, viewContainerRef: ViewContainerRef, { x, y }: MouseEvent, item: DirectoryDto) {
    this.closeContextMenu();
    const positionStrategy = this.overlay.position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: 'start',
          originY: 'bottom',
          overlayX: 'start',
          overlayY: 'top',
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(new TemplatePortal(contextMenu, viewContainerRef, {
      $implicit: item
    }));

    this.subscription = fromEvent<MouseEvent>(document, 'click')
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      ).subscribe(() => this.closeContextMenu());
  }

  closeContextMenu() {
    if (this.subscription) { this.subscription.unsubscribe(); }
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  ngOnDestroy(): void {
    this.subscription?.unsubscribe();
  }
}
