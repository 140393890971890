<div class="outer-container">
  <h1 class="mat-headline-3">{{ 'home.packagingUnitHome.title' | translate }}</h1>

  <div id="add-container">
    <div id="add-button-container">
      <button mat-raised-button *ngIf="!isUserValidator" (click)="createPackagingUnit()" color="primary" type="button"
        id="add-button">
        <mat-icon>add</mat-icon>
        {{ 'home.packagingUnitHome.addUnitButton' | translate }}
      </button>
    </div>
    <div id="packaging-image-container">
      <img id="packaging-image" [src]="packagingUnitImageSource">
    </div>
  </div>

  <div id="type-container">
    <div id="types-list">
      <mat-button-toggle-group id="selection-button-group" name="productType" #group="matButtonToggleGroup"
        [value]="selectedPackagingUnitTypeId" (change)="onPackagingUnitTypeChange(group.value)">
        <mat-button-toggle class="selection-button" *ngFor="let packagingUnitType of orderedPackagingUnitTypes"
          [value]="packagingUnitType.id">
          <img class="type-image" [src]="getImageForPackagingUnitType(packagingUnitType.id)">
          <div class="line-break icon-text-container">
            <span class="packaging-type-text">{{packagingUnitType.name}}</span>
            <mat-icon *ngIf="packagingUnitType.id === genericTypeId" class="gray-icon-16"
              matTooltip="{{'home.tooltips.other' | translate}}">info
            </mat-icon>
          </div>
        </mat-button-toggle>
      </mat-button-toggle-group>
    </div>
  </div>

  <div class="inner-container">
    <div class="table-container">
      <app-packaging-units-overview [dataSource]="dataSource" [displayedColumns]="displayedColumns"
        [isUserValidator]="isUserValidator" (packagingPartsSelected)="setSelectedForComparison($event)">
      </app-packaging-units-overview>
      <mat-paginator [pageSizeOptions]="[10, 25, 50, 100]"></mat-paginator>
    </div>
    <button *ngIf="!isUserValidator" (click)="compare()" mat-raised-button color="primary" id="compare-button"
      class="custom-button" [disabled]="compareButtonDisabled" type="button">
      <mat-icon>compare</mat-icon>
      {{ 'home.packagingUnitHome.compareButton' | translate }}
    </button>
  </div>
</div>