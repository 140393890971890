import { DirectoryDto } from './../../../../data-transfer/entities/directory-dto';
import { getDialogConfig } from 'src/app/util/dialog-util';
import { CreditsService } from './../../../../services/credits-service';
import { PackagingComponentTypesEnum } from './../../../../model/packaging-component-types-enum';
import { SimpleAlertDialogComponent, SimpleDialogData } from './../../../dialogs/simple-alert-dialog/simple-alert-dialog.component';
import { ImportExportApiService } from '../../../../data-transfer/services/import-export-api-service';
import { SelectLocationDialogComponent } from './../../dialogs/select-location-dialog/select-location-dialog.component';
import { DirectoryApiService } from 'src/app/data-transfer/services/directory-api-service';
import { OverviewTableComponent } from './../../../shared-components/parent-components/overview-table/overview-table.component';
import { DeleteDialogData, DeleteItemDialogComponent } from './../../../dialogs/delete-item-dialog/delete-item-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { ComponentApiService } from '../../../../data-transfer/services/component-api-service';
import { forkJoin } from 'rxjs';
import { ComponentTypeService } from './../../../../navigation/services/component-type-service';
import { ComponentNavigationService } from '../../../../navigation/services/navigation-services/component-navigation-service';
import { Component, Input, Output, EventEmitter, AfterViewInit, SimpleChanges, OnChanges, LOCALE_ID, Inject } from '@angular/core';
import { PackagingComponentDto } from 'src/app/data-transfer/entities/component-entities/packaging-component-dto';
import { PermissionTypeDto } from 'src/app/data-transfer/entities/permission-type-dto';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { NgxSpinnerService } from 'ngx-spinner';
import { DeletedEntitiesDto, DeletedEntityDto } from 'src/app/data-transfer/entities/deleted-entities-dto';
import { PackagingPart } from 'src/app/model/packaging-part-enum';
import { DialogActions, DictionaryHandler } from 'src/app/model/dictionary';

@Component({
  selector: 'app-packaging-components-overview',
  templateUrl: './packaging-components-overview.component.html',
  styleUrls: ['./packaging-components-overview.component.scss']
})
export class PackagingComponentsOverviewComponent extends OverviewTableComponent implements OnChanges, AfterViewInit {

  @Input() dataSource: MatTableDataSource<PackagingComponentDto>;
  @Input() isUserValidator = false;
  @Input() isRecyclingBinSelected = false;
  @Input() displayedColumns: string[] = [];

  @Output() dataChanged = new EventEmitter();

  selectedComponents: PackagingComponentDto[] = [];
  callerLevelId = PackagingPart.Component;

  constructor(
    private componentApiService: ComponentApiService,
    private componentTypeService: ComponentTypeService,
    private dictionaryHandler: DictionaryHandler,
    protected navigationService: ComponentNavigationService,
    protected translateService: TranslateService,
    protected dialog: MatDialog,
    protected directoryApiService: DirectoryApiService,
    protected importExportApiService: ImportExportApiService,
    protected spinner: NgxSpinnerService,
    protected creditsService: CreditsService,
    @Inject(LOCALE_ID) public locale: string
  ) {
    super(dialog, directoryApiService, importExportApiService, navigationService, translateService, spinner, creditsService, locale);
    this.dataSource = new MatTableDataSource<PackagingComponentDto>();
  }

  ngAfterViewInit() {
    this.dataSource.sort = this.sort;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dataSource != null) {
      super.setDataSourceFilter(this.dataSource);
      this.dataSourceSubscription = this.dataSource.connect().subscribe(_ => {
        this.updateDisplayTrackedColumn();
      });
    }
  }

  updateDisplayTrackedColumn() {
    super.updateDisplayTrackedColumn(this.dataSource);
  }

  getComponentCategoryNames() {
    return this.dictionaryHandler.getComponentCategoriesDictionary().map(x => x.value);
  }

  getSpecificComponentTypeNames(): string[] {
    const types: string[] = [];
    if (this.dataSource.data.length === 0) { return []; }
    this.dataSource.data.map(x => {
      if (types.findIndex(y => y === x.packagingComponentSubtypeName) === -1 && x.packagingComponentSubtypeName) {
        types.push(x.packagingComponentSubtypeName);
      }
    });
    return types;
  }

  navigateToComponent(component: PackagingComponentDto) {
    const componentTypeName = this.componentTypeService.getComponentTypeNameById(component.packagingComponentTypeId);
    if (component.packagingComponentCategoryId === PackagingComponentTypesEnum.Decoration) {
      this.navigationService.navigateToDecoration(componentTypeName, component.id ?? -1);
    } else {
      this.navigationService.navigateToComponent(componentTypeName, component.id ?? -1);
    }
  }

  selectAllComponents() {
    this.allItemsSelected = !this.allItemsSelected;
    this.selectedComponents = [];
    if (this.allItemsSelected) {
      for (const element of this.dataSource.data) {
        this.selectedComponents.push(element);
      }
    }
    this.packagingPartsSelected.emit(this.selectedComponents);
  }

  isItemSelected(item: PackagingComponentDto) {
    if (this.selectedComponents && this.selectedComponents.length > 0) {
      return this.selectedComponents.includes(item);
    }
  }

  setMultipleItemsSelection(checked: boolean, item: PackagingComponentDto) {
    if (checked) {
      this.selectedComponents.push(item);
    } else {
      const index = this.selectedComponents.indexOf(item);
      this.selectedComponents.splice(index, 1);
    }
    this.packagingPartsSelected.emit(this.selectedComponents);
  }

  setSingleItemSelection(checked: boolean, item: PackagingComponentDto) {
    if (checked) {
      this.selectedComponents = [item];
    } else {
      const index = this.selectedComponents.indexOf(item);
      this.selectedComponents.splice(index, 1);
    }
    this.packagingPartsSelected.emit(this.selectedComponents);
  }

  private openDeleteDialog(components: PackagingComponentDto[]) {
    const dialogData: DeleteDialogData = {
      dialogHeader: this.translateService.instant('component.deleteComponentsHeader'),
      dialogText: this.translateService.instant('component.deleteComponentsText')
    };
    const dialogRef = this.dialog.open(DeleteItemDialogComponent, getDialogConfig(dialogData, '500px'));
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.DELETE) {
        this.bulkDeleteComponents(components);
      }
    });
  }

  private bulkDeleteComponents(components: PackagingComponentDto[]) {
    this.spinner.show();
    const componentsGroupedByType: PackagingComponentDto[][] = Object.values(components.reduce((acc: any, item) => {
      acc[item.packagingComponentTypeId] = [...(acc[item.packagingComponentTypeId as keyof typeof acc] || []), item];
      return acc;
    }, {}));
    const observables = componentsGroupedByType.map(componentsOfCertainType => {
      const toDeleteDto = new DeletedEntitiesDto();
      const deleted: DeletedEntityDto[] = componentsOfCertainType.filter(x => x.id != null && x.version != null).map(x =>
        ({ id: x.id ?? -1, version: x.version ?? -1 }));
      toDeleteDto.deleted = deleted;
      return this.componentApiService.deletePackagingComponent(toDeleteDto, this.isRecyclingBinSelected, true);
    });
    this.deleteBackendSubscription = forkJoin(observables).subscribe(_ => {
      this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(this.getDeleteComponentSuccessData(), '500px'));
      this.dataSource.data = this.dataSource.data.filter(value => {
        return !components.map(x => x.id).includes(value.id);
      });
      this.spinner.hide();
    }, error => {
      if (error.status === 403) {
        this.showDeletionImpossibleDialog();
      } else {
        this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(this.getDeleteComponentErrorData(), '500px'));
      }
      this.spinner.hide();
    });
    this.resetSelection();
    this.packagingPartsSelected.emit(this.selectedComponents);
  }

  private getDeleteComponentSuccessData(): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.success'),
      messages: [this.translateService.instant('dataManagement.directory.dialog.removal.componentDeletedSuccess')], icon: 'info'
    };
  }

  private getDeleteComponentErrorData(): SimpleDialogData {
    return {
      title: this.translateService.instant('common.text.error'),
      messages: [this.translateService.instant('dataManagement.directory.dialog.removal.componentDeletedError')], icon: 'error'
    };
  }

  private showDeletionImpossibleDialog() {
    const data: SimpleDialogData = {
      title: this.translateService.instant('common.text.error'),
      messages: [this.translateService.instant('packagingUnit.messages.componentInUse')],
      icon: 'error'
    };
    const alertDialogConfig = getDialogConfig(data, '350px');
    this.dialog.open(SimpleAlertDialogComponent, alertDialogConfig);
  }

  getTagsSettingFunction(isEditing: boolean) {
    return (id: number, tagIds: number[]) => isEditing ?
      this.componentApiService.setComponentTags(id, tagIds) :
      this.componentApiService.addComponentTags(id, tagIds);
  }

  emptyRecyclingBin() {
    if (!this.isRecyclingBinSelected) {
      console.log('PackagingUnitsOverviewComponent: Recycling bin must be selected prior to emptying.');
      return;
    }
    this.bulkDeleteComponents(this.dataSource.data);
  }

  restorePackagingPart() {
    this.restorePackagingComponents(this.selectedComponents);
  }

  restoreAllPackagingComponents() {
    this.restorePackagingComponents(this.dataSource.data);
  }

  private restorePackagingComponents(components: PackagingComponentDto[]) {
    if (!this.isRecyclingBinSelected) {
      console.log('PackagingComponentsOverviewComponent: Recycling bin must be selected prior to restoring.');
      return;
    }
    for (const component of components) {
      this.dataSource.data = this.dataSource.data.filter(value => {
        return value.id !== component.id;
      });
      if (component.id == null || component.version == null) { return; }
      this.restoreSubscription = this.directoryApiService.restorePackagingComponentFromRecyclingBin(
        component.id, component.version).subscribe(_ => {
          window.location.reload();
        });
    }
  }

  movePackagingPart(rootFolder: DirectoryDto, unreachableFolders: DirectoryDto[]) {
    const data = { rootFolder, unreachableFolders };
    const dialogConfig = getDialogConfig(data, '500px');
    const dialogRef = this.dialog.open(SelectLocationDialogComponent, dialogConfig);
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.REJECT) { return; }
      const observables = this.selectedComponents.map(component => {
        if (component.id != null && component.version != null) {
          return this.directoryApiService.moveComponent(result.data.id, component.id, component.version);
        }
      });
      this.moveBackendSubscription = forkJoin(observables).subscribe(_ => this.dataChanged.emit());
    });
  }

  copyPackagingPart(rootFolder: DirectoryDto, unreachableFolders: DirectoryDto[]) {
    const data = { rootFolder, unreachableFolders };
    const dialogConfig = getDialogConfig(data, '500px');
    const dialogRef = this.dialog.open(SelectLocationDialogComponent, dialogConfig);
    this.dialogSubscription = dialogRef.afterClosed().subscribe(result => {
      if (result.event === DialogActions.REJECT) { return; }
      const observables = this.selectedComponents.map(component => {
        if (component.id != null && component.version != null) {
          return this.directoryApiService.copyComponent(result.data.id, component.id, component.version);
        }
      });
      this.copyBackendSubscription = forkJoin(observables).subscribe(_ => this.dataChanged.emit(), _ => this.spinner.hide());
    });
  }

  deletePackagingPart(itemsToDelete = this.selectedComponents) {
    const observables = itemsToDelete.map(component => {
      if (component.id != null) { return this.componentApiService.getComponentPermissions(component.id); }
    });
    this.permissionsSubscription = forkJoin(observables).subscribe((permissionsList: PermissionTypeDto[]) => {
      if (permissionsList.filter(x => x.delete === false).length > 0) {
        this.dialog.open(SimpleAlertDialogComponent, getDialogConfig(this.getDeleteNotAllowedData(this.translateService), '500px'));
      } else {
        this.openDeleteDialog(itemsToDelete);
      }
    });
  }

  exportPackagingPart() {
    this.spinner.show();
    const dialogConfig = getDialogConfig({ components: this.selectedComponents }, '50%');
    this.doExport(dialogConfig);
  }

  getComponentTypeImage(componentTypeId: number) {
    return this.componentTypeService.getComponentTypeImage(componentTypeId);
  }

  comparePackagingPart() {}

  editPackagingPartQuantity() {}

  resetSelection() {
    this.selectedComponents = [];
  }

  private async doExport(dialogConfig: MatDialogConfig) {
    const exportProfilesFunction = () => this.importExportApiService.getPackagingComponentExportProfiles();
    const exportFunction = (componentId: number, principalId: number, profileId?: number) =>
      this.importExportApiService.exportComponents(componentId, principalId, profileId);
    super.exportItems(dialogConfig, exportProfilesFunction, exportFunction);
    this.resetSelection();
  }
}
