import { PackagingSystemDto } from '../../packaging-system-entities/packaging-system-dto';
import { LifeCycleResultDto } from '../life-cycle-result-dto';
import { PackagingSystemEntryDto, PackagingUnitEntryDto } from './packaging-system-recyclability-result-dto';
import { PackagingUnitLifeCycleResultDto } from './packaging-unit-life-cycle-result-dto';

export class PackagingSystemLifeCycleResultDto extends LifeCycleResultDto {
  analyzedPackagingSystem!: PackagingSystemDto;
  packagingSystemResults!: PackagingSystemEntryLifeCycleResultDto[];
  packagingUnitResults!: PackagingUnitEntryLifeCycleResultDto[];
}

export class PackagingSystemEntryLifeCycleResultDto extends LifeCycleResultDto {
  analyzedPackagingSystemEntry!: PackagingSystemEntryDto;
  packagingSystemResult!: PackagingSystemLifeCycleResultDto;
}

export class PackagingUnitEntryLifeCycleResultDto extends LifeCycleResultDto {
  analyzedPackagingUnitEntry!: PackagingUnitEntryDto;
  packagingUnitResult!: PackagingUnitLifeCycleResultDto;
}