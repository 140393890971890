import { ComponentApiService } from '../services/component-api-service';
import { PackagingComponentDto } from '../entities/component-entities/packaging-component-dto';
import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { EMPTY, of } from 'rxjs';
import { PathResolveService } from 'src/app/services/path-resolve.service';
import { PermissionTypeDto } from '../entities/permission-type-dto';
import { PackagingPartTypeDto } from '../entities/packaging-part-type-dto';
import { AuthService } from 'src/app/services/auth-service';
import { ChangeText, OriginalText } from 'src/app/model/path-building-blocks';
import { PackagingUnitApiService } from '../services/packaging-unit-api-service';
import { ComponentSubtypeDto } from '../entities/component-subtype-dto';

export const componentPermissionsResolver: ResolveFn<PermissionTypeDto> = (route: ActivatedRouteSnapshot) => {
  const componentId = PathResolveService.getPackagingUnitIdFromRoute(route);
  return componentId != null ? inject(ComponentApiService).getComponentPermissions(componentId) : EMPTY;
};

export const componentResolver: ResolveFn<PackagingComponentDto> = (route: ActivatedRouteSnapshot) => {
  const id = PathResolveService.getPackagingUnitIdFromRoute(route);
  const type = PathResolveService.getComponentTypeFromRoute(route);
  const decorationType = PathResolveService.getDecorationTypeFromRoute(route);

  if (id != null && type) {
    const componentType = decorationType ? decorationType : type;
    return inject(ComponentApiService).getPackagingComponent(componentType, id);
  }
  return EMPTY;
};

export const componentHistoryResolver: ResolveFn<PackagingComponentDto> = (route: ActivatedRouteSnapshot) => {
  const componentId = PathResolveService.getPackagingUnitIdFromRoute(route);
  const type = PathResolveService.getComponentTypeFromRoute(route);
  const versionId = PathResolveService.getPackagingVersionIdFromRoute(route);
  const decorationType = PathResolveService.getDecorationTypeFromRoute(route);

  if (componentId != null && type && versionId != null) {
    const componentType = decorationType ? decorationType : type;
    return inject(ComponentApiService).getPackagingComponent(componentType, componentId, versionId);
  }
  return EMPTY;
};

export const componentTypesEngResolver: ResolveFn<PackagingPartTypeDto[]> = () => {
  return inject(ComponentApiService).getPackagingComponentTypesEnglish();
};

export const componentTypesResolver: ResolveFn<PackagingPartTypeDto[]> = () => {
  return inject(ComponentApiService).getPackagingComponentTypes();
};

export const closureSubtypesResolver: ResolveFn<ComponentSubtypeDto[]> = () => {
  return inject(ComponentApiService).getClosureSubtypes();
};

export const decorationSubtypesResolver: ResolveFn<ComponentSubtypeDto[]> = () => {
  return inject(ComponentApiService).getDecorationSubtypes();
};

export const decorationSubtypesEngResolver: ResolveFn<ComponentSubtypeDto[]> = () => {
  return inject(ComponentApiService).getDecorationSubtypesEnglish();
};

export const inlaySubtypesResolver: ResolveFn<ComponentSubtypeDto[]> = () => {
  return inject(ComponentApiService).getInlayTypes();
};

export const packagingAidSubtypesResolver: ResolveFn<ComponentSubtypeDto[]> = () => {
  return inject(ComponentApiService).getPackagingAidTypes();
};

export const mainBodySubtypesResolver: ResolveFn<PackagingPartTypeDto[]> = () => {
  return inject(PackagingUnitApiService).getPackagingUnitTypes();
};

export const lastViewedComponentsResolver: ResolveFn<PackagingComponentDto[]> = () => {
  if (!inject(AuthService).isUserValidator()) {
    return inject(ComponentApiService).getLastViewedComponents(8);
  } else {
    return of([]);
  }
};

export const changedMaterialsResolver: ResolveFn<number[]> = (route: ActivatedRouteSnapshot) => {
  const id = PathResolveService.getPackagingUnitIdFromRoute(route);
  return id != null ? inject(ComponentApiService).getMaterialChanges(id) : EMPTY;
};

export const componentPreviewResolver: ResolveFn<PackagingComponentDto> = (route: ActivatedRouteSnapshot) => {
  const candidateId: number = PathResolveService.getCandidateIdFromRoute(route);
  const type: string = PathResolveService.getComponentPreviewTypeFromRoute(route);
  if (candidateId == null || !type) { return EMPTY; }

  const url = route.url.map(segment => segment.toString());
  if (url.findIndex(x => x === ChangeText) > -1) {
    if (url.findIndex(y => y === OriginalText) > -1) {
      return resolveChange(candidateId, type, true);
    }
    return resolveChange(candidateId, type, false);
  } else {
    return resolveImport(candidateId, type);
  }
};

export const decorationPreviewResolver: ResolveFn<PackagingComponentDto> = (route: ActivatedRouteSnapshot) => {
  const candidateId: number = PathResolveService.getCandidateIdFromRoute(route);
  const type: string | undefined = PathResolveService.getDecorationTypeFromRoute(route);
  if (candidateId == null || !type) { return EMPTY; }

  const url = route.url.map(segment => segment.toString());
  if (url.findIndex(x => x === ChangeText) > -1) {
    if (url.findIndex(y => y === OriginalText) > -1) {
      return resolveChange(candidateId, type, true);
    }
    return resolveChange(candidateId, type, false);
  } else {
    return resolveImport(candidateId, type);
  }
};

const resolveImport = (candidateId: number, componentType: string) => {
  return inject(ComponentApiService).getComponentPreview(candidateId, componentType);
};

const resolveChange = (candidateId: number, componentType: string, isOriginal = false) => {
  return isOriginal ? inject(ComponentApiService).getPackagingComponent(componentType, candidateId) :
    inject(ComponentApiService).getComponentPreview(candidateId, componentType);
}