import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { BaseDataApiService } from 'src/app/data-transfer/services/base-data-api-service';

@Injectable({ providedIn: 'root' })
export class GtinCheckService {

  constructor(private baseDataApiService: BaseDataApiService) { }

  async checkGtinValidity(form: FormGroup) {
    const componentGtin = form.controls.gtin.value;
    if (!componentGtin) { return; }

    const gtinValid = await this.baseDataApiService.checkGtinValidity(componentGtin).toPromise();
    if (gtinValid === false) {
      form.controls.gtin.setErrors({ gtinError: true });
      form.updateValueAndValidity();
    }
  }
}
