import { MultiMaterialCompositeDto } from '../entities/material-entities/multi-material-composite-dto';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { PermissionTypeDto } from '../entities/permission-type-dto';
import { ParentApiService } from './parent-api-service';
import { VersionDto } from '../entities/version-dto';
import { MaterialDto } from '../entities/material-dto';
import { ManufacturingTypeDto } from '../entities/manufacturing-type-dto';
import { MaterialManifestationDto } from '../entities/material-manifestation-dto';
import { MaterialRecyclatePercentage } from '../entities/material-recyclate-percentage';
import { DeletedEntitiesDto } from '../entities/deleted-entities-dto';
import { catchError } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class MaterialApiService extends ParentApiService {

  constructor(private http: HttpClient) { super(); }

  public getValidMaterials(
    materialFunctionId: number,
    packagingComponentCategoryId: number,
    packagingUnitTypeId: number): Observable<MaterialDto[]> {
    const url = `${environment.webApiAddress}/api/materials/valid-materials?MaterialFunctionId=`
      + materialFunctionId + `&PackagingComponentCategoryId=`
      + packagingComponentCategoryId + `&PackagingTypeId=`
      + packagingUnitTypeId;
    return this.http.get<MaterialDto[]>(url);
  }

  public getValidManifestations(
    materialId: number, materialFunctionId: number, packagingComponentCategoryId: number, packagingUnitTypeId: number
  ): Observable<MaterialManifestationDto[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('materialId', materialId);
    params = params.set('materialFunctionId', materialFunctionId);
    params = params.set('packagingComponentCategoryId', packagingComponentCategoryId);
    params = params.set('packagingTypeId', packagingUnitTypeId);
    const url = `${environment.webApiAddress}/api/material-manifestations/by-parameters`;
    return this.http.get<MaterialManifestationDto[]>(url, { params });
  }

  public getValidManufacturingTypes(
    manifestationId: number, materialFunctionId: number, packagingComponentCategoryId: number, packagingUnitTypeId: number
  ): Observable<ManufacturingTypeDto[]> {
    let params: HttpParams = new HttpParams();
    params = params.set('materialManifestationId', manifestationId);
    params = params.set('materialFunctionId', materialFunctionId);
    params = params.set('packagingComponentCategoryId', packagingComponentCategoryId);
    params = params.set('packagingTypeId', packagingUnitTypeId);
    const url = `${environment.webApiAddress}/api/materials/manufacturing-processes`;
    return this.http.get<ManufacturingTypeDto[]>(url, { params });
  }

  public getRecyclateRercentagesForCountry(countryCode?: string): Observable<MaterialRecyclatePercentage[]> {
    const url = `${environment.webApiAddress}/api/materials/country-specific-material-settings?country=${countryCode}`;
    return this.http.get<MaterialRecyclatePercentage[]>(url);
  }

  public getAllCompositeMaterials(): Observable<MultiMaterialCompositeDto[]> {
    const url = `${environment.webApiAddress}/api/multi-materials`;
    return this.http.get<MultiMaterialCompositeDto[]>(url);
  }

  public putCompositeMaterial(
    material: MultiMaterialCompositeDto, shouldCreateNewVersion = true, stayOnPageIfError?: boolean
  ): Observable<any> {
    const url = `${environment.webApiAddress}/api/multi-materials?overwriteExistingVersion=${!shouldCreateNewVersion}`;
    console.log(JSON.stringify(material));
    return this.http.put<any>(url, material,
      { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

  public setCompositeMaterialTags(id: number, tags: number[], stayOnPageIfError?: boolean): Observable<any> {
    const url = `${environment.webApiAddress}/api/multi-materials/set-tags`;
    const params = new HttpParams().set('multiMaterialId', id);
    return this.http.put<any>(url, tags, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public addCompositeMaterialTags(id: number, tags: number[], stayOnPageIfError?: boolean): Observable<any> {
    const url = `${environment.webApiAddress}/api/multi-materials/add-tags`;
    const params = new HttpParams().set('multiMaterialId', id);
    return this.http.put<any>(url, tags, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public deleteCompositeMaterials(
    idsToDelete: DeletedEntitiesDto, deletePermanently: boolean, stayOnPageIfError?: boolean): Observable<any> {
    const url = `${environment.webApiAddress}/api/multi-materials?deletePermanently=${deletePermanently}`;
    return this.http.request('delete', url, {
      body: idsToDelete,
      headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader
    });
  }

  public getCompositeMaterial(materialId: number, versionId?: number): Observable<MultiMaterialCompositeDto> {
    let url = `${environment.webApiAddress}/api/multi-materials/by-id?id=${materialId}`;
    if (versionId != null) {
      url = url.concat(`&version=${versionId}`);
    }
    return this.http.get<MultiMaterialCompositeDto>(url);
  }

  public getCompositeMaterialHistoryVersions(materialId: number) {
    const url = `${environment.webApiAddress}/api/multi-materials/history?id=${materialId}`;
    return this.http.get<VersionDto[]>(url);
  }

  public getMaterialPermissions(materialId: number): Observable<PermissionTypeDto> {
    const url = `${environment.webApiAddress}/api/Permissions/effective-multi-material-permissions?multiMaterialId=${materialId}`;
    return this.http.get<PermissionTypeDto>(url);
  }

  public lockCompositeMaterial(materialIdId: number, stayOnPageIfError: boolean): Observable<any> {
    const url = `${environment.webApiAddress}/api/multi-materials/${materialIdId}/lock`;
    return this.http.get<any>(url, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader });
  }

  public releaseCompositeMaterial(materialIdId: number, stayOnPageIfError: boolean): Observable<any> {
    const url = `${environment.webApiAddress}/api/multi-materials/${materialIdId}/release`;
    return this.http.get<any>(url, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader })
      .pipe(catchError(this.handleError));
  }

  public getLastViewedMaterials(count: number): Observable<MultiMaterialCompositeDto[]> {
    const url = `${environment.webApiAddress}/api/multi-materials/latest?count=${count}`;
    return this.http.get<MultiMaterialCompositeDto[]>(url);
  }

  public renameCompositeMaterial(id: number, version: number, name: string, stayOnPageIfError?: boolean): Observable<any> {
    const url = `${environment.webApiAddress}/api/multi-materials/rename-version`;
    let params = new HttpParams().set('id', id);
    params = params.set('version', version);
    params = params.set('name', name);
    return this.http.put<any>(url, {}, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public getMaterialPreview(materialId: number): Observable<MultiMaterialCompositeDto> {
    const url = `${environment.webApiAddress}/api/multi-materials/by-import-candidate?importCandidateId=${materialId}`;
    return this.http.get<MultiMaterialCompositeDto>(url);
  }

  public getSubmaterialChanges(materialId: number): Observable<number[]> {
    const url = `${environment.webApiAddress}/api/multi-materials/sub-multi-material-changes?multiMaterialId=${materialId}`;
    return this.http.get<number[]>(url);
  }

  public acceptSubmaterialChange(materialId: number, submaterialId: number, shouldCreateNewVersion = true, stayOnPageIfError?: boolean) {
    let params: HttpParams = new HttpParams();
    params = params.set('multiMaterialId', materialId);
    params = params.set('subMultiMaterialId', submaterialId);
    params = params.set('overwriteExistingVersion', !shouldCreateNewVersion);
    const url = `${environment.webApiAddress}/api/multi-materials/adopt-sub-multi-material-changes`;
    return this.http.put<any>(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }

  public declineSubmaterialChange(materialId: number, submaterialId: number, stayOnPageIfError?: boolean) {
    let params: HttpParams = new HttpParams();
    params = params.set('multiMaterialId', materialId);
    params = params.set('subMultiMaterialId', submaterialId);
    const url = `${environment.webApiAddress}/api/multi-materials/ignore-sub-multi-material-changes`;
    return this.http.put<any>(url, null, { headers: stayOnPageIfError ? this.stayOnPageIfErrorHeader : this.emptyHeader, params });
  }
}
